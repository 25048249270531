import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import MenuIcon from '@jetbrains/icons/menu';
import CloseIcon from '@jetbrains/icons/close';
import Icon from '@jetbrains/ring-ui/components/icon';
import Button from '@jetbrains/ring-ui/components/button/button';
import { MenuItems } from './menu-items';
import styles from './navigation-menu.css';
export function MobileNavbar() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const handleNavOpen = () => {
        setIsNavOpen(!isNavOpen);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Icon, { glyph: MenuIcon, onClick: handleNavOpen, className: styles.hamburgerIcon }), isNavOpen && (_jsxs("div", { className: styles.overlay, children: [_jsx(MenuItems, { variant: "mobile", onLinkClick: handleNavOpen }), _jsx(Button, { icon: CloseIcon, onClick: handleNavOpen })] }))] }));
}
