import { getApiError } from '../../../api/errors';
export class PluginsMutationError extends Error {
    constructor(message, mutationResult) {
        super(message);
        this.mutationResult = mutationResult;
    }
}
export function buildDeletePluginVersionsErrorMessage(error, versionsToDelete) {
    const messages = [];
    const maxAmount = 3;
    for (let i = 0; i < versionsToDelete.length; i += 1) {
        const input = versionsToDelete[i].version;
        const output = error[i];
        if (output.status === 'rejected') {
            const msg = getApiError(output.reason).errorMessage || 'Unknown error';
            messages.push(`"${input}": ${msg}`);
        }
        if (messages.length > maxAmount) {
            break;
        }
    }
    const prefix = messages.length === 1
        ? 'Failed delete 1 plugin version'
        : `Failed to delete ${messages.length} plugin versions`;
    return `${prefix}\n${messages.join('\n')}`;
}
export function buildDeletePluginVersionsSuccessMessage(versions) {
    return versions.length === 1
        ? `1 plugin version has been deleted`
        : `${versions.length} plugin versions have been deleted`;
}
