import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import classNames from 'classnames';
import Code from '@jetbrains/ring-ui/components/code/code';
import copyIcon from '@jetbrains/icons/copy';
import downloadIcon from '@jetbrains/icons/download';
import Button, { IconSize } from '@jetbrains/ring-ui/components/button/button';
import { Size as CopyFieldSize } from '@jetbrains/ring-ui/components/input/input';
import { assignTestId } from '@common/infra/tests/assign-test-id';
// eslint-disable-next-line no-restricted-imports
import { downloadBlob } from '@common/util/blob';
import { copyToClipboard } from '../copy-to-clipboard';
import styles from './copied-code.css';
export { CopyFieldSize };
export default function CopiedCode(props) {
    const { text, className, inline = false, softWrap = false, size = CopyFieldSize.FULL, disabled = false, downloadFilename } = props;
    const classes = classNames(styles.container, {
        [styles.containerSizeFull]: size === CopyFieldSize.FULL,
        [styles.containerSizeAuto]: size === CopyFieldSize.AUTO,
        [styles.containerSizeS]: size === CopyFieldSize.S,
        [styles.containerSizeM]: size === CopyFieldSize.M,
        [styles.containerSizeL]: size === CopyFieldSize.L,
        [styles.disabled]: disabled
    }, className);
    const copy = useCallback(() => {
        if (!disabled) {
            copyToClipboard(text);
        }
    }, [text, disabled]);
    const handleDownload = useCallback((e) => {
        e.stopPropagation();
        downloadBlob(new Blob([text]), downloadFilename);
    }, [text, downloadFilename]);
    return (_jsxs("div", { role: "code", className: classes, onClick: copy, title: "Copy to clipboard", ...assignTestId('code-wrapper', props), children: [_jsx(Code, { softWrap: softWrap, code: text, className: classNames(styles.text, { [styles.inlineText]: inline }) }), !disabled && (_jsxs("div", { className: styles.actions, children: [_jsx(Button, { title: "Copy to clipboard", className: styles.copyBtn, iconSize: IconSize.Size14, icon: copyIcon }), downloadFilename && (_jsx(Button, { className: styles.downloadBtn, title: `Download ${downloadFilename}`, iconSize: IconSize.Size14, icon: downloadIcon, onClick: handleDownload }))] }))] }));
}
