import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import { useGetUsersForGroup } from '@app/common/queries/groups';
import NavigationLink from '@app/common/navigation/navigation-link';
import linkStyles from '@common/navigation/navigation-link.css';
import styles from './group-details.css';
const showUsersNumber = 10;
export function GroupDetails({ groupId }) {
    const { data, isLoading } = useGetUsersForGroup(groupId, showUsersNumber);
    if (isLoading) {
        return _jsx(LoaderInline, {});
    }
    return (_jsx("div", { className: styles.groupDetails, children: (data === null || data === void 0 ? void 0 : data.total) === 0 ? (_jsx(NavigationLink, { href: `/users?groupId=${groupId}`, target: "_blank", className: linkStyles.linkSecondary, children: "No users in this group" })) : (_jsxs(_Fragment, { children: [((data === null || data === void 0 ? void 0 : data.items) || []).slice(0, showUsersNumber).map(user => (_jsx("div", { children: _jsx(NavigationLink, { href: `/users/${user.id}`, target: "_blank", children: user.username }) }, `group-user-${user.id}`))), _jsxs(NavigationLink, { href: `/users?groupId=${groupId}`, target: "_blank", className: linkStyles.linkSecondary, children: [(data === null || data === void 0 ? void 0 : data.total) && (data === null || data === void 0 ? void 0 : data.total) > showUsersNumber
                            ? `View all ${data === null || data === void 0 ? void 0 : data.total} users in this group`
                            : 'Expand', ' '] })] })) }));
}
