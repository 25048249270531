// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createTokenBtn__f29 {
  margin-top: calc(var(--ring-unit) * 3);
  display: block;
}

.tokens__f8b {
  margin-top: calc(var(--ring-unit) * 1.5);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/automation-tokens/automation-tokens.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,cAAc;AAChB;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".createTokenBtn {\n  margin-top: calc(var(--ring-unit) * 3);\n  display: block;\n}\n\n.tokens {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createTokenBtn": `createTokenBtn__f29`,
	"tokens": `tokens__f8b`
};
export default ___CSS_LOADER_EXPORT___;
