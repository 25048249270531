import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import DropdownMenu from '@jetbrains/ring-ui/components/dropdown-menu/dropdown-menu';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import { noop } from 'lodash';
import chevronDownIcon from '@jetbrains/icons/chevron-down';
import Icon from '@jetbrains/ring-ui/components/icon';
import auth from '@common/auth/auth';
import { useSynchronizeLicense } from '@common/queries/tbe-license';
import { useOpenModal } from '@common/modals/hooks';
import { formatDateString } from '@common/dates/format';
import HorizontalContainer from '@common/page-layout/horizontal-container';
import { LicenseGenerateNewPublicKeyDialog, LicensePublicKeyDialog } from './public-key-dialogs';
import styles from './license-details.css';
export function LicenseDetailsActionButtons({ licenseData }) {
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    const cloudModeEnabled = !!auth.getFeatures().cloudModeEnabled;
    const { mutateAsync: syncLicenseMutation, isLoading: isSyncing } = useSynchronizeLicense();
    const generateNewPublicKey = useOpenModal(LicenseGenerateNewPublicKeyDialog, {});
    const viewPublicKey = useOpenModal(LicensePublicKeyDialog, {
        licenseData
    });
    const handleSynchroniseLicense = useCallback(() => {
        syncLicenseMutation().catch(noop);
    }, [syncLicenseMutation]);
    const publicKeyActions = useMemo(() => {
        const options = (licenseData === null || licenseData === void 0 ? void 0 : licenseData.publicKey)
            ? [
                {
                    key: 'view-public-key',
                    label: 'View public key...',
                    onClick: viewPublicKey
                }
            ]
            : [];
        if (cloudModeEnabled) {
            return options;
        }
        return options.concat([
            {
                key: 'regenerate-public-key',
                label: 'Regenerate public key...',
                className: styles.danger,
                async onClick() {
                    await generateNewPublicKey();
                }
            }
        ]);
    }, [licenseData === null || licenseData === void 0 ? void 0 : licenseData.publicKey, viewPublicKey, generateNewPublicKey, cloudModeEnabled]);
    const handleClick = (action, e) => {
        if (action === null || action === void 0 ? void 0 : action.onClick) {
            action.onClick(action, e);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(HorizontalContainer, { className: styles.buttonsContainer, children: [publicKeyActions.length === 1 ? (_jsx(Button, { onClick: e => handleClick(publicKeyActions[0], e), children: publicKeyActions[0].label })) : (_jsx(DropdownMenu, { data: publicKeyActions, anchor: ({ active }) => (_jsxs(Button, { active: active, children: ["Public key ", _jsx(Icon, { glyph: chevronDownIcon })] })) })), _jsx(Button, { disabled: isSyncing || offlineModeEnabled, onClick: handleSynchroniseLicense, children: "Refresh license" }), licenseData.lastRefreshedDate && (_jsxs(Text, { info: true, size: "s", children: ["Last refreshed on ", formatDateString(licenseData.lastRefreshedDate, 'd MMM yyyy')] }))] }), ((licenseData === null || licenseData === void 0 ? void 0 : licenseData.status) === 'VALIDATING' || isSyncing) && (_jsx("div", { children: _jsx(LoaderInline, { children: "JetBrains IDE Services is currently updating your license details." }) }))] }));
}
