// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title__be6 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--ring-unit) / 2);
}

.loader__f86 {
  margin-right: var(--ring-unit);
}

.description__a24 {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badge__cf3.badge__cf3 {
  background-color: var(--ring-added-background-color);
  color: var(--ring-success-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profiles-admin-page/profiles-admin-body.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,+BAA+B;AACjC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,oDAAoD;EACpD,gCAAgC;AAClC","sourcesContent":[".title {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: calc(var(--ring-unit) / 2);\n}\n\n.loader {\n  margin-right: var(--ring-unit);\n}\n\n.description {\n  max-width: 350px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.badge.badge {\n  background-color: var(--ring-added-background-color);\n  color: var(--ring-success-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `title__be6`,
	"loader": `loader__f86`,
	"description": `description__a24`,
	"badge": `badge__cf3`
};
export default ___CSS_LOADER_EXPORT___;
