import { jsx as _jsx } from "react/jsx-runtime";
import { isNil } from 'lodash';
import { parseAuditId } from '@api/audit-utils';
import NavigationLink from '@app/common/navigation/navigation-link';
export function AutomationTokenAuthor(props) {
    const { token } = props;
    const { createdBy } = token;
    // fixme: hack! need proper backend model, may broke when token would be created by some automation
    const userWithId = parseAuditId(createdBy);
    if (isNil(userWithId)) {
        return _jsx("span", { children: "System" });
    }
    return (_jsx(NavigationLink, { href: `/users/${userWithId.userId}`, children: userWithId.userName }));
}
