import { createEnumParam, NumberParam, StringParam } from 'serialize-query-params';
import { pagingSortOrder } from '@api/paging-utils';
import { useSyncedSearchArgs } from '@common/navigation/use-synced-search-args';
import { sortableColumns } from './constanst';
export function usePluginAuditLogsParams() {
    return useSyncedSearchArgs(tsTypeToQuerySchema, validatePluginAuditLogsFilter);
}
function validatePluginAuditLogsFilter(parsed) {
    var _a, _b, _c;
    return {
        filter: (_a = parsed.filter) !== null && _a !== void 0 ? _a : null,
        page: parsed.page && parsed.page > 0 ? parsed.page : 1,
        sortOrder: (_b = parsed.sortOrder) !== null && _b !== void 0 ? _b : 'DESC',
        sortKey: (_c = parsed.sortKey) !== null && _c !== void 0 ? _c : 'createdDate'
    };
}
const tsTypeToQuerySchema = {
    version: StringParam,
    page: NumberParam,
    sortKey: createEnumParam(Object.values(sortableColumns)),
    sortOrder: createEnumParam(pagingSortOrder)
};
