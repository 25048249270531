// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label__f6e {
  padding-left: calc(var(--ring-unit) / 4);
  color: var(--ring-secondary-color);
}

.label__f6e + .selector__f01 {
  margin-top: calc(var(--ring-unit) / 4);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ide-provisioner/application-settings/app-build-selector/selectors/selector.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,kCAAkC;AACpC;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".label {\n  padding-left: calc(var(--ring-unit) / 4);\n  color: var(--ring-secondary-color);\n}\n\n.label + .selector {\n  margin-top: calc(var(--ring-unit) / 4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `label__f6e`,
	"selector": `selector__f01`
};
export default ___CSS_LOADER_EXPORT___;
