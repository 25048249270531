// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorMessage__ee7 {
  display: flex;
  align-items: center;
  flex-direction: row;

  max-width: 600px;
  height: 100%;
}

.title__f29 {
  font-size: 18px;
}

.content__a55 {
  flex-direction: column;

  color: var(--ring-text-color);
}

.content__a55 > .title__f29,
.description__d79 {
  margin-bottom: 16px;
}

.icon__c53 {
  margin: 0 16px 24px 0;

  color: var(--ring-icon-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/common/errors/submit-error.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;;EAEnB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;;EAEtB,6BAA6B;AAC/B;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;;EAErB,uCAAuC;AACzC","sourcesContent":[".errorMessage {\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n\n  max-width: 600px;\n  height: 100%;\n}\n\n.title {\n  font-size: 18px;\n}\n\n.content {\n  flex-direction: column;\n\n  color: var(--ring-text-color);\n}\n\n.content > .title,\n.description {\n  margin-bottom: 16px;\n}\n\n.icon {\n  margin: 0 16px 24px 0;\n\n  color: var(--ring-icon-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorMessage": `errorMessage__ee7`,
	"title": `title__f29`,
	"content": `content__a55`,
	"description": `description__d79`,
	"icon": `icon__c53`
};
export default ___CSS_LOADER_EXPORT___;
