import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { MIN_LIST_SIZE } from '@api/paging-utils';
import { addProfileToUsers, createUser, getCurrentUser, getUnassignedUsersTotal, getUser, getUserAuditLogs, getUserAvailableApps, getUserBuildSettings, getUsersAndTotal, patchUser, revokeUserToken, unAssignUsersFromProfile } from '@api/users';
import { formatApiError } from '@api/errors';
import queryClient, { DEFAULT_STALE_TIME } from './query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from './formatters/alerts';
import { invalidateAllQueries } from './functions';
export const keys = {
    all: ['users'],
    count: ['users', 'count'],
    me: ['users', 'me'],
    infinite: (filters) => ['users', 'infinite', { filters }],
    list: (filters) => ['users', 'list', { filters }],
    details: () => ['users', 'detail'],
    detail: (id) => ['users', 'detail', id],
    detailCtx: (id) => ({
        applications: ['users', 'detail', id, 'applications']
    }),
    auditLogs: (id, params) => ['users', 'details', 'auditLogs', id, { params }],
    buildSettings: (userId, buildInfo) => ['app', 'build', userId, buildInfo]
};
const usersDetailQuery = (id) => ({
    queryKey: keys.detail(id),
    queryFn: () => getUser(id),
    enabled: !!id
});
export function useUsers(params) {
    return useQuery({
        queryKey: keys.list(params),
        queryFn: () => getUsersAndTotal(params, undefined),
        staleTime: DEFAULT_STALE_TIME,
        keepPreviousData: true,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load users'))
    });
}
export function useUnassignedUsersCount() {
    return useQuery({
        queryKey: keys.count,
        queryFn: () => getUnassignedUsersTotal(),
        staleTime: DEFAULT_STALE_TIME,
        keepPreviousData: true,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load users'))
    });
}
export function ensureUserDetailsQueryData(id) {
    return queryClient.ensureQueryData({ ...usersDetailQuery(id) });
}
export function useUser(id) {
    return useQuery({
        ...usersDetailQuery(id),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load user'))
    });
}
// fixme: move from users to idep?
export function useUserApplications(userId) {
    return useQuery({
        queryKey: keys.detailCtx(userId).applications,
        queryFn: () => getUserAvailableApps(userId),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load applications'))
    });
}
export function useApplicationBuildSettings(userId, buildInfo) {
    return useQuery({
        queryKey: keys.buildSettings(userId, buildInfo),
        queryFn: () => getUserBuildSettings(userId, buildInfo),
        staleTime: 0,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load build settings'))
    });
}
const onUserPatchSuccess = async (data) => {
    const detailKey = keys.detail(data.id);
    queryClient.setQueryData(detailKey, data);
    await invalidateAllQueries(queryKey => {
        return !isEqual(queryKey, detailKey);
    });
    return data;
};
const formatUsername = (username = '(empty username)') => username;
export function usePatchUserMutation() {
    return useMutation({
        mutationFn: ({ id, ...user }) => patchUser(id, user),
        onSuccess: onUserPatchSuccess,
        meta: getMutationAlertMessages((error, { username }) => formatApiError(error, `Failed to update user '${formatUsername(username)}'`), ({ username }) => `User '${formatUsername(username)}' updated`)
    });
}
export function useActiveStatusUserMutation() {
    return useMutation({
        mutationFn: ({ id, ...user }) => patchUser(id, user),
        onSuccess: onUserPatchSuccess,
        meta: getMutationAlertMessages((error, { username }) => formatApiError(error, `Failed to update user '${username}'`), ({ username, isActive }) => isActive
            ? `User '${formatUsername(username)}' is now active`
            : `User '${formatUsername(username)}' is deactivated`)
    });
}
export function useCreateUserMutation() {
    return useMutation({
        mutationFn: (userData) => createUser({
            ...userData,
            email: userData.email ? userData.email : undefined
        }),
        onSuccess: async (data) => {
            const detailKey = keys.detail(data.id);
            const allDetailsKey = keys.details();
            queryClient.setQueryData(detailKey, data);
            await invalidateAllQueries(queryKey => {
                return !isEqual(queryKey.slice(0, allDetailsKey.length), allDetailsKey);
            });
        },
        meta: getMutationAlertMessages((error, vars) => formatApiError(error, `Failed to create user '${vars.username || '(empty username)'}'`), (_data, vars) => `User '${vars.username || '(empty username)'}' created`)
    });
}
export function useAddProfileToUsersMutation(profileName, userNames) {
    return useMutation({
        mutationFn: ({ users, profileId }) => addProfileToUsers(users, profileId),
        onSuccess: async () => {
            await invalidateAllQueries();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, `Failed to assign profile ${profileName || ''} to ${userNames
            ? `${userNames.length === 1 ? 'user' : 'users'} ${userNames === null || userNames === void 0 ? void 0 : userNames.join(' ,')}.`
            : 'user.'}`), `Profile ${profileName || ''} assigned to ${userNames
            ? `${userNames.length === 1 ? 'user' : 'users'} ${userNames === null || userNames === void 0 ? void 0 : userNames.join(' ,')}.`
            : 'user.'}`)
    });
}
export function useUnAssignUsersMutation() {
    return useMutation({
        mutationFn: ({ profileId, usersList }) => unAssignUsersFromProfile(profileId, usersList),
        onSuccess: async () => {
            await invalidateAllQueries();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to remove assigned profile'), response => `${Array.isArray(response) && response.length === 1 ? 'User' : 'Users'} have been successfully removed from Profile`)
    });
}
const getPageParam = (pageParam) => ({
    page: pageParam,
    sortKey: 'username',
    sortOrder: 'ASC',
    limit: 10
});
export function useInfiniteUsersQuery(filters, enabled = true) {
    return useInfiniteQuery({
        enabled,
        queryKey: keys.infinite(filters),
        queryFn: ({ pageParam = 1 }) => getUsersAndTotal({ ...getPageParam(pageParam), ...filters }, undefined),
        getNextPageParam: (lastPage, allPages) => {
            const { total } = lastPage;
            const current = allPages.flatMap(({ users }) => users).length;
            if (total > current) {
                return current / MIN_LIST_SIZE + 1;
            }
            return undefined;
        },
        staleTime: DEFAULT_STALE_TIME,
        keepPreviousData: true,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load users'))
    });
}
export function fetchUserData(id) {
    return queryClient.fetchQuery({ ...usersDetailQuery(id) });
}
export function useRevokeUserToken(id) {
    return useMutation({
        mutationFn: () => revokeUserToken(id),
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to revoke user token'), () => 'User token has been revoked')
    });
}
export function useUserAuditLogs(userId, params) {
    return useQuery({
        queryKey: keys.auditLogs(userId, params),
        queryFn: () => getUserAuditLogs(userId, params),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load change history'))
    });
}
export function useCurrentUserPolling(enabled) {
    return useQuery({
        queryFn: getCurrentUser,
        enabled,
        refetchInterval: data => ((data === null || data === void 0 ? void 0 : data.serverActivated) === true ? 1000 : false),
        refetchIntervalInBackground: true
    });
}
