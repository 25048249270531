// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__d42 {
  .header__a9a {
    margin-bottom: 0;
  }

  .configuration__bd7 {
    margin-top: calc(var(--ring-unit) * 3);
  }

  .appSelector__ab5 {
    margin-top: calc(var(--ring-unit) * 3);
  }

  .header__a9a + .unavailableNote__c49 {
    margin-top: calc(var(--ring-unit) * 3);
  }

  .appSelector__ab5 + .unavailableNote__c49 {
    margin-top: calc(var(--ring-unit) * 3);
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/ide-provisioner/application-settings/application-settings.css"],"names":[],"mappings":"AAAA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,sCAAsC;EACxC;;EAEA;IACE,sCAAsC;EACxC;;EAEA;IACE,sCAAsC;EACxC;;EAEA;IACE,sCAAsC;EACxC;AACF","sourcesContent":[".wrapper {\n  .header {\n    margin-bottom: 0;\n  }\n\n  .configuration {\n    margin-top: calc(var(--ring-unit) * 3);\n  }\n\n  .appSelector {\n    margin-top: calc(var(--ring-unit) * 3);\n  }\n\n  .header + .unavailableNote {\n    margin-top: calc(var(--ring-unit) * 3);\n  }\n\n  .appSelector + .unavailableNote {\n    margin-top: calc(var(--ring-unit) * 3);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__d42`,
	"header": `header__a9a`,
	"configuration": `configuration__bd7`,
	"appSelector": `appSelector__ab5`,
	"unavailableNote": `unavailableNote__c49`
};
export default ___CSS_LOADER_EXPORT___;
