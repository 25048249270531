// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productLogo__f77 {
  width: calc(var(--ring-unit) * 4);
}

.productLogo__f77 + .appInfo__db3 {
  margin-left: var(--ring-unit);
}

.appVersion__bbf {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);

  color: var(--ring-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/modules/applications/applications-list/application-list-item/application-list-item.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,wCAAwC;EACxC,2CAA2C;;EAE3C,kCAAkC;AACpC","sourcesContent":[".productLogo {\n  width: calc(var(--ring-unit) * 4);\n}\n\n.productLogo + .appInfo {\n  margin-left: var(--ring-unit);\n}\n\n.appVersion {\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n\n  color: var(--ring-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productLogo": `productLogo__f77`,
	"appInfo": `appInfo__db3`,
	"appVersion": `appVersion__bbf`
};
export default ___CSS_LOADER_EXPORT___;
