import { apiClientManager } from '@common/api';
export async function fetchAgreementJson() {
    const r = await apiClientManager
        .getClient()
        .get('/api/transparency/agreement');
    return r.data;
}
export async function uploadNewAgreement(newFile) {
    const data = new FormData();
    data.append('file', newFile);
    const response = await apiClientManager.getClient().post('/api/transparency/agreement', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}
export async function resetAgreement() {
    const response = await apiClientManager.getClient().delete('/api/transparency/agreement');
    return response.data;
}
