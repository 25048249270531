import { useQuery } from '@tanstack/react-query';
import { fetchBundledPlugins } from '../../api/marketplace';
// eslint-disable-next-line import/no-restricted-paths
import { formatApiError } from '../../api/errors';
import { getPlugin } from './plugins';
import { getQueryAlertMessages } from './formatters/alerts';
export const keys = {
    plugins: ['bundled-plugins']
};
export function useBundledPlugins() {
    return useQuery({
        queryKey: keys.plugins,
        queryFn: () => fetchBundledPlugins().then(response => response.map(plugin => getPlugin(plugin.xmlId, 'MARKETPLACE', plugin.name))),
        placeholderData: [],
        staleTime: Infinity,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load bundled plugins'))
    });
}
