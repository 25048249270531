// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title__ace {
  padding: 0;
}

.title__ace svg {
  transition: transform 0.7s;
  margin: 0 -4px;
}

.titleExpanded__f63 svg {
  transform: rotate(90deg);
  transition: transform 0.4s;
}
`, "",{"version":3,"sources":["webpack://./src/common/collapsible/collapsible.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,wBAAwB;EACxB,0BAA0B;AAC5B","sourcesContent":[".title {\n  padding: 0;\n}\n\n.title svg {\n  transition: transform 0.7s;\n  margin: 0 -4px;\n}\n\n.titleExpanded svg {\n  transform: rotate(90deg);\n  transition: transform 0.4s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `title__ace`,
	"titleExpanded": `titleExpanded__f63`
};
export default ___CSS_LOADER_EXPORT___;
