import { isApiError } from '@api/is-api-error';
import { apiClientManager } from '@common/api';
export async function getAiState() {
    var _a, _b;
    const serviceClient = apiClientManager.getClient();
    try {
        const res = await serviceClient.get('/api/constituents/ai');
        return { status: 'enabled', data: res.data };
    }
    catch (e) {
        if (isApiError(e)) {
            if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                return { status: 'disabled' };
            }
            if (((_b = e.response) === null || _b === void 0 ? void 0 : _b.status) === 403) {
                return { status: 'disabled_jba' };
            }
        }
        throw e;
    }
}
export async function enableAi(params) {
    const serviceClient = apiClientManager.getClient();
    const res = await serviceClient.post('/api/constituents/ai', params);
    return { status: 'enabled', data: res.data };
}
export async function changeAiSettings(params) {
    const serviceClient = apiClientManager.getClient();
    const res = await serviceClient.put('/api/constituents/ai', params);
    return { status: 'enabled', data: res.data };
}
export async function disableAi() {
    await apiClientManager.getClient().delete('/api/constituents/ai');
    return { status: 'disabled' };
}
export async function getProviderInfo(provider) {
    const res = await apiClientManager.getClient().get(`/api/constituents/ai/providers/${provider}`);
    return res.data;
}
export async function deleteProvider(provider, replaceBy) {
    const res = await apiClientManager
        .getClient()
        .delete(`/api/constituents/ai/providers/${provider}`, { params: { 'replace-by': replaceBy } });
    return res.data;
}
