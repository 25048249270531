// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linksWrapper__a71 {
  display: flex;
  gap: calc(var(--ring-unit) * 3);
}

.mobileLinksWrapper__e55 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 3);
}

.defaultMenuWrapper__bba {
  margin-left: calc(var(--ring-unit) * 3);
}

.linkContainer__e0b {
  width: fit-content;
}

.hamburgerIcon__c02 {
  cursor: pointer;
  margin-left: calc(var(--ring-unit) * 3);
  align-self: center;
}

.overlay__fa7 {
  z-index: 100;
  width: 100%;
  height: calc(100vh - var(--header-size));
  background-color: var(--ring-navigation-background-color);
  position: absolute;
  top: var(--header-size);
  left: 0;
  box-sizing: border-box;
  padding: calc(var(--ring-unit) * 3) calc(var(--ring-unit) * 3);

  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/common/app-layout/app-header/navigation/navigation-menu.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,uCAAuC;EACvC,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,wCAAwC;EACxC,yDAAyD;EACzD,kBAAkB;EAClB,uBAAuB;EACvB,OAAO;EACP,sBAAsB;EACtB,8DAA8D;;EAE9D,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".linksWrapper {\n  display: flex;\n  gap: calc(var(--ring-unit) * 3);\n}\n\n.mobileLinksWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 3);\n}\n\n.defaultMenuWrapper {\n  margin-left: calc(var(--ring-unit) * 3);\n}\n\n.linkContainer {\n  width: fit-content;\n}\n\n.hamburgerIcon {\n  cursor: pointer;\n  margin-left: calc(var(--ring-unit) * 3);\n  align-self: center;\n}\n\n.overlay {\n  z-index: 100;\n  width: 100%;\n  height: calc(100vh - var(--header-size));\n  background-color: var(--ring-navigation-background-color);\n  position: absolute;\n  top: var(--header-size);\n  left: 0;\n  box-sizing: border-box;\n  padding: calc(var(--ring-unit) * 3) calc(var(--ring-unit) * 3);\n\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linksWrapper": `linksWrapper__a71`,
	"mobileLinksWrapper": `mobileLinksWrapper__e55`,
	"defaultMenuWrapper": `defaultMenuWrapper__bba`,
	"linkContainer": `linkContainer__e0b`,
	"hamburgerIcon": `hamburgerIcon__c02`,
	"overlay": `overlay__fa7`
};
export default ___CSS_LOADER_EXPORT___;
