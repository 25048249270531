import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Selection from '@jetbrains/ring-ui/components/table/selection';
import { BasicTableView } from './basic-table-view';
function useSynchronizedRef(item) {
    const itemRef = useRef(item);
    useEffect(() => {
        itemRef.current = item;
    }, [item]);
    return itemRef;
}
function useTableSelection(data, selection, onSelect) {
    const [tableSelection, setTableSelection] = useState(selection || new Selection({ data: data || [] }));
    const [initialFocusWasSet, setInitialFocusWasSet] = useState(false);
    const tableSelectionRef = useSynchronizedRef(tableSelection);
    const onSelectRef = useSynchronizedRef(onSelect);
    const doSetTableSelection = useCallback((updatedSelection) => {
        if (onSelectRef.current) {
            onSelectRef.current(updatedSelection);
        }
        setTableSelection(updatedSelection);
    }, [onSelectRef]);
    useEffect(() => {
        const newArgs = tableSelectionRef.current.getFocused() || initialFocusWasSet
            ? { data }
            : { data, focused: data && data[0] };
        const newSelection = tableSelectionRef.current.cloneWith(newArgs);
        doSetTableSelection(newSelection);
        setInitialFocusWasSet(!!newSelection.getFocused());
    }, [data, doSetTableSelection, initialFocusWasSet, tableSelectionRef]);
    return [tableSelection, doSetTableSelection];
}
export function BasicSelectableTable({ onItemEnter, autofocus = true, ...props }) {
    const { shortcuts, selection, data, onSelect } = props;
    const [patchedSelection, setPatchedSelection] = useTableSelection(data, selection, onSelect);
    const patchedShortcutsMap = useMemo(() => {
        const enter = onItemEnter && patchedSelection.getFocused()
            ? () => onItemEnter(patchedSelection.getFocused())
            : undefined;
        return {
            ...(enter ? { enter } : {}),
            ...(shortcuts || {})
        };
    }, [onItemEnter, patchedSelection, shortcuts]);
    return (_jsx(BasicTableView, { ...props, scrollOnTableFocus: false, autofocus: autofocus, shortcuts: patchedShortcutsMap, selection: patchedSelection, onSelect: setPatchedSelection }));
}
