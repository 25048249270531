import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import InlineLoader from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import { SettingsGroup, SettingsItem } from '@modules/ide-provisioner/application-settings/app-build-settings/settings-group/settings-group';
import { useMarketplacePlugins } from '@modules/plugins/marketplace-query-wrappers';
import styles from './plugins-preview.css';
export function PluginsPreview(props) {
    var _a, _b;
    const { disabledPlugins, enabledPlugins } = props;
    const enabledPluginsId = (_a = enabledPlugins.map(plugin => plugin.pluginData.value)) !== null && _a !== void 0 ? _a : [];
    const disabledPluginsId = (_b = disabledPlugins.map(plugin => plugin.value)) !== null && _b !== void 0 ? _b : [];
    const { data: enabledPluginsData, isFetching: isEnabledPluginsDataFetching } = useMarketplacePlugins(enabledPluginsId, enabledPlugins.length > 0);
    const { data: disabledPluginsData, isFetching: isDisabledPluginsDataFetching } = useMarketplacePlugins(disabledPluginsId, disabledPlugins.length > 0);
    const pluginsMap = useMemo(() => {
        const map = new Map();
        enabledPluginsData === null || enabledPluginsData === void 0 ? void 0 : enabledPluginsData.forEach(plugin => {
            map.set(plugin.key, plugin);
        });
        disabledPluginsData === null || disabledPluginsData === void 0 ? void 0 : disabledPluginsData.forEach(plugin => {
            map.set(plugin.key, plugin);
        });
        return map;
    }, [enabledPluginsData, disabledPluginsData]);
    return (_jsxs(SettingsGroup, { groupName: "Plugins", children: [enabledPlugins.map(enabledPlugin => {
                var _a;
                return (_jsx(PluginItem, { pluginId: enabledPlugin.pluginData.value, pluginName: isEnabledPluginsDataFetching ? (_jsx(InlineLoader, {})) : ((_a = pluginsMap.get(enabledPlugin.pluginData.value)) === null || _a === void 0 ? void 0 : _a.label), readonly: enabledPlugin.pluginData.forced, status: "auto-installed" }, enabledPlugin.pluginData.value));
            }), disabledPlugins.map(disabledPlugin => {
                var _a;
                return (_jsx(PluginItem, { pluginId: disabledPlugin.value, pluginName: isDisabledPluginsDataFetching ? (_jsx(InlineLoader, {})) : ((_a = pluginsMap.get(disabledPlugin.value)) === null || _a === void 0 ? void 0 : _a.label), readonly: disabledPlugin.forced, status: "disabled" }, disabledPlugin.value));
            })] }));
}
function PluginItem(props) {
    const { pluginName, pluginId, readonly, status } = props;
    return (_jsxs(SettingsItem, { readOnly: readonly, additionalInfo: status === 'auto-installed' ? (_jsx("span", { className: styles.autoInstalled, children: "Auto-installed" })) : (_jsx("span", { className: styles.disabled, children: "Disabled" })), children: [pluginName && _jsx("div", { className: styles.pluginName, children: pluginName }), _jsx("div", { className: styles.pluginId, children: pluginId })] }));
}
