import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@jetbrains/ring-ui/components/button/button';
import { ConstituentBlock } from '@modules/constituents/constituent-block';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { IdeProvisionerLogo } from '../../../common/ide-provisioner-logo/ide-provisioner-logo';
import styles from './ide-provisioner-disabled.css';
export function IdeProvisionerDisabled(props) {
    const { className, onEnabledClick } = props;
    return (_jsxs(ConstituentBlock, { title: "IDE Provisioner", titleLogo: _jsx(IdeProvisionerLogo, {}), state: "disabled", className: className, ...assignTestId('idep-block'), children: [_jsx("p", { children: "IDE Provisioner supplies your development teams with a curated selection of pre-configured plugins, settings, and tools" }), _jsx(Button, { onClick: onEnabledClick, className: styles.button, "data-test": "enable-button", children: "Enable..." })] }));
}
