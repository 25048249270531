// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paragraph__c95 + .paragraph__c95 {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.limit__c3e {
  margin-top: calc(var(--ring-unit) * 3);
}
`, "",{"version":3,"sources":["webpack://./src/modules/license-vault/common/dialogs/lv-enable-dialog/lv-enable-dialog.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".paragraph + .paragraph {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.limit {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraph": `paragraph__c95`,
	"limit": `limit__c3e`
};
export default ___CSS_LOADER_EXPORT___;
