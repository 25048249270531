import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import Button from '@jetbrains/ring-ui/components/button/button';
import Icon from '@jetbrains/ring-ui/components/icon';
import downloadIcon from '@jetbrains/icons/download';
import classNames from 'classnames';
import { fetchCWMOneTimeToken } from '../../../../common/queries/code-with-me';
import { useIsTimeExpired } from './use-is-time-expired';
import { GenerateLinkButton } from './generate-link-button';
import { DownloadCode } from './download-code';
import { getCurrentOs, OperatingSystems } from './operating-systems';
import styles from './manual-download-content.css';
const operatingSystemOptions = [
    {
        key: OperatingSystems.WINDOWS,
        label: 'Windows'
    },
    {
        key: OperatingSystems.MACOS,
        label: 'Mac OS'
    },
    {
        key: OperatingSystems.OTHER,
        label: 'Linux'
    }
];
export function ManualDownloadContent(props) {
    var _a;
    const { codeWithMeDataEntity, className } = props;
    const [operatingSystem, setOperatingSystem] = useState(getCurrentOs());
    const [tokenData, setTokenData] = useState();
    const sessionBaseDlUrl = useMemo(() => {
        if (!tokenData) {
            return tokenData;
        }
        const token = codeWithMeDataEntity.joinToken;
        const launcherUrlBase = codeWithMeDataEntity.sessionLauncherUrl.replace(token, '');
        return `${launcherUrlBase}${tokenData.token}`;
    }, [codeWithMeDataEntity, tokenData]);
    const operatingSystemOption = useMemo(() => operatingSystemOptions.filter(it => it.key === operatingSystem)[0], [operatingSystem]);
    const refreshToken = useCallback(async () => {
        const res = await fetchCWMOneTimeToken(codeWithMeDataEntity.id);
        setTokenData(res);
    }, [codeWithMeDataEntity]);
    const isLinkExpired = useIsTimeExpired((_a = tokenData === null || tokenData === void 0 ? void 0 : tokenData.expireDate) !== null && _a !== void 0 ? _a : 0);
    const launcherLinkValidUntil = useMemo(() => {
        if (!tokenData) {
            return undefined;
        }
        const date = new Date(tokenData.expireDate);
        return date.toLocaleString();
    }, [tokenData]);
    const displayWinDownloadButton = !!sessionBaseDlUrl && operatingSystem === OperatingSystems.WINDOWS && !isLinkExpired;
    const displayCodeBlock = !!sessionBaseDlUrl && operatingSystem !== OperatingSystems.WINDOWS;
    const displayGenerateLinkButton = !sessionBaseDlUrl || isLinkExpired;
    const showShareWarning = !!sessionBaseDlUrl && !isLinkExpired && launcherLinkValidUntil;
    return (_jsxs("section", { className: classNames(className), children: [_jsxs("p", { children: ["For", ' ', _jsx(Select, { label: operatingSystemOption.label, type: Select.Type.INLINE, data: operatingSystemOptions, selected: operatingSystemOption, onSelect: it => (it === null || it === void 0 ? void 0 : it.key) && setOperatingSystem(it === null || it === void 0 ? void 0 : it.key) }), operatingSystem === OperatingSystems.WINDOWS
                        ? ' download and launch JetBrains Client manually'
                        : ' copy and run the following code in the terminal to download JetBrains Client. The one-time command is valid for 10 minutes.'] }), displayWinDownloadButton && (_jsxs(Button, { href: `${sessionBaseDlUrl}/cwm-client-launcher.exe`, className: styles.downloadWinButton, children: [_jsx(Icon, { glyph: downloadIcon, size: Icon.Size.Size14 }), " Download JetBrains Client"] })), displayCodeBlock && (_jsx(DownloadCode, { linkExpired: isLinkExpired, operatingSystem: operatingSystem, sessionBaseUrl: sessionBaseDlUrl, className: styles.downloadCommand })), displayGenerateLinkButton && (_jsx(GenerateLinkButton, { operatingSystem: operatingSystem, onRefreshTokenClick: refreshToken, isFirstGenerate: !sessionBaseDlUrl, className: styles.generateDownloadLinkButton })), showShareWarning && (_jsxs(_Fragment, { children: [_jsxs("p", { className: styles.validMsg, children: ["Downloading is valid until: ", launcherLinkValidUntil] }), _jsx("p", { className: styles.warning, children: "Do not share link or downloaded launcher with anyone, as it contains one-time authorisation token." })] }))] }));
}
