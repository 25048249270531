// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.restriction__b39 {
  display: flex;
  flex-wrap: wrap;
}

.appName__c6c {
  font-weight: 600;
}

.buildNumber__d21 {
  margin-top: calc(var(--ring-unit) * 0.5);
  flex-basis: 100%;
}

.restrictedLabel__dce {
  color: var(--ring-error-color);
}

.plannedIcon__a34 {
  color: var(--ring-icon-warning-color);
}

.plannedDate__a2b {
  margin-left: calc(var(--ring-unit) * 0.5);
  color: var(--ring-warning-color);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ide-provisioner/application-settings/app-build-settings/build-settings-preview/restriction-preview/restriction-preview.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,wCAAwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,yCAAyC;EACzC,gCAAgC;AAClC","sourcesContent":[".restriction {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.appName {\n  font-weight: 600;\n}\n\n.buildNumber {\n  margin-top: calc(var(--ring-unit) * 0.5);\n  flex-basis: 100%;\n}\n\n.restrictedLabel {\n  color: var(--ring-error-color);\n}\n\n.plannedIcon {\n  color: var(--ring-icon-warning-color);\n}\n\n.plannedDate {\n  margin-left: calc(var(--ring-unit) * 0.5);\n  color: var(--ring-warning-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"restriction": `restriction__b39`,
	"appName": `appName__c6c`,
	"buildNumber": `buildNumber__d21`,
	"restrictedLabel": `restrictedLabel__dce`,
	"plannedIcon": `plannedIcon__a34`,
	"plannedDate": `plannedDate__a2b`
};
export default ___CSS_LOADER_EXPORT___;
