import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import pencil from '@jetbrains/icons/pencil';
import Shortcuts from '@jetbrains/ring-ui/components/shortcuts/shortcuts';
import { useDispatch, useSelector } from 'react-redux';
import { useEvaluatedReduxProfileFilters } from '@app/common/queries/profile-evaluations';
import { getApplications, getProfile, isProfileStale } from '@app/common/store/profiles/selectors';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
import { AddSettingButton } from '../common/add-setting-button';
import { SettingBlock } from '../common/setting-block';
import { SettingPlaceholder } from '../common/settings-placeholder';
import { useProfilesParams } from '../context/profiles-params-context';
import { useUserPluginRestrictedMode } from '../hooks/user-restricted-modes';
import FiltersPanel from './filters-panel/filters-panel';
import ApplicationFiltersDialog from './application-filters-dialog';
import { ProductBox } from './product-box/product-box';
import styles from './applications.css';
export function Applications() {
    const { clearParams } = useProfilesParams();
    const dispatch = useDispatch();
    const { content: applications } = useSelector(getApplications);
    const [showForm, setShowForm] = useState(false);
    const isStale = useSelector(isProfileStale);
    const profile = useSelector(getProfile);
    const { isRestricted } = useUserPluginRestrictedMode();
    const updateFilters = (filters) => {
        dispatch(ProfileActions.updateApplicationFilters({ filters }));
    };
    const { isFetching, data: evaluatedProfile } = useEvaluatedReduxProfileFilters(profile, isStale);
    useEffect(() => {
        if (evaluatedProfile) {
            dispatch(ProfileActions.setNewProfiles(evaluatedProfile));
        }
    }, [evaluatedProfile, dispatch]);
    const submit = (filters) => {
        updateFilters(filters);
        setShowForm(false);
    };
    const enabledApplications = useMemo(() => {
        return applications
            .filter(item => !item.excluded)
            .sort((i1, i2) => i1.productName.localeCompare(i2.productName));
    }, [applications]);
    // todo: change content.length once TBE-1585 will be completed
    return (_jsxs(_Fragment, { children: [_jsx(Shortcuts, { scope: "focused-app", map: {
                    esc: clearParams
                } }), _jsx(SettingBlock, { header: "Applications", subHeader: !isRestricted ? _jsx(FiltersPanel, {}) : undefined, bordered: true, headerActionComponent: _jsx(AddSettingButton, { onClick: () => setShowForm(true), glyph: pencil, loader: isFetching, title: "Edit applications", "data-test": "applications-edit-button" }), children: enabledApplications.length === 0 ? (_jsx(SettingPlaceholder, { title: "No applications yet" })) : (enabledApplications.map(item => (_jsx(ProductBox, { className: styles.item, item: item, selectable: !isRestricted }, item.product)))) }), showForm && (_jsx(ApplicationFiltersDialog, { onSubmit: submit, onReject: () => setShowForm(false) }))] }));
}
