import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@jetbrains/ring-ui/components/button/button';
import { useModalsController } from '@common/modals';
import UploadPlugin from './upload-plugin/upload-plugin';
export function PluginsPageActions() {
    const modalsController = useModalsController();
    const handleUploadPlugin = async () => {
        const uploadPluginDialog = modalsController.openModal(UploadPlugin, {
            title: 'Upload Plugin',
            onClose() {
                uploadPluginDialog.closeModal();
            }
        });
    };
    return (_jsx(Button, { primary: true, "data-test": "upload-plugin", onClick: handleUploadPlugin, children: "Upload plugin" }));
}
