// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descriptionContainer__dae {
  width: auto;
  background-color: var(--ring-tag-background-color);
  padding: calc(var(--ring-unit) * 1.25) calc(var(--ring-unit) * 2);
}

.select__db9 + .limitEditor__b0b {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.modelsTable__ba1 {
  padding-bottom: calc(var(--ring-unit) * 2);
  position: relative;

  & table:after {
    content: '';
    position: absolute;
    padding-bottom: var(--ring-unit);
    border-bottom: 1px solid var(--ring-line-color);
    width: calc(100% - calc(var(--ring-unit) * 4));
    transform: translateX(calc(var(--ring-unit) * 4));
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/common/forms/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kDAAkD;EAClD,iEAAiE;AACnE;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,0CAA0C;EAC1C,kBAAkB;;EAElB;IACE,WAAW;IACX,kBAAkB;IAClB,gCAAgC;IAChC,+CAA+C;IAC/C,8CAA8C;IAC9C,iDAAiD;EACnD;AACF","sourcesContent":[".descriptionContainer {\n  width: auto;\n  background-color: var(--ring-tag-background-color);\n  padding: calc(var(--ring-unit) * 1.25) calc(var(--ring-unit) * 2);\n}\n\n.select + .limitEditor {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.modelsTable {\n  padding-bottom: calc(var(--ring-unit) * 2);\n  position: relative;\n\n  & table:after {\n    content: '';\n    position: absolute;\n    padding-bottom: var(--ring-unit);\n    border-bottom: 1px solid var(--ring-line-color);\n    width: calc(100% - calc(var(--ring-unit) * 4));\n    transform: translateX(calc(var(--ring-unit) * 4));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionContainer": `descriptionContainer__dae`,
	"select": `select__db9`,
	"limitEditor": `limitEditor__b0b`,
	"modelsTable": `modelsTable__ba1`
};
export default ___CSS_LOADER_EXPORT___;
