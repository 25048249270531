import { keys as licenseQueryKeys } from './tbe-license';
import { keys as configsQueryKeys } from './server-config';
import queryClient from './query-client';
export const invalidateAllQueries = (conditions = () => true) => {
    return queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
            return (queryKey[0] !== licenseQueryKeys.all[0] &&
                queryKey[0] !== configsQueryKeys.config[0] &&
                conditions(queryKey));
        }
    });
};
