import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import RegisteredInput from '@common/hook-form/registered-input';
import { Required } from '@common/form/important-asterisk';
import { assignTestId } from '@common/infra/tests/assign-test-id';
export function UserNameField(props) {
    const { className, disabled } = props;
    const { control } = useFormContext();
    return (_jsx(RegisteredInput, { label: Required('Username'), name: "username", autoComplete: "off", disabled: disabled, control: control, className: className, ...assignTestId('form-username') }));
}
