// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__a1f {
  display: flex;
  gap: var(--ring-unit);
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/ide-settings/form/common/xml-file-attachment.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":[".container {\n  display: flex;\n  gap: var(--ring-unit);\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__a1f`
};
export default ___CSS_LOADER_EXPORT___;
