// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marketplaceLogo__b6e {
  margin: 4px;
}

.marketplaceLogo__b6e,
.marketplaceLogo__b6e svg {
  width: calc(var(--ring-unit) * 4);
}

.marketplaceInfo__dab {
  margin: 4px;
}

.marketplaceSettings__fc0 {
  color: var(--ring-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/marketplace-settings/marketplace-settings.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;;EAEE,iCAAiC;AACnC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".marketplaceLogo {\n  margin: 4px;\n}\n\n.marketplaceLogo,\n.marketplaceLogo svg {\n  width: calc(var(--ring-unit) * 4);\n}\n\n.marketplaceInfo {\n  margin: 4px;\n}\n\n.marketplaceSettings {\n  color: var(--ring-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marketplaceLogo": `marketplaceLogo__b6e`,
	"marketplaceInfo": `marketplaceInfo__dab`,
	"marketplaceSettings": `marketplaceSettings__fc0`
};
export default ___CSS_LOADER_EXPORT___;
