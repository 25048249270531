import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import addIcon from '@jetbrains/icons/add';
import chevronDown from '@jetbrains/icons/chevron-down';
import Button from '@jetbrains/ring-ui/components/button/button';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import Icon from '@jetbrains/ring-ui/components/icon';
import Select from '@jetbrains/ring-ui/components/select/select';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { indexOf } from 'lodash';
import { useProfileOptions } from '@common/queries/profiles';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { ProfileListItem } from './profile-list-item/profile-list-item';
import styles from './profiles-list.css';
export function ProfilesList({ disabled }) {
    const { watch, control } = useFormContext();
    const [enableFetch, setEnableFetch] = useState(false);
    const assignedProfiles = watch('profiles');
    const { append, remove } = useFieldArray({ control, name: 'profiles' });
    const onRemove = (id) => {
        const item = assignedProfiles.find(item => item.id === id);
        remove(indexOf(assignedProfiles, item));
    };
    const { data: fetchedProfileOptions, isFetching } = useProfileOptions(enableFetch);
    const profileOptions = useMemo(() => {
        return fetchedProfileOptions === null || fetchedProfileOptions === void 0 ? void 0 : fetchedProfileOptions.map(p => ({
            key: p.id,
            label: p.title
        }));
    }, [fetchedProfileOptions]);
    const availableOptions = useMemo(() => {
        return profileOptions === null || profileOptions === void 0 ? void 0 : profileOptions.filter(option => !assignedProfiles.map(({ id }) => id).includes(option.key));
    }, [assignedProfiles, profileOptions]);
    const addProfile = (profile) => {
        if (profile === null) {
            return;
        }
        append({ id: `${profile === null || profile === void 0 ? void 0 : profile.key}`, title: `${profile === null || profile === void 0 ? void 0 : profile.label}` });
    };
    const assignProfileAnchor = useCallback(({ wrapperProps, buttonProps, popup }) => (_jsxs("span", { ...wrapperProps, children: [_jsxs(Button, { icon: addIcon, primary: true, className: styles.assignProfileButton, ...buttonProps, children: ["Assign profile ", _jsx(Icon, { glyph: chevronDown })] }), popup] })), []);
    return (_jsxs("div", { className: styles.listContainer, ...assignTestId('profile-selector-list'), children: [_jsxs(H3, { children: [assignedProfiles === null || assignedProfiles === void 0 ? void 0 : assignedProfiles.length, " Profiles Assigned"] }), _jsx(Text, { info: true, children: "If a profile is assigned to a group, you can't unassign it from individual group members" }), assignedProfiles
                .sort((a, b) => a.title.localeCompare(b.title))
                .map(({ id, title, groups, isAssignedDirectly }) => (_jsx(ProfileListItem, { profile: { id, title, groups, isAssignedDirectly }, onRemove: onRemove, disabled: disabled }, id))), _jsx(Select, { customAnchor: assignProfileAnchor, type: Select.Type.CUSTOM, disabled: disabled, data: availableOptions, onSelect: addProfile, loading: isFetching, onBeforeOpen: () => setEnableFetch(true), onClose: () => setEnableFetch(false), filter: true, ...assignTestId('assign-profile') })] }));
}
