import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './selectable-list.css';
export function SelectableListItem(props) {
    const { onClick, className, children, selected, variant = 'primary' } = props;
    return (_jsx("div", { onClick: onClick, ...assignTestId('selectable-list-item', props), className: classNames(styles.item, className, {
            [styles.selected]: selected,
            [styles.interactive]: !!onClick,
            [styles.primary]: variant === 'primary',
            [styles.secondary]: variant === 'secondary'
        }), children: children }));
}
