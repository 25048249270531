// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ins__ee5 {
  background-color: rgba(var(--ring-added-background-components), 0.9);
  text-decoration: none;
}

.del__f15 {
  background-color: rgba(var(--ring-removed-background-components), 0.9);
  text-decoration: none;
}

.eq__cb7 {
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-history/cells/text-diff.css"],"names":[],"mappings":"AAAA;EACE,oEAAoE;EACpE,qBAAqB;AACvB;;AAEA;EACE,sEAAsE;EACtE,qBAAqB;AACvB;;AAEA;AACA","sourcesContent":[".ins {\n  background-color: rgba(var(--ring-added-background-components), 0.9);\n  text-decoration: none;\n}\n\n.del {\n  background-color: rgba(var(--ring-removed-background-components), 0.9);\n  text-decoration: none;\n}\n\n.eq {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ins": `ins__ee5`,
	"del": `del__f15`,
	"eq": `eq__cb7`
};
export default ___CSS_LOADER_EXPORT___;
