// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog__cb1 {
  min-width: 650px;
  max-width: 80vw;
}

.code__e10 {
  margin-top: calc(2 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/pages/plugins/popups/install-plugin-dialog.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".dialog {\n  min-width: 650px;\n  max-width: 80vw;\n}\n\n.code {\n  margin-top: calc(2 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": `dialog__cb1`,
	"code": `code__e10`
};
export default ___CSS_LOADER_EXPORT___;
