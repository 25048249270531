// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropZone__c99 {
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--ring-secondary-color);
  background-color: var(--ring-content-background-color);
  border: 1px dashed var(--ring-border-hover-color);
  backdrop-filter: blur(2px);
  transition: background-color 0.3s;
  border-radius: var(--ring-border-radius);
}

.dropZone__c99:hover {
  background-color: rgba(var(--ring-hover-background-components), 0.3);
}

.dropZoneText__b6b {
  padding-left: calc(2 * var(--ring-unit));
  padding-right: calc(2 * var(--ring-unit));
}

.dropZoneHovered__abe {
  background-color: var(--ring-hover-background-color);
}

.dropZoneDisabled__d01 {
  border: dashed 1px var(--ring-border-disabled-color);
  cursor: default;
}
`, "",{"version":3,"sources":["webpack://./src/common/drop-zone/files-attachment.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,kCAAkC;EAClC,sDAAsD;EACtD,iDAAiD;EACjD,0BAA0B;EAC1B,iCAAiC;EACjC,wCAAwC;AAC1C;;AAEA;EACE,oEAAoE;AACtE;;AAEA;EACE,wCAAwC;EACxC,yCAAyC;AAC3C;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,oDAAoD;EACpD,eAAe;AACjB","sourcesContent":[".dropZone {\n  justify-content: center;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  color: var(--ring-secondary-color);\n  background-color: var(--ring-content-background-color);\n  border: 1px dashed var(--ring-border-hover-color);\n  backdrop-filter: blur(2px);\n  transition: background-color 0.3s;\n  border-radius: var(--ring-border-radius);\n}\n\n.dropZone:hover {\n  background-color: rgba(var(--ring-hover-background-components), 0.3);\n}\n\n.dropZoneText {\n  padding-left: calc(2 * var(--ring-unit));\n  padding-right: calc(2 * var(--ring-unit));\n}\n\n.dropZoneHovered {\n  background-color: var(--ring-hover-background-color);\n}\n\n.dropZoneDisabled {\n  border: dashed 1px var(--ring-border-disabled-color);\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropZone": `dropZone__c99`,
	"dropZoneText": `dropZoneText__b6b`,
	"dropZoneHovered": `dropZoneHovered__abe`,
	"dropZoneDisabled": `dropZoneDisabled__d01`
};
export default ___CSS_LOADER_EXPORT___;
