// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.incorrectSettingIcon__eca {
  color: var(--ring-icon-error-color);
  margin-right: var(--ring-unit);
}

.duplicatedDescriptionsIcon__f02 {
  color: var(--ring-icon-warning-color);
  margin-right: var(--ring-unit);
}

.duplicatedDescriptionsText__f71 {
  color: var(--ring-warning-color);
}

.boxText__e8d {
  display: inline-block;
  line-height: var(--ring-line-height);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.boxHorizontal__e10 {
  display: flex;
}

.boxVertical__b7e {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - var(--ring-unit) * 4);
  margin: 4px 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/ide-settings/form/add-settings-fields.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,8BAA8B;AAChC;;AAEA;EACE,qCAAqC;EACrC,8BAA8B;AAChC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,qBAAqB;EACrB,oCAAoC;EACpC,uBAAuB;EACvB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,4CAA4C;EAC5C,aAAa;AACf","sourcesContent":[".incorrectSettingIcon {\n  color: var(--ring-icon-error-color);\n  margin-right: var(--ring-unit);\n}\n\n.duplicatedDescriptionsIcon {\n  color: var(--ring-icon-warning-color);\n  margin-right: var(--ring-unit);\n}\n\n.duplicatedDescriptionsText {\n  color: var(--ring-warning-color);\n}\n\n.boxText {\n  display: inline-block;\n  line-height: var(--ring-line-height);\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: pre;\n}\n\n.boxHorizontal {\n  display: flex;\n}\n\n.boxVertical {\n  display: flex;\n  flex-direction: column;\n  max-width: calc(100% - var(--ring-unit) * 4);\n  margin: 4px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"incorrectSettingIcon": `incorrectSettingIcon__eca`,
	"duplicatedDescriptionsIcon": `duplicatedDescriptionsIcon__f02`,
	"duplicatedDescriptionsText": `duplicatedDescriptionsText__f71`,
	"boxText": `boxText__e8d`,
	"boxHorizontal": `boxHorizontal__e10`,
	"boxVertical": `boxVertical__b7e`
};
export default ___CSS_LOADER_EXPORT___;
