import { apiClientManager } from '@common/api';
import { withAuditFieldTexts } from './audit-utils';
import { DEFAULT_TABLE_PAGE_SIZE } from './paging-utils';
const URL = '/api/profiles';
// TODO: actually this method returns Promise<ApiProfile>
export async function getProfile(id) {
    const res = await apiClientManager.getClient().get(`${URL}/${id}`);
    return res.data;
}
export async function postProfile(profile) {
    const res = await apiClientManager.getClient().post(`${URL}`, profile);
    return res.data;
}
export async function putProfile(id, profile) {
    const res = await apiClientManager.getClient().put(`${URL}/${id}`, profile);
    return res.data;
}
export async function deleteProfile(id) {
    await apiClientManager.getClient().delete(`${URL}/${id}`);
}
export async function evaluateProfileFilters(profile) {
    const res = await apiClientManager.getClient().post(`${URL}/evaluate/profile-filters`, profile);
    return res.data;
}
export async function evaluateSettingFilters(filters) {
    const res = await apiClientManager.getClient().post(`${URL}/evaluate/setting-filters`, filters);
    return res.data;
}
export async function evaluateAppFilters(filters) {
    const res = await apiClientManager
        .getClient()
        .post(`${URL}/evaluate/application-filters`, { filters });
    return res.data;
}
function getProfileUsersSortParam(sortKey) {
    switch (sortKey) {
        case 'username':
            return 'USERNAME';
        case 'email':
            return 'EMAIL';
        case 'role':
            return 'ROLE';
        default:
            return null;
    }
}
function buildUserByIdRequestParams(params) {
    var _a, _b, _c;
    return {
        'filter-query': (_a = params === null || params === void 0 ? void 0 : params.query) !== null && _a !== void 0 ? _a : null,
        'filter-active': (_b = params === null || params === void 0 ? void 0 : params.isActive) !== null && _b !== void 0 ? _b : null,
        'filter-role': (_c = params === null || params === void 0 ? void 0 : params.role) !== null && _c !== void 0 ? _c : null,
        sort: params.sortKey ? getProfileUsersSortParam(params.sortKey) : null,
        order: params.sortOrder,
        page: params.page,
        limit: params.sortKey ? DEFAULT_TABLE_PAGE_SIZE : undefined
    };
}
export async function getUsersByProfileId(id, params) {
    const res = await apiClientManager.getClient().get(`${URL}/${id}/users`, {
        params: params ? buildUserByIdRequestParams(params) : null
    });
    return res.data;
}
export const apiProfileAuditTypeOptions = [
    { key: 'FILTER', label: 'Applications' },
    { key: 'PROFILE', label: 'Profile Settings' },
    { key: 'IDE_SETTING', label: 'IDE Settings' },
    { key: 'VM_OPTION_SETTING', label: 'VM Options Settings' },
    { key: 'TOOLBOX_SETTING', label: 'Toolbox Settings' },
    { key: 'IJ_PLUGIN_SETTING', label: 'Plugins Settings' },
    { key: 'AUTOINSTALLED_TOOL', label: 'Autoinstalled Tools' },
    { key: 'MARKETPLACE_SETTING', label: 'Marketplace Settings' }
];
/* eslint-disable no-use-before-define,@typescript-eslint/no-use-before-define */
export async function getAuditLogDetails(profileId, eventId) {
    const res = await apiClientManager
        .getClient()
        .get(`${URL}/${profileId}/audit-logs/${eventId}`);
    return {
        ...res.data,
        snapshot: formatSnapshot(res.data.type, res.data.snapshot),
        prevSnapshot: res.data.prevSnapshot
            ? formatSnapshot(res.data.type, res.data.prevSnapshot)
            : undefined
    };
    function formatSnapshot(type, audit) {
        const arrayToSnapshot = (arr, version) => ({
            settings: arr || [],
            version: `${version || ''}`
        });
        const snapshotExtractors = {
            PROFILE: () => ({
                title: audit.title,
                description: audit.description,
                isDefault: audit.isDefault
            }),
            FILTER: () => audit.applications || {},
            IDE_SETTING: () => { var _a, _b; return arrayToSnapshot((_a = audit.settings) === null || _a === void 0 ? void 0 : _a.ide, (_b = audit.settings) === null || _b === void 0 ? void 0 : _b.ideVersion); },
            VM_OPTION_SETTING: () => { var _a, _b; return arrayToSnapshot((_a = audit.settings) === null || _a === void 0 ? void 0 : _a.vmOptions, (_b = audit.settings) === null || _b === void 0 ? void 0 : _b.vmOptionsVersion); },
            TOOLBOX_SETTING: () => { var _a, _b; return arrayToSnapshot((_a = audit.settings) === null || _a === void 0 ? void 0 : _a.toolbox, (_b = audit.settings) === null || _b === void 0 ? void 0 : _b.toolboxVersion); },
            MARKETPLACE_SETTING: () => { var _a, _b; return arrayToSnapshot((_a = audit.settings) === null || _a === void 0 ? void 0 : _a.marketplace, (_b = audit.settings) === null || _b === void 0 ? void 0 : _b.marketplaceVersion); },
            IJ_PLUGIN_SETTING: () => audit.plugins || {},
            AUTOINSTALLED_TOOL: () => {
                var _a, _b;
                return arrayToSnapshot((_a = audit.settings) === null || _a === void 0 ? void 0 : _a.autoinstalledTools, (_b = audit.settings) === null || _b === void 0 ? void 0 : _b.autoinstalledToolsVersion);
            }
        };
        return snapshotExtractors[type]();
    }
}
export async function getAuditLogsByProfileId(id, params) {
    const res = await apiClientManager
        .getClient()
        .get(`${URL}/${id}/audit-logs`, { params });
    return res.data;
}
function getSortParam(sortKey) {
    switch (sortKey) {
        case 'isDefault':
            return 'IS_DEFAULT';
        case 'title':
            return 'TITLE';
        case 'createdByText':
            return 'CREATED_BY';
        case 'lastModifiedByText':
            return 'LAST_MODIFIED_BY';
        case 'createdDateText':
            return 'CREATED_DATE';
        case 'lastModifiedDateText':
            return 'LAST_MODIFIED_DATE';
        default:
            return null;
    }
}
// todo extract common logic with buildRequestParams from users.ts
function buildRequestParams(params) {
    const { page, sortKey, sortOrder, query } = params;
    const res = {
        page,
        limit: String(DEFAULT_TABLE_PAGE_SIZE),
        'include-total-count': String(true),
        ...(sortKey ? { sort: getSortParam(sortKey), order: sortOrder } : {})
    };
    if (query) {
        res['filter-query'] = query;
    }
    return res;
}
export async function getProfiles(filtersWithPagination) {
    const response = await apiClientManager.getClient().get('/api/profiles', {
        params: buildRequestParams(filtersWithPagination)
    });
    const totalCount = response.data.total;
    const data = response.data.items.map(withAuditFieldTexts);
    return { profiles: data, totalCount };
}
export async function createProfile(profileData) {
    const response = await apiClientManager
        .getClient()
        .post('/api/profiles', profileData);
    return response.data;
}
export async function getProfileOptions() {
    const res = await apiClientManager
        .getClient()
        .get('/api/profiles?limit=1000&offset=0');
    return res.data.items;
}
