import { jsx as _jsx } from "react/jsx-runtime";
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import classnames from 'classnames';
import { ConstituentLogo } from '@modules/constituents/constituent-logo/constituent-logo';
import { getConstituentName } from '@modules/constituents/constituent-name';
import styles from '../users-admin-page.css';
export function UsersTableConstituents({ id, constituents }) {
    return (_jsx("div", { className: styles.constituentsLogos, children: constituents
            .filter(({ state }) => state === 'CONFIGURED' || state === 'DISABLED_BY_LIMITS')
            .map(item => (_jsx(Tooltip, { title: item.state === 'DISABLED_BY_LIMITS'
                ? `Restricted: exceeds ${getConstituentName(item.constituentId)} limit`
                : getConstituentName(item.constituentId), children: _jsx("span", { className: item.state === 'DISABLED_BY_LIMITS'
                    ? classnames(styles.logoContainer, styles.logoContainerDisabled)
                    : styles.logoContainer, children: _jsx(ConstituentLogo, { id: item.constituentId }) }) }, `${id}-${item.constituentId}`))) }));
}
