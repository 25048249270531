// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pluginName__d9f {
  font-weight: 600;
}

.pluginName__d9f + .pluginId__f9a {
  margin-top: calc(var(--ring-unit) / 2);
}

.autoInstalled__a2e {
  color: var(--ring-success-color);
}

.disabled__d3d {
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ide-provisioner/application-settings/app-build-settings/build-settings-preview/plugins-preview/plugins-preview.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".pluginName {\n  font-weight: 600;\n}\n\n.pluginName + .pluginId {\n  margin-top: calc(var(--ring-unit) / 2);\n}\n\n.autoInstalled {\n  color: var(--ring-success-color);\n}\n\n.disabled {\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pluginName": `pluginName__d9f`,
	"pluginId": `pluginId__f9a`,
	"autoInstalled": `autoInstalled__a2e`,
	"disabled": `disabled__d3d`
};
export default ___CSS_LOADER_EXPORT___;
