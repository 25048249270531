// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usersData__aa0 {
  margin-top: calc(var(--ring-unit) / 2);
}

.usersLimit__d0e + .usedBy__f54 {
  margin-left: calc(var(--ring-unit) * 1.5);
}

.notInUseWarning__ac8 {
  margin-top: calc(var(--ring-unit) / 2);
  color: var(--ring-warning-color);
}

.overLimitError__ac9 {
  margin-top: calc(var(--ring-unit) / 2);
  display: flex;
  align-items: center;
}

.overLimitIcon__b7f {
  color: var(--ring-error-color);
}

.overLimitErrorMsg__f28 {
  margin-left: calc(var(--ring-unit) / 2);
  color: var(--ring-error-color);
}

.contactMsg__d8f {
  color: var(--ring-error-color);
}

.actions__d1d {
  margin-top: calc(var(--ring-unit) * 1.5);

  display: flex;
  gap: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ide-provisioner/ide-provisioner-constituent/ide-provisioner-state-block/ide-provisioner-enabled/ide-provisioner-enabled.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,sCAAsC;EACtC,gCAAgC;AAClC;;AAEA;EACE,sCAAsC;EACtC,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,uCAAuC;EACvC,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,wCAAwC;;EAExC,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".usersData {\n  margin-top: calc(var(--ring-unit) / 2);\n}\n\n.usersLimit + .usedBy {\n  margin-left: calc(var(--ring-unit) * 1.5);\n}\n\n.notInUseWarning {\n  margin-top: calc(var(--ring-unit) / 2);\n  color: var(--ring-warning-color);\n}\n\n.overLimitError {\n  margin-top: calc(var(--ring-unit) / 2);\n  display: flex;\n  align-items: center;\n}\n\n.overLimitIcon {\n  color: var(--ring-error-color);\n}\n\n.overLimitErrorMsg {\n  margin-left: calc(var(--ring-unit) / 2);\n  color: var(--ring-error-color);\n}\n\n.contactMsg {\n  color: var(--ring-error-color);\n}\n\n.actions {\n  margin-top: calc(var(--ring-unit) * 1.5);\n\n  display: flex;\n  gap: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"usersData": `usersData__aa0`,
	"usersLimit": `usersLimit__d0e`,
	"usedBy": `usedBy__f54`,
	"notInUseWarning": `notInUseWarning__ac8`,
	"overLimitError": `overLimitError__ac9`,
	"overLimitIcon": `overLimitIcon__b7f`,
	"overLimitErrorMsg": `overLimitErrorMsg__f28`,
	"contactMsg": `contactMsg__d8f`,
	"actions": `actions__d1d`
};
export default ___CSS_LOADER_EXPORT___;
