import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { isStateChanged } from '@app/common/store/profiles/selectors';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
import { useProfilesParams } from '../context/profiles-params-context';
/**
 * Function that sets the profile state in the Redux store to the given 'profilesState' only when state
 * is not changed yet not to erase current work in progress changes
 * @param profilesState
 */
export function useSynchronizedProfilesState(profilesState) {
    const dispatch = useDispatch();
    const { clearParams } = useProfilesParams();
    const stateChanged = useSelector(isStateChanged);
    useEffect(() => {
        if (profilesState && !stateChanged) {
            batch(() => {
                dispatch(ProfileActions.setNewProfiles(profilesState));
                dispatch(ProfileActions.setImmutableProfilesState(profilesState));
                clearParams();
            });
        }
    }, [profilesState, dispatch, stateChanged, clearParams]);
    useEffect(() => {
        return () => {
            batch(() => {
                dispatch(ProfileActions.resetProfileState());
                dispatch(ProfileActions.resetImmutableProfilesState());
            });
        };
    }, [dispatch]);
}
