import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { formatApiError } from '@api/errors';
import { useUpdateTokenMutation } from '@app/common/queries/automation';
import SubmitError from '@app/common/errors/submit-error';
import { tAutomationTokenSchema } from '../../schemas/automation-token-schema';
import AutomationTokenEditFields from './automation-token-edit-fields';
import styles from './automation-token-popups.css';
export function AutomationTokenUpdatePopup(props) {
    const { token, onCloseAttempt } = props;
    const [errorResponse, setErrorResponse] = useState('');
    const updateTokenMutation = useUpdateTokenMutation();
    const form = useForm({
        defaultValues: {
            clientId: token.clientId,
            expireDate: token.expireDate
        },
        resolver: zodResolver(tAutomationTokenSchema)
    });
    const { formState: { isDirty, isSubmitting }, handleSubmit } = form;
    const onSubmit = async (values) => {
        const tokenData = {
            version: token.version,
            clientId: values.clientId,
            expireDate: values.expireDate ? values.expireDate : undefined
        };
        await updateTokenMutation.mutateAsync({ tokenId: token.id, tokenData }, {
            onSuccess: () => onCloseAttempt(undefined),
            onError: e => {
                setErrorResponse(formatApiError(e, 'Failed to update token'));
            }
        });
    };
    return (_jsxs(Dialog, { show: true, label: "Edit Automation Token", showCloseButton: true, closeButtonInside: true, autoFocusFirst: true, onEscPress: onCloseAttempt, onCloseAttempt: onCloseAttempt, children: [_jsx(Header, { children: "Edit Automation Token" }), _jsxs(Content, { children: [_jsx(SubmitError, { error: errorResponse }), _jsx(FormProvider, { ...form, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), className: styles.formContainer, children: _jsx(AutomationTokenEditFields, { disabled: isSubmitting }) }) })] }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, loader: isSubmitting, disabled: !isDirty, onClick: handleSubmit(onSubmit), children: "Edit" }), _jsx(Button, { onClick: onCloseAttempt, children: "Cancel" })] })] }));
}
