import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import HorizontalContainer from '@app/common/page-layout/horizontal-container';
import SearchFilter from '@app/common/search-filter/search-filter';
import EventsFilter from './events-filter';
export function HistoryFilters({ filters, onFiltersChange }) {
    const handleSearchValueChange = useCallback((newValue) => {
        if (typeof newValue === 'string') {
            onFiltersChange({
                ...filters,
                page: 1, // when we perform a new search, we need to always reset the list to page 1 so we see the new filtered list from the beginning
                search: newValue.length > 0 ? newValue : undefined
            });
        }
    }, [onFiltersChange, filters]);
    return (_jsxs(HorizontalContainer, { children: [_jsx(SearchFilter, { value: filters.search || '', onChange: handleSearchValueChange, placeholder: "Search" }), _jsx(EventsFilter, { value: filters.type, onChange: value => onFiltersChange({
                    ...filters,
                    page: 1, // when the filters change, we need to always reset the list to page 1 so we see the new filtered list from the beginning
                    type: value !== null && value !== void 0 ? value : undefined
                }) })] }));
}
