import { useSelector } from 'react-redux';
import { getProfileConstituents } from '@app/common/store/profiles/selectors';
import { useIdeProvisionerConstituent } from '@app/common/queries/ide-provisioner';
import { useRestrictedPlugins } from '@app/common/queries/profiles';
export function useUserPluginRestrictedMode() {
    const { idep: ideProvisionerProfileConstitunetState } = useSelector(getProfileConstituents);
    const { data: ideProvisionerConstituentState } = useIdeProvisionerConstituent();
    const { data: restrictedPlugins } = useRestrictedPlugins();
    return {
        restrictedPlugins: restrictedPlugins !== null && restrictedPlugins !== void 0 ? restrictedPlugins : [],
        isRestricted: !ideProvisionerProfileConstitunetState.isEnabled ||
            (ideProvisionerConstituentState === null || ideProvisionerConstituentState === void 0 ? void 0 : ideProvisionerConstituentState.status) !== 'enabled'
    };
}
