// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputWrapper__fa7.readOnly__ccd {
  display: flex;
  align-items: center;

  .input__cf0 + .needMore__b46 {
    margin-left: calc(var(--ring-unit) * 2);
  }
}

.label__d0c + .inputWrapper__fa7 {
  margin-top: calc(var(--ring-unit) * 0.5);
}

.toggle__af0 + .inputWrapper__fa7 {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.label__d0c {
  color: var(--ring-disabled-color);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.limitNotice__cd5 {
  margin-top: calc(var(--ring-unit) / 2);

  color: var(--ring-secondary-color);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
`, "",{"version":3,"sources":["webpack://./src/modules/constituents/constituent-dialog/limit-assigment-editor/limit-assigment-editor.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;;EAEnB;IACE,uCAAuC;EACzC;AACF;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,iCAAiC;EACjC,wCAAwC;EACxC,2CAA2C;AAC7C;;AAEA;EACE,sCAAsC;;EAEtC,kCAAkC;EAClC,wCAAwC;EACxC,2CAA2C;AAC7C","sourcesContent":[".inputWrapper.readOnly {\n  display: flex;\n  align-items: center;\n\n  .input + .needMore {\n    margin-left: calc(var(--ring-unit) * 2);\n  }\n}\n\n.label + .inputWrapper {\n  margin-top: calc(var(--ring-unit) * 0.5);\n}\n\n.toggle + .inputWrapper {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.label {\n  color: var(--ring-disabled-color);\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n}\n\n.limitNotice {\n  margin-top: calc(var(--ring-unit) / 2);\n\n  color: var(--ring-secondary-color);\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": `inputWrapper__fa7`,
	"readOnly": `readOnly__ccd`,
	"input": `input__cf0`,
	"needMore": `needMore__b46`,
	"label": `label__d0c`,
	"toggle": `toggle__af0`,
	"limitNotice": `limitNotice__cd5`
};
export default ___CSS_LOADER_EXPORT___;
