import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTbeLicense } from '@app/common/queries/tbe-license';
import auth from '@app/common/auth/auth';
import { LicenseActivation, UsageStats } from '@modules/license';
import { LicenseVaultConstituent } from '@modules/license-vault/license-vault-constituent';
import { IdeProvisionerConstituent } from '@modules/ide-provisioner/ide-provisioner-constituent';
import { CodeWithMeConstituent } from '@modules/code-with-me/code-with-me-constituent';
import { AiEnterpriseConstituent } from '@modules/ai/ai-constituent';
import { isConstituentAllowedToUseByLicense } from '@modules/constituents/is-constituent-allowed-to-use-by-license';
import styles from './license-activation-page.css';
function LicenseActivationPage() {
    var _a, _b, _c, _d;
    const { data } = useTbeLicense();
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    const licenseType = data === null || data === void 0 ? void 0 : data.plan;
    const lvLicense = (_a = data === null || data === void 0 ? void 0 : data.constituents) === null || _a === void 0 ? void 0 : _a.find(c => c.id === 'lv');
    const aiLicense = (_b = data === null || data === void 0 ? void 0 : data.constituents) === null || _b === void 0 ? void 0 : _b.find(c => c.id === 'ai');
    const cwmLicense = (_c = data === null || data === void 0 ? void 0 : data.constituents) === null || _c === void 0 ? void 0 : _c.find(c => c.id === 'cwm');
    const idepLicense = (_d = data === null || data === void 0 ? void 0 : data.constituents) === null || _d === void 0 ? void 0 : _d.find(c => c.id === 'idep');
    return (_jsxs(_Fragment, { children: [_jsx(LicenseActivation, { className: styles.licenseBlock }), _jsx(UsageStats, { className: classNames(styles.licenseBlock, styles.separator) }), licenseType && (_jsxs(_Fragment, { children: [lvLicense && isConstituentAllowedToUseByLicense(lvLicense) && (_jsx(LicenseVaultConstituent, { licenseType: licenseType, constituentLicense: lvLicense, className: styles.licenseBlock })), idepLicense && isConstituentAllowedToUseByLicense(idepLicense) && (_jsx(IdeProvisionerConstituent, { licenseType: licenseType, constituentLicense: idepLicense, className: styles.licenseBlock })), cwmLicense && isConstituentAllowedToUseByLicense(cwmLicense) && (_jsx(CodeWithMeConstituent, { licenseType: licenseType, constituentLicense: cwmLicense, className: styles.licenseBlock })), aiLicense && isConstituentAllowedToUseByLicense(aiLicense) && !offlineModeEnabled && (_jsx(AiEnterpriseConstituent, { licenseType: licenseType, constituentLicense: aiLicense, className: styles.licenseBlock }))] }))] }));
}
export default LicenseActivationPage;
