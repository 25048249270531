import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Checkbox from '@jetbrains/ring-ui/components/checkbox/checkbox';
import Text from '@jetbrains/ring-ui/components/text/text';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { EnumerableTextElements } from '@common/text-formattings/enumerable-text-elements';
import styles from './build-field.css';
export function NestedCheckbox(props) {
    const { buildOption, onChange, hasCheckedParent } = props;
    const itemKey = buildOption.build;
    const isChecked = buildOption.selected || hasCheckedParent;
    const asteriskIndex = itemKey.lastIndexOf('*');
    const handleSelection = useCallback(() => onChange(itemKey), [itemKey, onChange]);
    return (_jsxs("div", { className: styles.checkboxContainer, children: [_jsxs("div", { className: styles.titleContainer, children: [_jsx(Checkbox, { label: buildOption.build, onChange: handleSelection, checked: isChecked, ...assignTestId(buildOption.build) }), asteriskIndex === -1 && (_jsx(Text, { info: true, size: "s", className: styles.checkboxSubtitle, children: buildOption.label && (_jsx(EnumerableTextElements, { getKey: val => `build-${val}`, getValue: val => val, maxVisibleElems: 2, items: buildOption.label })) }))] }), buildOption.items.map(item => (_jsx(NestedCheckbox, { hasCheckedParent: isChecked, buildOption: item, onChange: onChange })))] }));
}
