import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import DropdownMenu from '@jetbrains/ring-ui/components/dropdown-menu/dropdown-menu';
import Icon from '@jetbrains/ring-ui/components/icon';
import chevronDown from '@jetbrains/icons/chevron-down';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import idProvider from '@modules/users/id-provider/id-provider';
import { IdpBannerDefaultContent } from '@modules/users/banners/idp-banner-default-content';
import HorizontalContainer from '@common/page-layout/horizontal-container';
import { PopupType } from './popups/users-page-popups';
const DROPDOWN_OPTIONS = [
    PopupType.INVITATION_LINK,
    PopupType.CONFIGURATION_FILE,
    PopupType.MANUALL
].map(label => ({
    label,
    key: label
}));
export function UsersPageActions({ openPopup, canAssignProfile }) {
    const idpImportEnabled = idProvider.isEnabled();
    const addUsersAnchor = useMemo(() => {
        if (idpImportEnabled) {
            return (_jsx(Tooltip, { title: _jsx(IdpBannerDefaultContent, {}), children: _jsxs(Button, { disabled: true, children: ["Add users ", _jsx(Icon, { glyph: chevronDown })] }) }));
        }
        return (_jsxs(Button, { children: ["Add users ", _jsx(Icon, { glyph: chevronDown })] }));
    }, [idpImportEnabled]);
    return (_jsxs(HorizontalContainer, { children: [_jsx(Button, { primary: true, onClick: () => openPopup(PopupType.ADD_PROFILE), disabled: !canAssignProfile, children: "Assign profile" }), _jsx(DropdownMenu, { anchor: addUsersAnchor, onSelect: ({ label }) => openPopup(label), data: DROPDOWN_OPTIONS })] }));
}
