import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { usePaginatedProfiles } from '@app/common/queries/profiles';
import LoadingError from '@app/common/errors/loading-error';
import HorizontalContainer from '@common/page-layout/horizontal-container';
import { useProfilesParams } from './use-profiles-params';
import { ProfilesFilters } from './filters/profile-filters';
import { ProfilesPageTable } from './profiles-page-table';
import { ProfilesPageActions } from './profiles-page-actions';
export function ProfilesAdminBody() {
    const [urlParams, setUrlParams] = useProfilesParams();
    const { data, isFetching, isError } = usePaginatedProfiles(urlParams);
    return (_jsxs(_Fragment, { children: [isError && _jsx(LoadingError, {}), _jsxs(HorizontalContainer, { children: [_jsx(ProfilesPageActions, {}), _jsx(ProfilesFilters, { params: urlParams, onParamsChange: setUrlParams })] }), _jsx(ProfilesPageTable, { data: data, isLoading: isFetching, params: urlParams, onParamsChange: setUrlParams })] }));
}
