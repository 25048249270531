import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { isPast } from 'date-fns';
import Icon, { Size } from '@jetbrains/ring-ui/components/icon';
import info from '@jetbrains/icons/info';
import { AutoInstalledToolsPreview } from '@modules/ide-provisioner/application-settings/app-build-settings/build-settings-preview/auto-installed-tools-preview/auto-installed-tools-preview';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { PluginsPreview } from './plugins-preview/plugins-preview';
import { IdeSettingsPreview } from './ide-settings-preview/ide-settings-preview';
import { RestrictionPreview } from './restriction-preview/restriction-preview';
import { VmOptionsPreview } from './vm-options-preview/vm-options-preview';
import { ToolboxSettingsPreview } from './toolbox-settings-preview/toolbox-settings-preview';
import styles from './settings-preview.css';
export function BuildSettingsPreview(props) {
    var _a, _b, _c, _d;
    const { className, appName, buildNumber, settings, buildArch, buildOs } = props;
    const { toolboxSettings } = settings;
    const vmOptions = (_a = settings.toolSettings) === null || _a === void 0 ? void 0 : _a.vmOptions;
    const disabledPlugins = (_b = settings.toolSettings) === null || _b === void 0 ? void 0 : _b.disabledPlugins;
    const enabledPlugins = (_c = settings.toolSettings) === null || _c === void 0 ? void 0 : _c.enabledPlugins;
    const restriction = (_d = settings.toolSettings) === null || _d === void 0 ? void 0 : _d.restriction;
    const ideConfiguration = settings.ideSettings;
    return (_jsxs("div", { className: classNames(styles.wrapper, className), ...assignTestId('build-settings-preview'), children: [toolboxSettings && (_jsxs(_Fragment, { children: [_jsx(ToolboxSettingsPreview, { toolboxSettings: toolboxSettings }), !!toolboxSettings.autoInstalledTools.length && (_jsx(AutoInstalledToolsPreview, { tools: toolboxSettings.autoInstalledTools, buildArch: buildArch, buildOs: buildOs }))] })), restriction && (_jsxs(_Fragment, { children: [_jsx(RestrictionPreview, { restriction: restriction, appName: appName, buildNumber: buildNumber }), isPast(restriction.deadline) && (_jsxs("div", { className: styles.restrictionWarning, children: [_jsx(Icon, { glyph: info, size: Size.Size16 }), _jsx("span", { className: styles.restrictionMsg, children: "Since the tool is restricted, settings cannot be applied." })] }))] })), !!(vmOptions === null || vmOptions === void 0 ? void 0 : vmOptions.length) && _jsx(VmOptionsPreview, { vmOptions: vmOptions }), !!(ideConfiguration === null || ideConfiguration === void 0 ? void 0 : ideConfiguration.length) && _jsx(IdeSettingsPreview, { ideSettings: ideConfiguration }), (!!(enabledPlugins === null || enabledPlugins === void 0 ? void 0 : enabledPlugins.length) || !!(disabledPlugins === null || disabledPlugins === void 0 ? void 0 : disabledPlugins.length)) && (_jsx(PluginsPreview, { disabledPlugins: disabledPlugins !== null && disabledPlugins !== void 0 ? disabledPlugins : [], enabledPlugins: enabledPlugins !== null && enabledPlugins !== void 0 ? enabledPlugins : [] }))] }));
}
