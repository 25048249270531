// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fieldsContainer__ef1 {
  margin-top: calc(var(--ring-unit) * 2);

  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: calc(var(--ring-unit) * 1.5) calc(var(--ring-unit) * 3);

  & > :nth-child(odd) {
    white-space: nowrap;
    color: var(--ring-secondary-color);
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/users/user-details/user-machine-info-list/user-machine-info-list.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;;EAEtC,aAAa;EACb,sCAAsC;EACtC,mBAAmB;EACnB,4DAA4D;;EAE5D;IACE,mBAAmB;IACnB,kCAAkC;EACpC;AACF","sourcesContent":[".fieldsContainer {\n  margin-top: calc(var(--ring-unit) * 2);\n\n  display: grid;\n  grid-template-columns: min-content 1fr;\n  align-items: center;\n  gap: calc(var(--ring-unit) * 1.5) calc(var(--ring-unit) * 3);\n\n  & > :nth-child(odd) {\n    white-space: nowrap;\n    color: var(--ring-secondary-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldsContainer": `fieldsContainer__ef1`
};
export default ___CSS_LOADER_EXPORT___;
