import { jsx as _jsx } from "react/jsx-runtime";
import { useLvConstituentState } from '@common/queries/license-vault';
import { useOpenModal } from '@common/modals/hooks';
import { LvEnableDialog } from '../../common/dialogs/lv-enable-dialog/lv-enable-dialog';
import { LvDisableDialog } from '../../common/dialogs/lv-disable-dialog/lv-disable-dialog';
import { LicenseVaultLimits } from '../../common/dialogs/license-vault-limits/license-vault-limits';
import { LicenseVaultDisabled } from './license-vault-disabled/license-vault-disabled';
import { LicenseVaultEnabled } from './license-vault-enabled/license-vault-enabled';
export function LicenseVaultConstituent(props) {
    const { className, licenseType, constituentLicense } = props;
    const { data } = useLvConstituentState();
    const openDisableDialog = useOpenModal(LvDisableDialog, {});
    const openEnableDialog = useOpenModal(LvEnableDialog, {
        showPostpaidHint: licenseType === 'POSTPAID' && constituentLicense.trial.state !== 'ACTIVE',
        limit: constituentLicense.trial.state === 'ACTIVE' ? constituentLicense.trial.maxUsage : undefined
    });
    const openLicensesDialog = useOpenModal(LicenseVaultLimits, {});
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled') {
        return _jsx(LicenseVaultDisabled, { className: className, onEnabledClick: openEnableDialog });
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'enabled') {
        return (_jsx(LicenseVaultEnabled, { className: className, onDisableClick: openDisableDialog, onOpenLicensesDetails: openLicensesDialog, licenseType: licenseType, license: constituentLicense }));
    }
    return null;
}
