// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__ce2 {
  position: relative;
}

.anchorWrapper__a18 {
  display: contents;
}

/*hack to mitigate extra ring ui element*/
.messageWrapper__b96 {
  position: absolute;
  left: 0;
  right: 0;
}

.popup__bae.popup__bae:not(.withIcon__ec4) {
  padding: calc(var(--ring-unit) * 2);
}
`, "",{"version":3,"sources":["webpack://./src/common/popup-message/popup-message.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA,yCAAyC;AACzC;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".wrapper {\n  position: relative;\n}\n\n.anchorWrapper {\n  display: contents;\n}\n\n/*hack to mitigate extra ring ui element*/\n.messageWrapper {\n  position: absolute;\n  left: 0;\n  right: 0;\n}\n\n.popup.popup:not(.withIcon) {\n  padding: calc(var(--ring-unit) * 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__ce2`,
	"anchorWrapper": `anchorWrapper__a18`,
	"messageWrapper": `messageWrapper__b96`,
	"popup": `popup__bae`,
	"withIcon": `withIcon__ec4`
};
export default ___CSS_LOADER_EXPORT___;
