// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label__dd3 {
  margin-top: calc(var(--ring-unit) * 0.5);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
  color: var(--ring-secondary-color);
}

.companyName__eb9 {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.companyNameEditor__ba8 {
  margin-left: var(--ring-unit);
}

.logo__ae4 {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.connectionBlock__ace {
  max-width: calc(var(--ring-unit) * 66);
  margin-top: calc(var(--ring-unit) * 3);
}

.infoText__bb9 {
  margin-top: calc(var(--ring-unit) * 0.5);
}

.actions__b73 {
  margin-top: calc(var(--ring-unit) * 1.5);
  display: flex;
  flex-wrap: wrap;
  gap: var(--ring-unit);
}

.downloadToolboxContainer__c83 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 2);
  margin-top: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/client-machine-configuration/client-machine-configuration.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,wCAAwC;EACxC,2CAA2C;EAC3C,kCAAkC;AACpC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,sCAAsC;EACtC,sCAAsC;AACxC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;EACxC,aAAa;EACb,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,4BAA4B;AAC9B","sourcesContent":[".label {\n  margin-top: calc(var(--ring-unit) * 0.5);\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n  color: var(--ring-secondary-color);\n}\n\n.companyName {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.companyNameEditor {\n  margin-left: var(--ring-unit);\n}\n\n.logo {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.connectionBlock {\n  max-width: calc(var(--ring-unit) * 66);\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.infoText {\n  margin-top: calc(var(--ring-unit) * 0.5);\n}\n\n.actions {\n  margin-top: calc(var(--ring-unit) * 1.5);\n  display: flex;\n  flex-wrap: wrap;\n  gap: var(--ring-unit);\n}\n\n.downloadToolboxContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 2);\n  margin-top: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `label__dd3`,
	"companyName": `companyName__eb9`,
	"companyNameEditor": `companyNameEditor__ba8`,
	"logo": `logo__ae4`,
	"connectionBlock": `connectionBlock__ace`,
	"infoText": `infoText__bb9`,
	"actions": `actions__b73`,
	"downloadToolboxContainer": `downloadToolboxContainer__c83`
};
export default ___CSS_LOADER_EXPORT___;
