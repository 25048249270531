import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import activityLogo from '@jetbrains/icons/activity';
import { THIS_MONTH_TIMEFRAME } from '@api/dashboard';
import { ConstituentEnabledWidget } from '@modules/constituents/widget/constituent-enabled-widget';
import { useConstituentUsagePerDays } from '@common/queries/dashboard';
import { useUsers } from '@common/queries/users';
import NavigationLink from '@common/navigation/navigation-link';
import { IntervalStatisticsWidgetCounter, StatisticsWidgetCounter } from '@common/widgets/statistics-widget/statistics-widget-counter';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { StatisticsWidgetTimeframeSelector } from '@common/widgets/statistics-widget/statistics-widget-timeframe-selector';
import { StatisticsWidgetBarChart } from '@common/widgets/statistics-widget/statistics-widget-bar-chart';
import { StatisticsWidgetInfoStats, StatisticsWidgetInfoStatsSize } from '@common/widgets/statistics-widget/statistics-widget-info-stats';
import { JustifyContent } from '@common/widgets/widget/widget';
export function CwmActiveWidget(props) {
    var _a, _b;
    const constituent = { id: 'cwm', title: 'Code with Me' };
    const [timeframe, setTimeframe] = useState(THIS_MONTH_TIMEFRAME);
    const [activeInterval, setActiveInterval] = useState();
    const { data, isLoading } = useConstituentUsagePerDays(constituent, timeframe);
    const { data: usageData, isLoading: isLoadingUsageData } = useUsers({
        constituentId: 'cwm',
        limit: 0,
        page: 1
    });
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.days.length) {
            setActiveInterval(data.days[data.days.length - 1] || undefined);
        }
    }, [data]);
    return (_jsxs(ConstituentEnabledWidget, { ...props, ...assignTestId('widget-code-with-me'), loading: isLoading || isLoadingUsageData, justifyContent: JustifyContent.Bottom, children: [_jsx(StatisticsWidgetInfoStats, { statName: "Current users", statValue: _jsx(NavigationLink, { href: "/users?constituentId=cwm", children: (_a = usageData === null || usageData === void 0 ? void 0 : usageData.total) !== null && _a !== void 0 ? _a : 0 }), size: StatisticsWidgetInfoStatsSize.TwoColumns }), activeInterval && (data === null || data === void 0 ? void 0 : data.days) && (_jsxs(_Fragment, { children: [_jsx(StatisticsWidgetBarChart, { intervals: data === null || data === void 0 ? void 0 : data.days, activeInterval: activeInterval, onActivateInterval: setActiveInterval }), _jsx(StatisticsWidgetTimeframeSelector, { timeframe: timeframe, onSelect: setTimeframe, label: "Daily usages" }), _jsx(IntervalStatisticsWidgetCounter, { icon: activityLogo, count: (_b = activeInterval.total) !== null && _b !== void 0 ? _b : 0, measure: "Maximum sessions", activeInterval: activeInterval }), data.maxUsageDay && (_jsx(StatisticsWidgetCounter, { icon: activityLogo, count: data.maxUsageDay.total, label: "Maximum sessions for the period" }))] }))] }));
}
