import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import Icon, { Size } from '@jetbrains/ring-ui/components/icon';
import info from '@jetbrains/icons/info';
import styles from './settings-group.css';
export function SettingsGroup(props) {
    const { groupName, children, className } = props;
    return (_jsxs("div", { className: classNames(styles.wrapper, className), children: [_jsx(H3, { className: styles.groupName, children: groupName }), children] }));
}
export function SettingsItem(props) {
    const { children, additionalInfo, readOnly, className } = props;
    return (_jsxs("div", { className: classNames(styles.item, className), children: [_jsx("div", { className: styles.mainContent, children: children }), additionalInfo && _jsx("div", { className: styles.additionalInfo, children: additionalInfo }), readOnly && (_jsxs("div", { className: styles.readOnly, children: [_jsx("span", { children: "Read Only" }), _jsx(Icon, { glyph: info, size: Size.Size16, className: styles.readOnlyIcon })] }))] }));
}
