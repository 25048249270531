import { fetchCodeWithMeDataEntity } from '../../api/code-with-me';
export function codeWithMeSessionResolver(sessionId) {
    return fetchCodeWithMeDataEntity(sessionId).catch((err) => {
        var _a, _b;
        const returnedError = { ...err };
        if ((_b = (_a = returnedError.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.detail) {
            returnedError.response.data.detail = `Code with Me - ${returnedError.response.data.detail}`;
        }
        return Promise.reject(returnedError);
    });
}
