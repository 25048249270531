import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import HorizontalContainer from '@common/page-layout/horizontal-container';
import SearchFilter from '@common/search-filter/search-filter';
import { EventsFilter } from './events-filter';
export function UserAuditLogsFilters({ params, onFiltersChange }) {
    const handleSearchValueChange = useCallback((newValue) => {
        onFiltersChange({ ...params, search: newValue });
    }, [onFiltersChange, params]);
    return (_jsxs(HorizontalContainer, { children: [_jsx(SearchFilter, { value: params.search || '', onChange: handleSearchValueChange, placeholder: "Search" }), _jsx(EventsFilter, { value: params.type, onChange: value => onFiltersChange({ ...params, type: value }) })] }));
}
