import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import auth from '@common/auth/auth';
import ProtectedHeaderLink from '../protected-header-link';
import styles from './navigation-menu.css';
const screenOptions = {
    default: styles.linksWrapper,
    mobile: styles.mobileLinksWrapper
};
export function MenuItems({ variant, onLinkClick }) {
    const isAnalyticsHeaderEnabled = auth.getFeatures().analyticsEnabled === true;
    const onClick = useCallback((evt) => {
        var _a, _b;
        if (onLinkClick && ((_b = (_a = evt.target) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.getNamedItem('href'))) {
            onLinkClick();
        }
    }, [onLinkClick]);
    return (_jsxs("div", { className: screenOptions[variant], onClick: onClick, children: [_jsx(ProtectedHeaderLink, { roles: ['ADMIN'], href: "/configuration", "data-test": "header-configuration-link", children: "Configuration" }), _jsx(ProtectedHeaderLink, { roles: ['ADMIN'], href: "/users", "data-test": "header-users-link", children: "Users" }), _jsx(ProtectedHeaderLink, { roles: ['ADMIN'], href: "/profiles", "data-test": "header-profiles-link", children: "Profiles" }), _jsx(ProtectedHeaderLink, { roles: ['ADMIN'], href: "/plugins", "data-test": "header-plugins-link", children: "Plugins" }), _jsx(ProtectedHeaderLink, { roles: ['ADMIN'], "data-test": "header-analytics-link", href: "/analytics", isHeaderPermitted: isAnalyticsHeaderEnabled, children: "Analytics" }), _jsx(ProtectedHeaderLink, { href: "/license-vault", roles: ['ADMIN'], "data-test": "header-license-vault-link", children: "Licenses" })] }));
}
