import { useCallback } from 'react';
import { createEnumParam } from 'serialize-query-params';
import { useSyncedSearchArgs } from '@common/navigation/use-synced-search-args';
export function useSelectedView() {
    var _a;
    const [urlParams, setUrlParams] = useSyncedSearchArgs(tsTypeToQuerySchema);
    const handleViewSelect = useCallback((view) => {
        setUrlParams({ ...urlParams, view });
    }, [urlParams, setUrlParams]);
    return [(_a = urlParams.view) !== null && _a !== void 0 ? _a : 'preview', handleViewSelect];
}
const tsTypeToQuerySchema = {
    view: createEnumParam(['preview', 'json'])
};
