// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gridContainer__d13 {
  display: grid;
  grid-template-columns: min-content 1fr max-content;
  grid-template-rows: min-content 1fr min-content;
  align-items: center;
  grid-template-areas:
    'icon heading heading'
    '. body body'
    '. footerLeft footerRight';
}

.gridContainer__d13 > *:nth-child(1) {
  grid-area: icon;
  margin-right: calc(var(--ring-unit) * 1.5);
  margin-bottom: calc(var(--ring-unit) * 0.5);
}

.gridContainer__d13 > *:nth-child(2) {
  grid-area: heading;
  margin-bottom: calc(var(--ring-unit) * 0.5);
}

.gridContainer__d13 > *:nth-child(3) {
  grid-area: body;
  margin-bottom: calc(var(--ring-unit) * 2);
}

.gridContainer__d13 > *:nth-child(4) {
  grid-area: footerLeft;
  margin-right: calc(var(--ring-unit) * 2);
}

.gridContainer__d13 > *:nth-child(5) {
  grid-area: footerRight;
}

.bodyText__fe5 {
  color: var(--ring-text-color);
}

.headerText__d46 {
  color: var(--ring-white-text-color);
}

.documentationButton__a2a {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/common/notifications/notification-content/actionable-notification-content/actionable-notification-content.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kDAAkD;EAClD,+CAA+C;EAC/C,mBAAmB;EACnB;;;8BAG4B;AAC9B;;AAEA;EACE,eAAe;EACf,0CAA0C;EAC1C,2CAA2C;AAC7C;;AAEA;EACE,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,eAAe;EACf,yCAAyC;AAC3C;;AAEA;EACE,qBAAqB;EACrB,wCAAwC;AAC1C;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".gridContainer {\n  display: grid;\n  grid-template-columns: min-content 1fr max-content;\n  grid-template-rows: min-content 1fr min-content;\n  align-items: center;\n  grid-template-areas:\n    'icon heading heading'\n    '. body body'\n    '. footerLeft footerRight';\n}\n\n.gridContainer > *:nth-child(1) {\n  grid-area: icon;\n  margin-right: calc(var(--ring-unit) * 1.5);\n  margin-bottom: calc(var(--ring-unit) * 0.5);\n}\n\n.gridContainer > *:nth-child(2) {\n  grid-area: heading;\n  margin-bottom: calc(var(--ring-unit) * 0.5);\n}\n\n.gridContainer > *:nth-child(3) {\n  grid-area: body;\n  margin-bottom: calc(var(--ring-unit) * 2);\n}\n\n.gridContainer > *:nth-child(4) {\n  grid-area: footerLeft;\n  margin-right: calc(var(--ring-unit) * 2);\n}\n\n.gridContainer > *:nth-child(5) {\n  grid-area: footerRight;\n}\n\n.bodyText {\n  color: var(--ring-text-color);\n}\n\n.headerText {\n  color: var(--ring-white-text-color);\n}\n\n.documentationButton {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridContainer": `gridContainer__d13`,
	"bodyText": `bodyText__fe5`,
	"headerText": `headerText__d46`,
	"documentationButton": `documentationButton__a2a`
};
export default ___CSS_LOADER_EXPORT___;
