export class BuildKey {
    constructor(buildNumber, buildOs, buildArchitecture) {
        this.buildNumber = buildNumber;
        this.buildOs = buildOs;
        this.buildArchitecture = buildArchitecture;
    }
    static fromProductBuild(build) {
        return new BuildKey(build.buildNumber, build.buildOs, build.buildArchitecture);
    }
    static getValidBuildKey(build) {
        return `${build.buildNumber}:${build.buildOs}:${build.buildArchitecture}`;
    }
    getValidBuildKey() {
        if (this.buildNumber && this.buildOs && this.buildArchitecture) {
            return `${this.buildNumber}:${this.buildOs}:${this.buildArchitecture}`;
        }
        return undefined;
    }
}
