import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import NavigationLink from '@app/common/navigation/navigation-link';
import { useEventsHistoryDateTableColumn } from '@modules/events-history';
import { ActorMention } from '@modules/actor';
import { BasicSelectableTable } from '@common/tables/basic-selectable-table';
import { withSortableColumnFlag } from '@common/tables/table-columns';
import { AuditLogsAction } from '../cells/audit-logs-action';
import { sortableColumns } from '../constanst';
const auditLogBaseColumns = [
    {
        id: 'plugin',
        title: 'Plugin',
        getValue(entry) {
            return _jsx(NavigationLink, { href: makePluginLink(entry), children: entry.plugin.id });
        }
    },
    {
        id: 'version',
        title: 'Version',
        getValue(entry) {
            return entry.plugin.version && _jsx(Text, { children: entry.plugin.version });
        }
    },
    {
        id: 'description',
        title: 'Event',
        getValue(plugin) {
            return _jsx(AuditLogsAction, { ...plugin });
        }
    },
    {
        id: 'createdBy',
        title: 'Author',
        getValue({ createdBy }) {
            return _jsx(ActorMention, { actor: createdBy, "data-test": "plugins-audit-log-item-author" });
        }
    }
];
export function PluginsAuditLogsTable({ data, isLoading, params, onParamsChange }) {
    var _a;
    const navigate = useNavigate();
    const dateColumn = useEventsHistoryDateTableColumn(data === null || data === void 0 ? void 0 : data.items, getEventDate, 'createdDate');
    const total = (_a = data === null || data === void 0 ? void 0 : data.total) !== null && _a !== void 0 ? _a : 0;
    const auditLogColumns = useMemo(() => withSortableColumnFlag([dateColumn].concat(auditLogBaseColumns), sortableColumns), [dateColumn]);
    return (_jsx(BasicSelectableTable, { data: (data === null || data === void 0 ? void 0 : data.items) || [], getItemKey: x => x.id, loading: isLoading, columns: auditLogColumns, selectable: false, renderEmpty: () => 'No changes found', onItemEnter: entry => navigate(makePluginLink(entry)), pagerProps: {
            total,
            disablePageSizeSelector: true,
            currentPage: 1,
            pageSize: DEFAULT_TABLE_PAGE_SIZE,
            onPageChange: newPage => {
                onParamsChange({ ...params, page: newPage });
            }
        }, sortKey: params.sortKey, sortOrder: params.sortOrder === 'DESC', onSort: event => onParamsChange({
            ...params,
            sortKey: event.column.id,
            sortOrder: event.order ? 'DESC' : 'ASC',
            page: 1
        }) }));
}
function getEventDate(auditLogEntry) {
    return auditLogEntry.createdDate;
}
function makePluginLink(auditLogEntry) {
    var _a;
    return `/plugins/${encodeURIComponent((_a = auditLogEntry === null || auditLogEntry === void 0 ? void 0 : auditLogEntry.plugin) === null || _a === void 0 ? void 0 : _a.id)}`;
}
