import { createEnumParam } from 'serialize-query-params';
import { pagingSortOrder } from '@api/paging-utils';
import { useSyncedSearchArgs } from '@common/navigation/use-synced-search-args';
import { sortableColumns } from './constants';
export function usePluginParams() {
    return useSyncedSearchArgs(tsTypeToQuerySchema, validatePluginFilter);
}
function validatePluginFilter(parsed) {
    return {
        sortKey: parsed.sortKey || 'version',
        sortOrder: parsed.sortOrder || 'DESC'
    };
}
const tsTypeToQuerySchema = {
    sortKey: createEnumParam(Object.values(sortableColumns)),
    sortOrder: createEnumParam(pagingSortOrder)
};
