import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import RingFieldsList from '@app/common/form/ring-fields-list';
import { PERSISTENT_STATE_XML_KIND, PERSISTENT_STATE_XPATH_KIND, REGISTRY_KIND, PROPERTIES_COMPONENT_KIND, CODESTYLE_XML_KIND, INSPECTION_PROFILE_XML_KIND } from '@modules/ide-provisioner/ide-settings/settings-builders';
import RegistryFields from './registry-fields';
import PersistentStateXpathFields from './persistent-state-xpath-fields';
import PersistentStateXmlFields from './persistent-state-xml-fields';
import PropertiesComponentFields from './properties-component-fields';
import CodeStyleFields from './code-style-fields';
import InspectionProfileFields from './inspection-profile-fields';
function SettingFields({ settingIndex }) {
    const { watch } = useFormContext();
    const watchKind = watch(`content.${settingIndex}.kind`);
    return (_jsxs(RingFieldsList, { children: [watchKind === PERSISTENT_STATE_XML_KIND && (_jsx(PersistentStateXmlFields, { settingIndex: settingIndex })), watchKind === PERSISTENT_STATE_XPATH_KIND && (_jsx(PersistentStateXpathFields, { settingIndex: settingIndex })), watchKind === REGISTRY_KIND && _jsx(RegistryFields, { settingIndex: settingIndex }), watchKind === PROPERTIES_COMPONENT_KIND && (_jsx(PropertiesComponentFields, { settingIndex: settingIndex })), watchKind === CODESTYLE_XML_KIND && _jsx(CodeStyleFields, { settingIndex: settingIndex }), watchKind === INSPECTION_PROFILE_XML_KIND && (_jsx(InspectionProfileFields, { settingIndex: settingIndex }))] }));
}
export default SettingFields;
