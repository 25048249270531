import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getMachineConfig, patchMachineConfig } from '../../api/machine-config';
import { formatApiError } from '../../api/errors';
// eslint-disable-next-line import/no-restricted-paths
import { useServerConfigurationContext } from '../../modules/machine-config/server-configuration-context';
import { DEFAULT_STALE_TIME } from './query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from './formatters/alerts';
export const keys = {
    all: ['machine-config']
};
export function useMachineConfig() {
    return useQuery({
        queryKey: keys.all,
        queryFn: () => getMachineConfig(),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load client machine configuration'))
    });
}
export function useMachineConfigMutation() {
    const queryClient = useQueryClient();
    const { onConfigChanged } = useServerConfigurationContext();
    return useMutation({
        mutationFn: (patch) => patchMachineConfig(patch),
        onSuccess: newMachineConfig => {
            queryClient.setQueryData(keys.all, newMachineConfig);
            // side-effect
            onConfigChanged();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to update client machine configuration'), () => 'Client machine configuration updated')
    });
}
