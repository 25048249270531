// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.refreshIconLoader__db8 svg {
  transform: rotate(-180deg);
  transition: transform 300ms;
}
`, "",{"version":3,"sources":["webpack://./src/common/loaders/icon-loaders.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,2BAA2B;AAC7B","sourcesContent":[".refreshIconLoader svg {\n  transform: rotate(-180deg);\n  transition: transform 300ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refreshIconLoader": `refreshIconLoader__db8`
};
export default ___CSS_LOADER_EXPORT___;
