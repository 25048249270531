import * as z from 'zod';
import { tSettingOptionSchema } from './setting-option-schema';
export const tDisabledPluginsSchema = (isRestricted, restrictedPlugins) => tSettingOptionSchema.extend({
    content: z
        .array(z.object({
        pluginId: isRestricted
            ? z
                .string()
                .min(1, { message: 'Please fill in this empty field' })
                .refine(val => restrictedPlugins === null || restrictedPlugins === void 0 ? void 0 : restrictedPlugins.includes(val), {
                message: 'This plugin is not allowed in Restricted Mode'
            })
            : z.string().min(1, { message: 'Please fill in this empty field' }),
        description: z
            .string()
            .nullable()
            .optional()
            .transform(it => it || ''),
        forced: z.boolean()
    }))
        .min(1, { message: 'Please add Plugin' })
});
