import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getIdeSettings } from '@app/common/store/profiles/selectors';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
import { SettingsList } from '../../common/settings-list';
import SettingsListEntry from '../../common/settings-list-entry';
import { SettingPlaceholder } from '../../common/settings-placeholder';
import { useSettingsContext } from '../../context/settings-context';
import { showHiddenSettingsPlaceholder, showFilteredSettings } from '../../functions/settings';
import { filterByFocusedApp, useProfilesParams } from '../../context/profiles-params-context';
import ProfilesIdeSettingsDialogForm from './profiles-ide-settings-dialog-form';
export function ProfileIdeSettings() {
    const dispatch = useDispatch();
    const settings = useSelector(getIdeSettings);
    const { focusedApp } = useProfilesParams();
    const filteredSettings = filterByFocusedApp(settings, focusedApp);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { setFormState, showForm, resetFormState, settingId } = useSettingsContext('ide');
    const deleteEntry = (settingId) => {
        dispatch(ProfileActions.deleteSetting({ settingType: 'ide', settingId }));
    };
    const onAddSetting = (values) => {
        dispatch(ProfileActions.updateIdeSettings(values));
        setIsCollapsed(false);
        resetFormState();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(SettingsList, { title: "ide settings", isCollapsed: isCollapsed, onToggleCollapsed: () => setIsCollapsed(prev => !prev), children: [isEmpty(settings) && _jsx(SettingPlaceholder, { title: "No IDE settings yet" }), showHiddenSettingsPlaceholder(settings, filteredSettings) && (_jsx(SettingPlaceholder, { title: settings.length === 1
                            ? 'One IDE setting is hidden'
                            : `${settings.length} IDE settings are hidden` })), showFilteredSettings(filteredSettings) &&
                        filteredSettings.map(entry => (_jsx(SettingsListEntry, { entry: entry, onEditClick: setFormState, onDeleteClick: deleteEntry, settingType: "ide" }, entry.id)))] }), showForm && (_jsx(ProfilesIdeSettingsDialogForm, { onReject: resetFormState, onSubmit: it => onAddSetting(it), initialFormValues: settings.find(({ id }) => id === settingId) }))] }));
}
