import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Select from '@jetbrains/ring-ui/components/select/select';
import Text from '@jetbrains/ring-ui/components/text/text';
import { getConfigMachineDownloadUrl } from '@modules/machine-config';
import NavigationLink from '@common/navigation/navigation-link';
import CopiedCode from '@common/clipboard/copied-code/copied-code';
import { copyToClipboard } from '@common/clipboard/copy-to-clipboard';
import RingFieldsList from '@common/form/ring-fields-list';
import auth from '@common/auth/auth';
import styles from './configuration-file-popup.css';
const codeValues = [
    {
        key: 'Windows',
        label: 'Windows',
        code: '%PROGRAMDATA%\\JetBrains\\Toolbox\\enterprise-config.json'
    },
    {
        key: 'Mac',
        label: 'Mac',
        code: '/Library/Application Support/JetBrains/Toolbox/enterprise-config.json'
    },
    {
        key: 'Linux',
        label: 'Linux',
        code: '/usr/share/jetbrains-toolbox/enterprise-config.json'
    }
];
const selectOptions = codeValues.map(({ key, label }) => {
    return { key, label };
});
const getCodeValue = (osLabel) => {
    var _a, _b;
    return (_b = (_a = codeValues.find(({ label }) => label === osLabel)) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : '';
};
export function ConfigurationFilePopup({ onReject }) {
    const [selectedOs, setSelectedOs] = useState(selectOptions[0]);
    const machineConfigUrl = getConfigMachineDownloadUrl(auth.getBaseURL());
    const onChange = (selected) => {
        if (selected) {
            setSelectedOs(selected);
        }
    };
    const handleCopy = () => {
        copyToClipboard(getCodeValue(selectedOs.label));
        onReject();
    };
    return (_jsxs(Dialog, { show: true, onCloseAttempt: onReject, contentClassName: styles.contentContainer, children: [_jsx(Header, { children: "Share Client Machine Configuration" }), _jsx(Content, { children: _jsxs(RingFieldsList, { children: [_jsxs(Text, { children: [_jsx(NavigationLink, { href: machineConfigUrl, reloadDocument: true, children: "Download the client machine configuration file\u00A0" }), "and place it in a dedicated system location on the developer machine:"] }), _jsx(Select, { data: selectOptions, selected: selectedOs, onChange: onChange }), _jsx(CopiedCode, { className: styles.codeBlock, text: getCodeValue(selectedOs.label), inline: true })] }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleCopy, children: "Copy path" }), _jsx(Button, { onClick: onReject, children: "Close" })] })] }));
}
