import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import Button from '@jetbrains/ring-ui/components/button/button';
import addIcon from '@jetbrains/icons/add';
import closeIcon from '@jetbrains/icons/close';
import Text from '@jetbrains/ring-ui/components/text/text';
import Select from '@jetbrains/ring-ui/components/select/select';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import chevronDown from '@jetbrains/icons/chevron-down';
import { Directions } from '@jetbrains/ring-ui/components/popup/popup.consts';
import RingFieldsList from '@app/common/form/ring-fields-list';
import { getAutoInstalledFormContentPlugin } from '../../../schemas/ctors';
import { formatPluginSourceName } from '../../formatters';
import { useAllFormattedPluginsSelectOptions } from '../../hooks';
import EditPluginDialog from './edit-plugin-dialog';
import NewPluginDialog from './new-plugin-dialog';
import styles from './content-field.css';
const PreferableSelectsPopupDirections = [Directions.BOTTOM_LEFT, Directions.TOP_LEFT];
function ContentField() {
    const { fields, append, remove, update } = useFieldArray({ name: 'content' });
    const [newPlugin, setNewPlugin] = useState();
    const [configurePluginIdx, setConfigurePluginIdx] = useState();
    const [filterPlugins, setFilterPlugins] = useState('');
    const onSelectNewPlugin = useCallback((option) => {
        const key = (typeof option === 'string' ? option : option === null || option === void 0 ? void 0 : option.key) || 'new plugin';
        const source = typeof option === 'string' ? 'MARKETPLACE' : option === null || option === void 0 ? void 0 : option.source;
        setNewPlugin(getAutoInstalledFormContentPlugin(`${key}`, source));
    }, []);
    const { data: plugins, isFetching: isFetchingPlugins } = useAllFormattedPluginsSelectOptions(filterPlugins);
    const addPluginAnchor = useCallback(({ wrapperProps, buttonProps, popup }) => {
        const restProps = {
            ...buttonProps,
            'data-test': [buttonProps['data-test'], 'add-plugin-anchor'].join(' ')
        };
        return (_jsxs("span", { ...wrapperProps, children: [_jsxs(Button, { icon: addIcon, primary: true, ...restProps, children: ["Add plugin ", _jsx(Icon, { glyph: chevronDown })] }), popup] }));
    }, []);
    const onSubmitNewPlugin = useCallback((val) => {
        const newVal = mapFormAutoinstalledPluginToModel(val);
        if (newPlugin !== undefined) {
            append(newVal);
            setNewPlugin(undefined);
        }
    }, [newPlugin, append]);
    const onRejectNewPlugin = useCallback(() => {
        setNewPlugin(undefined);
    }, []);
    const onEditPlugin = useCallback((value) => {
        const newValue = mapFormAutoinstalledPluginToModel(value);
        setConfigurePluginIdx(undefined);
        if (configurePluginIdx != null) {
            update(configurePluginIdx, newValue);
        }
    }, [configurePluginIdx, update]);
    const onCancelEditingNewPlugin = useCallback(() => setConfigurePluginIdx(undefined), []);
    return (_jsxs(RingFieldsList, { children: [_jsx(Text, { info: true, children: "Select plugins to install automatically" }), fields.map((x, ind) => {
                var _a;
                const label = ((_a = plugins.find(p => p.key === x.pluginId)) === null || _a === void 0 ? void 0 : _a.label) || x.pluginId;
                return (_jsxs("div", { className: styles.contentFieldBlock, children: [_jsx(Button, { icon: closeIcon, onClick: () => remove(ind) }), _jsxs("div", { children: [_jsx("div", { className: styles.contentFieldBlockTitle, children: label }), _jsxs("div", { className: styles.contentFieldBlockDescription, children: ["Version ", x.version || 'latest compatible', " from ", formatPluginSourceName(x.source), ' ', x.channel != null &&
                                            x.channel.name !== 'Stable' &&
                                            ` with an additional channel ${x.channel.name}`] })] }), _jsx(Button, { onClick: () => setConfigurePluginIdx(ind), text: true, primary: true, children: "Configure" })] }, x.id));
            }), _jsx(Select, { type: Select.Type.CUSTOM, customAnchor: addPluginAnchor, onSelect: onSelectNewPlugin, filter: true, loading: isFetchingPlugins, onFilter: setFilterPlugins, onClose: () => setFilterPlugins(''), data: plugins, directions: PreferableSelectsPopupDirections, onAdd: onSelectNewPlugin, maxHeight: 500, add: {
                    alwaysVisible: true,
                    label: filterPlugins,
                    prefix: 'Add plugin with ID:'
                } }), newPlugin !== undefined && (_jsx(NewPluginDialog, { initialValue: newPlugin, onSave: onSubmitNewPlugin, onCancel: onRejectNewPlugin })), configurePluginIdx !== undefined && !!fields[configurePluginIdx] && (_jsx(EditPluginDialog, { initialValue: fields[configurePluginIdx], onSave: onEditPlugin, onCancel: onCancelEditingNewPlugin }))] }));
}
export default ContentField;
function mapFormAutoinstalledPluginToModel(value) {
    if (value.type === 'channel') {
        return { ...value, version: undefined };
    }
    return { ...value, channel: undefined, forced: true };
}
