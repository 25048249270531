import { AxiosHeaders } from 'axios';
import jetBrainsLogo from '@jetbrains/logos/jetbrains-ide-services/apple-touch-icon.png';
import showAuthDialog from '@jetbrains/ring-ui/components/auth-dialog-service/auth-dialog-service';
import { localStorageService } from '@app/common/storage';
import { apiClientManager, baseUrl } from '@common/api';
import { getHashParams, removeHashParamsFromUrl } from './url-hash';
import withLock from './lock';
import { doRefreshTokens, exchangeCodeForTokens, getUserProfile, redirectToLogin } from './auth-requests';
import { AuthError, UserDeactivatedError, SessionExpiredError, Tokens, UnexpectedServerError } from './auth-models';
import tabStateListener from './tab-state-listener';
const AUTH_LOCK = 'AUTH_LOCK';
const TOKENS_KEY = 'TOKENS_V2';
const LAST_USER_AUTH_ACTION = 'LAST_USER_AUTH_ACTION';
export class Auth {
    constructor(clientManager) {
        this.clientManager = clientManager;
        this.sessionExpired = false;
        this.isInitialized = false;
        // fixme: should be postponed, until we authenticate
        clientManager.addRequestInterceptor({
            onFulfilled: this.getAuthRequestInterceptor()
        });
        // fixme: split this interceptor into separate
        clientManager.addResponseInterceptor({
            onRejected: this.getErrorResponseInterceptor()
        });
    }
    getBaseURL() {
        return baseUrl;
    }
    async login(option) {
        await redirectToLogin(baseUrl, option).then(targetUrl => {
            window.location.href = targetUrl;
        });
    }
    async logout() {
        await withLock(AUTH_LOCK, () => this.doLogout());
        await withLock(AUTH_LOCK, () => {
            localStorageService.set(LAST_USER_AUTH_ACTION, 'logout');
            return Promise.resolve();
        });
    }
    isAuthenticated() {
        return !!this.userProfile;
    }
    isGuest() {
        return this.isInitialized && !this.isAuthenticated();
    }
    getUserProfile() {
        return this.userProfile;
    }
    getFeatures() {
        if (this.userProfile == null) {
            return {};
        }
        return this.userProfile.features || {};
    }
    hasRoles(roles = []) {
        return this.userProfile ? roles.includes(this.userProfile.role) : false;
    }
    /** @throws {UnexpectedServerError} */
    async init() {
        await withLock(AUTH_LOCK, async () => {
            try {
                const authCode = getHashParams().code;
                if (authCode) {
                    removeHashParamsFromUrl();
                    await this.continueLoginFlow(authCode);
                    const tokens = await this.getTokens();
                    if (tokens) {
                        // tokens presented, means manual login was successful
                        localStorageService.set(LAST_USER_AUTH_ACTION, 'login');
                    }
                    else {
                        localStorageService.remove(LAST_USER_AUTH_ACTION);
                    }
                }
                await this.loadUserProfile();
            }
            catch (error) {
                if (error instanceof AuthError) {
                    await this.markSessionAsExpired(error.message);
                    await this.doLogout(); // Gracefully log out user.
                }
                else {
                    throw error;
                }
            }
            finally {
                this.isInitialized = true;
            }
        });
        this.setUpStorageListener();
    }
    getAuthRequestInterceptor() {
        return async (requestConfig) => {
            const tokens = await withLock(AUTH_LOCK, () => this.getTokens());
            const headers = AxiosHeaders.from(requestConfig.headers || {});
            if (tokens) {
                headers.set('Authorization', `${tokens.tokenType} ${tokens.accessToken}`);
            }
            return {
                ...requestConfig,
                headers
            };
        };
    }
    getErrorResponseInterceptor(sessionExpiredErrorCodes = [401]) {
        return async (error) => {
            const { response } = error;
            if (sessionExpiredErrorCodes.some(code => code === (response === null || response === void 0 ? void 0 : response.status))) {
                await this.markSessionAsExpired(response === null || response === void 0 ? void 0 : response.statusText);
                return Promise.reject(new SessionExpiredError());
            }
            return Promise.reject(error);
        };
    }
    async getTokens() {
        if (this.sessionExpired) {
            throw new SessionExpiredError();
        }
        let tokens = this.loadTokensFromStorage();
        if (tokens && tokens.shouldBeRefreshed()) {
            tokens = await this.refreshTokens(tokens);
            this.saveTokensToStorage(tokens);
        }
        return tokens;
    }
    /** Continues login flow - exchanges authorization code from URL for tokens and saves tokens to storage. */
    async continueLoginFlow(authCode) {
        try {
            const response = await exchangeCodeForTokens(baseUrl, authCode);
            const tokens = Tokens.fromOAuthResponse(response);
            console.log('Auth: Exchanged authorization code for tokens');
            this.saveTokensToStorage(tokens);
        }
        catch (error) {
            if (error instanceof AuthError) {
                console.error('Auth: Failed to exchange code for tokens', error);
                this.showAuthPopover(`Error: ${error.message}`);
            }
            this.saveTokensToStorage(undefined);
        }
    }
    /**
     * @param {Tokens} tokens
     * @returns {Promise<Tokens>}
     * @throws {SessionExpiredError}
     * @throws {UnexpectedServerError}
     */
    async refreshTokens(tokens) {
        try {
            const response = await doRefreshTokens(baseUrl, tokens.refreshToken);
            const refreshedTokens = Tokens.fromOAuthResponse(response, tokens.refreshToken);
            console.log('Auth: Refreshed tokens');
            return refreshedTokens;
        }
        catch (error) {
            if (error instanceof AuthError) {
                await this.markSessionAsExpired(error.message);
                if (error.status === 400 || error.status === 401) {
                    console.warn('Auth: Could not load user profile - session expired');
                    throw new SessionExpiredError();
                }
                else if (error.status === 403) {
                    console.warn('Auth: Could not load user profile - user is deactivated');
                    throw new UserDeactivatedError();
                }
                else {
                    console.error('Auth: Failed to refresh tokens', error);
                    this.showAuthPopover(`Failed to refresh tokens: ${error.message}`);
                    throw new UnexpectedServerError(error);
                }
            }
            return undefined;
        }
    }
    /**
     * @throws {SessionExpiredError}
     * @throws {UnexpectedServerError}
     */
    async loadUserProfile() {
        const tokens = await this.getTokens();
        if (tokens != null) {
            await this.makeRequestForUserProfile(tokens.accessToken);
        }
        else {
            this.userProfile = undefined;
        }
    }
    async makeRequestForUserProfile(accessToken) {
        try {
            this.userProfile = await getUserProfile(baseUrl, accessToken);
            console.log('Auth: Loaded user profile');
        }
        catch (error) {
            if (error instanceof AuthError) {
                if (error.status === 401) {
                    console.warn('Auth: Could not load user profile - session expired');
                    throw new SessionExpiredError();
                }
                if (error.status === 403) {
                    console.warn('Auth: Could not load user profile - user is deactivated');
                    throw new UserDeactivatedError();
                }
            }
            else if (error instanceof Error) {
                console.error('Auth: Failed to load user profile', error);
                this.showAuthPopover(`Error: ${error.message}`);
                throw new UnexpectedServerError(error);
            }
        }
    }
    async doLogout() {
        this.saveTokensToStorage(undefined);
        this.userProfile = undefined;
        this.sessionExpired = false;
    }
    saveTokensToStorage(tokens) {
        if (tokens == null) {
            localStorageService.remove(TOKENS_KEY);
            console.log('Auth: Removed tokens from local storage');
        }
        else {
            localStorageService.set(TOKENS_KEY, tokens);
            console.log('Auth: Saved tokens to local storage');
        }
    }
    loadTokensFromStorage() {
        const tokensConfig = localStorageService.get(TOKENS_KEY);
        if (tokensConfig) {
            try {
                const tokens = new Tokens(tokensConfig);
                console.debug('Auth: Loaded tokens from local storage');
                return tokens;
            }
            catch {
                console.warn('Auth: Could not load tokens from local storage - broken state');
                localStorageService.remove(TOKENS_KEY);
                return undefined;
            }
        }
        else {
            console.debug('Auth: No tokens found in local storage');
            return undefined;
        }
    }
    /** Starts listening to changes in local storage made by other browser tabs */
    setUpStorageListener() {
        this.unsubscribeOtherTabSessionListener = localStorageService.subscribeFromOtherTabChange(LAST_USER_AUTH_ACTION, async (value) => {
            if (value === 'logout' && this.isAuthenticated()) {
                this.reloadPage();
            }
        });
        this.unsubscribeActivateTabListener = tabStateListener.on(isTabVisible => {
            if (isTabVisible && !this.isAuthenticated()) {
                const lastOperation = localStorageService.get(LAST_USER_AUTH_ACTION);
                if (lastOperation === 'login') {
                    this.reloadPage();
                }
            }
        });
    }
    clearListeners() {
        if (this.unsubscribeOtherTabSessionListener) {
            this.unsubscribeOtherTabSessionListener();
            this.unsubscribeOtherTabSessionListener = undefined;
        }
        if (this.unsubscribeActivateTabListener) {
            this.unsubscribeActivateTabListener();
            this.unsubscribeActivateTabListener = undefined;
        }
    }
    async markSessionAsExpired(expiredSessionMessage = 'Session expired') {
        // User's tokens expired, was revoked, or user logged out in a different tab.
        // We display auth popover but don't reload the UI because the user may have some unsaved changes.
        console.warn(`Auth: ${expiredSessionMessage}`);
        this.saveTokensToStorage(undefined);
        this.sessionExpired = true;
        this.showAuthPopover(expiredSessionMessage);
    }
    showAuthPopover(errorMessage) {
        showAuthDialog({
            // The AuthDialog component does not support applying your own data-test, even though the props accept it
            // https://github.com/JetBrains/ring-ui/blob/master/src/auth-dialog/auth-dialog.tsx#L101-L115
            // So in E2E tests, we match for the hardcoded data-test values in ring-ui instead
            // ...assignTestId('auth-dialog'),
            serviceName: 'JetBrains IDE Services',
            serviceImage: jetBrainsLogo,
            errorMessage,
            onConfirm: () => this.login(),
            onCancel: async () => {
                await this.logout();
                window.location.reload();
            }
        });
    }
    reloadPage() {
        this.clearListeners();
        window.location.reload();
    }
}
// fixme: initialize explicitly
const auth = new Auth(apiClientManager);
export default auth;
