import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
import styles from './paragraph-loader.css';
export function ParagraphLoader({ linesNumber = 3, className }) {
    const arr = useMemo(() => [...new Array(Math.max(linesNumber, 1))].map((_, idx) => idx), [linesNumber]);
    return (_jsx("div", { className: classNames(styles.wrapper, className), "data-test": "paragraph-loader", children: arr.map(paragraphNum => (_jsx("div", { className: classNames({
                [styles.paragraph]: true,
                [styles.paragraphShort]: paragraphNum === 0
            }) }, `paragraph-loader-line-${paragraphNum}`))) }));
}
