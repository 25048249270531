import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import RegisteredInput from '@app/common/hook-form/registered-input';
import { Required } from '@app/common/form/important-asterisk';
import RegisteredCheckbox from '@app/common/hook-form/registered-checkbox';
function ReplaceTextFields({ settingIndex, specsIndex }) {
    const { control } = useFormContext();
    return (_jsxs(_Fragment, { children: [_jsx(RegisteredInput, { label: Required('Replacement text'), size: Size.FULL, name: `content.${settingIndex}.specs.${specsIndex}.text`, control: control, autoComplete: "off" }), _jsx(RegisteredCheckbox, { label: "CDATA", name: `content.${settingIndex}.specs.${specsIndex}.cdata`, control: control })] }));
}
export default ReplaceTextFields;
