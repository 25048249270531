import { useQuery } from '@tanstack/react-query';
import { formatApiError } from '@api/errors';
import { getServerConfig } from '@api/server-config';
import { getQueryAlertMessages } from '@app/common/queries/formatters/alerts';
export const keys = {
    config: ['server-confing']
};
export function useGetServerConfig({ enabled }) {
    return useQuery({
        queryKey: keys.config,
        queryFn: getServerConfig,
        enabled,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load server config')),
        refetchInterval: 60 * 60 * 1000,
        refetchIntervalInBackground: true
    });
}
