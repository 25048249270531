import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import { noop } from 'lodash';
import { useAiDisableMutation, useProviderInfo } from '@app/common/queries/ai-enterprise';
import { ConstituentDialog } from '@modules/constituents/constituent-dialog';
import { AI_PROVIDERS } from '../../constants';
export function AiDisableWithoutProvidersDialog({ provider, onCloseAttempt }) {
    const providerLabel = useMemo(() => { var _a; return (_a = AI_PROVIDERS.find(({ key }) => key === provider)) === null || _a === void 0 ? void 0 : _a.label; }, [provider]);
    const { data } = useProviderInfo(provider);
    const activeIdeProfilesCount = useMemo(() => data === null || data === void 0 ? void 0 : data.activeIdeProfilesCount, [data]);
    const { mutateAsync: disableAi, isLoading } = useAiDisableMutation(() => onCloseAttempt(true));
    const handleDisable = useCallback(() => disableAi().catch(noop), [disableAi]);
    const handleClose = useCallback(() => onCloseAttempt(false), [onCloseAttempt]);
    return (_jsxs(ConstituentDialog, { title: `Delete ${providerLabel} LLM provider`, children: [_jsx(Content, { children: _jsxs(Text, { children: [activeIdeProfilesCount, " users are currently using ", providerLabel, ". If you remove the", providerLabel, " ", _jsx("br", {}), " LLM provider, the AI Enterprise will be disabled."] }) }), _jsxs(Panel, { children: [_jsx(Button, { onClick: handleDisable, loader: isLoading, danger: true, children: "Remove" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
