import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import Button from '@jetbrains/ring-ui/components/button/button';
import trashIcon from '@jetbrains/icons/trash';
import pencilIcon from '@jetbrains/icons/pencil';
import classNames from 'classnames';
// eslint-disable-next-line no-restricted-imports
import utilStyles from '@common/util/utils.css';
import styles from './rule-editor.css';
function RuleEditor({ infoText, onRemove, onEdit, className, contentClassName, children, isEditable = true, isRemovable = true }) {
    return (_jsxs("div", { className: className, children: [_jsxs("div", { className: styles.topBar, children: [_jsx(Text, { info: true, children: infoText }), _jsxs("div", { className: styles.buttonsToolbar, children: [isEditable && (_jsx(Button, { icon: pencilIcon, text: true, inline: true, onClick: () => {
                                    if (onEdit !== undefined) {
                                        onEdit();
                                    }
                                } })), isRemovable && (_jsx(Button, { icon: trashIcon, text: true, inline: true, className: utilStyles.zeroPadded, onClick: () => {
                                    if (onRemove !== undefined) {
                                        onRemove();
                                    }
                                } }))] })] }), _jsx("div", { className: classNames(styles.content, contentClassName), children: children })] }));
}
export default RuleEditor;
