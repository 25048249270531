// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.propertyName__dce {
  letter-spacing: 1px;
  font-weight: bold;
}

.propertyValue__e19 {
  white-space: normal;
}

.property__fa9 {
  line-height: var(--ring-line-height-lower);
  font-size: var(--ring-font-size-smaller);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-history/cells/extract-properties-diff.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,0CAA0C;EAC1C,wCAAwC;AAC1C","sourcesContent":[".propertyName {\n  letter-spacing: 1px;\n  font-weight: bold;\n}\n\n.propertyValue {\n  white-space: normal;\n}\n\n.property {\n  line-height: var(--ring-line-height-lower);\n  font-size: var(--ring-font-size-smaller);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"propertyName": `propertyName__dce`,
	"propertyValue": `propertyValue__e19`,
	"property": `property__fa9`
};
export default ___CSS_LOADER_EXPORT___;
