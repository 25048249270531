// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleWrapper__b94 {
  display: inline-flex;
  align-items: baseline;
}

.badge__d42 {
  margin-left: var(--ring-unit);

  font-weight: 400;
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.badge__d42 + .badge__d42 {
  margin-left: var(--ring-unit);
}

.badge__d42.enabled__e22 {
  color: var(--ring-success-color);
}

.badge__d42.disabled__de8 {
  color: var(--ring-secondary-color);
}

.badge__d42.warn__c13 {
  color: var(--ring-warning-color);
}

.badge__d42.primary__c05 {
  color: var(--ring-main-color);
}

.badge__d42.error__d12 {
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/modules/constituents/constituent-block/constituent-block.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;;EAE7B,gBAAgB;EAChB,wCAAwC;EACxC,2CAA2C;AAC7C;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".titleWrapper {\n  display: inline-flex;\n  align-items: baseline;\n}\n\n.badge {\n  margin-left: var(--ring-unit);\n\n  font-weight: 400;\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n}\n\n.badge + .badge {\n  margin-left: var(--ring-unit);\n}\n\n.badge.enabled {\n  color: var(--ring-success-color);\n}\n\n.badge.disabled {\n  color: var(--ring-secondary-color);\n}\n\n.badge.warn {\n  color: var(--ring-warning-color);\n}\n\n.badge.primary {\n  color: var(--ring-main-color);\n}\n\n.badge.error {\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrapper": `titleWrapper__b94`,
	"badge": `badge__d42`,
	"enabled": `enabled__e22`,
	"disabled": `disabled__de8`,
	"warn": `warn__c13`,
	"primary": `primary__c05`,
	"error": `error__d12`
};
export default ___CSS_LOADER_EXPORT___;
