import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { partition } from 'lodash';
import { SelectableList } from '@common/selectable-list/selectable-list';
import { SelectableListGroup } from '@common/selectable-list/selectable-list-group';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { ApplicationListItem } from './application-list-item/application-list-item';
export function ApplicationsList(props) {
    const { applications, selectedApplication, onApplicationSelect, className } = props;
    const [availableApplications, unavailableApplications] = useMemo(() => {
        return partition(applications, app => app.available);
    }, [applications]);
    return (_jsxs(SelectableList, { ...assignTestId('applications-list'), className: className, children: [!!availableApplications.length && (_jsx(SelectableListGroup, { ...assignTestId('available-applications-group'), groupName: "Available Applications", children: availableApplications.map(app => (_jsx(ApplicationListItem, { item: app, onItemClick: onApplicationSelect, selected: selectedApplication.id === app.id }, app.id))) })), !!unavailableApplications.length && (_jsx(SelectableListGroup, { ...assignTestId('unavailable-applications-group'), groupName: "Unavailable Applications", children: unavailableApplications.map(app => (_jsx(ApplicationListItem, { item: app, onItemClick: onApplicationSelect, selected: selectedApplication.id === app.id }, app.id))) }))] }));
}
