// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__c89 .successful__a85 {
  color: var(--ring-success-color);
  fill: var(--ring-success-color);
}

.wrapper__c89 .failed__e67 {
  color: var(--ring-error-color);
  fill: var(--ring-error-color);
}

.infoText__b21 {
  margin-top: var(--ring-unit);
}

.statisticsCounter__a46 {
  margin-top: calc(var(--ring-unit) * 1.5);
}
`, "",{"version":3,"sources":["webpack://./src/modules/code-with-me/code-with-me-constituent/code-with-me-sessions-widget/code-with-me-sessions-widget.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,+BAA+B;AACjC;;AAEA;EACE,8BAA8B;EAC9B,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".wrapper .successful {\n  color: var(--ring-success-color);\n  fill: var(--ring-success-color);\n}\n\n.wrapper .failed {\n  color: var(--ring-error-color);\n  fill: var(--ring-error-color);\n}\n\n.infoText {\n  margin-top: var(--ring-unit);\n}\n\n.statisticsCounter {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__c89`,
	"successful": `successful__a85`,
	"failed": `failed__e67`,
	"infoText": `infoText__b21`,
	"statisticsCounter": `statisticsCounter__a46`
};
export default ___CSS_LOADER_EXPORT___;
