import { createEnumParam, NumberParam, StringParam } from 'serialize-query-params';
import { pagingSortOrder } from '@api/paging-utils';
import { useSyncedSearchArgs } from '@common/navigation/use-synced-search-args';
import { sortableColumns } from './constants';
export function useProfilesParams() {
    return useSyncedSearchArgs(tsTypeToQuerySchema, validateProfilesFilter);
}
const tsTypeToQuerySchema = {
    query: StringParam,
    page: NumberParam,
    sortKey: createEnumParam(Object.values(sortableColumns)),
    sortOrder: createEnumParam(pagingSortOrder)
};
function validateProfilesFilter(parsed) {
    var _a, _b, _c;
    return {
        query: (_a = parsed.query) !== null && _a !== void 0 ? _a : '',
        page: parsed.page && parsed.page > 0 ? parsed.page : 1,
        sortKey: (_b = parsed.sortKey) !== null && _b !== void 0 ? _b : 'title',
        sortOrder: (_c = parsed.sortOrder) !== null && _c !== void 0 ? _c : 'ASC'
    };
}
