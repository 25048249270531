export class Tokens {
    constructor(config) {
        this.accessToken = config.accessToken;
        this.tokenType = config.tokenType || 'Bearer';
        this.expiresAt = config.expiresAt;
        this.refreshToken = config.refreshToken;
    }
    timeToRefresh() {
        const timeToExpiry = this.expiresAt - Date.now();
        return timeToExpiry - 60000; // refresh 1 minute before token expires
    }
    shouldBeRefreshed() {
        return this.timeToRefresh() <= 0;
    }
    /**
     * Creates new instance from OAuth "exchange authorization code for access and refresh tokens" response.
     */
    static fromOAuthResponse(response, refreshTokenFallback = '') {
        return new Tokens({
            accessToken: response.access_token,
            tokenType: response.token_type,
            expiresAt: Date.now() + 1000 * response.expires_in,
            refreshToken: response.refresh_token || refreshTokenFallback
        });
    }
}
export class AuthError extends Error {
    constructor(message, status) {
        super(message);
        this.name = this.constructor.name;
        this.status = status;
    }
}
export class SessionExpiredError extends AuthError {
    constructor() {
        super('Session expired');
    }
}
export class UserDeactivatedError extends AuthError {
    constructor() {
        super('User is deactivated');
    }
}
// fixme: move to license-activation
export class LicenseInactiveError extends AuthError {
    constructor() {
        super('License inactive');
    }
}
/** Server responded with unexpected error (e.g. 500) or did not respond at all. */
export class UnexpectedServerError extends AuthError {
    constructor(cause) {
        super(`Unexpected server error: ${cause.message}`, cause.status);
        this.cause = cause;
    }
}
