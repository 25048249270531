import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Button from '@jetbrains/ring-ui/components/button/button';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Toggle from '@jetbrains/ring-ui/components/toggle/toggle';
import { useDialogCancelConfirmation } from '@app/common/hooks/use-dialog-cancel-confirmation';
import NavigationLink from '@common/navigation/navigation-link';
import { ExternalLinks } from '@common/constants/constants';
export function MarketplaceSettingsDialogForm({ item, onReject, onSubmit }) {
    const [initialValue] = useState((item === null || item === void 0 ? void 0 : item.value) === undefined ? true : item === null || item === void 0 ? void 0 : item.value);
    const [currentValue, setCurrentValue] = useState(initialValue);
    const isDirty = currentValue !== initialValue;
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(onReject, isDirty);
    const marketPlaceLink = (_jsx(NavigationLink, { href: ExternalLinks.JB_MARKETPLACE, target: "_blank", children: "JetBrains Marketplace" }));
    return (_jsxs(Dialog, { show: true, autoFocusFirst: false, onCloseAttempt: onCloseAttempt, children: [_jsx(Header, { children: "Configure Marketplace Settings" }), _jsx(Content, { children: _jsx(Toggle, { checked: currentValue, onChange: e => setCurrentValue(e.target.checked), children: currentValue ? (_jsxs("span", { children: ["Access to ", marketPlaceLink, " is allowed.", _jsx("br", {}), _jsx(Text, { info: true, size: "s", children: "Default behaviour" })] })) : (_jsxs(Text, { children: ["Access to ", marketPlaceLink, " is denied", _jsx("br", {}), _jsx(Text, { info: true, size: "s", children: "Overrides default" })] })) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: () => onSubmit({ value: currentValue }), type: "submit", disabled: !isDirty, children: "Save" }), _jsx(Button, { ref: cancelButtonRef, onClick: onReject, children: "Cancel" })] })] }));
}
