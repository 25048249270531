import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import exceptionIcon from '@jetbrains/icons/exception';
import ButtonSet from '@jetbrains/ring-ui/components/button-set/button-set';
import Button from '@jetbrains/ring-ui/components/button/button';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import Text from '@jetbrains/ring-ui/components/text/text';
import { TutorialAlertContent } from '@app/pages/profiles/profile-details/banner/tutorial/tutorial-alert-content';
import { ensureProfileDetailsQueryData, useProfileBackendChanges, useUpdateProfileMutation } from '@app/common/queries/profiles';
import { getProfile, getProfileTitle, isStateChanged } from '@app/common/store/profiles/selectors';
import { InformationBanner } from '@app/common/information-banner/information-banner';
import { RouteLeavingGuard } from '@app/common/route-leaving-guard/route-leaving-guard';
import { useControlledNotification } from '@app/common/notifications/use-controlled-notification';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
import styles from './profiles-banner.css';
export function ProfilesBanner({ is404, allowedRoutes }) {
    var _a, _b;
    const dispatch = useDispatch();
    const profileTitle = useSelector(getProfileTitle);
    const isChanged = useSelector(isStateChanged);
    const mutation = useUpdateProfileMutation();
    const profile = useSelector(getProfile);
    const { data: cannotSync } = useProfileBackendChanges(profile, !mutation.isLoading);
    const isUpdateConflict = ((_b = (_a = mutation.error) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.status) === 409;
    const handleDiscard = useCallback(async () => {
        const originalProfile = await ensureProfileDetailsQueryData(profile.id);
        batch(() => {
            dispatch(ProfileActions.setNewProfiles(originalProfile));
            dispatch(ProfileActions.setImmutableProfilesState(originalProfile));
        });
    }, [dispatch, profile]);
    const { showNotification } = useControlledNotification('profile-tutorial-alert', _jsx(TutorialAlertContent, {}));
    const handleSubmit = useCallback(() => {
        mutation.mutate(profile, {
            onSuccess: () => {
                batch(() => {
                    dispatch(ProfileActions.setNewProfiles(profile));
                    dispatch(ProfileActions.setImmutableProfilesState(profile));
                });
                showNotification();
            }
        });
    }, [dispatch, mutation, profile, showNotification]);
    if (is404) {
        return (_jsx(InformationBanner, { variant: "error", children: "Profile has been deleted by someone else. Please create a new profile" }));
    }
    if (!isChanged) {
        return null;
    }
    if (cannotSync || isUpdateConflict) {
        return (_jsx(InformationBanner, { variant: "error", buttons: _jsx(Button, { "data-test": "reset-button", onClick: handleDiscard, children: "Reset & Reload" }), children: "Profile was changed by someone else. Please refresh the page." }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(InformationBanner, { buttons: _jsxs(ButtonSet, { children: [mutation.isError && _jsx(Icon, { glyph: exceptionIcon, className: styles.errorIcon }), _jsx(Button, { onClick: handleDiscard, loader: mutation.isLoading, children: "Discard" }), _jsx(Button, { "data-test": "save-changes-button", onClick: handleSubmit, primary: true, loader: mutation.isLoading, children: "Save changes" })] }), children: _jsxs(Text, { children: ["\"", profileTitle, "\" profile was updated. Make sure to save the changes once you've completed updating the profile."] }) }), _jsx(RouteLeavingGuard, { when: isChanged, allowedRoutesPatterns: allowedRoutes })] }));
}
