import { useQuery, useMutation } from '@tanstack/react-query';
import { formatApiError } from '@api/errors';
import { disableLvConstituent, enableLvConstituent, getLvConstituentState, saveLicenseVaultPostpaidLimits, getLicenseVaultLimits, getDeniedLicensesRequests } from '@api/license-vault';
import queryClient, { DEFAULT_POLLING_INTERVAL } from '@app/common/queries/query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from '@app/common/queries/formatters/alerts';
import { invalidateAllQueries } from './functions';
export const keys = {
    all: ['lv'],
    status: ['lv', 'lv-enabled'],
    limits: ['lv', 'lv-limits'],
    deniedLicenses: ['lv', 'lv-denied-licenses']
};
export function useLvConstituentState() {
    return useQuery({
        queryKey: keys.status,
        queryFn: getLvConstituentState,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch License Vault status'))
    });
}
export function useEnableLvConstituent(onSuccess) {
    return useMutation({
        mutationFn: enableLvConstituent,
        onSuccess: async (newLvConstituentState) => {
            queryClient.setQueryData(keys.status, newLvConstituentState);
            await invalidateAllQueries();
            onSuccess(newLvConstituentState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to enable License Vault'), () => 'License Vault enabled')
    });
}
export function useDisableLvConstituent(onSuccess) {
    return useMutation({
        mutationFn: disableLvConstituent,
        onSuccess: async (newLvConstituentState) => {
            queryClient.setQueryData(keys.status, newLvConstituentState);
            await invalidateAllQueries();
            onSuccess(newLvConstituentState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to disable License Vault'), () => 'License Vault disabled')
    });
}
export function useLicenseVaultLimits(licenseType) {
    return useQuery({
        queryKey: keys.limits,
        queryFn: getLicenseVaultLimits,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch AI Enterprise status')),
        select: limits => limits.filter(limit => (limit.postpaid && licenseType === 'post-paid') ||
            (!limit.postpaid && licenseType === 'pre-paid'))
    });
}
export const useLicenseVaultPostpaidLimitsMutation = () => {
    return useMutation({
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to save updated limits to License Vault'), () => `Limits for License Vault were updated`),
        mutationFn: saveLicenseVaultPostpaidLimits,
        onSuccess: async () => {
            await invalidateAllQueries();
        }
    });
};
const deniedLicensesNumberQueryArgs = {
    queryKey: keys.deniedLicenses,
    queryFn: getDeniedLicensesRequests,
    meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch License Vault denied licenses for last day')),
    select: (data) => {
        return (data || []).reduce((accum, current) => accum + current.denialCount, 0);
    }
};
export const useDeniedLicensesNumber = () => {
    return useQuery(deniedLicensesNumberQueryArgs);
};
export const useDeniedLicensesNumberPolling = (enabled = true) => {
    return useQuery({
        ...deniedLicensesNumberQueryArgs,
        refetchInterval: DEFAULT_POLLING_INTERVAL,
        enabled
    });
};
