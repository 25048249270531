// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detailsContainer__f4b {
  padding: 0 0 6px 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-history/cells/profile-history-item-details.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV","sourcesContent":[".detailsContainer {\n  padding: 0 0 6px 12px;\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailsContainer": `detailsContainer__f4b`
};
export default ___CSS_LOADER_EXPORT___;
