import { jsx as _jsx } from "react/jsx-runtime";
import ButtonGroup from '@jetbrains/ring-ui/components/button-group/button-group';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useFormContext } from 'react-hook-form';
function TypeField() {
    const { setValue, watch } = useFormContext();
    const watchType = watch('type');
    const watchSource = watch('source');
    const typeOptions = [
        {
            key: 'channel',
            label: watchSource === 'MARKETPLACE' ? 'Install the latest' : 'Install by channel'
        },
        { key: 'version', label: 'Install by version' }
    ];
    const handleTypeChange = (key) => {
        setValue('type', key);
        setValue('forced', false);
    };
    return (_jsx(ButtonGroup, { children: typeOptions.map(x => (_jsx(Button, { active: watchType === x.key, onClick: () => handleTypeChange(x.key), "data-test": `install-by-${x.key}`, children: x.label }, x.key))) }));
}
export default TypeField;
