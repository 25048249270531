import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import Select from '@jetbrains/ring-ui/components/select/select';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { tAiSettingsFormSchema } from '@modules/ai/ai-settings-form/ai-settings-form-schema';
import { HiddenLimit } from '@modules/constituents/constituent-dialog/hidden-limit';
import { ConstituentDialog, LimitAssigmentEditor } from '@modules/constituents/constituent-dialog';
import RingFieldsList from '@common/form/ring-fields-list';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { JetbrainsAiProviderForm, OpenAiProviderForm } from '../../forms';
import { DEFAULT_PRESET_VALUES, FORM_INITIAL_DATA } from '../../constants';
import { AiLogo } from '../../ai-logo/ai-logo';
import styles from './ai-settings-dialog.css';
const providerOptions = [
    {
        key: 'jetbrains',
        label: 'JetBrains AI',
        provider: { jetbrains: { id: 'jetbrains' } }
    },
    {
        key: 'openai',
        label: 'OpenAI',
        provider: {
            openai: {
                id: 'openai',
                ...DEFAULT_PRESET_VALUES.AZURE
            }
        }
    }
];
export function AiSettingsDialogBase(props) {
    const { onCloseAttempt, title, isLoading, onActivate, limitLockType, initialLimitUsage } = props;
    const form = useForm({
        defaultValues: { ...FORM_INITIAL_DATA, assignedUsersLimit: initialLimitUsage },
        resolver: zodResolver(tAiSettingsFormSchema)
    });
    const { setValue, watch, handleSubmit } = form;
    const provider = watch('provider');
    const usage = watch('assignedUsersLimit');
    const selectedProvider = Object.values(provider)[0].id;
    const handleSelect = useCallback((item) => {
        if (item) {
            setValue('provider', item.provider);
        }
    }, [setValue]);
    const handleClose = useCallback(() => onCloseAttempt(undefined), [onCloseAttempt]);
    const handleActivate = useCallback((values) => onActivate(values), [onActivate]);
    const handleLimitChange = (val) => {
        setValue('assignedUsersLimit', val === 'unlimited' ? undefined : val, { shouldValidate: true });
        setValue('defaultProvider', selectedProvider);
    };
    return (_jsxs(ConstituentDialog, { title: title, titleLogo: _jsx(AiLogo, {}), children: [_jsx(FormProvider, { ...form, children: _jsx("form", { onSubmit: handleSubmit(onActivate), children: _jsx(Content, { className: styles.contentWrapper, children: _jsxs(RingFieldsList, { children: [_jsx(Select, { ...assignTestId('provider-select'), selectedLabel: "Provider", className: styles.select, data: providerOptions, selected: providerOptions.find(({ key }) => selectedProvider === key), onSelect: handleSelect }), provider.jetbrains && _jsx(JetbrainsAiProviderForm, { form: form }), provider.openai && _jsx(OpenAiProviderForm, { form: form }), limitLockType === 'hidden' && _jsx(HiddenLimit, { className: styles.limit }), limitLockType !== 'hidden' && (_jsx(LimitAssigmentEditor, { toggleText: "Unlimited", limitNotice: _jsxs(_Fragment, { children: ["You can control your spending by setting a limit on the number of assigned users.", _jsx("br", {}), "Users who exceed this limit will have restricted access."] }), limit: usage === undefined ? 'unlimited' : usage, readonly: limitLockType === 'readonly', onLimitChange: handleLimitChange, className: styles.limit }))] }) }) }) }), _jsxs(Panel, { children: [_jsx(Button, { loader: isLoading, onClick: handleSubmit(handleActivate), primary: true, "data-test": "dialog-confirm-button", children: "Apply" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
