import { apiClientManager } from '@common/api';
export async function getCollectedDataError(id) {
    return apiClientManager
        .getClient()
        .get(`/api/analytics/errors/${id}`)
        .then(({ data }) => data);
}
export async function getStacktracesZip(id) {
    return apiClientManager.getClient().get(`/api/analytics/${id}/stacktraces.zip`, {
        responseType: 'blob'
    });
}
export async function getErrorsAnalytics(range, page, limit, offset, sortColumn, order) {
    let restParams = '';
    if (page) {
        restParams += `&page=${page}`;
    }
    if (limit) {
        restParams += `&limit=${limit}`;
    }
    if (offset) {
        restParams += `&offset=${offset}`;
    }
    if (sortColumn) {
        restParams += `&sort=${sortColumn}`;
    }
    if (order) {
        restParams += `&order=${order}`;
    }
    return apiClientManager
        .getClient()
        .get(`/api/analytics/errors?range=${range}${restParams}`)
        .then(r => r.data);
}
