import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import MarkdownIt from 'markdown-it';
import { markdownItTable } from 'markdown-it-table';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
import styles from './markdown.css';
export function withLinks(md) {
    md.renderer.rules.link_open = (tokens, idx) => {
        const token = tokens[idx];
        const href = token.attrGet('href') || '';
        const title = token.attrGet('title') || '';
        const classes = token.attrGet('class');
        const titleAttr = title ? ` title="${title}"` : '';
        return `<a class="${classes}" href="${href}"${titleAttr} target="_blank" rel="noopener noreferrer nofollow">`;
    };
    md.renderer.rules.link_close = () => '</a>';
    return md;
}
const md = new MarkdownIt('commonmark', {
    html: false,
    linkify: true
})
    .enable('strikethrough', true)
    .enable('linkify')
    .use(markdownItTable)
    .use(withLinks);
export default function Markdown({ text }) {
    const markdownRef = useRef(null);
    useEffect(() => {
        if (markdownRef.current) {
            markdownRef.current.innerHTML = md.render(text || '');
        }
    }, [text]);
    return _jsx("div", { ref: markdownRef, className: styles.markdown, ...assignTestId('markdown') });
}
