import * as z from 'zod';
import { apiVmOptionsGroupProvisionModeValues } from '@api/@types';
import { tSettingOptionSchema } from './setting-option-schema';
export const tProvisionModeSchema = z.enum(apiVmOptionsGroupProvisionModeValues);
export const tVmOptionsSchema = tSettingOptionSchema.extend({
    content: z.object({
        vmOptionsGroups: z.array(z.object({
            options: z.array(z.object({ value: z.string() })).min(1, { message: 'Please add VM Option' }),
            provisionMode: tProvisionModeSchema
        }))
    })
});
