import { isNil, uniqBy } from 'lodash';
function includeQualityLabel(qualityName) {
    return `${qualityName} or more stable`;
}
function excludeQualityLabel(qualityName) {
    return `Less stable than ${qualityName}`;
}
export function getQualityLabelByType(type, qualityName) {
    return type === 'include' ? includeQualityLabel(qualityName) : excludeQualityLabel(qualityName);
}
export function buildProductOptions(allProducts, selectedProducts) {
    let options = [
        ...allProducts.map(product => ({
            key: product.id,
            label: product.name
        })),
        ...selectedProducts.map(product => ({ key: product, label: product }))
    ];
    options = uniqBy(options, option => option.key);
    return options;
}
export function toQualityOption(quality, type) {
    const label = getQualityLabelByType(type, quality.name);
    return {
        key: quality.orderValue,
        label,
        qualityName: quality.name
    };
}
export function buildQualityOptions(allQualities, selectedQuality, type) {
    let options = [
        ...allQualities.map(quality => toQualityOption(quality, type)),
        ...(selectedQuality ? [toQualityOption(selectedQuality, type)] : [])
    ];
    options = uniqBy(options, option => option.key);
    return options;
}
export function withReplacedSuffix(item) {
    return { ...item, type: item.type.replace('-all', '') };
}
export function replaceProps(obj, props) {
    return props.reduce((accum, prop) => {
        accum[prop] = accum[prop] || [];
        return accum;
    }, obj);
}
export function replaceQuality(object) {
    if (isNil(object === null || object === void 0 ? void 0 : object.quality)) {
        return object;
    }
    return {
        quality: { orderValue: undefined },
        ...object
    };
}
