import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import { useFormContext } from 'react-hook-form';
import RegisteredDatePicker from '@app/common/hook-form/registered-date-picker';
import RegisteredInput from '@app/common/hook-form/registered-input';
import { Required } from '@app/common/form/important-asterisk';
import { localDateString } from '@app/common/dates/format';
export function NotificationFields() {
    const { control } = useFormContext();
    const today = useMemo(localDateString, []);
    return (_jsxs(_Fragment, { children: [_jsx(RegisteredDatePicker, { name: "notification.dueDate", label: Required('Effective from'), control: control, minDate: today, withTime: true }), _jsx(RegisteredInput, { name: "notification.description", label: Required('Notification text'), control: control, autoComplete: "off", size: Size.L, placeholder: "Notification text" })] }));
}
