// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email__ffb + .instruction__dcb {
  margin-top: calc(1.5 * var(--ring-unit));
}

.instruction__dcb + .joinMsg__a8b {
  margin-top: calc(1.5 * var(--ring-unit));
}

.email__ffb + .joinMsg__a8b {
  margin-top: calc(1.5 * var(--ring-unit));
}

.actions__f75 {
  margin-top: calc(1.5 * var(--ring-unit));
  gap: var(--ring-unit);
}

.actions__f75 + .table__a32 {
  margin-top: calc(3 * var(--ring-unit));
}

.downloadToolboxContainer__f58 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 2);
  margin-top: calc(var(--ring-unit) * 2);
}
`, "",{"version":3,"sources":["webpack://./src/pages/welcome-page/user-welcome-page/user-welcome-page.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;EACxC,qBAAqB;AACvB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,sCAAsC;AACxC","sourcesContent":[".email + .instruction {\n  margin-top: calc(1.5 * var(--ring-unit));\n}\n\n.instruction + .joinMsg {\n  margin-top: calc(1.5 * var(--ring-unit));\n}\n\n.email + .joinMsg {\n  margin-top: calc(1.5 * var(--ring-unit));\n}\n\n.actions {\n  margin-top: calc(1.5 * var(--ring-unit));\n  gap: var(--ring-unit);\n}\n\n.actions + .table {\n  margin-top: calc(3 * var(--ring-unit));\n}\n\n.downloadToolboxContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 2);\n  margin-top: calc(var(--ring-unit) * 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"email": `email__ffb`,
	"instruction": `instruction__dcb`,
	"joinMsg": `joinMsg__a8b`,
	"actions": `actions__f75`,
	"table": `table__a32`,
	"downloadToolboxContainer": `downloadToolboxContainer__f58`
};
export default ___CSS_LOADER_EXPORT___;
