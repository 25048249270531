import z from 'zod';
import { apiOpenAiPlatformProviderPresetValues } from '@api/@types';
const tOpenAiPlatformModel = z.object({
    deploymentName: z.string().nullish(),
    id: z.string(),
    modelName: z.string(),
    tokenLimit: z.number()
});
export const tOpenAiFormSchema = z.object({
    apiKey: z.string().min(5, 'API Key is required').nullable(),
    id: z.string().optional(),
    preset: z.enum(apiOpenAiPlatformProviderPresetValues),
    url: z.string().url('Endpoint must be a vaild URL'),
    models: z.array(tOpenAiPlatformModel)
});
