import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import { useSelector } from 'react-redux';
import InlineLoader from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import classNames from 'classnames';
import { isProfileStale } from '@app/common/store/profiles/selectors';
import styles from './setting-block.css';
export function SettingBlock(props) {
    const { header, headerActionComponent, subHeader, bordered, children } = props;
    const isStale = useSelector(isProfileStale);
    return (_jsxs("div", { className: styles.container, "data-test": `${header.charAt(0).toLowerCase() + header.slice(1)}-block`, children: [_jsxs("div", { className: styles.headerContainer, children: [_jsx(H3, { className: styles.header, children: header }), _jsxs("div", { className: styles.headerActionContainer, children: [headerActionComponent, isStale && _jsx(InlineLoader, {})] })] }), _jsxs("div", { className: classNames(styles.contentContainer, { [styles.bordered]: bordered }), children: [subHeader && _jsx("div", { className: styles.subHeader, children: subHeader }), _jsx("div", { className: styles.content, children: children })] })] }));
}
