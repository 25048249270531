import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import HorizontalContainer from '@app/common/page-layout/horizontal-container';
import SearchFilter from '@app/common/search-filter/search-filter';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
import styles from './groups-filter.css';
export function GroupsFilters({ filters, onFiltersChange }) {
    const handleSearchValueChange = useCallback((newValue) => {
        if (typeof newValue === 'string') {
            onFiltersChange({
                ...filters,
                query: newValue.length > 0 ? newValue : null
            });
        }
    }, [onFiltersChange, filters]);
    return (_jsx(HorizontalContainer, { className: styles.container, ...assignTestId('group-filters'), children: _jsx(SearchFilter, { value: filters.query || '', onChange: handleSearchValueChange, placeholder: "Search by group" }) }));
}
