import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { lazy, Suspense, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Text from '@jetbrains/ring-ui/components/text/text';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import RingFieldsList from '@app/common/form/ring-fields-list';
import { parseIdeSetting } from './json-utils';
import styles from './json-editor-fields.css';
const stringify = (x) => JSON.stringify(x, null, 2);
const JsonEditor = lazy(() => import('@app/common/editors/json-editor'));
function JsonEditorFields({ settingIndex }) {
    const [errors, setErrors] = useState([]);
    const [warnings, setWarnings] = useState([]);
    const { getValues } = useFormContext();
    const { update } = useFieldArray({ name: 'content' });
    const editorDefaultValue = stringify(getValues(`content.${settingIndex}`));
    const onChange = (x) => {
        const res = parseIdeSetting(x);
        if (res.success) {
            update(settingIndex, res.data);
            setErrors([]);
            setWarnings(res.warnings);
        }
        else {
            setErrors(res.errors);
            setWarnings([]);
        }
    };
    const onLoad = () => {
        onChange(editorDefaultValue);
    };
    return (_jsxs(RingFieldsList, { children: [errors.map(x => (_jsxs(Text, { info: true, className: styles.errorText, children: ["Error: ", x] }, x))), warnings.map(x => (_jsxs(Text, { info: true, className: styles.warningText, children: ["Warning: ", x] }, x))), _jsx(Suspense, { fallback: _jsx(JsonEditorPlaceholder, {}), children: _jsx(JsonEditor, { defaultValue: editorDefaultValue, onChange: onChange, onLoad: onLoad, minLines: 30, debounceChangePeriod: 200 }) })] }));
}
function JsonEditorPlaceholder() {
    return (_jsx("div", { className: styles.placeholder, children: _jsx(Loader, { message: "Loading JSON editor..." }) }));
}
export default JsonEditorFields;
