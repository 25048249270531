import { isNil } from 'lodash';
/**
 * Returns a new array with element added or replaced. elementToAdd is searched by the "id" prop
 * @param elements
 * @param elementToAdd
 */
export function addOrReplace(elements, elementToAdd) {
    const shouldUpdate = elements.map(x => x.id).includes(elementToAdd.id);
    return shouldUpdate
        ? elements.map(x => (x.id === elementToAdd.id ? elementToAdd : x))
        : [...elements, elementToAdd];
}
export function findSetting(state, id, settingType) {
    let arr;
    if (settingType === 'autoInstalled') {
        arr = state.plugins.autoInstalled;
    }
    else if (settingType === 'disabled') {
        arr = state.plugins.disabled;
    }
    else if (settingType === 'ide') {
        arr = state.settings.ide;
    }
    else if (settingType === 'vmOptions') {
        arr = state.settings.vmOptions;
    }
    else if (settingType === 'autoinstalledTools') {
        arr = state.settings.autoinstalledTools;
    }
    else {
        arr = [];
    }
    return arr.find(x => x.id === id);
}
export function findApplicationRule(state, id) {
    return state.applications.rules.find(x => x.id === id);
}
export function getProfileMetadata() {
    return { isProfileStale: false };
}
/**
 * By convention, we split rules into two parts: main rule + rest rules. Main rule contains filters. Rest rules
 * contain notifications
 * @param rules
 */
export function findMainApplicationsRule(rules) {
    return rules.find(x => isNil(x.notification));
}
