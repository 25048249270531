import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import { apiProfileAuditTypeOptions } from '@api/profiles';
import ToolbarItem from '@app/common/toolbar-item/toolbar-item';
function EventsFilter({ value, onChange }) {
    const handleChange = useCallback((selected) => {
        onChange((selected === null || selected === void 0 ? void 0 : selected.key) ? selected.key : undefined);
    }, [onChange]);
    return (_jsx(ToolbarItem, { label: "Event type", children: _jsx(Select, { clear: true, label: "All", size: Select.Size.M, data: apiProfileAuditTypeOptions, selected: apiProfileAuditTypeOptions.find(option => option.key === value), onChange: handleChange }) }));
}
export default EventsFilter;
