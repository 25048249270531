import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon, { Size } from '@jetbrains/ring-ui/components/icon';
import codeWithMeLogo from '@jetbrains/logos/code-with-me/code-with-me.svg';
import { H1, H3 } from '@jetbrains/ring-ui/components/heading/heading';
import Button from '@jetbrains/ring-ui/components/button/button';
import classNames from 'classnames';
import { ControlsHeight } from '@jetbrains/ring-ui/components/global/controls-height';
import { CompanyLogo, LogoSize } from '@app/common/company-logo/company-logo';
import auth from '../../../common/auth/auth';
import { ExternalLinks } from '../../../common/constants/constants';
import { useMachineConfig } from '../../../common/queries/machine-config';
import { useCompanyName } from '../../../common/hooks/use-company-name';
import styles from './session-page-unauthorized.css';
export function CodeWithMeSessionPageUnauthorized() {
    const { data } = useMachineConfig();
    const logo = data === null || data === void 0 ? void 0 : data.companyLogoUrl;
    const { companyName, isFetching: isCompanyNameFetching } = useCompanyName();
    return (_jsxs("div", { className: styles.wrapper, "data-test": "cwm-unauth-page-wrapper", children: [_jsxs("header", { className: styles.header, children: [_jsx(Icon, { glyph: codeWithMeLogo, size: Size.Size48 }), _jsx(H3, { children: "Code With Me Enterprise" })] }), _jsx(CompanyLogo, { className: styles.companyLogo, logoUrl: logo, size: LogoSize.L }), _jsxs(H1, { className: styles.companyName, children: ["Code With Me Enterprise", _jsx("br", {}), !isCompanyNameFetching && `for ${companyName}`] }), _jsxs("p", { className: styles.info, children: ["Log in with your organization email to start collaborating in the Code With Me", _jsx("br", {}), "Enterprise session."] }), _jsx(Button, { onClick: () => auth.login(), className: styles.loginBtn, height: ControlsHeight.L, primary: true, "data-test": "code-with-me-landing-login", children: isCompanyNameFetching ? 'Login' : `Login to ${companyName}` }), _jsx(Button, { className: classNames(styles.aboutLink, styles.link), href: ExternalLinks.JB_CWM, text: true, primary: true, children: "About Code With Me" })] }));
}
