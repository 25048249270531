import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { isNil, noop } from 'lodash';
import { useEnableLvConstituent } from '@app/common/queries/license-vault';
import { ConstituentDialog, LimitAssigmentEditor } from '@modules/constituents/constituent-dialog';
import { LicenseVaultLogo } from '../../license-vault-logo/license-vault-logo';
import styles from './lv-enable-dialog.css';
export function LvEnableDialog(props) {
    const { onCloseAttempt, showPostpaidHint, limit } = props;
    const { mutate: enable, isLoading } = useEnableLvConstituent(onCloseAttempt);
    const handleEnable = useCallback(() => {
        enable();
    }, [enable]);
    const handleClose = useCallback(() => onCloseAttempt(undefined), [onCloseAttempt]);
    return (_jsxs(ConstituentDialog, { title: "Enable License Vault", titleLogo: _jsx(LicenseVaultLogo, {}), children: [_jsxs(Content, { children: [_jsx("p", { className: styles.paragraph, children: "License Vault automates the licensing of JetBrains tools in your organization." }), _jsxs("p", { className: styles.paragraph, children: ["It can distribute your prepaid JetBrains licenses and also automatically issue additional", _jsx("br", {}), "postpaid licenses when your prepaid ones run out."] }), showPostpaidHint && (_jsxs("p", { className: styles.paragraph, children: ["By default, you\u2019ll have an unlimited number of postpaid licenses available.", _jsx("br", {}), "You\u2019ll be able to set your postpaid license limits once License Vault is enabled."] })), !isNil(limit) && (_jsx(LimitAssigmentEditor, { className: styles.limit, toggleText: "", limit: limit, readonly: true, onLimitChange: noop }))] }), _jsxs(Panel, { children: [_jsx(Button, { loader: isLoading, onClick: handleEnable, primary: true, "data-test": "dialog-confirm-button", children: "Enable License Vault" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
