import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useGetServerConfig } from '@app/common/queries/server-config';
import { localStorageService } from '@app/common/storage';
import auth from '@app/common/auth/auth';
import { renderVersionAlert } from './render-version-alert';
const VERSION_UPDATE_LOCAL_STORAGE_KEY = 'versionUpdate';
export const VERSION_NOTIFICATIONS_LOCAL_STORAGE_KEY = 'versionNotifications';
export const ServerConfigContext = createContext({
    notifications: null,
    setNotifications: () => { },
    configData: undefined
});
export function useServerConfigContext() {
    const context = useContext(ServerConfigContext);
    if (context === undefined) {
        throw new Error('useNotificationsFormContext must be used within SharedFormContext');
    }
    return context;
}
export function ServerConfigProvider({ children }) {
    const enableQuery = auth.isAuthenticated();
    const { data, isSuccess } = useGetServerConfig({ enabled: enableQuery });
    const [notifications, setNotifications] = useState(localStorageService.get(VERSION_NOTIFICATIONS_LOCAL_STORAGE_KEY));
    const showNotification = useMemo(() => isSuccess && compareBuildVersions(data === null || data === void 0 ? void 0 : data.availableUpdate), [data === null || data === void 0 ? void 0 : data.availableUpdate, isSuccess]);
    const handleUserAction = useCallback((section) => {
        if (!notifications || !notifications[section]) {
            return;
        }
        const newNotificationsValue = {
            ...notifications,
            [section]: false
        };
        localStorageService.set(VERSION_NOTIFICATIONS_LOCAL_STORAGE_KEY, newNotificationsValue);
        setNotifications(newNotificationsValue);
    }, [notifications]);
    const resetNotifications = () => {
        const initialNotification = {
            upgradeVersion: true,
            whatsNew: true
        };
        localStorageService.set(VERSION_NOTIFICATIONS_LOCAL_STORAGE_KEY, initialNotification);
        setNotifications(initialNotification);
    };
    const context = useMemo(() => ({
        notifications,
        setNotifications: handleUserAction,
        configData: data === null || data === void 0 ? void 0 : data.availableUpdate
    }), [data === null || data === void 0 ? void 0 : data.availableUpdate, handleUserAction, notifications]);
    useEffect(() => {
        if (isSuccess && showNotification) {
            if (data === null || data === void 0 ? void 0 : data.availableUpdate) {
                localStorageService.set(VERSION_UPDATE_LOCAL_STORAGE_KEY, data.availableUpdate);
                resetNotifications();
                renderVersionAlert(data.availableUpdate.releaseNotesUrl);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, showNotification]);
    return _jsx(ServerConfigContext.Provider, { value: context, children: children });
}
function compareBuildVersions(data) {
    if (!data) {
        localStorageService.set(VERSION_UPDATE_LOCAL_STORAGE_KEY, null);
        return false;
    }
    const savedVersion = localStorageService.get(VERSION_UPDATE_LOCAL_STORAGE_KEY);
    if (!savedVersion) {
        return true;
    }
    return savedVersion.buildNumber !== data.buildNumber;
}
