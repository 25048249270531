import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import user from '@jetbrains/icons/user';
import warning from '@jetbrains/icons/warning';
import Icon from '@jetbrains/ring-ui/components/icon';
import Select from '@jetbrains/ring-ui/components/select/select';
import Text from '@jetbrains/ring-ui/components/text/text';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import classnames from 'classnames';
import NavigationLink from '@app/common/navigation/navigation-link';
import styles from './users-profile-button.css';
const msg = 'Failed to fetch users for this profile';
function ErrorPlaceholder({ error }) {
    const suffix = (error === null || error === void 0 ? void 0 : error.message) ? `: ${error.message}` : '';
    return (_jsxs(Tooltip, { title: `${msg}${suffix}`, children: [_jsx(Icon, { glyph: warning, className: classnames('ring-icon', styles.warningIconColor) }), _jsx(Text, { className: styles.usersWarningText, children: " users" })] }));
}
export function UsersProfileButton({ className, useUsersFetch, enabled }) {
    const { data, isError, error, isFetching } = useUsersFetch;
    if (isError) {
        return _jsx(ErrorPlaceholder, { error: error });
    }
    return (_jsxs(NavigationLink, { enableIsActive: enabled, type: Select.Type.BUTTON, href: "users?isActive=true", className: className, "data-test": "profiles-users-button", children: [_jsx(Icon, { glyph: user, className: styles.icon }), isFetching && !data && 'Loading...', data && (data.items.length === 1 ? '1 user' : `${data.items.length} users`)] }));
}
