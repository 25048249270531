import { useCallback, useMemo } from 'react';
import { StringParam } from 'serialize-query-params';
import { BuildKey } from '@modules/ide-provisioner/application-settings/app-build-selector';
import { useSyncedSearchArgs } from '@common/navigation/use-synced-search-args';
export function useSelectedBuild() {
    const [urlParams, setUrlParams] = useSyncedSearchArgs(tsTypeToQuerySchema);
    const buildKey = useMemo(() => {
        return new BuildKey(urlParams.buildNumber, urlParams.buildOs, urlParams.buildArchitecture);
    }, [urlParams]);
    const handleBuildSelect = useCallback((build) => {
        setUrlParams({
            ...urlParams,
            buildNumber: build.buildNumber,
            buildOs: build.buildOs,
            buildArchitecture: build.buildArchitecture
        });
    }, [setUrlParams, urlParams]);
    return [buildKey, handleBuildSelect];
}
const tsTypeToQuerySchema = {
    buildNumber: StringParam,
    buildOs: StringParam,
    buildArch: StringParam
};
