import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './statistics-widget.css';
export var StatisticsWidgetInfoStatsSize;
(function (StatisticsWidgetInfoStatsSize) {
    StatisticsWidgetInfoStatsSize["Wide"] = "wide";
    StatisticsWidgetInfoStatsSize["Narrow"] = "narrow";
    StatisticsWidgetInfoStatsSize["TwoColumns"] = "two-columns";
})(StatisticsWidgetInfoStatsSize || (StatisticsWidgetInfoStatsSize = {}));
export function StatisticsWidgetInfoStats(props) {
    const { statName, statValue, size = StatisticsWidgetInfoStatsSize.Wide } = props;
    return (_jsxs("div", { className: classNames(styles.infoStat, {
            [styles.narrow]: size === StatisticsWidgetInfoStatsSize.Narrow,
            [styles.twoColumns]: size === StatisticsWidgetInfoStatsSize.TwoColumns,
            [styles.wide]: size === StatisticsWidgetInfoStatsSize.Wide
        }), ...assignTestId('info-stat', props), children: [_jsx("div", { className: styles.statName, title: typeof statName === 'string' ? statName : undefined, children: statName }), _jsx("div", { className: styles.statValue, ...assignTestId('stat-value'), children: statValue })] }));
}
