// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__ee0 {
  padding-left: calc(var(--ring-unit) * 2);
}

.productLogo__c27 {
  width: calc(var(--ring-unit) * 4);
  flex: 0 0 auto;
}

.textContainer__eb0 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: calc(var(--ring-unit));
}

.infoContainer__c48 {
  display: flex;
  gap: calc(var(--ring-unit));
}

.notificationIcon__b26 {
  color: var(--ring-icon-warning-color);
}

.excludeInfo__d44 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 170px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/applications/product-box/product-box.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,iCAAiC;EACjC,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".wrapper {\n  padding-left: calc(var(--ring-unit) * 2);\n}\n\n.productLogo {\n  width: calc(var(--ring-unit) * 4);\n  flex: 0 0 auto;\n}\n\n.textContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  margin-left: calc(var(--ring-unit));\n}\n\n.infoContainer {\n  display: flex;\n  gap: calc(var(--ring-unit));\n}\n\n.notificationIcon {\n  color: var(--ring-icon-warning-color);\n}\n\n.excludeInfo {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  max-width: 170px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__ee0`,
	"productLogo": `productLogo__c27`,
	"textContainer": `textContainer__eb0`,
	"infoContainer": `infoContainer__c48`,
	"notificationIcon": `notificationIcon__b26`,
	"excludeInfo": `excludeInfo__d44`
};
export default ___CSS_LOADER_EXPORT___;
