import { jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './information-banner.css';
export function InformationBanner({ children, buttons, className, variant, ...restProps }) {
    const bannerClasses = classNames(styles.bannerContainer, className, {
        [styles.success]: variant === 'success',
        [styles.warning]: variant === 'warning',
        [styles.error]: variant === 'error',
        [styles.neutral]: variant === 'neutral'
    });
    return (_jsxs("div", { className: classNames(styles.bannerContainer, bannerClasses, className), ...assignTestId('information-banner', restProps), children: [children, buttons] }));
}
