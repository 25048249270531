import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { isEqual } from 'lodash';
import HorizontalContainer from '@app/common/page-layout/horizontal-container';
import SearchFilter from '@app/common/search-filter/search-filter';
import auth from '@app/common/auth/auth';
import RoleFilter from './role-filter';
import ProfileFilter from './profile-filter';
import ActiveFilter from './active-filter';
import GroupFilter from './group-filter';
import ConstituentFilter from './constituent-filter';
export function UsersFilters({ params, onParamsChange }) {
    var _a;
    const { idpImportEnabled } = auth.getFeatures();
    const onFiltersChange = useCallback((newFilters) => {
        onParamsChange({
            ...params,
            ...newFilters,
            page: isEqual(newFilters, params) ? params.page : 1
        });
    }, [onParamsChange, params]);
    const handleSearchValueChange = useCallback((newValue) => {
        onFiltersChange({ ...params, query: newValue });
    }, [onFiltersChange, params]);
    return (_jsxs(HorizontalContainer, { children: [_jsx(SearchFilter, { value: params.query || '', onChange: handleSearchValueChange, placeholder: "Search by name or email" }), _jsx(RoleFilter, { value: params.role || '', onChange: value => onFiltersChange({ ...params, role: value !== null && value !== void 0 ? value : null }) }), _jsx(ProfileFilter, { profileId: params.profileId || '', onChange: value => {
                    onFiltersChange({ ...params, profileId: value !== null && value !== void 0 ? value : null });
                } }), idpImportEnabled && (_jsx(GroupFilter, { groupId: params.groupId || '', onChange: value => {
                    onFiltersChange({ ...params, groupId: value !== null && value !== void 0 ? value : null });
                } })), _jsx(ConstituentFilter, { constituentId: params.constituentId || '', onChange: value => {
                    onFiltersChange({ ...params, constituentId: value !== null && value !== void 0 ? value : null });
                } }), _jsx(ActiveFilter, { isActive: (_a = params.isActive) !== null && _a !== void 0 ? _a : undefined, onChange: value => onFiltersChange({ ...params, isActive: value !== null && value !== void 0 ? value : null }) })] }));
}
