// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialogContent__dc6 {
  width: 630px;
}

.jsonEditorButton__ad9 {
  color: var(--ring-icon-secondary-color);
  font-size: 13px;
  line-height: 13px;
}

.dialogBreadcrumbs__b73 {
  display: inline;
}

.dialogHeaderButton__b84 {
  float: right;
}

.formEditorButton__e05 {
  color: var(--ring-link-color);
  font-size: 13px;
  cursor: pointer;
  line-height: 13px;
}

.errorMessageContainer__f69 {
  margin-top: var(--ring-unit);
}

.contentErrorMessage__ff1 {
  color: var(--ring-error-color);
}

.addSettingsFields__fe0 {
  margin-top: calc(2 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/ide-settings/profiles-ide-settings-dialog-form.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,uCAAuC;EACvC,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".dialogContent {\n  width: 630px;\n}\n\n.jsonEditorButton {\n  color: var(--ring-icon-secondary-color);\n  font-size: 13px;\n  line-height: 13px;\n}\n\n.dialogBreadcrumbs {\n  display: inline;\n}\n\n.dialogHeaderButton {\n  float: right;\n}\n\n.formEditorButton {\n  color: var(--ring-link-color);\n  font-size: 13px;\n  cursor: pointer;\n  line-height: 13px;\n}\n\n.errorMessageContainer {\n  margin-top: var(--ring-unit);\n}\n\n.contentErrorMessage {\n  color: var(--ring-error-color);\n}\n\n.addSettingsFields {\n  margin-top: calc(2 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContent": `dialogContent__dc6`,
	"jsonEditorButton": `jsonEditorButton__ad9`,
	"dialogBreadcrumbs": `dialogBreadcrumbs__b73`,
	"dialogHeaderButton": `dialogHeaderButton__b84`,
	"formEditorButton": `formEditorButton__e05`,
	"errorMessageContainer": `errorMessageContainer__f69`,
	"contentErrorMessage": `contentErrorMessage__ff1`,
	"addSettingsFields": `addSettingsFields__fe0`
};
export default ___CSS_LOADER_EXPORT___;
