import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { formatApiError } from '@api/errors';
import { useCreateTokenMutation } from '@app/common/queries/automation';
import SubmitError from '@app/common/errors/submit-error';
import { tAutomationTokenSchema } from '../../schemas/automation-token-schema';
import AutomationTokenEditFields from './automation-token-edit-fields';
import styles from './automation-token-popups.css';
const initFormData = {
    clientId: '',
    expireDate: ''
};
export function AutomationTokenCreatePopup(props) {
    const { onCloseAttempt } = props;
    const [errorResponse, setErrorResponse] = useState('');
    const createMutation = useCreateTokenMutation();
    const form = useForm({
        defaultValues: initFormData,
        resolver: zodResolver(tAutomationTokenSchema)
    });
    const { formState: { isDirty, isSubmitting }, handleSubmit } = form;
    const onSubmit = async (values) => {
        const tokenData = {
            clientId: values.clientId,
            expireDate: values.expireDate ? values.expireDate : undefined
        };
        await createMutation.mutateAsync(tokenData, {
            onSuccess: data => {
                onCloseAttempt(data);
            },
            onError: e => {
                setErrorResponse(formatApiError(e, 'Failed to create token'));
            }
        });
    };
    const handleCancel = useCallback(() => {
        if (!isSubmitting) {
            onCloseAttempt(undefined);
        }
    }, [isSubmitting, onCloseAttempt]);
    return (_jsxs(Dialog, { show: true, autoFocusFirst: true, label: "Create Automation Token", onCloseAttempt: handleCancel, children: [_jsx(Header, { children: "Create Automation Token" }), _jsxs(Content, { children: [_jsx(SubmitError, { error: errorResponse }), _jsx(FormProvider, { ...form, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), className: styles.formContainer, children: _jsx(AutomationTokenEditFields, { disabled: isSubmitting }) }) })] }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, loader: isSubmitting, disabled: !isDirty, onClick: handleSubmit(onSubmit), children: "Create" }), _jsx(Button, { onClick: handleCancel, disabled: isSubmitting, loader: isSubmitting, children: "Cancel" })] })] }));
}
