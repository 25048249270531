// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__fb4 {
  position: relative;
}

.sectionHeader__f78 {
  display: flex;
  align-items: center;
}

.logoWrapper__b1d {
  width: 16px;
  height: 16px;
}

.h3__b96.h3__b96 {
  margin-top: 0;
}

.logoWrapper__b1d + .h3__b96 {
  margin-left: calc(var(--ring-unit) / 2);
}

.blockContent__a2b {
  margin-top: calc(var(--ring-unit) * 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/common/titled-block/titled-block.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".wrapper {\n  position: relative;\n}\n\n.sectionHeader {\n  display: flex;\n  align-items: center;\n}\n\n.logoWrapper {\n  width: 16px;\n  height: 16px;\n}\n\n.h3.h3 {\n  margin-top: 0;\n}\n\n.logoWrapper + .h3 {\n  margin-left: calc(var(--ring-unit) / 2);\n}\n\n.blockContent {\n  margin-top: calc(var(--ring-unit) * 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__fb4`,
	"sectionHeader": `sectionHeader__f78`,
	"logoWrapper": `logoWrapper__b1d`,
	"h3": `h3__b96`,
	"blockContent": `blockContent__a2b`
};
export default ___CSS_LOADER_EXPORT___;
