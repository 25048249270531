import { jsx as _jsx } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import { ConstituentDisabledWidget } from '@modules/constituents/widget/constituent-disabled-widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useOpenModal } from '@common/modals/hooks';
import { IdePEnableDialog } from '../../../common/dialogs/idep-settings-dialog/idep-enable-dialog';
export function IdepDisableNoTrialWidget(props) {
    const { licenseType } = props;
    const openEnableModal = useOpenModal(IdePEnableDialog, {
        limitLockType: licenseType === 'PREPAID' ? 'hidden' : undefined
    });
    return (_jsx(ConstituentDisabledWidget, { ...props, ...assignTestId('widget-ide-provisioner'), onEnableClick: openEnableModal, children: _jsx(Text, { children: "Centralize IDE management, optimize plugin selection, and standardize settings, ensuring efficient and unified developer environment" }) }));
}
