import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { noop } from 'lodash';
import { useFormContext } from 'react-hook-form';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useSelector } from 'react-redux';
import { useEvaluatedSettingFilters } from '@app/common/queries/profile-evaluations';
import { getApplications } from '@common/store/profiles/selectors';
import FiltersEditor from './filters-editor/filters-editor';
import SmartToolsPreview from './smart-tools-preview';
import styles from './filters-field.css';
function SettingFiltersField({ onIsFetchingChange = noop, disabled, disabledText }) {
    const { watch, setValue, formState } = useFormContext();
    const watchFilters = watch('filters');
    const { errors } = formState;
    const setFilters = (filters) => {
        setValue('filters', filters, {
            shouldDirty: true
        });
    };
    const applications = useSelector(getApplications);
    const { isFetching, data: tools } = useEvaluatedSettingFilters(applications.rules, watchFilters);
    const watchTools = watch('tools', tools);
    useEffect(() => {
        if (tools) {
            setValue('tools', tools, { shouldDirty: true });
        }
    }, [tools, setValue]);
    useEffect(() => {
        onIsFetchingChange(isFetching);
    }, [isFetching, onIsFetchingChange]);
    return (_jsxs("div", { className: styles.filterField, "data-test": "filter-field", children: [watchFilters.length !== 0 && (_jsx(SmartToolsPreview, { tools: watchTools, isLoading: isFetching, className: styles.toolsPreview })), disabled ? (_jsx("div", { className: styles.disabledModeText, children: _jsx(Text, { children: disabledText }) })) : (_jsx(FiltersEditor, { filters: watchFilters, onFiltersChanged: setFilters })), errors.filters && _jsx("p", { className: styles.errorMessage, children: errors.filters.message })] }));
}
export default SettingFiltersField;
