import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import Icon from '@jetbrains/ring-ui/components/icon';
import newWindowIcon from '@jetbrains/icons/new-window';
import Text from '@jetbrains/ring-ui/components/text/text';
import { usePluginVersions } from '@app/common/queries/plugins';
import RegisteredInput from '@app/common/hook-form/registered-input';
import RegisteredSelect from '@app/common/hook-form/registered-select';
import { useMarketplacePluginVersionsUrl } from '@app/modules/plugins/marketplace-query-wrappers';
import { ExternalLinks } from '@app/common/constants/constants';
import NavigationLink from '@app/common/navigation/navigation-link';
import { Required } from '@common/form/important-asterisk';
function RawVersionField() {
    const { control, watch } = useFormContext();
    const watchSource = watch('source');
    const watchPluginId = watch('pluginId');
    const disabled = isEmpty(watchPluginId);
    const { data: versionsUrl, isFetching: isFetchingVersionsUrl } = useMarketplacePluginVersionsUrl(watchPluginId, watchPluginId !== '' && watchSource === 'MARKETPLACE');
    return (_jsx(RegisteredInput, { label: Required('Version'), autoComplete: "off", control: control, name: "version", disabled: disabled, size: Size.L, afterInput: !disabled && (_jsxs("small", { children: [isFetchingVersionsUrl && '...', !isEmpty(versionsUrl) && (_jsxs(NavigationLink, { href: versionsUrl, target: "_blank", children: ["Check available plugin versions", ' ', _jsx(Icon, { glyph: newWindowIcon, size: Icon.Size.Size12 })] })), !isFetchingVersionsUrl && isEmpty(versionsUrl) && watchSource === 'MARKETPLACE' && (_jsxs(Text, { info: true, children: ["You can find all available plugin versions on", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_MARKETPLACE, children: "JetBrains Marketplace" })] }))] })) }));
}
function TbePluginVersionField() {
    const { control, watch } = useFormContext();
    const watchVersion = watch('version');
    const watchPluginId = watch('pluginId');
    const disabled = isEmpty(watchPluginId);
    const { data, isFetching } = usePluginVersions(watchPluginId, undefined, !!watchPluginId);
    const versions = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.items) === null || _a === void 0 ? void 0 : _a.map(x => x.version).sort((a, b) => b.localeCompare(a)); }, [data]);
    const selected = isEmpty(watchVersion)
        ? undefined
        : { key: watchVersion, label: watchVersion };
    return (_jsx(RegisteredSelect, { name: "version", control: control, selectedLabel: Required('Version'), disabled: disabled, data: versions === null || versions === void 0 ? void 0 : versions.map(x => ({ key: x, label: x, 'data-test': `version-option-${x}` })), size: Size.L, selected: selected, loading: isFetching, "data-test": "version-field" }));
}
function VersionField({ isSmartMode }) {
    const { watch } = useFormContext();
    const watchSource = watch('source');
    return isSmartMode && watchSource === 'TBE' ? _jsx(TbePluginVersionField, {}) : _jsx(RawVersionField, {});
}
export default VersionField;
