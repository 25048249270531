// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hiddenPart__ec1 {
  animation: appearanceAnimation__ceb 100ms cubic-bezier(0, 0.7, 0.3, 1) forwards;
}

@keyframes appearanceAnimation__ceb {
  from {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.15s;
  }
  to {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.15s;
  }
}
`, "",{"version":3,"sources":["webpack://./src/common/text-formattings/partially-hidden-text.css"],"names":[],"mappings":"AAAA;EACE,+EAA0E;AAC5E;;AAEA;EACE;IACE,UAAU;IACV,4BAA4B;IAC5B,2BAA2B;EAC7B;EACA;IACE,UAAU;IACV,yBAAyB;IACzB,2BAA2B;EAC7B;AACF","sourcesContent":[".hiddenPart {\n  animation: appearanceAnimation 100ms cubic-bezier(0, 0.7, 0.3, 1) forwards;\n}\n\n@keyframes appearanceAnimation {\n  from {\n    opacity: 0;\n    transform: translateY(-100%);\n    transition: transform 0.15s;\n  }\n  to {\n    opacity: 1;\n    transform: translateY(0%);\n    transition: transform 0.15s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hiddenPart": `hiddenPart__ec1`,
	"appearanceAnimation": `appearanceAnimation__ceb`
};
export default ___CSS_LOADER_EXPORT___;
