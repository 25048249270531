import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import RegisteredCheckbox from '@app/common/hook-form/registered-checkbox';
// eslint-disable-next-line no-restricted-imports
import HelpIcon from '@app/common/util/help-icon';
import styles from './forced-field.css';
function ForcedField({ settingIndex, hidden = false }) {
    const { control, register } = useFormContext();
    // RegisteredCheckbox doesn't get hidden when setting hidden to true that's why we render a hidden input instead
    return hidden ? (_jsx("input", { type: "checkbox", hidden: hidden, ...register(`content.${settingIndex}.forced`) })) : (_jsxs("div", { children: [_jsx(RegisteredCheckbox, { label: "Forced", name: `content.${settingIndex}.forced`, control: control, labelClassName: styles.forcedCheckbox }), _jsx(Tooltip, { title: "Forced settings can't be overwritten in the IDE settings", children: _jsx(HelpIcon, {}) })] }));
}
export default ForcedField;
