import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Button from '@jetbrains/ring-ui/components/button/button';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
// eslint-disable-next-line import/no-cycle
import { useTbeLicense, useTbeLicenseMutation } from '@app/common/queries/tbe-license';
import { LicenseActivationContent } from './license-activation-content';
import styles from './license-activation-dialog.css';
export function LicenseActivationDialog({ show, onClose }) {
    const [licenseKeyDraft, setLicenseKeyDraft] = useState('');
    const { mutateAsync: licenseKeyMutation, isLoading: isMutating } = useTbeLicenseMutation();
    const { data, refetch } = useTbeLicense();
    const isValidating = useMemo(() => (data === null || data === void 0 ? void 0 : data.status) === 'VALIDATING', [data]);
    const handleActivation = async () => {
        if (licenseKeyDraft) {
            await licenseKeyMutation(licenseKeyDraft)
                .then(() => {
                if (onClose) {
                    onClose();
                }
                refetch();
            })
                .catch(() => { });
        }
    };
    return (_jsxs(Dialog, { label: "Activate JetBrains IDE Services", show: show, contentClassName: styles.dialogContainer, children: [_jsx(Header, { children: "Activate JetBrains IDE Services" }), _jsx(Content, { className: styles.contentContainer, children: _jsx(LicenseActivationContent, { inputValue: licenseKeyDraft, inputValueOnChange: setLicenseKeyDraft }) }), _jsxs(Panel, { children: [_jsx(Button, { onClick: handleActivation, loader: isMutating, disabled: !licenseKeyDraft.trim() || isMutating || isValidating, primary: true, children: "Activate" }), _jsx(Button, { onClick: onClose, children: "Close" })] })] }));
}
