import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import { useEventsHistoryDateTableColumn } from '@modules/events-history';
import { ActorMention } from '@modules/actor';
import { BasicSelectableTable } from '@common/tables/basic-selectable-table';
import { withSortableColumnFlag } from '@common/tables/table-columns';
import { AuditLogsAction } from '../cells/audit-logs-action';
import { sortableColumns } from '../constanst';
const baseColumns = [
    {
        id: 'version',
        title: 'Version',
        getValue: ({ plugin }) => (plugin ? _jsx(Text, { children: plugin.version }) : '')
    },
    {
        id: 'description',
        title: 'Event',
        getValue: (plugin) => _jsx(AuditLogsAction, { ...plugin })
    },
    {
        id: 'createdBy',
        title: 'Author',
        getValue: (item) => (_jsx(ActorMention, { actor: item.createdBy, "data-test": "plugin-audit-log-item-author" }))
    }
];
export function PluginAuditLogsTable({ data, isLoading, params, onParamsChange }) {
    var _a;
    const dateColumn = useEventsHistoryDateTableColumn(data === null || data === void 0 ? void 0 : data.items, getEventDate, 'createdDate');
    const total = (_a = data === null || data === void 0 ? void 0 : data.total) !== null && _a !== void 0 ? _a : 0;
    const columns = useMemo(() => withSortableColumnFlag([dateColumn].concat(baseColumns), sortableColumns), [dateColumn]);
    return (_jsx(BasicSelectableTable, { data: (data === null || data === void 0 ? void 0 : data.items) || [], selectable: false, columns: columns, loading: isLoading, renderEmpty: () => _jsx(Text, { children: "No data available" }), pagerProps: {
            total,
            currentPage: params.page,
            pageSize: DEFAULT_TABLE_PAGE_SIZE,
            onPageChange: newPage => {
                onParamsChange({ ...params, page: newPage });
            },
            disablePageSizeSelector: true
        }, sortKey: params.sortKey, sortOrder: params.sortOrder === 'DESC', onSort: event => onParamsChange({
            ...params,
            sortKey: event.column.id,
            sortOrder: event.order ? 'DESC' : 'ASC',
            page: 1
        }) }));
}
function getEventDate(auditLogEntry) {
    return auditLogEntry.createdDate;
}
