// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fieldContainer__c9a {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) / 2);
}

.scopeGroup__a51 {
  width: min-content;
}

.scopeGroupLabel__f21 {
  color: var(--ring-secondary-color);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/ide-settings/form/common/scope-field.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;EAClC,wCAAwC;EACxC,2CAA2C;AAC7C","sourcesContent":[".fieldContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) / 2);\n}\n\n.scopeGroup {\n  width: min-content;\n}\n\n.scopeGroupLabel {\n  color: var(--ring-secondary-color);\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldContainer": `fieldContainer__c9a`,
	"scopeGroup": `scopeGroup__a51`,
	"scopeGroupLabel": `scopeGroupLabel__f21`
};
export default ___CSS_LOADER_EXPORT___;
