import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { endOfDay, endOfToday, startOfDay } from 'date-fns';
import DatePicker from '@jetbrains/ring-ui/components/date-picker/date-picker';
import { Size as InputSize } from '@jetbrains/ring-ui/components/input/input';
import { formatDateString } from '@app/common/dates/format';
import ToolbarItem from '@app/common/toolbar-item/toolbar-item';
import styles from './date-filters.css';
export function DateFilter({ params, onChange }) {
    const maxDateString = useMemo(() => formatDateString(endOfToday().toISOString(), 'dd MM yyyy'), []);
    const onDateChange = useCallback((change) => {
        onChange({
            ...params,
            from: change.from ? startOfDay(change.from).getTime() : params.from,
            to: change.to ? endOfDay(change.to).getTime() : params.to
        });
    }, [onChange, params]);
    return (_jsx(ToolbarItem, { label: "Date", children: _jsx(DatePicker, { size: InputSize.M, from: params.from, to: params.to, range: true, maxDate: maxDateString, onChange: onDateChange, className: styles.removeRightPadding }) }));
}
