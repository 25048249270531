import { jsx as _jsx } from "react/jsx-runtime";
import { useRouteError } from 'react-router-dom';
import { isApiError } from '@api/is-api-error';
import NotFoundPage from '@app/common/errors/not-found-page';
import ForbiddenPage from '@app/common/errors/forbidden-page';
import UnknownErrorPage from '@app/common/errors/unknown-error-page';
// eslint-disable-next-line import/no-restricted-paths
import { LicenseActivationPage } from '@app/pages/license/license-activation-page';
import { licenseActivationService } from '@common/licensing/license-activation-service';
export function RouteErrorBoundary() {
    var _a;
    const error = useRouteError();
    if (!licenseActivationService.hasLicense()) {
        return _jsx(LicenseActivationPage, {});
    }
    if (isApiError(error)) {
        const { response } = error;
        const message = ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.detail) || (response === null || response === void 0 ? void 0 : response.statusText) || error.message;
        if ((response === null || response === void 0 ? void 0 : response.status) === 404) {
            return _jsx(NotFoundPage, { message: message });
        }
        if ((response === null || response === void 0 ? void 0 : response.status) === 403) {
            return _jsx(ForbiddenPage, { message: message });
        }
        return _jsx(UnknownErrorPage, { code: `${(response === null || response === void 0 ? void 0 : response.status) || 'Unknown'}`, message: message });
    }
    // ignore non api errors
    throw error;
}
