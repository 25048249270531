import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import InlineLoader from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import NavigationLink from '@common/navigation/navigation-link';
import { WidgetHighlightingColor } from '@common/widgets/widget/widget';
import { ExternalLinks } from '@common/constants/constants';
import { useAuthentication } from '@common/queries/authentication';
import { ConfigurationBadge } from './badges/configuration-badge';
import { TrialEndedBadge } from './badges/trial-ended-badge';
import { PopupSteppedMessage } from './badges/popup-stepped-message/popup-stepped-message';
import { TrialNotificationWrapper } from './trial-notification-wrapper/trial-notification-wrapper';
import { BaseConstituentWidget } from './base-constituent-widget';
import { DisabledBadge } from './badges/disabled-badge';
export function ConstituentDisabledWidget(props) {
    const { onEnableClick, constituentLicense, constituentName, licenseType, children, ...rest } = props;
    const { trial, isIncludedInLicense } = constituentLicense;
    const [isEnabling, setIsEnabling] = useState(false);
    const handleEnabledClick = useCallback(() => {
        setIsEnabling(true);
        onEnableClick().finally(() => setIsEnabling(false));
    }, [onEnableClick, setIsEnabling]);
    const badges = useMemo(() => {
        if (isEnabling) {
            return _jsx(InlineLoader, {});
        }
        return (_jsxs(_Fragment, { children: [trial.state === 'ENDED' && licenseType === 'POSTPAID' && (_jsx(TrialEndedBadge, { tooltipContent: "When the trial ends, the product will switch to a paid subscription model. Current users will retain access to the product features." })), trial.state === 'ENDED' && licenseType === 'PREPAID' && !isIncludedInLicense && (_jsx(TrialEndedBadge, { tooltipContent: _jsxs(_Fragment, { children: ["Your trial has ended, and the product is now disabled. Please reach out to the", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_SALES, children: "IDE Services sales team" }), ' ', "to continue using the product."] }) })), trial.state === 'AVAILABLE' && !isIncludedInLicense && (_jsx(ConfigurationBadge, { tooltipContent: _jsxs(_Fragment, { children: ["A new trial is available. To activate the ", constituentName, " trial, reach out", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_SALES, children: "IDE Services sales team" }), "."] }) })), trial.state === 'ACTIVE' && (_jsx(ConfigurationBadge, { tooltipContent: _jsx(EnableTooltipContent, { constituentName: constituentName }), tooltipTitle: `To enable ${constituentName}` })), _jsx(DisabledBadge, {})] }));
    }, [isEnabling, licenseType, constituentName, isIncludedInLicense, trial]);
    return (_jsx(BaseConstituentWidget, { topRightContent: badges, highlighting: trial.state === 'ACTIVE' || (trial.state === 'AVAILABLE' && !isIncludedInLicense)
            ? WidgetHighlightingColor.Warning
            : undefined, controls: _jsxs(_Fragment, { children: [(isIncludedInLicense || trial.state === 'ACTIVE') && (_jsx(Button, { onClick: handleEnabledClick, loader: isEnabling, primary: trial.state === 'ACTIVE', children: "Enable" })), trial.state !== 'ACTIVE' && (_jsx(NavigationLink, { href: "configuration/license-activation", children: "Settings" }))] }), ...rest, children: _jsx(TrialNotificationWrapper, { trial: constituentLicense.trial, children: children }) }));
}
function EnableTooltipContent(props) {
    const { constituentName } = props;
    const { data: authenticationData } = useAuthentication();
    return (_jsxs(PopupSteppedMessage, { children: [`Enable ${constituentName} for your organization.`, (authenticationData === null || authenticationData === void 0 ? void 0 : authenticationData.configurationUrl) && (_jsxs(_Fragment, { children: [_jsx(NavigationLink, { href: authenticationData === null || authenticationData === void 0 ? void 0 : authenticationData.configurationUrl, children: "Configure your identity provider" }), ' ', "and synchronize users."] })), _jsxs(_Fragment, { children: ["Enable ", constituentName, " in a", ' ', _jsx(NavigationLink, { href: "/profiles", children: "new or existing profile" }), "."] }), _jsxs(_Fragment, { children: ["Assign the configured profile to ", _jsx(NavigationLink, { href: "/users", children: "users" }), " or user groups."] })] }));
}
