import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import groupIcon from '@jetbrains/icons/group';
import { useUnassignedUsersCount } from '@app/common/queries/users';
import { Widget, WidgetSize, WidgetVariant } from '@common/widgets/widget/widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { formatPlural } from '@common/format-plural';
import { useGetIdeProvisioning } from '../../common/queries/dashboard';
import NavigationLink from '../../common/navigation/navigation-link';
import styles from './popular-profiles.css';
function usersCountText(usersLength) {
    return usersLength === 1 ? 'One user ' : `${usersLength} users `;
}
export function PopularProfilesWidget() {
    const { data: idepData, isLoading } = useGetIdeProvisioning();
    const { data: usersCount, isLoading: userCountIsLoading } = useUnassignedUsersCount();
    return (_jsx(Widget, { title: "Popular Profiles", ...assignTestId('widget-popular-profiles'), icon: groupIcon, widgetSize: WidgetSize.Wide, variant: WidgetVariant.Filled, loading: isLoading || userCountIsLoading, children: _jsxs("div", { className: styles.contentContainer, children: [usersCount && usersCount.total > 0 && (_jsx("div", { className: styles.bottomContainer, children: _jsxs(NavigationLink, { className: styles.warningText, href: "users?profileId=no-profile", children: [formatPlural(usersCount.total, {
                                one: 'One user',
                                multiple: `${usersCount.total} users`
                            }), ' ', "without any assigned profiles"] }) })), !(idepData === null || idepData === void 0 ? void 0 : idepData.topProfiles.length) && (_jsxs("div", { children: [_jsx(Text, { info: true, children: "No profiles" }), "\u00A0\u00A0\u00A0", _jsx(NavigationLink, { href: "/profiles", children: "Create..." })] })), _jsx("div", { className: styles.listContainer, children: idepData === null || idepData === void 0 ? void 0 : idepData.topProfiles.map(({ id, title, usersAmount }) => (_jsxs("div", { children: [_jsx(Text, { children: _jsx(NavigationLink, { href: `/profiles/${id}`, children: title }) }), "\u00A0\u00A0", _jsx(Text, { info: true, size: "s", children: _jsx(NavigationLink, { secondary: true, href: `/profiles/${id}/users`, children: usersCountText(usersAmount) }) })] }, `top-profile-${id}`))) })] }) }));
}
