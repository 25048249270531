import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import ButtonGroup from '@jetbrains/ring-ui/components/button-group/button-group';
import Button from '@jetbrains/ring-ui/components/button/button';
import classNames from 'classnames';
import { useApplicationBuildSettings } from '@common/queries/users';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { BuildSettingsJsonView } from './build-settings-json-view/build-settings-json-view';
import { BuildSettingsPreview } from './build-settings-preview/build-settings-preview';
import styles from './app-build-settings.css';
export function AppBuildSettings(props) {
    const { className, userId, productBuild, application, onViewSelect, selectedView } = props;
    const { data, isLoading } = useApplicationBuildSettings(userId, {
        appType: application.appType,
        arch: productBuild.buildArchitecture,
        os: productBuild.buildOs,
        build: productBuild.buildNumber
    });
    if (isLoading || !data) {
        return _jsx(Loader, {});
    }
    const tbaConfig = JSON.stringify(data.tbaSettings, null, 2);
    const ideSettings = data.ideSettings ? JSON.stringify(data.ideSettings, null, 2) : undefined;
    return (_jsxs("div", { className: classNames(styles.wrapper, className), ...assignTestId('build-settings'), children: [_jsxs(ButtonGroup, { className: styles.viewSelector, ...assignTestId('view-switcher'), children: [_jsx(Button, { active: selectedView === 'preview', ...assignTestId('short-preview-view-btn'), onClick: () => onViewSelect('preview'), children: "Short preview" }), _jsx(Button, { active: selectedView === 'json', ...assignTestId('full-json-view-btn'), onClick: () => onViewSelect('json'), children: "Full JSON" })] }), selectedView === 'preview' && (_jsx(BuildSettingsPreview, { className: styles.settingsView, appName: application.name, buildOs: productBuild.buildOs, buildArch: productBuild.buildArchitecture, buildNumber: productBuild.buildNumber, settings: data.settings })), selectedView === 'json' && (_jsx(BuildSettingsJsonView, { appType: application.appType, productBuild: productBuild, toolboxAppConfigJSON: tbaConfig, ideSettingsJSON: ideSettings, className: styles.settingsView }))] }));
}
