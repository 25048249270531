import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { CodeWithMeLogo } from '@jetbrains/logos/react';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useNavigate } from 'react-router-dom';
import { objectToSearchString } from 'serialize-query-params';
import { useCodeWithMeMonthlyConcurrentSessions } from '@app/common/queries/code-with-me';
import NavigationLink from '@app/common/navigation/navigation-link';
import { ConstituentBlock } from '@modules/constituents/constituent-block';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './code-with-me-enabled.css';
export function CodeWithMeEnabled(props) {
    const { className, data, onDisableClick, onEditClick, allowEditSettings, licenseType, license } = props;
    const { concurrentHostsLimit, activeUsers } = data;
    const { data: monthlyConcurrentSessionsCount = 0, isLoading: isMonthlySessionLoading } = useCodeWithMeMonthlyConcurrentSessions();
    const navigate = useNavigate();
    const handleShowUsersClick = useCallback(() => navigate({
        pathname: '/users',
        search: objectToSearchString({ constituentId: 'cwm' })
    }), [navigate]);
    const handleGoToProfilesClick = useCallback(() => navigate('/profiles'), [navigate]);
    return (_jsxs(ConstituentBlock, { title: "Code With Me", titleLogo: _jsx(CodeWithMeLogo, {}), state: "enabled", className: className, notConfigured: activeUsers === 0, trialData: { licenseType, license }, ...assignTestId('cwm-block'), children: [_jsx("p", { children: "Code With Me Enterprise offers real-time synchronous collaboration for developers within your organization." }), _jsxs("p", { className: styles.sessionsData, children: [_jsxs("span", { className: styles.sessionsLimit, children: ["Maximum number of concurrent sessions: ", concurrentHostsLimit !== null && concurrentHostsLimit !== void 0 ? concurrentHostsLimit : 'unlimited'] }), !isMonthlySessionLoading && activeUsers > 0 && (_jsxs(NavigationLink, { href: "/dashboard", className: styles.currentConcurrent, children: [monthlyConcurrentSessionsCount, " concurrent sessions this month"] }))] }), activeUsers === 0 && (_jsx("p", { className: styles.notInUseWarning, children: "Enable Code With Me Enterprise in relevant profiles to provide users with access to the product functionality." })), _jsxs("div", { className: styles.actions, children: [activeUsers > 0 ? (_jsx(Button, { onClick: handleShowUsersClick, children: "Go to Users" })) : (_jsx(Button, { onClick: handleGoToProfilesClick, primary: true, children: "Go to Profiles" })), allowEditSettings && _jsx(Button, { onClick: onEditClick, children: "Edit limits" }), _jsx(Button, { onClick: onDisableClick, "data-test": "disable-button", children: "Disable..." })] })] }));
}
