// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonsContainer__ea1 {
  margin-top: var(--ring-unit);
}

.linkButton__a13:first-child {
  margin: 0 calc(-1 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/modules/license/license-activation/usage-stats/usage-stats.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".buttonsContainer {\n  margin-top: var(--ring-unit);\n}\n\n.linkButton:first-child {\n  margin: 0 calc(-1 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsContainer": `buttonsContainer__ea1`,
	"linkButton": `linkButton__a13`
};
export default ___CSS_LOADER_EXPORT___;
