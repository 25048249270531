import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import LoadingError from '@common/errors/loading-error';
import { usePluginsAuditLogs } from '@common/queries/plugins';
import { usePluginAuditLogsParams } from '../use-plugin-audit-logs-params';
import { PluginsAuditLogsTable } from './plugins-audit-logs-table';
import { PluginsAuditLogsFilters } from './plugins-audit-logs-filters';
export function PluginsAuditLogsBody() {
    const [urlParams, setUrlParams] = usePluginAuditLogsParams();
    const { data: auditLogsPage, isError: isFetchError, isFetching } = usePluginsAuditLogs(urlParams);
    if (isFetchError) {
        return _jsx(LoadingError, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(PluginsAuditLogsFilters, { params: urlParams, onFiltersChange: setUrlParams }), _jsx(PluginsAuditLogsTable, { data: auditLogsPage, isLoading: isFetching, params: urlParams, onParamsChange: setUrlParams })] }));
}
