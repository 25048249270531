import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import classNames from 'classnames';
import Button from '@jetbrains/ring-ui/components/button/button';
import chevronRight from '@jetbrains/icons/chevron-right';
import { Collapse } from '@jetbrains/ring-ui/components/collapse/collapse';
import { CollapseContent } from '@jetbrains/ring-ui/components/collapse/collapse-content';
import { CollapseControl } from '@jetbrains/ring-ui/components/collapse/collapse-control';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
import styles from './collapsible.css';
export function Collapsible({ title, children, className, titleClassName, primary, ...rest }) {
    // Hi! I'm Hack, please remove useState & onChange once RG-2469 is fixed
    const [isHidden, setHidden] = useState(true);
    const onChange = (isCollapsed) => {
        setHidden(isCollapsed);
    };
    return (_jsxs(Collapse, { className: className, onChange: onChange, children: [_jsx(CollapseControl, { children: (collapsed) => (_jsx(Button, { inline: true, icon: chevronRight, primary: primary, className: classNames(styles.title, titleClassName, {
                        [styles.titleExpanded]: !collapsed
                    }), ...assignTestId('collapsible-title', rest), children: title })) }), _jsx(CollapseContent, { children: !isHidden && children })] }));
}
