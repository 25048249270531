import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { licenseVaultApi } from '@jetbrains-license-vault/client/lib';
import { useDisableLvConstituent } from '@app/common/queries/license-vault';
import { ConstituentDialog } from '@modules/constituents/constituent-dialog';
export function LvDisableDialog(props) {
    var _a, _b;
    const { onCloseAttempt } = props;
    const { mutate: disable, isLoading } = useDisableLvConstituent(onCloseAttempt);
    const usersTotalResponse = licenseVaultApi.users.useUsersTotal();
    const usersTotal = (_b = (_a = usersTotalResponse.data) === null || _a === void 0 ? void 0 : _a.total) !== null && _b !== void 0 ? _b : 0;
    const handleDisable = useCallback(() => disable(), [disable]);
    const handleClose = useCallback(() => onCloseAttempt(undefined), [onCloseAttempt]);
    const confirmationText = useMemo(() => {
        if (usersTotal === 0) {
            return 'Developers in your organization will no longer be able to obtain licenses using License Vault';
        }
        return (_jsxs(_Fragment, { children: [usersTotal === 1
                    ? 'There is currently one active License Vault user.'
                    : `There are currently ${usersTotal} active License Vault users.`, _jsx("br", {}), "They will lose access to their JetBrains licenses."] }));
    }, [usersTotal]);
    return (_jsxs(ConstituentDialog, { title: "Disable License Vault?", children: [_jsx(Content, { children: _jsx("p", { children: confirmationText }) }), _jsxs(Panel, { children: [_jsx(Button, { onClick: handleDisable, loader: isLoading, danger: true, "data-test": "dialog-confirm-button", children: "Disable" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
