// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon__e97 {
  padding-right: calc(var(--ring-unit) / 2);
}

.usersWarningText__b30 {
  margin-right: var(--ring-unit);
}

.warningIconColor__d3d {
  color: var(--ring-icon-warning-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/users-profile-button.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".icon {\n  padding-right: calc(var(--ring-unit) / 2);\n}\n\n.usersWarningText {\n  margin-right: var(--ring-unit);\n}\n\n.warningIconColor {\n  color: var(--ring-icon-warning-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `icon__e97`,
	"usersWarningText": `usersWarningText__b30`,
	"warningIconColor": `warningIconColor__d3d`
};
export default ___CSS_LOADER_EXPORT___;
