// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterDescription__ee2 {
  line-height: var(--ring-line-height-lowest);
  font-size: var(--ring-font-size-smaller);
}

/* We need to add this padding because:
  filters are draggable
  => it's automatically added a 'metaColumn' by the RingUI table
  => because of this column it's added a two pixel padding for the first line of the filter preview
  => we also add a two pixel padding for all the rest rows
*/
.draggableFix__ea9 {
  padding-left: 2px;
}

/* Use it to add margin between form body and the form footer */
.formBody__b67 {
  margin-bottom: calc(3 * var(--ring-unit));
}

.dialogContent__a5a {
  padding-bottom: calc(2 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/filters-editor/filters-editor.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,wCAAwC;AAC1C;;AAEA;;;;;CAKC;AACD;EACE,iBAAiB;AACnB;;AAEA,+DAA+D;AAC/D;EACE,yCAAyC;AAC3C;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".filterDescription {\n  line-height: var(--ring-line-height-lowest);\n  font-size: var(--ring-font-size-smaller);\n}\n\n/* We need to add this padding because:\n  filters are draggable\n  => it's automatically added a 'metaColumn' by the RingUI table\n  => because of this column it's added a two pixel padding for the first line of the filter preview\n  => we also add a two pixel padding for all the rest rows\n*/\n.draggableFix {\n  padding-left: 2px;\n}\n\n/* Use it to add margin between form body and the form footer */\n.formBody {\n  margin-bottom: calc(3 * var(--ring-unit));\n}\n\n.dialogContent {\n  padding-bottom: calc(2 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterDescription": `filterDescription__ee2`,
	"draggableFix": `draggableFix__ea9`,
	"formBody": `formBody__b67`,
	"dialogContent": `dialogContent__a5a`
};
export default ___CSS_LOADER_EXPORT___;
