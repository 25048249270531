import { apiClientManager } from '@common/api';
export async function getFeedFilterProducts(profileId) {
    const response = await apiClientManager
        .getClient()
        .get(`/api/feed-filters/${profileId}/products`);
    return response.data.items;
}
export async function getQualities() {
    const response = await apiClientManager
        .getClient()
        .get(`/api/feed-filters/qualities`);
    return response.data.items;
}
