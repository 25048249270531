import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@jetbrains/ring-ui/components/button/button';
import Confirm from '@jetbrains/ring-ui/components/confirm/confirm';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { isNil } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { getApiError } from '@api/errors';
import { getUserFormValues } from '@modules/users/user-details/user-edit-form/schemas/ctors';
import { tUserSchema } from '@modules/users/user-details/user-edit-form/schemas/user-schema';
import { UserOAuthSubjectField } from '@modules/users/user-details/user-edit-form/form-fields/user-oauth-subject-field';
import { UserNameField } from '@modules/users/user-details/user-edit-form/form-fields/user-name-field';
import { UserEmailField } from '@modules/users/user-details/user-edit-form/form-fields/user-email-field';
import { UserRoleField } from '@modules/users/user-details/user-edit-form/form-fields/user-role-field';
import { ProfilesTagsField } from '@modules/users/user-details/user-edit-form/form-fields/profiles-tags-field';
import { DeactivateUserField } from '@modules/users/user-details/user-edit-form/form-fields/deactivate-user-field';
import { useCreateUserMutation } from '@common/queries/users';
import { useProfileOptions } from '@common/queries/profiles';
import RingFieldsList from '@common/form/ring-fields-list';
export default function UserAddPopup({ onReject, onUserAdded }) {
    const [showConfirm, setShowConfirm] = useState(false);
    const mutation = useCreateUserMutation();
    const form = useForm({
        defaultValues: getUserFormValues(),
        resolver: zodResolver(tUserSchema)
    });
    const { setValue, handleSubmit, reset, setError, watch, formState: { isSubmitting, isDirty } } = form;
    const isActive = watch('isActive');
    const { data: fetchedProfileOptions, isFetching, isError: isFetchingError } = useProfileOptions();
    const profileOptions = fetchedProfileOptions !== null && fetchedProfileOptions !== void 0 ? fetchedProfileOptions : [];
    // Reset form when fetched ProfileOptions
    useEffect(() => {
        if (!isNil(fetchedProfileOptions)) {
            reset(getUserFormValues(fetchedProfileOptions.filter(p => p.isDefault).map(p => p.id)));
        }
    }, [fetchedProfileOptions, reset, setValue]);
    const onSubmit = async (values) => {
        await mutation.mutateAsync(values, {
            onError: error => {
                const res = getApiError(error);
                if (res.errors !== undefined) {
                    res.errors.forEach(e => {
                        if (e.type === 'VALIDATION') {
                            const ve = e;
                            setError(ve.field, { type: 'custom', message: ve.message }, { shouldFocus: true });
                        }
                    });
                }
            },
            onSuccess: user => {
                onUserAdded(user);
            }
        });
    };
    const tryClose = () => {
        if (isDirty) {
            setShowConfirm(true);
        }
        else {
            onReject();
        }
    };
    const forceClose = () => {
        setShowConfirm(false);
        onReject();
    };
    const disableFields = isFetching || isFetchingError;
    return (_jsxs(Dialog, { show: true, onCloseAttempt: tryClose, children: [_jsx(Header, { children: "Create New User" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [isFetching && _jsx(LoaderInline, {}), _jsxs(RingFieldsList, { children: [_jsx(UserOAuthSubjectField, { disabled: disableFields }), _jsx(UserNameField, { disabled: disableFields }), _jsx(UserEmailField, { disabled: disableFields }), _jsx(UserRoleField, { disabled: disableFields }), _jsx(ProfilesTagsField, { profileOptions: profileOptions, disabled: disableFields }), _jsx(DeactivateUserField, { isActive: isActive, onChange: value => setValue('isActive', value) })] }), _jsx("input", { type: "submit", hidden: true, disabled: !isDirty })] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, loader: isSubmitting, disabled: !isDirty, onClick: handleSubmit(onSubmit), children: "Create" }), _jsx(Button, { onClick: onReject, children: "Cancel" })] }), _jsx(Confirm, { show: showConfirm, onConfirm: forceClose, onReject: () => setShowConfirm(false), text: "Do you really want to close dialog?", description: "Changes are not saved" })] }));
}
