import { apiClientManager } from '@common/api';
import { setFreezesAnalyticsFetchError, setFreezesAnalyticsInfo, setFreezesAnalyticsIsFetching } from '../common/store/freezes-analytics';
import { DEFAULT_TABLE_PAGE_SIZE, getSortOrder } from './paging-utils';
export async function getCollectedDataFreeze(id) {
    return apiClientManager
        .getClient()
        .get(`/api/analytics/freezes/${id}`)
        .then(({ data }) => data);
}
export async function getFreezeZip(id) {
    return apiClientManager.getClient().get(`/api/analytics/freeze-reports/${id}`, {
        responseType: 'blob'
    });
}
export async function getFreezesAnalytics(period, dispatch, order, page) {
    try {
        const params = {
            range: period,
            sort: 'DURATION_MS',
            order: getSortOrder(order),
            page: 0,
            limit: ''
        };
        if (page !== undefined) {
            params.page = page;
            params.limit = String(DEFAULT_TABLE_PAGE_SIZE);
        }
        dispatch(setFreezesAnalyticsIsFetching(true));
        const response = await apiClientManager
            .getClient()
            .get('/api/analytics/freezes', {
            params
        });
        dispatch(setFreezesAnalyticsInfo(response.data.items, response.data.total));
    }
    catch (e) {
        dispatch(setFreezesAnalyticsFetchError(e));
    }
}
