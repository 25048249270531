import { apiClientManager } from '@common/api';
import auth from '../common/auth/auth';
import { withAuditFieldTexts } from './audit-utils';
import { DEFAULT_TABLE_PAGE_SIZE } from './paging-utils';
export const roleTexts = {
    USER: 'Regular user',
    ADMIN: 'Administrator'
};
const normalizeUserId = (rawUserId) => { var _a; return (rawUserId === null || rawUserId === void 0 ? void 0 : rawUserId.toLowerCase()) === 'me' ? (_a = auth.getUserProfile()) === null || _a === void 0 ? void 0 : _a.id : rawUserId; };
export const ROLE_OPTION_ITEMS = [
    { key: 'ADMIN', label: roleTexts.ADMIN },
    { key: 'USER', label: roleTexts.USER }
];
function getSortParam(sortKey) {
    switch (sortKey) {
        case 'username':
            return 'USERNAME';
        case 'email':
            return 'EMAIL';
        case 'role':
            return 'ROLE';
        case 'isActive':
            return 'IS_ACTIVE';
        case 'lastActionDate':
            return 'LAST_ACTION_DATE';
        default:
            return null;
    }
}
function buildRequestParams(params) {
    const { page, sortKey, sortOrder, query, isActive, profileId, role, limit, groupId, constituentId } = params;
    const res = {};
    res.page = page;
    res.limit = limit ? String(limit) : String(DEFAULT_TABLE_PAGE_SIZE);
    if (sortKey) {
        res.sort = getSortParam(sortKey);
        res.order = sortOrder;
    }
    if (query) {
        res['filter-query'] = query;
    }
    if (isActive != null) {
        res['filter-is-active'] = isActive;
    }
    if (profileId != null) {
        res['filter-profile-id'] = profileId;
    }
    if (role != null) {
        res['filter-role'] = role;
    }
    if (groupId != null) {
        res['filter-group-id'] = groupId;
    }
    if (constituentId != null) {
        res['filter-constituent'] = constituentId;
    }
    return res;
}
export const apiUserAuditTypeOptions = [
    { key: 'CREATED', label: 'Created' },
    { key: 'UPDATED', label: 'Updated' }
    // TODO: https://youtrack.jetbrains.com/issue/IDES-5572
    // {key: 'ASSIGNED_TO_PROFILE', label: 'Assigned to profile'},
    // {key: 'UNASSIGNED_FROM_PROFILE', label: 'Unassigned from profile'}
];
export async function patchUser(id, userPatch) {
    const response = await apiClientManager
        .getClient()
        .patch(`/api/users/${normalizeUserId(id)}`, userPatch);
    return response.data;
}
export async function getCurrentUser() {
    const response = await apiClientManager.getClient().get('/api/users/me');
    return response.data;
}
export async function signEvaluationTerms() {
    return apiClientManager
        .getClient()
        .post('/api/users/me/sign-evaluation-terms')
        .then(({ data }) => data);
}
export async function getUser(id) {
    const res = await apiClientManager.getClient().get(`/api/users/${normalizeUserId(id)}`);
    return res.data;
}
export async function createUser(newUser) {
    const response = await apiClientManager.getClient().post('/api/users', newUser);
    return response.data;
}
export async function getUserAvailableApps(userId) {
    const response = await apiClientManager
        .getClient()
        .get(`/api/users/${normalizeUserId(userId)}/products`);
    return response.data;
}
export async function getUserBuildSettings(userId, buildInfo) {
    const response = await apiClientManager
        .getClient()
        .get(`/api/users/${userId}/settings`, {
        params: buildInfo
    });
    return response.data;
}
export async function getUnassignedUsersTotal() {
    const response = await apiClientManager.getClient().get('/api/users', {
        params: {
            'include-total-count': true,
            'filter-profile-id': 'no-profile'
        }
    });
    return { total: response.data.total };
}
export async function getUsersAndTotal(params, signal) {
    const response = await apiClientManager.getClient().get('/api/users', 
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    { params: buildRequestParams(params), signal });
    return {
        users: response.data.items.map(x => withAuditFieldTexts(x)),
        total: response.data.total
    };
}
export async function addProfileToUsers(users, profileId) {
    await apiClientManager.getClient().put(`/api/profiles/${profileId}/users`, {}, {
        params: {
            id: users.map(it => it.id)
        }
    });
}
export async function unAssignUsersFromProfile(profileId, users) {
    const response = await apiClientManager.getClient().delete(`/api/profiles/${profileId}/users`, {
        params: {
            id: users.map(({ id }) => id)
        }
    });
    return response.data;
}
export async function revokeUserToken(userId) {
    const response = await apiClientManager.getClient().delete(`api/users/${userId}/tokens`);
    return response.data;
}
function getAuditLogSortParam(param) {
    switch (param) {
        case 'date':
            return 'DATE';
        default:
            return '';
    }
}
function buildAuditLogParams(params) {
    const res = {};
    res.page = params.page;
    res.limit = params.limit ? String(params.limit) : String(DEFAULT_TABLE_PAGE_SIZE);
    if (params.sortKey) {
        res.sort = getAuditLogSortParam(params.sortKey);
        res.order = params.sortOrder;
    }
    if (params.type) {
        res.type = params.type;
    }
    if (params.search) {
        res.search = params.search;
    }
    return res;
}
export async function getUserAuditLogs(userId, params) {
    const response = await apiClientManager.getClient().get(`api/users/${userId}/audit-logs`, {
        params: buildAuditLogParams(params)
    });
    return response.data;
}
