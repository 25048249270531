import { jsx as _jsx } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import { ConstituentDisabledWidget } from '@modules/constituents/widget/constituent-disabled-widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useOpenModal } from '@common/modals/hooks';
import { CwmEnableDialog } from '../../../common/dialogs/cwm-enable-dialog/cwm-enable-dialog';
export function CwmDisabledNoTrialWidget(props) {
    const openEnableDialog = useOpenModal(CwmEnableDialog, {});
    return (_jsx(ConstituentDisabledWidget, { ...props, ...assignTestId('widget-code-with-me'), onEnableClick: openEnableDialog, children: _jsx(Text, { children: "Enable real-time distributed code collaboration right in your JetBrains IDEs \u2013 perfect for pair programming" }) }));
}
