// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__cf1 {
  border-top: solid 1px var(--ring-borders-color);
  padding-top: calc(2 * var(--ring-unit));
  margin: 100% calc(4 * var(--ring-unit)) calc(2 * var(--ring-unit)) calc(4 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/common/app-layout/sidebar/sidebar-footer/sidebar-footer.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,uCAAuC;EACvC,6FAA6F;AAC/F","sourcesContent":[".footer {\n  border-top: solid 1px var(--ring-borders-color);\n  padding-top: calc(2 * var(--ring-unit));\n  margin: 100% calc(4 * var(--ring-unit)) calc(2 * var(--ring-unit)) calc(4 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer__cf1`
};
export default ___CSS_LOADER_EXPORT___;
