// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbContainer__c07 {
  display: flex;
  white-space: nowrap;
  height: calc(var(--ring-unit) * 2.5);
}
`, "",{"version":3,"sources":["webpack://./src/common/page-layout/breadcrumbs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,oCAAoC;AACtC","sourcesContent":[".breadcrumbContainer {\n  display: flex;\n  white-space: nowrap;\n  height: calc(var(--ring-unit) * 2.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbContainer": `breadcrumbContainer__c07`
};
export default ___CSS_LOADER_EXPORT___;
