import confirm from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import { AI_PROVIDERS } from '../../constants';
export default function confirmDefaultProviderChange(provider) {
    var _a;
    const providerLabel = (_a = AI_PROVIDERS.find(({ key }) => key === provider)) === null || _a === void 0 ? void 0 : _a.label;
    return confirm({
        text: 'Change a default AI Provider',
        description: `${providerLabel} is enabled by default in some profiles, and it will remain available even if the default is changed.`,
        confirmLabel: 'Change default',
        rejectLabel: 'Cancel'
    });
}
