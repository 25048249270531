import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import classNames from 'classnames';
import NavigationLink from '@common/navigation/navigation-link';
import styles from '@common/charts/interval-bar-chart/interval-bar-chart.css';
export function IntervalWrapper(props) {
    const { interval, children, onActivateInterval, active } = props;
    const handleMouseEnter = useCallback(() => onActivateInterval(interval), [onActivateInterval, interval]);
    if (interval.link) {
        return (_jsx(NavigationLink, { onMouseEnter: handleMouseEnter, className: classNames(styles.chartBar, styles.chartBarLink, {
                [styles.chartBarActive]: active
            }), href: interval.link, children: children }));
    }
    return (_jsx("div", { onMouseEnter: handleMouseEnter, className: classNames(styles.chartBar, { [styles.chartBarActive]: active }), children: children }));
}
