// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listContainer__d82 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--ring-unit) * 1.5);
}

.assignProfileButton__b4a {
  padding-left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/modules/users/user-details/user-edit-form/form-fields/profiles-list/profiles-list.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iCAAiC;AACnC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".listContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.assignProfileButton {\n  padding-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": `listContainer__d82`,
	"assignProfileButton": `assignProfileButton__b4a`
};
export default ___CSS_LOADER_EXPORT___;
