import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLoaderData } from 'react-router-dom';
import { UserAuditLogsFilters, UserAuditLogsTable } from '@modules/users/user-details/audit-logs';
import { PageTitle } from '@common/page-layout/page-title';
import { useUserAuditLogs } from '@common/queries/users';
import { useUserAuditLogsParams } from './use-user-audit-logs-params';
export function UserAuditLogsPage() {
    var _a;
    const user = useLoaderData();
    const [params, setUrlParams] = useUserAuditLogsParams();
    const { data, isLoading } = useUserAuditLogs((_a = user.id) !== null && _a !== void 0 ? _a : '', params);
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: "Change history", breadcrumbs: [
                    { title: 'Users', href: '/users' },
                    { title: `${user.username}`, href: `/users/${user.id}` }
                ] }), _jsx(UserAuditLogsFilters, { params: params, onFiltersChange: setUrlParams }), _jsx(UserAuditLogsTable, { params: params, onParamsChange: setUrlParams, data: data, isLoading: isLoading })] }));
}
