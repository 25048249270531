import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Toggle, { Size } from '@jetbrains/ring-ui/components/toggle/toggle';
import { CodeWithMeLogo } from '@jetbrains/logos/react';
import { useCwmEnableMutation } from '@app/common/queries/code-with-me';
import { ConstituentDialog, LimitAssigmentEditor } from '@modules/constituents/constituent-dialog';
import auth from '@common/auth/auth';
import styles from './cwm-enable-dialog.css';
export function CwmEnableDialog(props) {
    var _a;
    const { onCloseAttempt, inputSettings } = props;
    const { cloudModeEnabled } = auth.getFeatures();
    const { mutate: enable, isLoading } = useCwmEnableMutation(onCloseAttempt);
    const [confirmDisableGlobalAccess, setConfirmDisableGlobalAccess] = useState(false);
    const [confirmRelayServer, setConfirmRelayServer] = useState(false);
    const [usage, setUsage] = useState((_a = inputSettings === null || inputSettings === void 0 ? void 0 : inputSettings.initialLimitUsage) !== null && _a !== void 0 ? _a : 'unlimited');
    const activateActive = useMemo(() => {
        if (cloudModeEnabled) {
            return confirmDisableGlobalAccess;
        }
        return confirmDisableGlobalAccess && confirmRelayServer;
    }, [cloudModeEnabled, confirmDisableGlobalAccess, confirmRelayServer]);
    const handleClose = () => onCloseAttempt(undefined);
    const handleActivate = () => {
        enable({});
    };
    return (_jsxs(ConstituentDialog, { title: "Code With Me Enterprise", titleLogo: _jsx(CodeWithMeLogo, {}), children: [_jsxs(Content, { children: [_jsxs("p", { children: ["Code With Me Enterprise allows you to restrict", _jsx("br", {}), "Code With Me access to authenticated users"] }), _jsxs("p", { className: styles.confirmGlobalAccessWrapper, children: [_jsx(Toggle, { checked: confirmDisableGlobalAccess, onChange: e => setConfirmDisableGlobalAccess(e.target.checked), size: Size.Size16, "data-test": "confirm-global-toggle", children: "I confirm that global Code With Me access will be disabled" }), _jsxs("p", { className: styles.globalDisableWarning, children: ["Enabling Code With Me Enterprise disables access to the global", _jsx("br", {}), "Code With Me servers for all users connected to JetBrains IDE Services"] })] }), !cloudModeEnabled && (_jsx("p", { className: styles.relayToggleWrapper, children: _jsx(Toggle, { checked: confirmRelayServer, onChange: e => setConfirmRelayServer(e.target.checked), size: Size.Size16, "data-test": "confirm-relay-toggle", children: "I confirm that one or more relay servers are configured and running" }) })), inputSettings && (_jsx(LimitAssigmentEditor, { className: styles.limit, toggleText: "Unlimited concurrent sessions", limitNotice: _jsxs(_Fragment, { children: ["You can set a limit on the number of concurrent sessions,", _jsx("br", {}), "blocking users from creating new sessions when the limit is reached"] }), limit: usage, readonly: inputSettings.readonly, onLimitChange: setUsage }))] }), _jsxs(Panel, { children: [_jsx(Button, { onClick: handleActivate, disabled: !activateActive, loader: isLoading, primary: true, "data-test": "dialog-confirm-button", children: "Enable Code With Me Enterprise" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
