import { useCallback, useEffect, useState } from 'react';
// this hook required to synchronize tooltip state between multiple components, to avoid multiple tooltips opened at the same time
export function useSynchronizedTooltipState() {
    const [tooltipId] = useState(() => singlePopupMessageService.nextId);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        singlePopupMessageService.registerTooltipState(tooltipId, setOpen);
        return () => {
            singlePopupMessageService.unregisterTooltipState(tooltipId);
        };
    }, [tooltipId]);
    const setOpenSync = useCallback((open) => singlePopupMessageService.setTooltipState(tooltipId, open), [tooltipId]);
    return [open, setOpenSync];
}
class SynchronizedPopupStateService {
    constructor() {
        this.lastIssuedMessageId = 0;
        this.registeredStates = new Map();
    }
    get nextId() {
        // eslint-disable-next-line no-plusplus
        return `${this.lastIssuedMessageId++}`;
    }
    registerTooltipState(tooltipId, onStateChange) {
        this.registeredStates.set(tooltipId, onStateChange);
    }
    unregisterTooltipState(tooltipId) {
        this.registeredStates.delete(tooltipId);
    }
    setTooltipState(tooltipId, open) {
        this.registeredStates.forEach((setState, registeredTooltipId) => {
            setState(registeredTooltipId === tooltipId ? open : false);
        });
    }
}
const singlePopupMessageService = new SynchronizedPopupStateService();
