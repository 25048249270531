import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { createTextCn } from '@rescui/typography';
import { Button as MarketingButton } from '@rescui/button';
import Logo from '@jetbrains/ring-ui/components/header/logo';
import { useCompanyName } from '@app/common/hooks/use-company-name';
import auth from '@app/common/auth/auth';
import { DownloadTbeToolboxApp, ToolboxAppLink, useMachineConfigDownloadButtonProps } from '@app/modules/machine-config';
import { useColorMode, useColorModeValue } from '@app/common/color-mode-context';
import toolboxAppBannerLight from '@app/assets/images/toolbox-app-banner-light.png';
import toolboxAppBannerDark from '@app/assets/images/toolbox-app-banner-dark.png';
import jbDecorationLogo from '@app/assets/login-page-background.svg';
import styles from './unauthorized-welcome-page.css';
export function UnauthorizedWelcomePage() {
    const [showDownloadToolbox, setShowDownloadToolbox] = useState(false);
    const tbaBanner = useColorModeValue(toolboxAppBannerLight, toolboxAppBannerDark);
    const { colorMode: theme } = useColorMode();
    const [startFirstColumnAnimation, setStartFirstColumnAnimation] = useState(false);
    const { companyName, isLoading: companyNameIsLoading } = useCompanyName();
    const rescuiCN = createTextCn(theme);
    const rescuiTextClassNames = rescuiCN('rs-text-2', {
        hardness: 'average'
    });
    useEffect(() => {
        let subscribed = true;
        setTimeout(() => {
            if (subscribed) {
                setStartFirstColumnAnimation(true);
            }
        }, 100);
        return () => {
            subscribed = false;
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.backgroundColor }), _jsxs("div", { className: styles.welcomeBox, children: [_jsx("div", { className: styles.backgroundWrapper, children: _jsx(Logo, { glyph: jbDecorationLogo, className: styles.background, size: 2000 }) }), _jsxs("div", { className: styles.welcomeBody, children: [_jsxs("div", { className: classNames(styles.welcomeBodyColumn, styles.dataColumn, {
                                    [styles.welcomeBodyColumnVisible]: !companyNameIsLoading
                                }), children: [_jsxs("header", { "data-test": "unauthorized-header", className: rescuiCN('rs-h1'), children: ["Welcome to ", companyName] }), _jsx("div", { className: styles.joinBtnWrapper, onClickCapture: () => setShowDownloadToolbox(true), children: _jsx(MarketingButton, { theme: theme, size: "l", mode: showDownloadToolbox ? 'rock' : 'classic', "data-test": "join-organization-button", ...useMachineConfigDownloadButtonProps(`Join ${companyName}`) }) }), _jsx("div", { className: styles.infoContainer, children: showDownloadToolbox ? (_jsxs("div", { className: classNames(styles.downloadToolboxAppContainer, rescuiTextClassNames), children: [_jsx("header", { className: rescuiCN('rs-h2'), children: "Did that not work?" }), "Make sure the Toolbox App is installed on your computer", _jsx(DownloadTbeToolboxApp, { useRescUI: true })] })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: classNames(styles.textContainer, rescuiTextClassNames), children: ["Install the ", _jsx(ToolboxAppLink, { tooltip: false, className: styles.tbaLink }), " and join your organization to get access to developer tools", auth.getFeatures().offlineModeEnabled && (_jsx(DownloadTbeToolboxApp, { className: styles.downloadToolboxApp }))] }), _jsx("div", { className: rescuiTextClassNames, children: _jsx(DownloadTbeToolboxApp, { className: classNames(styles.tbaLink, rescuiTextClassNames), inline: true }) })] })) })] }), _jsx("div", { className: styles.spring }), _jsx("div", { className: classNames(styles.welcomeBodyColumn, styles.imageColumn, {
                                    [styles.welcomeBodyColumnVisible]: startFirstColumnAnimation
                                }), children: _jsx("div", { children: _jsx("img", { className: styles.toolboxAppImg, src: tbaBanner, alt: "Toolbox App" }) }) })] })] })] }));
}
