import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import { ROLE_OPTION_ITEMS } from '@api/users';
import ToolbarItem from '@app/common/toolbar-item/toolbar-item';
function RoleFilter({ value, onChange }) {
    const handleChange = useCallback((selected) => {
        onChange((selected === null || selected === void 0 ? void 0 : selected.key) ? `${selected === null || selected === void 0 ? void 0 : selected.key}` : undefined);
    }, [onChange]);
    return (_jsx(ToolbarItem, { label: "Role", children: _jsx(Select, { clear: true, label: "All", size: Select.Size.M, data: ROLE_OPTION_ITEMS, selected: ROLE_OPTION_ITEMS.find(option => option.key === value), onChange: handleChange }) }));
}
export default RoleFilter;
