import { isNil } from 'lodash';
import * as z from 'zod';
import { formatDateAndTime } from '../common/util/i18n';
export function parseAuditId(auditId) {
    const auditIdByParts = (auditId === null || auditId === void 0 ? void 0 : auditId.split(':')) || [];
    if (auditIdByParts.length === 2) {
        return { userId: auditIdByParts[0], userName: auditIdByParts[1] };
    }
    if (auditIdByParts.length === 3) {
        return { userId: auditIdByParts[0], userName: auditIdByParts[2] };
    }
    return null;
}
export function formatAuditId(auditId) {
    return isNil(auditId) ? null : auditId.split(':')[0];
}
export function withAuditFieldTexts(data) {
    const parsed = z
        .object({
        createdBy: z
            .string()
            .optional()
            .nullable()
            .transform(x => formatAuditId(x)),
        createdDate: z
            .string()
            .optional()
            .nullable()
            .transform(x => formatDateAndTime(x)),
        lastModifiedBy: z
            .string()
            .optional()
            .nullable()
            .transform(x => formatAuditId(x)),
        lastModifiedDate: z
            .string()
            .optional()
            .nullable()
            .transform(x => formatDateAndTime(x))
    })
        .parse(data);
    return {
        ...data,
        createdByText: parsed.createdBy,
        createdDateText: parsed.createdDate,
        lastModifiedByText: parsed.lastModifiedBy,
        lastModifiedDateText: parsed.lastModifiedDate
    };
}
