import { getLicenseVaultClient } from '@jetbrains-license-vault/client/lib';
import { isNil } from 'lodash';
import { isApiError } from '@api/is-api-error';
import { apiClientManager } from '@common/api';
export async function getLvConstituentState() {
    var _a, _b;
    const serviceClient = apiClientManager.getClient();
    try {
        const res = await serviceClient.get('/api/constituents/lv');
        return { status: 'enabled', data: res.data };
    }
    catch (e) {
        if (isApiError(e)) {
            if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                return { status: 'disabled' };
            }
            if (((_b = e.response) === null || _b === void 0 ? void 0 : _b.status) === 403) {
                return { status: 'disabled_jba' };
            }
        }
        throw e;
    }
}
export async function enableLvConstituent() {
    const serviceClient = apiClientManager.getClient();
    const res = await serviceClient.post('/api/constituents/lv', { id: 'lv' });
    return { status: 'enabled', data: res.data };
}
export async function disableLvConstituent() {
    const serviceClient = apiClientManager.getClient();
    await serviceClient.delete('/api/constituents/lv');
    return { status: 'disabled' };
}
export async function getLicenseVaultLimits() {
    var _a;
    const response = await getLicenseVaultClient().get('ides-ui/licenses/limits');
    return (_a = response.data) === null || _a === void 0 ? void 0 : _a.map(({ code, limit, ...rest }) => ({
        id: code,
        limit: fromServerLimit(limit),
        ...rest
    }));
    function fromServerLimit(limit) {
        return isNil(limit) ? 'UNLIMITED' : limit;
    }
}
export async function saveLicenseVaultPostpaidLimits(postpaidLicenses = []) {
    const serverModel = postpaidLicenses.map(it => ({ code: it.id, limit: toServerLimit(it.limit) }));
    await getLicenseVaultClient().post('ides-ui/licenses/limits', serverModel);
    function toServerLimit(limit) {
        return typeof limit === 'number' ? limit : null;
    }
}
export async function getDeniedLicensesRequests() {
    const response = await getLicenseVaultClient().get('tickets/denials/products', {
        params: {
            // TODO:(katriyna) filter by denialReason in future, e.g. `denialReason=CANCELED`. Now we do not such a filter in API.
            // See the status of [Cannot get number of users with denied requests for the last 24 hours due to absence of licenses](https://youtrack.jetbrains.com/issue/JPF-16393)
            timeSpan: 'LAST_DAY'
        }
    });
    return response.data;
}
