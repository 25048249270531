import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import sniffer from '@jetbrains/ring-ui/components/global/sniffer';
import Button from '@jetbrains/ring-ui/components/button/button';
import Select from '@jetbrains/ring-ui/components/select/select';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import classNames from 'classnames';
import { Button as RescUIButton } from '@rescui/button';
import { DownIcon } from '@rescui/icons';
import { DropdownMenu as RescUIDropdownMenu, MenuItem } from '@rescui/dropdown-menu';
import { useToolboxInfo } from '@common/queries/toolbox';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import HorizontalContainer from '../../common/page-layout/horizontal-container';
import styles from './download-toolbox-app.css';
function tryToDetectMacArm() {
    try {
        const glcontext = document.createElement('canvas').getContext('webgl');
        const debugrenderer = glcontext ? glcontext.getExtension('WEBGL_debug_renderer_info') : null;
        const rendererName = (debugrenderer && (glcontext === null || glcontext === void 0 ? void 0 : glcontext.getParameter(debugrenderer.UNMASKED_RENDERER_WEBGL))) || '';
        return ((rendererName.includes('Apple') &&
            (rendererName.includes('M1') ||
                rendererName.includes('M2') ||
                rendererName.includes('M3'))) ||
            false);
    }
    catch (e) {
        console.warn(`can't determine MacOS architecture: ${e}`);
        return false;
    }
}
function getDefaultOptionKey() {
    switch (sniffer.sniff(navigator.userAgent).os.name) {
        case 'macos':
            if (tryToDetectMacArm()) {
                return 'MAC_OS_X-ARM64';
            }
            return 'MAC_OS_X-X64';
        case 'windows':
            return 'WINDOWS-ALL';
        case 'linux':
            return 'LINUX-ALL';
        default:
            return null;
    }
}
function getLabel(apiDownloadUrl) {
    if (apiDownloadUrl.os === 'WINDOWS' && apiDownloadUrl.arch === 'ALL') {
        return '.exe';
    }
    if (apiDownloadUrl.os === 'LINUX' && apiDownloadUrl.arch === 'ALL') {
        return '.tar.gz';
    }
    if (apiDownloadUrl.os === 'MAC_OS_X' && apiDownloadUrl.arch === 'ARM64') {
        return '.dmg (ARM)';
    }
    if (apiDownloadUrl.os === 'MAC_OS_X' && apiDownloadUrl.arch === 'X64') {
        return '.dmg (Intel)';
    }
    console.warn(`Unexpected binary type (OS: ${apiDownloadUrl.os}; arch: ${apiDownloadUrl.arch}`);
    if (apiDownloadUrl.arch != null) {
        return `${apiDownloadUrl.os} ${apiDownloadUrl.arch}`;
    }
    return `${apiDownloadUrl.os}`;
}
function getUrl(apiDownloadUrl, params) {
    const urlObj = new URL(apiDownloadUrl.url);
    Object.keys(params).forEach(key => {
        urlObj.searchParams.append(key, params[key]);
    });
    return urlObj.toString();
}
export function DownloadTbeToolboxApp({ className, inline = false, primary = false, useRescUI = false, shortLabel = false }) {
    const query = useMemo(() => ({ type: 'tbe' }), []);
    return (_jsx(DownloadToolboxApp, { className: className, inline: inline, query: query, primary: primary, useRescUI: useRescUI, shortLabel: shortLabel }));
}
export function DownloadCwmInTbeToolboxApp({ className, inline = false, sessionID, primary }) {
    const query = useMemo(() => ({
        type: 'cwm-in-tbe',
        cwmSessionID: sessionID
    }), [sessionID]);
    return (_jsx(DownloadToolboxApp, { className: className, inline: inline, query: query, primary: primary, useRescUI: false }));
}
function DownloadToolboxApp({ query, useRescUI, inline, className, primary, shortLabel }) {
    const { data: toolboxInfo, isFetching } = useToolboxInfo();
    const selectItems = useMemo(() => {
        return ((toolboxInfo === null || toolboxInfo === void 0 ? void 0 : toolboxInfo.downloadUrls.map(urlInfo => ({
            key: `${urlInfo.os}-${urlInfo.arch}`,
            os: urlInfo.os,
            arch: urlInfo.arch,
            label: getLabel(urlInfo),
            downloadUrl: getUrl(urlInfo, query)
        }))) || []);
    }, [query, toolboxInfo === null || toolboxInfo === void 0 ? void 0 : toolboxInfo.downloadUrls]);
    const defaultBinaryTypeKey = useMemo(() => {
        return getDefaultOptionKey();
    }, []);
    const [selectedBinaryType, setSelectedBinaryType] = useState(null);
    useEffect(() => {
        const defaultBinaryType = selectItems.find(bt => bt.key === defaultBinaryTypeKey) || null;
        setSelectedBinaryType(defaultBinaryType);
    }, [defaultBinaryTypeKey, selectItems]);
    if (useRescUI) {
        return (_jsx(RescUiDownloadButtons, { isFetching: isFetching, items: selectItems, onItemChange: setSelectedBinaryType, selectedBinaryType: selectedBinaryType }));
    }
    return (_jsx(DownloadButtons, { isFetching: isFetching, items: selectItems, onItemChange: setSelectedBinaryType, selectedBinaryType: selectedBinaryType, inline: inline, primary: primary, className: className, shortLabel: shortLabel }));
}
function DownloadButtons({ className, primary, inline, isFetching, selectedBinaryType, items, onItemChange, shortLabel }) {
    return (_jsxs(HorizontalContainer, { className: classNames(styles.wrapper, className), children: [_jsxs(Button, { primary: primary, loader: isFetching, disabled: isFetching || selectedBinaryType === null, inline: inline, text: inline, onClick: () => {
                    if (selectedBinaryType) {
                        window.open(selectedBinaryType.downloadUrl);
                    }
                }, ...assignTestId('download-toolbox-app'), children: [isFetching && _jsx(LoaderInline, { className: styles.fetchingLoader }), shortLabel ? 'Download' : 'Download Toolbox App'] }), _jsx(Select, { type: Select.Type.INLINE, className: styles.archDropdown, disabled: isFetching, notFoundMessage: "You have no versions of Toolbox available for use based on your profiles. Please contact your administrator.", data: items, selected: selectedBinaryType, onChange: onItemChange })] }));
}
function RescUiDownloadButtons({ className, isFetching, selectedBinaryType, items, onItemChange }) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => setIsOpen(prev => !prev);
    return (_jsxs(HorizontalContainer, { className: classNames(styles.rescUiWrapper, className), children: [_jsx(RescUIButton, { busy: isFetching, flat: "right", disabled: isFetching || selectedBinaryType === null, onClick: () => {
                    if (selectedBinaryType) {
                        window.open(selectedBinaryType.downloadUrl);
                    }
                }, ...assignTestId('download-toolbox-app'), children: "Download Toolbox App\u00A0" }), _jsx(RescUIDropdownMenu, { isOpen: isOpen, onRequestClose: () => setIsOpen(false), trigger: _jsx(RescUIButton, { icon: _jsx(DownIcon, {}), iconPosition: "right", flat: "left", onClick: toggleIsOpen, children: selectedBinaryType === null || selectedBinaryType === void 0 ? void 0 : selectedBinaryType.label }), children: items.map(item => {
                    return _jsx(MenuItem, { onClick: () => onItemChange(item), children: item.label });
                }) })] }));
}
