import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import helpIcon from '@jetbrains/icons/help';
import Button from '@jetbrains/ring-ui/components/button/button';
import Toggle from '@jetbrains/ring-ui/components/toggle/toggle';
import { assignTestId } from '@common/infra/tests/assign-test-id';
const DEACTIVATE_USER_TEXT = 'Deactivated users are not managed by JetBrains IDE Services';
export function DeactivateUserField({ isActive, onChange, disabled = false }) {
    return (_jsxs("div", { children: [_jsx(Toggle, { defaultChecked: isActive, checked: isActive, disabled: disabled, onChange: evt => onChange(evt.target.checked), "aria-label": isActive ? 'Is active' : 'Is inactive', name: "isActive", ...assignTestId('activate-user-toggle'), children: _jsxs(Text, { children: [isActive ? 'Active' : 'Deactivated', " "] }) }, isActive ? 'Active' : 'Deactivated'), _jsx(Tooltip, { title: disabled
                    ? 'The user is managed in IDP. To deactivate user go to your Identity Provider'
                    : DEACTIVATE_USER_TEXT, children: _jsx(Button, { icon: helpIcon }) })] }));
}
