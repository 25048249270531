import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import { noop } from 'lodash';
import { useAiDisableMutation } from '@app/common/queries/ai-enterprise';
import { ConstituentDialog } from '@modules/constituents/constituent-dialog';
export function AiDisableDialog(props) {
    const { onCloseAttempt } = props;
    const { mutateAsync: disableAi, isLoading } = useAiDisableMutation(() => onCloseAttempt(true));
    const handleDisable = useCallback(() => disableAi().catch(noop), [disableAi]);
    const handleClose = useCallback(() => onCloseAttempt(false), [onCloseAttempt]);
    return (_jsxs(ConstituentDialog, { title: "Disable AI Enterprise?", children: [_jsx(Content, { children: _jsxs("p", { children: ["If you disable AI Enterprise, developers connected to this server", _jsx("br", {}), "will no longer have access to AI Enterprise.", _jsx("br", {}), "If AI access is enabled on your JetBrains Account,", _jsx("br", {}), "all connected users will be able to initiate AI trials and use AI Pro", _jsx("br", {}), "with a separate license."] }) }), _jsxs(Panel, { children: [_jsx(Button, { onClick: handleDisable, loader: isLoading, danger: true, "data-test": "dialog-confirm-button", children: "Disable" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
