// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbarItem__fed {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.toolbarItemLabel__dfd {
  font-size: var(--ring-font-size-smaller);
}
`, "",{"version":3,"sources":["webpack://./src/common/toolbar-item/toolbar-item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".toolbarItem {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.toolbarItemLabel {\n  font-size: var(--ring-font-size-smaller);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarItem": `toolbarItem__fed`,
	"toolbarItemLabel": `toolbarItemLabel__dfd`
};
export default ___CSS_LOADER_EXPORT___;
