import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import Message from '@jetbrains/ring-ui/components/message/message';
import classNames from 'classnames';
import { useSynchronizedTooltipState } from './synchronized-popup-state';
import styles from './popup-message.css';
export function PopupMessage(props) {
    const { children, anchor, title, icon, delay = 500 } = props;
    const [messageVisible, setMessageVisible] = useSynchronizedTooltipState();
    const timeoutRef = useRef();
    const onMouseEnter = useCallback(() => {
        timeoutRef.current = window.setTimeout(() => {
            setMessageVisible(true);
        }, delay);
    }, [setMessageVisible, delay]);
    const onMouseLeave = useCallback(() => window.clearTimeout(timeoutRef.current), []);
    return (_jsxs("div", { className: styles.wrapper, children: [messageVisible && (_jsx(Message, { icon: icon !== null && icon !== void 0 ? icon : null, title: title, className: classNames(styles.popup, { [styles.withIcon]: !!icon }), popupProps: { onOutsideClick: () => setMessageVisible(false) }, children: children })), _jsx("div", { className: styles.anchorWrapper, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, children: anchor })] }));
}
