export function isConstituentAllowedToUseByLicense(constituentLicense) {
    if (constituentLicense.isIncludedInLicense) {
        return true;
    }
    switch (constituentLicense.trial.state) {
        case 'ACTIVE':
            return true;
        default:
            return false;
    }
}
