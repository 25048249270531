import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { format } from 'date-fns/format';
import { TooltipDelays } from '@common/constants/constants';
const contentTypeOption = {
    CREATED: CreatedUserContent,
    UPDATED: UpdatedUserContent
    // TODO: https://youtrack.jetbrains.com/issue/IDES-5572
    // ASSIGNED_TO_PROFILE: AssignProfileContent,
    // UNASSIGNED_FROM_PROFILE: UnassignProfileContent
};
export function UserAuditLogsContent({ content, type, date }) {
    const Component = contentTypeOption[type];
    const title = `Event time: ${format(date, 'HH:mm')}`;
    return (_jsx(Tooltip, { title: title, delay: TooltipDelays.NORMAL, children: _jsx(Component, { ...content }) }));
}
// TODO: https://youtrack.jetbrains.com/issue/IDES-5572
// function AssignProfileContent({assignedToProfile}: ApiUserAuditLogContent) {
//   const profile = assignedToProfile?.profile;
//
//   if (!profile) {
//     return null;
//   }
//   return (
//     <Text>
//       Assigned <NavigationLink href={`/profiles/${profile.id}`}>{profile.title}</NavigationLink> to{' '}
//       user
//     </Text>
//   );
// }
//
// function UnassignProfileContent({unassignedFromProfile}: ApiUserAuditLogContent) {
//   const profile = unassignedFromProfile?.profile;
//
//   if (!profile) {
//     return null;
//   }
//
//   return (
//     <Text>
//       Unassigned <NavigationLink href={`/profiles/${profile.id}`}>{profile.title}</NavigationLink>{' '}
//       from user
//     </Text>
//   );
// }
function CreatedUserContent({ created }) {
    const userName = created === null || created === void 0 ? void 0 : created.newUser.username;
    if (!userName) {
        return null;
    }
    return _jsxs(Text, { children: ["Created user ", userName] });
}
function UpdatedUserContent({ updated }) {
    if (!updated) {
        return null;
    }
    return (_jsx(_Fragment, { children: Object.keys(updated.newUser).map(key => {
            const newProp = updated.newUser[key];
            const oldProp = updated.prevUser[key];
            if (newProp !== oldProp) {
                if (key === 'isActive') {
                    return _jsxs("div", { children: [updated.newUser.isActive ? 'Activated' : 'Deactivated', " user"] });
                }
                if (!oldProp) {
                    return (_jsxs("div", { children: ["Set ", _jsx("strong", { children: key }), " to ", newProp] }));
                }
                return (_jsxs("div", { children: ["Changed ", _jsx("strong", { children: key }), " from ", oldProp, " to ", newProp] }));
            }
            return '';
        }) }));
}
