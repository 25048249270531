import { addMinutes } from 'date-fns';
import { isApiError } from '@api/is-api-error';
import { apiClientManager } from '@common/api';
export function fetchCodeWithMeDataEntity(sessionId) {
    return apiClientManager
        .getClient()
        .get(`/api/cwm/${sessionId}`)
        .then(({ data }) => {
        const newData = {
            ...data,
            id: sessionId
        };
        return newData;
    });
}
export async function fetchCodeWithMeOneTimeToken(sessionId) {
    const serviceClient = apiClientManager.getClient();
    const expireDate = addMinutes(new Date(), 10).getTime();
    const res = await serviceClient.get(`/api/cwm/${sessionId}/generateOneTimeToken`);
    return { expireDate, token: res.data };
}
export async function getCodeWithMeState() {
    var _a, _b, _c;
    const serviceClient = apiClientManager.getClient();
    try {
        const res = await serviceClient.get('/api/constituents/cwm');
        return {
            status: 'enabled',
            data: res.data
        };
    }
    catch (e) {
        if (isApiError(e)) {
            if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 400 && isConfigurationErrorData(e.response.data)) {
                return { status: 'configuration_error', data: e.response.data };
            }
            if (((_b = e.response) === null || _b === void 0 ? void 0 : _b.status) === 404) {
                return { status: 'disabled' };
            }
            if (((_c = e.response) === null || _c === void 0 ? void 0 : _c.status) === 403) {
                return { status: 'disabled_jba' };
            }
        }
        throw e;
    }
}
export async function enableCodeWithMe(params) {
    var _a;
    const serviceClient = apiClientManager.getClient();
    try {
        const res = await serviceClient.post('/api/constituents/cwm', params);
        return {
            status: 'enabled',
            data: res.data
        };
    }
    catch (e) {
        if (isApiError(e)) {
            if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 400 && isConfigurationErrorData(e.response.data)) {
                return { status: 'configuration_error', data: e.response.data };
            }
        }
        throw e;
    }
}
export async function changeCodeWithMeSettings(params) {
    var _a;
    const serviceClient = apiClientManager.getClient();
    try {
        const res = await serviceClient.put('/api/constituents/cwm', params);
        return {
            status: 'enabled',
            data: res.data
        };
    }
    catch (e) {
        if (isApiError(e)) {
            if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 400 && isConfigurationErrorData(e.response.data)) {
                return { status: 'configuration_error', data: e.response.data };
            }
        }
        throw e;
    }
}
export async function disableCodeWithMe() {
    await apiClientManager.getClient().delete('/api/constituents/cwm');
    return { status: 'disabled' };
}
function isConfigurationErrorData(data) {
    return 'errorCode' in data;
}
