// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downloadsContainer__af0 {
  display: flex;
  align-items: center;
  gap: var(--ring-unit);
}

.statsLink__ec4 {
  margin-top: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/plugins/plugin-page/plugin-info/plugin-downloads-info.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".downloadsContainer {\n  display: flex;\n  align-items: center;\n  gap: var(--ring-unit);\n}\n\n.statsLink {\n  margin-top: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadsContainer": `downloadsContainer__af0`,
	"statsLink": `statsLink__ec4`
};
export default ___CSS_LOADER_EXPORT___;
