import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { lazy, Suspense } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Button from '@jetbrains/ring-ui/components/button/button';
import pencilIcon from '@jetbrains/icons/pencil';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import { formatXml } from '@modules/ide-provisioner/ide-settings/settings-templates';
import XmlFileAttachment from './xml-file-attachment';
import styles from './xml-payload-field.css';
const XmlEditor = lazy(() => import('@app/common/editors/xml-editor'));
function XmlPayloadField({ settingIndex }) {
    const { control } = useFormContext();
    const { field: { onChange, value } } = useController({
        name: `content.${settingIndex}.xmlPayload`,
        defaultValue: '',
        control
    });
    return (_jsxs(_Fragment, { children: [_jsx(Suspense, { fallback: _jsx(XmlEditorPlaceholder, {}), children: _jsx(XmlEditor, { minLines: 20, maxLines: 50, onChange: onChange, value: value }) }), _jsxs("div", { className: styles.toolbar, children: [_jsx(XmlFileAttachment, { onFileRead: onChange }), _jsx(Button, { primary: true, onClick: () => onChange(formatXml(value)), icon: pencilIcon, children: "Reformat XML" })] })] }));
}
function XmlEditorPlaceholder() {
    return (_jsx("div", { className: styles.placeholder, children: _jsx(Loader, { message: "Loading JSON editor..." }) }));
}
export default XmlPayloadField;
