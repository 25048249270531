import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import LoadingError from '@common/errors/loading-error';
import { usePluginsAuditLogs } from '@common/queries/plugins';
import PluginInfo from '../../plugin-page/plugin-info/plugin-info';
import { usePluginAuditLogsParams } from '../use-plugin-audit-logs-params';
import { PluginAuditLogsTable } from './plugin-audit-logs-table';
export function PluginAuditLogsBody({ plugin }) {
    const [urlParams, setUrlParams] = usePluginAuditLogsParams();
    const { data: auditLogsPage, isError: isFetchError, isFetching } = usePluginsAuditLogs({ ...urlParams, filter: plugin.id });
    return (_jsxs(_Fragment, { children: [_jsx(PluginInfo, { plugin: plugin, downloadsEnabled: false }), isFetchError ? (_jsx(LoadingError, {})) : (
            // TODO: Filter on Version after API changes
            _jsx(PluginAuditLogsTable, { data: auditLogsPage, isLoading: isFetching, params: urlParams, onParamsChange: setUrlParams }))] }));
}
