// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__f16 {
  display: flex;
  align-items: center;
}

.logo__ba3 {
  height: calc(var(--ring-unit) * 3);
}

.logo__ba3 + .name__c2e {
  margin-left: var(--ring-unit);
}

.name__c2e {
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/modules/constituents/constituents-list/constituent-list-item/constituent-list-item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n}\n\n.logo {\n  height: calc(var(--ring-unit) * 3);\n}\n\n.logo + .name {\n  margin-left: var(--ring-unit);\n}\n\n.name {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__f16`,
	"logo": `logo__ba3`,
	"name": `name__c2e`
};
export default ___CSS_LOADER_EXPORT___;
