import { NumberParam, StringParam } from 'serialize-query-params';
import { useSyncedSearchArgs } from '@common/navigation/use-synced-search-args';
export function usePluginsParams() {
    return useSyncedSearchArgs(tsTypeToQuerySchema, validatePluginsFilter);
}
function validatePluginsFilter(parsed) {
    var _a;
    return {
        searchValue: (_a = parsed.searchValue) !== null && _a !== void 0 ? _a : '',
        page: parsed.page && parsed.page > 0 ? parsed.page : 1
    };
}
const tsTypeToQuerySchema = {
    searchValue: StringParam,
    page: NumberParam
};
