import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import { useGetConstituents } from '@app/common/queries/constituents-common';
import ToolbarItem from '@app/common/toolbar-item/toolbar-item';
import { IdeProvisionerLogo } from '@modules/ide-provisioner/common/ide-provisioner-logo/ide-provisioner-logo';
import { CodeWithMeLogo } from '@modules/code-with-me/common/code-with-me-logo/code-with-me-logo';
import { AiLogo } from '@modules/ai/common/ai-logo/ai-logo';
import styles from './constituent-filter.css';
const getConstituentsOptions = (constituents) => {
    const OPTIONS = [
        {
            key: 'ai',
            label: 'AI Enterprise',
            rightNodes: _jsx(AiLogo, { className: styles.filterLogo })
        },
        {
            key: 'idep',
            label: 'IDE Provisioner',
            rightNodes: _jsx(IdeProvisionerLogo, { className: styles.filterLogo })
        },
        {
            key: 'cwm',
            label: 'Code With Me',
            rightNodes: _jsx(CodeWithMeLogo, { className: styles.filterLogo })
        }
    ];
    return OPTIONS.filter(item => { var _a; return ((_a = constituents.find(constituent => constituent.id === item.key)) === null || _a === void 0 ? void 0 : _a.state) === 'SERVER_ENABLED'; });
};
function ConstituentFilter({ constituentId, onChange }) {
    const { data: constituents, isLoading } = useGetConstituents();
    const handleChange = useCallback((selected) => {
        onChange((selected === null || selected === void 0 ? void 0 : selected.key) ? `${selected === null || selected === void 0 ? void 0 : selected.key}` : undefined);
    }, [onChange]);
    const CONSTITUENTS_OPTIONS = useMemo(() => getConstituentsOptions(constituents !== null && constituents !== void 0 ? constituents : []), [constituents]);
    return (_jsx(ToolbarItem, { label: "Product", children: _jsx(Select, { clear: true, filter: true, label: "All", size: Select.Size.M, loading: isLoading, data: CONSTITUENTS_OPTIONS, selected: CONSTITUENTS_OPTIONS.find(option => option.key === constituentId), onChange: handleChange }) }));
}
export default ConstituentFilter;
