import { addDays, differenceInCalendarDays, differenceInDays, endOfDay, endOfToday, format, parseISO, startOfDay } from 'date-fns';
import { apiClientManager } from '@common/api';
import { DEFAULT_TABLE_PAGE_SIZE } from './paging-utils';
import { getUser } from './users';
export const THIS_MONTH_TIMEFRAME = {
    from: startOfDay(addDays(endOfToday(), -27)),
    to: endOfToday()
};
export async function getIdeProvisioning() {
    const r = await apiClientManager
        .getClient()
        .get('/api/services/ide-provisioning');
    return r.data;
}
async function makeCwmEventsArgs(params, limit) {
    const { user, from, to, sortKey, sortOrder, sessionId, ...restParams } = params;
    const resolveUserById = user ? await getUser(user) : undefined;
    return {
        user: resolveUserById === null || resolveUserById === void 0 ? void 0 : resolveUserById.id,
        limit: limit ? `${limit}` : undefined,
        from: Math.ceil(from / 1000),
        to: Math.ceil(to / 1000),
        sort: sortKey === null || sortKey === void 0 ? void 0 : sortKey.toUpperCase(),
        order: sortOrder,
        'session-id': sessionId,
        ...restParams
    };
}
export async function getCwmEvents(filters, limit = DEFAULT_TABLE_PAGE_SIZE) {
    const params = await makeCwmEventsArgs(filters, limit);
    const r = await apiClientManager
        .getClient()
        .get('/api/cwm-tbe/events', {
        params
    });
    return r.data;
}
export async function getCwmWidgetEventsStatistics(filters = {}) {
    const startOfInterval = startOfDay(addDays(endOfToday(), -13));
    const fullFilters = {
        ...filters,
        to: endOfToday().getTime(),
        from: startOfInterval.getTime()
    };
    const params = await makeCwmEventsArgs(fullFilters);
    const loadEventsStatistics = (type) => apiClientManager
        .getClient()
        .get('/api/cwm-tbe/events-stats', {
        params: { ...params, type }
    })
        .then(it => it.data);
    const sessionCreated = await loadEventsStatistics('SessionCreated');
    const sessionFailed = await loadEventsStatistics('SessionCreationFailed');
    const getEventsCount = (from, eventsStats) => {
        var _a;
        return (((_a = eventsStats.filter(it => {
            return Math.abs(differenceInCalendarDays(parseISO(it.from), from)) < 1;
        })[0]) === null || _a === void 0 ? void 0 : _a.eventsCount) || 0);
    };
    return [...new Array(14)].map((_, idx) => {
        const from = addDays(startOfInterval, idx);
        const to = endOfDay(from);
        return {
            id: `${from.getTime()}-${to.getTime()}`,
            from,
            to,
            sessionsCount: getEventsCount(from, sessionCreated.items),
            sessionsFailureCount: getEventsCount(from, sessionFailed.items)
        };
    });
}
function completeIntervalsWithEmptyDays(intervals, timeFrame, checkFun, makeEmptyInterval) {
    const daysCount = differenceInDays(timeFrame.to, timeFrame.from) + 1;
    return [...new Array(daysCount)].map((_, idx) => {
        const date = addDays(timeFrame.from, idx);
        return (intervals || []).filter(it => checkFun(it, date))[0] || makeEmptyInterval(date);
    });
}
export async function getActiveUsersStatistics() {
    const params = {
        period: 'LAST_28_DAYS'
    };
    const r = await apiClientManager
        .getClient()
        .get('/api/usage-analytics/users/active-per-day', {
        params
    });
    const totalResponse = await apiClientManager
        .getClient()
        .get('/api/usage-analytics/users/active', {
        params
    });
    const startOfInterval = startOfDay(addDays(endOfToday(), -27));
    const statisticsPerDayWithFilledEmptyDays = completeIntervalsWithEmptyDays(r.data, { from: startOfInterval, to: endOfToday() }, (arg, date) => arg.date === format(date, 'yyyy-MM-dd'), (date) => ({ date: format(date, 'yyyy-MM-dd'), activeUsersCount: 0 }));
    return {
        total: totalResponse.data,
        perDays: statisticsPerDayWithFilledEmptyDays
    };
}
export async function getConstituentUsageStatistics(constituent, timeframe = THIS_MONTH_TIMEFRAME) {
    var _a;
    const response = await apiClientManager
        .getClient()
        .get(`/api/usage-stats/events-stats-${constituent}`, {
        params: {
            to: Math.ceil(timeframe.to.getTime() / 1000),
            from: Math.ceil(timeframe.from.getTime() / 1000)
        }
    });
    return completeIntervalsWithEmptyDays((_a = response.data.items) === null || _a === void 0 ? void 0 : _a.map(it => ({ from: parseISO(it.from), usage: it.usage })), timeframe, (arg, date) => Math.abs(differenceInCalendarDays(date, arg.from)) < 1, (date) => ({
        from: date,
        usage: 0
    }));
}
export async function getConstituentCurrentUsage(constituent) {
    const result = await apiClientManager
        .getClient()
        .get(`/api/usage-stats/events-stats-${constituent}-last`, {});
    return result.data;
}
