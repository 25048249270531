import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Toggle, { Size } from '@jetbrains/ring-ui/components/toggle/toggle';
import RingFieldsList from '@common/form/ring-fields-list';
import auth from '@common/auth/auth';
import styles from '../styles.css';
export function JetbrainsAiProviderForm({ form }) {
    const { setValue, watch, getValues } = form;
    const { enableCloneFinder } = auth.getFeatures();
    const handleCloneFinderChange = (e) => {
        const provider = getValues('provider.jetbrains');
        if (provider) {
            setValue('provider.jetbrains.enableCloneFinder', e.target.checked, {
                shouldDirty: true
            });
        }
    };
    if (!enableCloneFinder) {
        return null;
    }
    return (_jsx(RingFieldsList, { children: _jsxs("div", { className: styles.toggleWrapper, children: [_jsx(Toggle, { checked: watch('provider.jetbrains.enableCloneFinder'), onChange: e => handleCloneFinderChange(e), size: Size.Size16, children: "Allow filtering out code snippets violating IP rights in code completion" }), ' '] }) }));
}
