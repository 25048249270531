import { jsx as _jsx } from "react/jsx-runtime";
import TagsInput from '@jetbrains/ring-ui/components/tags-input/tags-input';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { buildProductOptions } from '../../../../../../../modules/applications/build-filters/filter-build-options';
function ProductField({ allProducts }) {
    const { setValue, watch } = useFormContext();
    const products = watch('products');
    const productOptions = buildProductOptions(allProducts, products || []);
    return (_jsx("div", { ...assignTestId('application-filter-product'), children: _jsx(TagsInput, { id: "productsField", label: "IDEs", size: Size.FULL, placeholder: isEmpty(products) ? 'All' : '', dataSource: () => productOptions, tags: productOptions.filter(tag => products === null || products === void 0 ? void 0 : products.includes(tag.key)), filter: true, onAddTag: ({ tag }) => setValue('products', products ? [...products, tag.key] : [tag.key]), onRemoveTag: ({ tag }) => products &&
                setValue('products', products.filter(item => item !== tag.key)) }) }));
}
export default ProductField;
