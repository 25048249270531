import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { ensureProfileDetailsQueryData } from '@app/common/queries/profiles';
import { PageTitle } from '@app/common/page-layout/page-title';
import ProfileHistoryPageBody from './profile-history-page-body';
export async function profileHistoryPageResolver(profileId) {
    return ensureProfileDetailsQueryData(profileId);
}
export function ProfileHistoryPage() {
    const profile = useLoaderData();
    const titleParts = useMemo(() => {
        return [
            { title: 'Profiles', href: '/profiles' },
            { title: profile === null || profile === void 0 ? void 0 : profile.title, href: `/profiles/${profile === null || profile === void 0 ? void 0 : profile.id}` }
        ];
    }, [profile]);
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: "History", breadcrumbs: titleParts }), _jsx(ProfileHistoryPageBody, { profileId: profile === null || profile === void 0 ? void 0 : profile.id })] }));
}
