import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import { BasicSelectableTable } from '@common/tables/basic-selectable-table';
import { withSortableColumnFlag } from '@common/tables/table-columns';
import { sortableColumns } from './constants';
import { getProfileUrl } from './functions';
import { ProfilesTableTitle } from './cells/profiles-table-title';
import { ProfilesTableActions } from './cells/profiles-table-actions';
import styles from './profiles-admin-body.css';
export function ProfilesPageTable({ data, params, onParamsChange, isLoading }) {
    var _a;
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);
    const navigate = useNavigate();
    const profiles = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.profiles) !== null && _a !== void 0 ? _a : []; }, [data]);
    const totalCount = (_a = data === null || data === void 0 ? void 0 : data.totalCount) !== null && _a !== void 0 ? _a : 0;
    const columns = useMemo(() => withSortableColumnFlag([
        {
            id: sortableColumns.title,
            title: 'Title',
            getValue: profile => _jsx(ProfilesTableTitle, { ...profile })
        },
        {
            id: 'description',
            title: 'Description',
            getValue: profile => _jsx("div", { className: styles.description, children: profile.description })
        },
        {
            id: 'actionButtons',
            getValue: profile => (_jsx(ProfilesTableActions, { setLoadingUsers: setIsLoadingUsers, ...profile }))
        }
    ], sortableColumns), []);
    return (_jsx(BasicSelectableTable, { data: profiles, getItemKey: item => item.id, loading: isLoadingUsers !== null && isLoadingUsers !== void 0 ? isLoadingUsers : isLoading, columns: columns, selectable: false, onItemEnter: ({ id }) => navigate(getProfileUrl(id)), renderEmpty: () => 'No profiles found', sortKey: params.sortKey, sortOrder: params.sortOrder === 'ASC', onSort: event => onParamsChange({
            ...params,
            sortKey: event.column.id,
            sortOrder: event.order ? 'ASC' : 'DESC',
            page: 1
        }), pagerProps: {
            total: totalCount,
            disablePageSizeSelector: true,
            currentPage: params.page,
            pageSize: DEFAULT_TABLE_PAGE_SIZE,
            onPageChange: newPage => onParamsChange({
                ...params,
                page: newPage
            })
        } }));
}
