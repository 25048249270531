import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { isNil } from 'lodash';
import { useConstitutionOverusePolling } from '@app/common/queries/constituents-common';
import { useTbeLicense } from '@app/common/queries/tbe-license';
import { useDeniedLicensesNumberPolling } from '@app/common/queries/license-vault';
import { constituentExceedNotificationStorage } from '@app/modules/constituents/constituent-exceed-notification-controller/constituent-exceed-notification-storage';
import { NotificationRenderer } from './notification-renderer';
import { BaseNotification, BaseNotificationHeader } from './base-notification/base-notification';
import styles from './base-notification/base-notification.css';
const licenseActivationTabUrl = '/configuration/license-activation';
export function ConstituentExceedNotificationController() {
    const location = useLocation();
    const isLicenseTabOpened = location.pathname === licenseActivationTabUrl;
    const { data: constituentsData, remove } = useConstitutionOverusePolling(!isLicenseTabOpened);
    useEffect(() => {
        if (isLicenseTabOpened) {
            remove();
        }
    }, [isLicenseTabOpened, remove]);
    const { data: tbeLicense } = useTbeLicense();
    const navigate = useNavigate();
    const handlePrimaryAction = useCallback(() => {
        navigate(licenseActivationTabUrl);
        alert.message('Follow the instructions on this page on how to increase usage limits in order to accommodate the requested number of users');
    }, [navigate]);
    const licenseType = tbeLicense === null || tbeLicense === void 0 ? void 0 : tbeLicense.plan;
    const cwmConstituent = constituentsData === null || constituentsData === void 0 ? void 0 : constituentsData.find(it => it.id === 'cwm');
    const idepConstituent = constituentsData === null || constituentsData === void 0 ? void 0 : constituentsData.find(it => it.id === 'idep');
    const aiConstituent = constituentsData === null || constituentsData === void 0 ? void 0 : constituentsData.find(it => it.id === 'ai');
    const lvConstituent = constituentsData === null || constituentsData === void 0 ? void 0 : constituentsData.find(it => it.id === 'lv');
    const { data: licenseVaultOveruse } = useDeniedLicensesNumberPolling(!isLicenseTabOpened && !!lvConstituent && lvConstituent.state === 'SERVER_ENABLED');
    if (!licenseType || isLicenseTabOpened) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [isOverLimit(cwmConstituent) && (_jsx(CwmOveruseNotification, { onAction: handlePrimaryAction, onCancel: () => constituentExceedNotificationStorage.saveDismissedNotification(cwmConstituent), licenseType: licenseType, usage: cwmConstituent.usage, overLimit: cwmConstituent.overLimit })), isOverLimit(idepConstituent) && (_jsx(IdePOveruseNotification, { onCancel: () => constituentExceedNotificationStorage.saveDismissedNotification(idepConstituent), onAction: handlePrimaryAction, licenseType: licenseType, usage: idepConstituent.usage, overLimit: idepConstituent.overLimit })), isOverLimit(aiConstituent) && (_jsx(AiOveruseNotification, { onCancel: () => constituentExceedNotificationStorage.saveDismissedNotification(aiConstituent), onAction: handlePrimaryAction, licenseType: licenseType, usage: aiConstituent.usage, overLimit: aiConstituent.overLimit })), !!licenseVaultOveruse &&
                !constituentExceedNotificationStorage.isNotificationDismissed({
                    id: 'lv',
                    overLimit: licenseVaultOveruse
                }) && (_jsx(LicenseVaultOveruseNotification, { onCancel: () => constituentExceedNotificationStorage.saveDismissedNotification({
                    id: 'lv',
                    overLimit: licenseVaultOveruse
                }), onAction: handlePrimaryAction, overLimit: licenseVaultOveruse }))] }));
}
function CwmOveruseNotification(props) {
    const { usage = 0, onCancel } = props;
    return (_jsx(NotificationRenderer, { onCancel: onCancel, children: _jsx(BaseNotification, { name: "Code With Me", ...props, currentLimit: usage }) }));
}
function IdePOveruseNotification(props) {
    const { usage = 0, onCancel } = props;
    return (_jsx(NotificationRenderer, { onCancel: onCancel, children: _jsx(BaseNotification, { name: "IDE Provisioner", ...props, currentLimit: usage }) }));
}
function AiOveruseNotification(props) {
    const { usage = 0, onCancel } = props;
    return (_jsx(NotificationRenderer, { onCancel: onCancel, children: _jsx(BaseNotification, { name: "AI Enterprise", ...props, currentLimit: usage }) }));
}
function LicenseVaultOveruseNotification({ overLimit, onAction, onCancel }) {
    const navigate = useNavigate();
    const openLicenseVault = () => navigate('/license-vault/stats');
    return (_jsx(NotificationRenderer, { onCancel: onCancel, children: _jsxs("div", { className: styles.wrapper, children: [_jsx(BaseNotificationHeader, { name: "License Vault" }), _jsxs("p", { className: styles.text, children: [overLimit, " license requests denied in the past 24h"] }), _jsxs("div", { className: styles.buttons, children: [_jsx(Button, { onClick: onAction, primary: true, children: "Edit licenses..." }), _jsx(Button, { text: true, onClick: openLicenseVault, children: "Show Denials report" })] })] }) }));
}
function isOverLimit(constituent) {
    if (!constituent) {
        return false;
    }
    return (!isNil(constituent.overLimit) &&
        constituent.overLimit > 0 &&
        !isNil(constituent.usage) &&
        !constituentExceedNotificationStorage.isNotificationDismissed(constituent));
}
