import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import Button from '@jetbrains/ring-ui/components/button/button';
import { ToolboxLogo } from '@jetbrains/logos/react';
import { DownloadTbeToolboxApp, useMachineConfigDownloadButtonProps } from '@modules/machine-config';
import { useCompanyName } from '@common/hooks/use-company-name';
import { WidgetVariant, Widget, WidgetSize } from '@common/widgets/widget/widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './toolbox-app-widget.css';
export function ToolboxAppWidget() {
    const { companyName } = useCompanyName();
    return (_jsx(Widget, { variant: WidgetVariant.Filled, widgetSize: WidgetSize.Wide, title: "Toolbox App", icon: _jsx(ToolboxLogo, {}), ...assignTestId('toolbox-app-widget'), controls: _jsxs(_Fragment, { children: [_jsx(Button, { className: styles.button, loader: !companyName, "data-test": "join-organization-button", autoFocus: true, ...useMachineConfigDownloadButtonProps(`Join ${companyName}`) }), _jsx(DownloadTbeToolboxApp, { inline: true, shortLabel: true })] }), children: _jsxs(Text, { className: styles.textContainer, children: ["Join ", companyName, " in the Toolbox App to gain access to approved applications."] }) }));
}
