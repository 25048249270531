import { jsx as _jsx } from "react/jsx-runtime";
import { licenseActivationService } from '@common/licensing/license-activation-service';
import auth from '../../auth/auth';
import { HeaderLink } from './header-link';
export default function ProtectedHeaderLink({ href, roles, children, isHeaderPermitted = true, ...rest }) {
    if (licenseActivationService.hasLicense() &&
        auth.isAuthenticated() &&
        auth.hasRoles(roles) &&
        isHeaderPermitted) {
        return (_jsx(HeaderLink, { href: href, ...rest, children: children }));
    }
    return null;
}
