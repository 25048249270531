import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import _ from 'lodash';
import Button from '@jetbrains/ring-ui/components/button/button';
import { formatDateAndTime } from '../../../common/util/i18n';
import { useExpirationDateMutation } from '../../../common/queries/plugins';
import { getApiError } from '../../../api/errors';
import ExpirationDateEditor from './expiration-date-editor';
import styles from './plugin-page.css';
/**
 * Wrapper which is primarily used in the table representing plugin version as a column component.
 */
export default function ExpirationDateEditorWrapper({ pluginId, pluginVersion, expirationDate }) {
    const [isEditing, setIsEditing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const mutation = useExpirationDateMutation();
    const expirationDateText = expirationDate ? formatDateAndTime(expirationDate) : 'N/A';
    const handleSubmit = (newExpirationDate) => {
        if (_.isEqual(expirationDate, newExpirationDate)) {
            setIsEditing(false);
            return;
        }
        mutation.mutate({
            pluginId,
            pluginVersion,
            expirationDate: newExpirationDate
        }, {
            onSuccess: () => {
                setIsEditing(false);
            },
            onError: e => {
                setErrorMessage(getApiError(e).errorMessage || '');
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { text: true, className: styles.noPaddingButton, onClick: () => setIsEditing(true), children: expirationDateText }), isEditing && (_jsx(ExpirationDateEditor, { initialExpirationDate: expirationDate, errorMessage: errorMessage, isSubmitting: mutation.isLoading, onSubmit: newExpirationDate => handleSubmit(newExpirationDate), onCancel: () => setIsEditing(false) }))] }));
}
