// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__d7f {
  display: flex;
  gap: calc(2 * var(--ring-unit));
  flex-direction: column;
}

.formWrapper__dc1 {
  display: flex;
}

.formData__ef1 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 3);
}

.formFields__ca1 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 1.5);
}

.constituents__d48 {
  margin-left: calc(var(--ring-unit) * 4);
}

.constituentsTitle__e7c {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);

  color: var(--ring-secondary-color);
}

.constituentsTitle__e7c + .constituentList__e19 {
  margin-top: calc(var(--ring-unit) * 2);
}

.constituentsTitle__e7c + .emptyConstituents__cd1 {
  margin-top: calc(var(--ring-unit) * 2);
}

.emptyConstituents__cd1 {
  color: var(--ring-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/user-details-page/user-details-edit-page/user-details-edit-page.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,wCAAwC;EACxC,2CAA2C;;EAE3C,kCAAkC;AACpC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".wrapper {\n  display: flex;\n  gap: calc(2 * var(--ring-unit));\n  flex-direction: column;\n}\n\n.formWrapper {\n  display: flex;\n}\n\n.formData {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 3);\n}\n\n.formFields {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.constituents {\n  margin-left: calc(var(--ring-unit) * 4);\n}\n\n.constituentsTitle {\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n\n  color: var(--ring-secondary-color);\n}\n\n.constituentsTitle + .constituentList {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n\n.constituentsTitle + .emptyConstituents {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n\n.emptyConstituents {\n  color: var(--ring-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__d7f`,
	"formWrapper": `formWrapper__dc1`,
	"formData": `formData__ef1`,
	"formFields": `formFields__ca1`,
	"constituents": `constituents__d48`,
	"constituentsTitle": `constituentsTitle__e7c`,
	"constituentList": `constituentList__e19`,
	"emptyConstituents": `emptyConstituents__cd1`
};
export default ___CSS_LOADER_EXPORT___;
