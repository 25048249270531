import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { isFuture } from 'date-fns';
import styles from './nested-data-list.css';
export function BuildDataTitle({ item }) {
    const childrenWithFutureRestrictions = item.children.filter(hasFutureRestriction);
    const isDisabledFully = item.children.length > 0 && item.children.every(it => it.isExcluded);
    const isDisabledPartially = item.children.some(it => it.isExcluded) && !isDisabledFully;
    const willBeDisabled = !isDisabledFully && childrenWithFutureRestrictions.length > 0;
    const description = useMemo(() => {
        if (!item.children.length) {
            return '';
        }
        const firstChildBuild = item.children[0].version;
        if (item.children.length === 1) {
            return `${item.product} ${firstChildBuild}`;
        }
        return `${item.product} from ${item.children[item.children.length - 1].version} to ${firstChildBuild}`;
    }, [item.children, item.product]);
    return (_jsxs(Text, { className: styles.title, children: [item.id, _jsx("br", {}), description && (_jsx(Text, { info: true, size: "s", className: styles.titleDescription, children: description })), isDisabledFully && (_jsx(Text, { size: "s", className: styles.disabled, children: "Disabled" })), isDisabledPartially && (_jsx(Text, { size: "s", className: styles.disabled, children: "Some builds are disabled" })), willBeDisabled && (_jsx(Text, { size: "s", className: styles.parentWithRestriction, children: childrenWithFutureRestrictions.length === item.children.length
                    ? 'Will be disabled'
                    : 'Some builds will be disabled' }))] }));
    function hasFutureRestriction(item) {
        return (!item.isExcluded &&
            item.notifications.length > 0 &&
            item.notifications.every(it => isFuture(it.dueDate)));
    }
}
