import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import SearchFilter from '@common/search-filter/search-filter';
export function ProfilesFilters({ params, onParamsChange }) {
    var _a;
    const onFiltersChange = useCallback((newFilters) => {
        onParamsChange({
            ...params,
            query: newFilters.query,
            page: newFilters.query !== params.query ? 1 : params.page
        });
    }, [onParamsChange, params]);
    return (_jsx(SearchFilter, { value: (_a = params.query) !== null && _a !== void 0 ? _a : '', onChange: value => onFiltersChange({ query: value }), placeholder: "Search by title or description" }));
}
