// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolName__b1b {
  font-weight: 600;
}

.toolOs__eb1 {
  margin-top: calc(var(--ring-unit) * 0.5);
}

.toolArch__bb0 {
  margin-top: calc(var(--ring-unit) * 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ide-provisioner/application-settings/app-build-settings/build-settings-preview/auto-installed-tools-preview/auto-installed-tools-preview.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".toolName {\n  font-weight: 600;\n}\n\n.toolOs {\n  margin-top: calc(var(--ring-unit) * 0.5);\n}\n\n.toolArch {\n  margin-top: calc(var(--ring-unit) * 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolName": `toolName__b1b`,
	"toolOs": `toolOs__eb1`,
	"toolArch": `toolArch__bb0`
};
export default ___CSS_LOADER_EXPORT___;
