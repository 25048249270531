// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar__adf {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.xpathEditor__b04 {
  border-top: 1px solid var(--ring-line-color);
  padding-top: calc(1.5 * var(--ring-unit));
}

.xpathEditorContent__ed6 {
  border-width: 0;
}

.placeholder__c11 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 320px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/ide-settings/form/common/xml-payload-field.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,4CAA4C;EAC5C,yCAAyC;AAC3C;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,aAAa;EACb,WAAW;AACb","sourcesContent":[".toolbar {\n  display: flex;\n  justify-content: space-between;\n  align-items: start;\n}\n\n.xpathEditor {\n  border-top: 1px solid var(--ring-line-color);\n  padding-top: calc(1.5 * var(--ring-unit));\n}\n\n.xpathEditorContent {\n  border-width: 0;\n}\n\n.placeholder {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  height: 320px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": `toolbar__adf`,
	"xpathEditor": `xpathEditor__b04`,
	"xpathEditorContent": `xpathEditorContent__ed6`,
	"placeholder": `placeholder__c11`
};
export default ___CSS_LOADER_EXPORT___;
