import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import Message from '@jetbrains/ring-ui/components/message/message';
import frownIcon from '@jetbrains/icons/frown';
import Button from '@jetbrains/ring-ui/components/button/button';
import auth from '../../common/auth/auth';
import { useMachineConfig } from '../../common/queries/machine-config';
export function getConfigMachineDownloadUrl(baseUrl) {
    return `${baseUrl}/api/machine-config`;
}
export function getConfigMachineToolboxUrl(baseUrl, proxyRequired) {
    const requireProxyParam = proxyRequired ? '&proxy-required=true' : '';
    return `jetbrains://toolbox/enterprise/config?url=${encodeURIComponent(getConfigMachineDownloadUrl(baseUrl))}${requireProxyParam}`;
}
export function checkIfCanActivateTba(publicUrl) {
    return (publicUrl.toLowerCase().startsWith('https://') ||
        publicUrl.toLowerCase().startsWith('http://localhost:') ||
        publicUrl.toLowerCase().startsWith('http://localhost/'));
}
function HttpsRequiredMessage({ onClose }) {
    return (_jsx(Message, { icon: frownIcon, onClose: onClose, title: "HTTPS is required", children: "Toolbox App requires HTTPS to connect to JetBrains IDE Services organization. Please contact your IT department for details" }));
}
function buildTbaWarningMessage(publicUrl) {
    return (`\n\n` +
        `An attempt to activate Toolbox App with non HTTPS server URL: schema = ${new URL(publicUrl).protocol}\n` +
        `Please contact your IT department for details.\n\n` +
        `Try the following to fix the problem:\n ` +
        `  make sure JetBrains IDE Services uses HTTPS URL in the application settings or is implicitly authenticated` +
        `\n\n`);
}
export function useMachineConfigDownloadButtonProps(buttonChildren) {
    const { data, isLoading } = useMachineConfig();
    const [showMessage, setShowMessage] = useState(false);
    const publicUrl = auth.getBaseURL();
    const url = data && getConfigMachineToolboxUrl(data.tbeServerUrl, data.proxyRequired);
    const canActivateTba = checkIfCanActivateTba(publicUrl);
    useEffect(() => {
        if (!canActivateTba) {
            // eslint-disable-next-line no-console
            console.warn(buildTbaWarningMessage(publicUrl));
        }
    }, [canActivateTba, publicUrl]);
    const onClick = useCallback(() => {
        if (canActivateTba && url) {
            window.open(url, '_self');
        }
        else {
            setShowMessage(true);
        }
    }, [canActivateTba, url]);
    return useMemo(() => {
        const defaultChildren = buttonChildren || publicUrl;
        return {
            onClick,
            children: (_jsxs(_Fragment, { children: [defaultChildren, showMessage && _jsx(HttpsRequiredMessage, { onClose: () => setShowMessage(false) })] })),
            disabled: isLoading
        };
    }, [buttonChildren, publicUrl, onClick, showMessage, isLoading]);
}
export function DownloadMachineConfigurationButton({ children, ...rest }) {
    const publicUrl = getConfigMachineDownloadUrl(auth.getBaseURL());
    const onClick = () => {
        window.open(publicUrl, '_self');
    };
    return (_jsx(Button, { onClick: onClick, ...rest, children: _jsx("span", { children: children || publicUrl }) }));
}
