import { createEnumParam } from 'serialize-query-params';
import { apiAutomationTokenDetailStateValues } from '@api/@types';
import { useSyncedSearchArgs } from '@common/navigation/use-synced-search-args';
export function useAutomationTokensParams() {
    return useSyncedSearchArgs(tsTypeToQuerySchema, validateAutomationTokensFilter);
}
function validateAutomationTokensFilter(parsed) {
    var _a;
    return {
        tokenState: (_a = parsed.tokenState) !== null && _a !== void 0 ? _a : 'ACTIVE'
    };
}
const tsTypeToQuerySchema = {
    tokenState: createEnumParam([...apiAutomationTokenDetailStateValues, 'ALL'])
};
