import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRouteLoaderData } from 'react-router-dom';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import { useSelectedApplication } from '@app/pages/user/user-details-page/user-aggregated-settings-page/use-selected-application';
import { useSelectedBuild } from '@app/pages/user/user-details-page/user-aggregated-settings-page/use-selected-build';
import { useSelectedView } from '@app/pages/user/user-details-page/user-aggregated-settings-page/use-selected-view';
import { ApplicationsList } from '@modules/applications/applications-list/applications-list';
import { ApplicationSettings } from '@modules/ide-provisioner/application-settings/application-settings';
import { useUserApplications } from '@common/queries/users';
import styles from './user-aggregated-settings-page.css';
export function UserAggregatedSettingsPage() {
    const user = useRouteLoaderData('user-details');
    const { data, isLoading } = useUserApplications(user.id);
    const [selectedApplication, setSelectedApplication] = useSelectedApplication(data === null || data === void 0 ? void 0 : data.items);
    const [selectedBuildKey, setSelectedBuildKey] = useSelectedBuild();
    const [selectedView, setSelectedView] = useSelectedView();
    if (isLoading || !data) {
        return _jsx(Loader, {});
    }
    if (data.items.length === 0) {
        throw new Error('Invalid application state, no applications found');
    }
    return (_jsxs("div", { className: styles.wrapper, children: [_jsx(ApplicationsList, { className: styles.appList, applications: data.items, selectedApplication: selectedApplication !== null && selectedApplication !== void 0 ? selectedApplication : data.items[0], onApplicationSelect: setSelectedApplication }), _jsx(ApplicationSettings, { userId: user.id, className: styles.appSettings, application: selectedApplication !== null && selectedApplication !== void 0 ? selectedApplication : data.items[0], selectedBuildKey: selectedBuildKey, setSelectedBuildKey: setSelectedBuildKey, onViewSelect: setSelectedView, selectedView: selectedView }, selectedApplication === null || selectedApplication === void 0 ? void 0 : selectedApplication.id)] }));
}
