import { useMutation, useQuery } from '@tanstack/react-query';
import { createToken, deleteToken, getAutomationTokens, updateToken } from '@api/automation-tokens';
import { formatApiError } from '@api/errors';
import queryClient, { DEFAULT_STALE_TIME } from './query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from './formatters/alerts';
export const keys = {
    all: ['automation'],
    filtered: (state) => ['automation', { state }]
};
export const useUpdateTokenMutation = () => {
    return useMutation({
        meta: getMutationAlertMessages((err, vars) => {
            return formatApiError(err, `Failed to update token '${vars.tokenData.clientId}'`);
        }, () => `Token updated`),
        mutationFn: ({ tokenId, tokenData }) => updateToken(tokenId, tokenData),
        onSuccess: () => {
            queryClient.invalidateQueries(keys.all);
        }
    });
};
export const useCreateTokenMutation = () => {
    return useMutation({
        meta: getMutationAlertMessages((error, vars) => formatApiError(error, `Failed to create token with an id '${vars.clientId}'`), () => `Token created`),
        mutationFn: (tokenData) => createToken(tokenData),
        onSuccess: () => {
            queryClient.invalidateQueries(keys.all);
        }
    });
};
export const useDeleteTokenMutation = () => {
    return useMutation({
        meta: getMutationAlertMessages((error, vars) => {
            return formatApiError(error, `Failed to remove automation token '${vars.clientId}'`);
        }, () => {
            return `Token deleted`;
        }),
        mutationFn: (vars) => deleteToken(vars.tokenId),
        onSuccess: () => {
            queryClient.invalidateQueries(keys.all);
        }
    });
};
export const useAutomationTokens = (state) => {
    return useQuery({
        queryKey: keys.filtered(state),
        queryFn: () => getAutomationTokens(state),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load automation token'))
    });
};
