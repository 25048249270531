import { useQuery } from '@tanstack/react-query';
import { formatApiError } from '@api/errors';
import { getAuthentication, getIdentityProviders } from '@api/authentication';
import { getQueryAlertMessages } from '@common/queries/formatters/alerts';
export const keys = {
    authentication: ['authentication'],
    providers: ['authentication', 'providers']
};
export function useAuthentication() {
    return useQuery({
        queryKey: keys.authentication,
        queryFn: getAuthentication,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch authentication state'))
    });
}
export function useIdentityProviders() {
    return useQuery({
        queryKey: keys.providers,
        queryFn: getIdentityProviders,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch identity providers'))
    });
}
