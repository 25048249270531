import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { objectToSearchString, StringParam } from 'serialize-query-params';
import { H1 } from '@jetbrains/ring-ui/components/heading/heading';
import Button from '@jetbrains/ring-ui/components/button/button';
import { AppFooter } from '@app/common/app-layout';
import { useSyncedSearchArgs } from '@app/common/navigation/use-synced-search-args';
import { ExternalLinks } from '@app/common/constants/constants';
import NotFoundPage from '../../common/errors/not-found-page';
import styles from './app-authenticated-page.css';
const pageQuerySchema = {
    state: StringParam,
    code: StringParam
};
function AppAuthenticatedPage() {
    const [searchArgs, setSearchArgs] = useSyncedSearchArgs(pageQuerySchema);
    const toolboxUrl = useRef(makeToolboxUrl(searchArgs));
    useEffect(() => {
        if (toolboxUrl.current) {
            window.location.replace(toolboxUrl.current);
        }
        setSearchArgs({});
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    if (!toolboxUrl.current) {
        return _jsx(NotFoundPage, { message: "Your authentication link seems to be broken" });
    }
    return (_jsxs(_Fragment, { children: [_jsxs("main", { className: styles.wrapper, children: [_jsx(H1, { children: "Joining organization..." }), _jsxs("p", { children: ["You'll be redirected to the Toolbox App.", _jsx("br", {}), "If you aren't redirected automatically,", ' ', _jsx(Button, { className: styles.buttonLink, href: toolboxUrl.current, text: true, children: "click here" }), "."] })] }), _jsx(AppFooter, {})] }));
    function makeToolboxUrl({ code, state }) {
        const queryStr = objectToSearchString({
            code: validateUrlArg(code),
            state: validateUrlArg(state)
        });
        return queryStr ? `${ExternalLinks.TOOLBOX_APP_AUTH_URL}?${queryStr}` : '';
        function validateUrlArg(arg) {
            const sanitized = arg === null || arg === void 0 ? void 0 : arg.replace(/[^0-9a-zA-Z-_]/g, '');
            return sanitized === arg ? sanitized : undefined;
        }
    }
}
export default AppAuthenticatedPage;
