import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { Button } from '@jetbrains/ring-ui/components/button/button';
import { H2 } from '@jetbrains/ring-ui/components/heading/heading';
import { useTbeLicense } from '@app/common/queries/tbe-license';
import { ExternalLinks } from '@app/common/constants/constants';
import { useModalsController } from '@app/common/modals';
import NavigationLink from '@app/common/navigation/navigation-link';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
import HorizontalContainer from '@common/page-layout/horizontal-container';
import LicenseDetails from './license-details';
import { LicenseActivationDialog } from './activation-dialog/license-activation-dialog';
import styles from './license-activation.css';
const getPlanLabels = (plan) => {
    switch (plan) {
        case 'PREPAID':
            return 'Prepaid';
        case 'POSTPAID':
            return 'Pay-as-you-go';
        default:
            return '';
    }
};
export function LicenseActivation(props) {
    const { className } = props;
    const modalsController = useModalsController();
    const { data } = useTbeLicense();
    const handleActivateLicense = useCallback(async () => {
        const licenseActivationDialog = modalsController.openModal(LicenseActivationDialog, {
            show: true,
            onClose() {
                licenseActivationDialog.closeModal();
            }
        });
    }, [modalsController]);
    return (_jsxs("div", { className: styles.container, children: [_jsx(H2, { ...assignTestId('license-plan'), children: `IDE Services ${getPlanLabels(data === null || data === void 0 ? void 0 : data.plan)}` }), _jsxs(Text, { children: ["To find out about pricing", (data === null || data === void 0 ? void 0 : data.plan) === 'PREPAID' && ' or increase your product usage limits', ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_SALES, children: "contact JetBrains IDE Services sales team" }), ' ', "or access this information in your", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_ACCOUNT, children: "JetBrains Account" }), ' '] }), _jsx(HorizontalContainer, { children: _jsx(Button, { onClick: handleActivateLicense, primary: true, ...assignTestId('activate-new-license-button'), children: "Activate new license" }) }), _jsx("div", { className: className, children: data && _jsx(LicenseDetails, { licenseData: data }) })] }));
}
