// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flexRow__a72 {
  display: flex;
  gap: calc(1.5 * var(--ring-unit));
}

.fitButton__b2a {
  width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/ide-settings/form/common/common.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".flexRow {\n  display: flex;\n  gap: calc(1.5 * var(--ring-unit));\n}\n\n.fitButton {\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexRow": `flexRow__a72`,
	"fitButton": `fitButton__b2a`
};
export default ___CSS_LOADER_EXPORT___;
