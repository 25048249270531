import axios from 'axios';
import { apiClientManager } from '@common/api';
export async function getAuthentication() {
    var _a;
    try {
        const res = await apiClientManager
            .getClient()
            .get('/api/authentication');
        return res.data;
    }
    catch (e) {
        if (axios.isAxiosError(e) && ((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 501) {
            return null;
        }
        throw e;
    }
}
export async function getIdentityProviders() {
    const res = await apiClientManager
        .getClient()
        .get('/api/authentication/identity-providers');
    return res.data;
}
