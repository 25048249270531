import { jsx as _jsx } from "react/jsx-runtime";
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import TagsInput from '@jetbrains/ring-ui/components/tags-input/tags-input';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import { assignTestId } from '@common/infra/tests/assign-test-id';
const operatingSystemOptions = [
    { key: 'windows', label: 'Windows' },
    { key: 'linux', label: 'Linux' },
    { key: 'macOs', label: 'macOs' }
];
function OsField() {
    const { setValue, watch } = useFormContext();
    const os = watch('os');
    return (_jsx("div", { ...assignTestId('application-filter-os'), children: _jsx(TagsInput, { id: "osField", label: "Operating systems", size: Size.FULL, placeholder: isEmpty(os) ? 'All' : '', dataSource: () => operatingSystemOptions, tags: operatingSystemOptions.filter(tag => os === null || os === void 0 ? void 0 : os.includes(tag.key)), filter: true, onAddTag: ({ tag }) => setValue('os', os ? [...os, tag.key] : [tag.key]), onRemoveTag: ({ tag }) => os &&
                setValue('os', os.filter(item => item !== tag.key)) }) }));
}
export default OsField;
