import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isNil } from 'lodash';
import styles from './popup-stepped-message.css';
export function PopupSteppedMessage(props) {
    const { children } = props;
    return (_jsx("div", { className: styles.wrapper, children: children
            .filter(child => !isNil(child))
            .map((child, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        _jsxs("p", { className: styles.messageRow, children: [_jsx("div", { className: styles.messageNum, children: idx + 1 }), _jsx("div", { className: styles.message, children: child })] }, idx))) }));
}
