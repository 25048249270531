import axios from 'axios';
import { baseUrl } from '@common/api/base-url';
export class ApiClientManager {
    constructor() {
        this.client = axios.create({
            baseURL: baseUrl
        });
    }
    getClient() {
        return this.client;
    }
    addRequestInterceptor(requestInterceptorParams) {
        const interceptorId = this.client.interceptors.request.use(requestInterceptorParams.onFulfilled, requestInterceptorParams.onRejected);
        return {
            remove: () => this.client.interceptors.request.eject(interceptorId)
        };
    }
    addResponseInterceptor(responseInterceptorParams) {
        const interceptorId = this.client.interceptors.response.use(responseInterceptorParams.onFulfilled, responseInterceptorParams.onRejected);
        return {
            remove: () => this.client.interceptors.response.eject(interceptorId)
        };
    }
}
// fixme: initialize explicitly
export const apiClientManager = new ApiClientManager();
