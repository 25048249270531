// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.danger__d8e {
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-dropdown-menu/profile-dropdown-menu.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC","sourcesContent":[".danger {\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"danger": `danger__d8e`
};
export default ___CSS_LOADER_EXPORT___;
