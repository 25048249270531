import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { AlertTimeouts } from '../constants/constants';
function invokeOrReturn(x, ...args) {
    return typeof x === 'function' ? x(...args) : x;
}
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'always',
            refetchOnWindowFocus: false,
            retry: false
        },
        mutations: {
            networkMode: 'always'
        }
    },
    queryCache: new QueryCache({
        onError: (error, query) => {
            var _a;
            const handler = (_a = query.meta) === null || _a === void 0 ? void 0 : _a.errorMessage;
            if (!handler) {
                return;
            }
            const message = invokeOrReturn(handler, error, query);
            if (message === null) {
                return;
            }
            alert.error(message);
        },
        onSuccess: (data, query) => {
            var _a;
            const handler = (_a = query.meta) === null || _a === void 0 ? void 0 : _a.successMessage;
            if (!handler) {
                return;
            }
            const message = invokeOrReturn(handler, data, query);
            if (message === null) {
                return;
            }
            alert.successMessage(message, AlertTimeouts.SHORT);
        }
    }),
    mutationCache: new MutationCache({
        onError: (error, variables, context, mutation) => {
            var _a;
            const handler = (_a = mutation.meta) === null || _a === void 0 ? void 0 : _a.errorMessage;
            if (!handler) {
                return;
            }
            const message = invokeOrReturn(handler, error, variables, mutation);
            if (message === null) {
                return;
            }
            alert.error(message);
        },
        onSuccess: (data, variables, _context, mutation) => {
            var _a;
            const handler = (_a = mutation.meta) === null || _a === void 0 ? void 0 : _a.successMessage;
            if (!handler) {
                return;
            }
            const message = invokeOrReturn(handler, data, variables, mutation);
            if (message === null) {
                return;
            }
            alert.successMessage(message, AlertTimeouts.SHORT);
        }
    })
});
export default queryClient;
export const DEFAULT_STALE_TIME = 2 * 60 * 1000; // 2 min
export const DEFAULT_POLLING_INTERVAL = 10 * 1000; // 10 sec
