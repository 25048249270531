import { useCallback, useMemo } from 'react';
import { StringParam } from 'serialize-query-params';
import { useSyncedSearchArgs } from '@common/navigation/use-synced-search-args';
export function useSelectedApplication(apps) {
    const [urlParams, setUrlParams] = useSyncedSearchArgs(tsTypeToQuerySchema, createValidateSearch(apps));
    const selectedApp = useMemo(() => apps === null || apps === void 0 ? void 0 : apps.find(app => app.id === urlParams.appId), [apps, urlParams]);
    const handleAppSelect = useCallback((app) => {
        setUrlParams({ ...urlParams, appId: app.id });
    }, [setUrlParams, urlParams]);
    return [selectedApp !== null && selectedApp !== void 0 ? selectedApp : apps === null || apps === void 0 ? void 0 : apps[0], handleAppSelect];
}
const tsTypeToQuerySchema = {
    appId: StringParam
};
function createValidateSearch(appData) {
    return parsed => { var _a, _b; return ({ ...parsed, appId: (_a = parsed.appId) !== null && _a !== void 0 ? _a : (_b = getInitialApp(appData)) === null || _b === void 0 ? void 0 : _b.id }); };
}
function getInitialApp(apps) {
    var _a;
    return (_a = apps === null || apps === void 0 ? void 0 : apps.find(app => app.available)) !== null && _a !== void 0 ? _a : apps === null || apps === void 0 ? void 0 : apps[0];
}
