// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root .title__cee:first-child {
  margin-top: calc(var(--ring-unit) * 4);
}

.widgetsContainer__ba5 {
  display: flex;
  gap: calc(var(--ring-unit) * 3);
  flex-wrap: wrap;
}

.title__cee + .widgetsContainer__ba5 {
  margin-top: calc(var(--ring-unit) * 3);
}

.widgetsContainer__ba5 + .title__cee {
  margin-top: calc(var(--ring-unit) * 4);
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/dashboard-page.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,+BAA+B;EAC/B,eAAe;AACjB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[":global(#root) .title:first-child {\n  margin-top: calc(var(--ring-unit) * 4);\n}\n\n.widgetsContainer {\n  display: flex;\n  gap: calc(var(--ring-unit) * 3);\n  flex-wrap: wrap;\n}\n\n.title + .widgetsContainer {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.widgetsContainer + .title {\n  margin-top: calc(var(--ring-unit) * 4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `title__cee`,
	"widgetsContainer": `widgetsContainer__ba5`
};
export default ___CSS_LOADER_EXPORT___;
