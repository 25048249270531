export function getArchName(arch) {
    switch (arch) {
        case 'X86':
            return 'x86';
        case 'X64':
            return 'x64';
        case 'ARM64':
            return 'arm64';
        case 'ALL':
            return 'All';
        default:
            return arch;
    }
}
