// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downloadsLabel__f75 {
  margin-right: calc(var(--ring-unit) / 2);
}

.privacyLabel__f0a {
  margin-right: calc(var(--ring-unit) / 2);
}

.privacyToggle__c1d {
  margin-right: var(--ring-unit);
}

.hiddenElement__cb5 {
  visibility: hidden;
}

.infoContainer__a19 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) / 2);
  margin-bottom: calc(var(--ring-unit) * 3);
}
`, "",{"version":3,"sources":["webpack://./src/pages/plugins/plugin-page/plugin-info/plugin-info.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,yCAAyC;AAC3C","sourcesContent":[".downloadsLabel {\n  margin-right: calc(var(--ring-unit) / 2);\n}\n\n.privacyLabel {\n  margin-right: calc(var(--ring-unit) / 2);\n}\n\n.privacyToggle {\n  margin-right: var(--ring-unit);\n}\n\n.hiddenElement {\n  visibility: hidden;\n}\n\n.infoContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) / 2);\n  margin-bottom: calc(var(--ring-unit) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadsLabel": `downloadsLabel__f75`,
	"privacyLabel": `privacyLabel__f0a`,
	"privacyToggle": `privacyToggle__c1d`,
	"hiddenElement": `hiddenElement__cb5`,
	"infoContainer": `infoContainer__a19`
};
export default ___CSS_LOADER_EXPORT___;
