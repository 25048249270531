import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
import { licenseVaultApi } from '@jetbrains-license-vault/client/lib';
import { BasicTableView } from '@common/tables/basic-table-view';
import { AllocationDateField } from './allocation-date-field';
import styles from './licenses-table.css';
function toLicense(licenseTicketDto) {
    return licenseTicketDto.productTickets.map(productTicketDto => {
        return {
            id: productTicketDto.id,
            from: new Date(productTicketDto.dates.from),
            to: productTicketDto.dates.release ? new Date(productTicketDto.dates.release) : undefined,
            applicationName: productTicketDto.product.name,
            licenseName: licenseTicketDto.license.name,
            hostName: productTicketDto.hostname,
            ip: productTicketDto.ip
        };
    });
}
export function LicencesTable(props) {
    const { className } = props;
    const { data: licensesDto } = licenseVaultApi.users.useLicenses();
    const licenses = useMemo(() => {
        return licensesDto ? licensesDto.flatMap(toLicense) : [];
    }, [licensesDto]);
    const columns = useMemo(() => [
        {
            id: 'licenseName',
            title: 'License Name',
            getValue: license => (_jsx("span", { className: classNames({ [styles.activeLicense]: !license.to }), children: license.licenseName }))
        },
        {
            id: 'allocation',
            title: 'Allocation (from - to)',
            rightAlign: true,
            getValue: license => _jsx(AllocationDateField, { license: license })
        },
        { id: 'applicationName', title: 'Application' },
        { id: 'hostName', title: 'Hostname' },
        { id: 'ip', title: 'IP' }
    ], []);
    return (_jsxs("div", { className: classNames(styles.wrapper, className), "data-test": "licenses-table", children: [_jsxs("h3", { className: styles.counter, children: [licenses.length, " Licenses"] }), _jsx("div", { className: styles.tableWrapper, children: _jsx(BasicTableView, { columns: columns, data: licenses, selectable: false }) })] }));
}
