import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import userIcon from '@jetbrains/icons/user';
import { isNil } from 'lodash';
import { THIS_MONTH_TIMEFRAME } from '@api/dashboard';
import { ConstituentEnabledWidget } from '@modules/constituents/widget/constituent-enabled-widget';
import { useConstituentCurrentUsage, useConstituentUsagePerDays } from '@common/queries/dashboard';
import NavigationLink from '@common/navigation/navigation-link';
import { IntervalStatisticsWidgetCounter, StatisticsWidgetCounter } from '@common/widgets/statistics-widget/statistics-widget-counter';
import { StatisticsWidgetTimeframeSelector } from '@common/widgets/statistics-widget/statistics-widget-timeframe-selector';
import { StatisticsWidgetBarChart } from '@common/widgets/statistics-widget/statistics-widget-bar-chart';
import { StatisticsWidgetInfoStats, StatisticsWidgetInfoStatsSize } from '@common/widgets/statistics-widget/statistics-widget-info-stats';
import { useIdeProvisionerConstituent } from '@common/queries/ide-provisioner';
import { JustifyContent } from '@common/widgets/widget/widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
export function IdepActiveWidget(props) {
    var _a, _b;
    const constituent = { id: 'idep', title: 'IDE Provisioner' };
    const [timeframe, setTimeframe] = useState(THIS_MONTH_TIMEFRAME);
    const [activeInterval, setActiveInterval] = useState();
    const { data: constituentState, isLoading: isStateLoading } = useIdeProvisionerConstituent();
    const { data, isLoading } = useConstituentUsagePerDays(constituent, timeframe);
    const { data: currentUsage, isLoading: isCurrentUsageLoading } = useConstituentCurrentUsage(constituent);
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.days.length) {
            setActiveInterval(data.days[data.days.length - 1] || undefined);
        }
    }, [data]);
    const userLimit = (constituentState === null || constituentState === void 0 ? void 0 : constituentState.status) === 'enabled' ? constituentState === null || constituentState === void 0 ? void 0 : constituentState.data.assignedUsersLimit : undefined;
    return (_jsxs(ConstituentEnabledWidget, { ...props, ...assignTestId('widget-ide-provisioner'), loading: isLoading || isCurrentUsageLoading || isStateLoading, justifyContent: JustifyContent.Bottom, children: [_jsx(StatisticsWidgetInfoStats, { statName: "Current users", statValue: _jsxs(NavigationLink, { href: "/users?constituentId=idep", children: [(_a = currentUsage === null || currentUsage === void 0 ? void 0 : currentUsage.value) !== null && _a !== void 0 ? _a : 0, " ", !isNil(userLimit) && _jsxs(_Fragment, { children: ["/ ", userLimit] })] }), size: StatisticsWidgetInfoStatsSize.TwoColumns }), activeInterval && (data === null || data === void 0 ? void 0 : data.days) && (_jsxs(_Fragment, { children: [_jsx(StatisticsWidgetBarChart, { intervals: data === null || data === void 0 ? void 0 : data.days, activeInterval: activeInterval, onActivateInterval: setActiveInterval }), _jsx(StatisticsWidgetTimeframeSelector, { timeframe: timeframe, onSelect: setTimeframe, label: "Daily usages" }), _jsx(IntervalStatisticsWidgetCounter, { icon: userIcon, count: (_b = activeInterval === null || activeInterval === void 0 ? void 0 : activeInterval.total) !== null && _b !== void 0 ? _b : 0, measure: "Maximum daily usages", activeInterval: activeInterval }), data.maxUsageDay && (_jsx(StatisticsWidgetCounter, { icon: userIcon, count: data.maxUsageDay.total, label: "Maximum daily usages for the period" }))] }))] }));
}
