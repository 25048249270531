import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import RegisteredInput from '@app/common/hook-form/registered-input';
function DescriptionField() {
    const { control, watch } = useFormContext();
    const watchPluginId = watch('pluginId');
    const disabled = !watchPluginId;
    return (_jsx(RegisteredInput, { label: "Description", control: control, name: "description", disabled: disabled, size: Size.L, placeholder: "Description", autoComplete: "off" }));
}
export default DescriptionField;
