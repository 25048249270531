import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import TagsInput from '@jetbrains/ring-ui/components/tags-input/tags-input';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import ErrorBubble from '@jetbrains/ring-ui/components/error-bubble/error-bubble';
import { ensurePluginChannels } from '../../../common/queries/plugins';
import { validateChannel } from '../channel-validation';
function toTag(channel) {
    return { key: channel.id, label: channel.name };
}
export default function ChannelsEditor({ show, loading, initialChannels, errorMessage, onSubmit, onCancel }) {
    const [channelsTags, setChannelsTags] = useState(initialChannels === null || initialChannels === void 0 ? void 0 : initialChannels.map(toTag));
    const [renderedErrorMessage, setRenderedErrorMessage] = useState('');
    useEffect(() => setRenderedErrorMessage(errorMessage), [errorMessage]);
    useEffect(() => setRenderedErrorMessage(''), [channelsTags]);
    const handleAddChannel = useCallback(({ tag }) => {
        if (!tag.label) {
            return;
        }
        const { errorMessage } = validateChannel(tag);
        if (errorMessage) {
            setRenderedErrorMessage(errorMessage);
            return;
        }
        setRenderedErrorMessage(undefined);
        setChannelsTags(channelsTags.concat([tag]));
    }, [channelsTags]);
    const handleCancel = useCallback(() => {
        setChannelsTags(initialChannels.map(toTag));
        onCancel();
    }, [initialChannels, onCancel]);
    if (!channelsTags) {
        return _jsx("div", {});
    }
    return (_jsxs(Dialog, { label: "Dialog", show: show, onCloseAttempt: handleCancel, trapFocus: true, autoFocusFirst: false, children: [_jsx(Header, { children: "Add Channels" }), _jsx(Content, { children: _jsx(ErrorBubble, { error: renderedErrorMessage, children: _jsx(TagsInput, { label: "Channels", tags: channelsTags.map(item => item), dataSource: () => ensurePluginChannels().then(result => result.map(it => toTag(it))), allowAddNewTags: true, autoOpen: true, onAddTag: handleAddChannel, onRemoveTag: ({ tag }) => setChannelsTags(channelsTags.filter(it => it.label !== tag.label)), filter: true }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, loader: loading, disabled: channelsTags.length === 0, onClick: () => onSubmit(channelsTags.map(it => `${it.label}`)), children: "OK" }), _jsx(Button, { onClick: handleCancel, children: "Cancel" })] })] }));
}
