// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item__d1f {
  &:first-child {
    padding-top: var(--ring-unit);
  }

  &:last-child {
    padding-bottom: var(--ring-unit);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/applications/applications.css"],"names":[],"mappings":"AAAA;EACE;IACE,6BAA6B;EAC/B;;EAEA;IACE,gCAAgC;EAClC;AACF","sourcesContent":[".item {\n  &:first-child {\n    padding-top: var(--ring-unit);\n  }\n\n  &:last-child {\n    padding-bottom: var(--ring-unit);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `item__d1f`
};
export default ___CSS_LOADER_EXPORT___;
