import { jsx as _jsx } from "react/jsx-runtime";
import { format } from 'date-fns';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import Text from '@jetbrains/ring-ui/components/text/text';
import { apiProfileAuditTypeOptions } from '@api/profiles';
import { Collapsible } from '@app/common/collapsible/collapsible';
import { TooltipDelays } from '@common/constants/constants';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { ProfileHistoryItemDetails } from './profile-history-item-details';
import styles from './profile-history-item.css';
function getTypeLabel(type) {
    var _a, _b;
    return (_b = (_a = apiProfileAuditTypeOptions.find(item => item.key === type)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : 'Settings';
}
function ProfileHistoryItem({ item, profileId, ...rest }) {
    const eventText = `${getTypeLabel(item.type)} were updated`;
    const time = item.date ? ` at ${format(item.date, 'HH:mm')}` : '';
    return (_jsx(Collapsible, { ...rest, className: styles.collapsibleWithBookedSpaceForCollapsedContent, primary: true, title: _jsx(Tooltip, { delay: TooltipDelays.LONG, title: `${eventText}${time}`, children: _jsx(Text, { ...assignTestId('profile-history-event-title'), children: eventText }) }), children: _jsx(ProfileHistoryItemDetails, { itemId: item.id, profileId: profileId, ...assignTestId('profile-history-event-details') }) }));
}
export default ProfileHistoryItem;
