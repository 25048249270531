import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import { BasicSelectableTable } from '@common/tables/basic-selectable-table';
import { getPluginUrl, getPluginVersionTitle } from './functions';
import { PluginsTableName } from './cells/plugins-table-name';
import { PluginsTableActions } from './cells/plugins-table-actions';
export function PluginsPageTable({ data, isLoading, params, onParamsChange }) {
    const navigate = useNavigate();
    const { plugins, total } = useMemo(() => {
        var _a, _b;
        return {
            plugins: (_a = data === null || data === void 0 ? void 0 : data.items) !== null && _a !== void 0 ? _a : [],
            total: (_b = data === null || data === void 0 ? void 0 : data.total) !== null && _b !== void 0 ? _b : 0
        };
    }, [data]);
    const columns = useMemo(() => [
        {
            id: 'name',
            title: 'Plugin Name',
            getValue: plugin => _jsx(PluginsTableName, { ...plugin })
        },
        { id: 'vendor', title: 'Vendor' },
        {
            id: 'versions',
            title: 'Versions',
            getValue: getPluginVersionTitle
        },
        {
            id: 'actionButtons',
            getValue: plugin => _jsx(PluginsTableActions, { ...plugin })
        }
    ], []);
    const onEdit = useCallback((plugin) => {
        navigate(getPluginUrl(plugin.id), { state: { urlsafe: true } });
    }, [navigate]);
    return (_jsx(BasicSelectableTable, { data: plugins, getItemKey: plugin => plugin.id, loading: isLoading, columns: columns, renderEmpty: () => 'No plugins in the local repository yet', onItemEnter: onEdit, selectable: false, pagerProps: {
            total,
            pageSize: DEFAULT_TABLE_PAGE_SIZE,
            currentPage: params.page,
            disablePageSizeSelector: true,
            onPageChange: newPage => onParamsChange({ ...params, page: newPage })
        } }));
}
