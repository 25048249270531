// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.licenseContainer__b31 {
  display: flex;
  gap: calc(var(--ring-unit) * 1.5);
}

.danger__a26 {
  color: var(--ring-error-color);
}

.buttonsContainer__de7 {
  display: flex;
  align-items: center;
}

.container__dde {
  background-color: var(--ring-sidebar-background-color);
  border: 1px solid var(--ring-line-color);
  border-radius: calc(var(--ring-unit) / 2);
  display: flex;
  flex-direction: column;
  padding: calc(var(--ring-unit) * 4);
  gap: calc(var(--ring-unit) * 1.5);

  position: relative;
}

.title__dc5 {
  width: calc(var(--ring-unit) * 10);
}

.floatingIcon__baf {
  position: absolute;
  top: calc(var(--ring-unit) * 4);
  right: calc(var(--ring-unit) * 4);
}

.icon__aa3 {
  color: var(--ring-icon-color);
}
`, "",{"version":3,"sources":["webpack://./src/modules/license/license-activation/license-details.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,sDAAsD;EACtD,wCAAwC;EACxC,yCAAyC;EACzC,aAAa;EACb,sBAAsB;EACtB,mCAAmC;EACnC,iCAAiC;;EAEjC,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,+BAA+B;EAC/B,iCAAiC;AACnC;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".licenseContainer {\n  display: flex;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.danger {\n  color: var(--ring-error-color);\n}\n\n.buttonsContainer {\n  display: flex;\n  align-items: center;\n}\n\n.container {\n  background-color: var(--ring-sidebar-background-color);\n  border: 1px solid var(--ring-line-color);\n  border-radius: calc(var(--ring-unit) / 2);\n  display: flex;\n  flex-direction: column;\n  padding: calc(var(--ring-unit) * 4);\n  gap: calc(var(--ring-unit) * 1.5);\n\n  position: relative;\n}\n\n.title {\n  width: calc(var(--ring-unit) * 10);\n}\n\n.floatingIcon {\n  position: absolute;\n  top: calc(var(--ring-unit) * 4);\n  right: calc(var(--ring-unit) * 4);\n}\n\n.icon {\n  color: var(--ring-icon-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"licenseContainer": `licenseContainer__b31`,
	"danger": `danger__a26`,
	"buttonsContainer": `buttonsContainer__de7`,
	"container": `container__dde`,
	"title": `title__dc5`,
	"floatingIcon": `floatingIcon__baf`,
	"icon": `icon__aa3`
};
export default ___CSS_LOADER_EXPORT___;
