import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useMemo } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import { useInfiniteUsersQuery, useUser } from '../../../common/queries/users';
const toSelectOption = (user) => ({
    key: user.id,
    label: user.username,
    description: user.email
});
function UsersFilter({ selected, onSelect }) {
    const [isQueryEnabled, setIsQueryEnabled] = useState(false);
    const [query, setQuery] = useState('');
    const { data, isFetching, fetchNextPage } = useInfiniteUsersQuery({ query }, isQueryEnabled);
    const resolvedSelectedUser = useUser(selected || '');
    const selectedOption = useMemo(() => (resolvedSelectedUser.data ? toSelectOption(resolvedSelectedUser.data) : undefined), [resolvedSelectedUser.data]);
    const usersData = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.pages.flatMap(({ users }) => users).map(toSelectOption)) !== null && _a !== void 0 ? _a : []; }, [data === null || data === void 0 ? void 0 : data.pages]);
    return (_jsx(Select, { clear: true, filter: {
            fn: ({ label, description }, value) => !!(label === null || label === void 0 ? void 0 : label.toString().includes(value)) || !!(description === null || description === void 0 ? void 0 : description.toString().includes(value))
        }, label: "All users", selectedLabel: "User", size: Select.Size.M, loading: isFetching, data: usersData, onClose: () => {
            setIsQueryEnabled(false);
            setQuery('');
        }, onFilter: setQuery, onChange: (option) => onSelect(option ? `${option.key}` : ''), onBeforeOpen: () => setIsQueryEnabled(true), selected: selectedOption, onLoadMore: fetchNextPage, popupStyle: { width: 450 } }));
}
export default UsersFilter;
