import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { Tray } from '@jetbrains/ring-ui/components/header/header';
import Theme, { ThemeProvider } from '@jetbrains/ring-ui/components/global/theme';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Container } from '@webteam/layout';
import classNames from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';
import settings from '@jetbrains/icons/settings';
import DropdownMenu from '@jetbrains/ring-ui/components/dropdown-menu/dropdown-menu';
// eslint-disable-next-line no-restricted-imports
import { ListProps } from '@jetbrains/ring-ui/components/popup-menu/popup-menu';
// eslint-disable-next-line import/no-restricted-paths
import TrayIcon from '@jetbrains/ring-ui/components/header/tray-icon';
import Text from '@jetbrains/ring-ui/components/text/text';
// eslint-disable-next-line import/no-restricted-paths
import { IdeServicesLogo } from '@modules/ide-services/ide-services-logo/ide-services-logo';
import { useAuthentication } from '@common/queries/authentication';
import { ExternalLinks } from '@common/constants/constants';
import NavigationLink from '@common/navigation/navigation-link';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import auth from '../../auth/auth';
import styles from './app-header.css';
function UnauthorizedHeader() {
    const navigate = useNavigate();
    const { data: authentication } = useAuthentication();
    const doLogin = useCallback((option) => {
        auth.login(option).then(() => navigate('/'));
    }, [navigate]);
    const isGuest = auth.isGuest();
    const dropdownOptions = useMemo(() => {
        return [
            !!authentication &&
                !!authentication.loginOptions.length && {
                key: 'login-options',
                rgItemType: ListProps.Type.CUSTOM,
                onClick: () => doLogin(authentication.loginOptions[0]),
                template: _jsx(Text, { ...assignTestId('login-options'), children: "Login Options" })
            },
            {
                key: 'read-help',
                rgItemType: ListProps.Type.CUSTOM,
                template: () => (_jsx(NavigationLink, { className: classNames(styles.navLinkContainer, styles.clearLinkStyles), href: ExternalLinks.JB_TBE_DOCS, target: "_blank", ...assignTestId('documentation'), children: "Documentation" }))
            },
            {
                key: 'submit-feedback',
                rgItemType: ListProps.Type.CUSTOM,
                template: () => (_jsx(NavigationLink, { className: classNames(styles.navLinkContainer, styles.clearLinkStyles), href: ExternalLinks.FEEDBACK, target: "_blank", ...assignTestId('feedback'), children: "Share feedback" }))
            },
            {
                key: 'bug-report',
                rgItemType: ListProps.Type.CUSTOM,
                template: () => (_jsx(NavigationLink, { className: classNames(styles.navLinkContainer, styles.clearLinkStyles), href: ExternalLinks.BUG_REPORT, target: "_blank", ...assignTestId('bug-report'), children: "Report bug" }))
            },
            {
                key: 'third-party-licenses',
                rgItemType: ListProps.Type.CUSTOM,
                template: () => (_jsx(NavigationLink, { className: classNames(styles.navLinkContainer, styles.clearLinkStyles), href: "/third-party-licenses", ...assignTestId('third-party-licenses'), children: "Third-party licenses" }))
            }
        ].filter(Boolean);
    }, [authentication, doLogin]);
    return (_jsx(ThemeProvider, { theme: Theme.DARK, children: _jsx("header", { className: classNames(styles.appHeader, styles.unauthorized), ...assignTestId('app-header', 'unauthorized'), children: _jsx(Container, { className: styles.contentWrapper, children: isGuest && (_jsxs(_Fragment, { children: [_jsx(NavLink, { to: "/", children: _jsx(IdeServicesLogo, { collapsible: true }) }), _jsxs(Tray, { className: styles.tray, children: [_jsx(Button, { text: true, inline: true, className: styles.loginButton, onClick: () => doLogin(), "data-test": "unauthorized-header-login-button", children: "Log in to JetBrains IDE Services" }), _jsx(DropdownMenu, { ...assignTestId('unauthorized-header-dropdown'), anchor: ({ active, pinned, ...ariaProps }) => (_jsx(TrayIcon, { title: "Settings", active: active, rotatable: true, icon: settings, ...ariaProps })), data: dropdownOptions })] })] })) }) }) }));
}
export default UnauthorizedHeader;
