import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { format } from 'date-fns';
import Text from '@jetbrains/ring-ui/components/text/text';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import Button from '@jetbrains/ring-ui/components/button/button';
import infoIcon from '@jetbrains/icons/info';
import { Size as IconSize } from '@jetbrains/ring-ui/components/icon/icon__constants';
import { TooltipDelays } from '@common/constants/constants';
export function LicenseEventDescription({ event }) {
    return (_jsxs(Text, { children: [_jsx(Tooltip, { delay: TooltipDelays.LONG, title: _jsxs(_Fragment, { children: [event.action, " at ", format(event.timestamp, 'HH:mm')] }), children: event.action }), event.message && (_jsx(Tooltip, { title: `Reason: ${event.message}`, children: _jsx(Button, { icon: infoIcon, iconSize: IconSize.Size14 }) }))] }));
}
