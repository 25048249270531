// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bodyContainer__f45 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  border: calc(var(--ring-border-radius) / 4) solid var(--ring-borders-color);
  padding: calc(var(--ring-unit) / 2);
  border-radius: calc(var(--ring-unit) / 2);
  margin-bottom: calc(var(--ring-unit) / 2);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/settings-placeholder.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,2EAA2E;EAC3E,mCAAmC;EACnC,yCAAyC;EACzC,yCAAyC;AAC3C","sourcesContent":[".bodyContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 44px;\n  border: calc(var(--ring-border-radius) / 4) solid var(--ring-borders-color);\n  padding: calc(var(--ring-unit) / 2);\n  border-radius: calc(var(--ring-unit) / 2);\n  margin-bottom: calc(var(--ring-unit) / 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyContainer": `bodyContainer__f45`
};
export default ___CSS_LOADER_EXPORT___;
