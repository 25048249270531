import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import ToolbarItem from '@app/common/toolbar-item/toolbar-item';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
const isActiveOptions = [
    { key: 1, label: 'Active', ...assignTestId('status-active') },
    { key: 0, label: 'Deactivated', ...assignTestId('status-deactivated') }
];
function ActiveFilter({ isActive, onChange }) {
    var _a;
    const handleChange = useCallback((selected) => {
        onChange(selected === null ? undefined : !!selected.key);
    }, [onChange]);
    const selectedItem = isActive === undefined ? undefined : isActiveOptions.find(option => !!option.key === isActive);
    return (_jsx(ToolbarItem, { label: "Status", ...assignTestId('status-filter'), children: _jsx(Select, { clear: true, label: "All", data: isActiveOptions, size: Select.Size.S, selected: selectedItem, onChange: handleChange, ...assignTestId('status-select', (_a = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem['data-test']) !== null && _a !== void 0 ? _a : 'empty') }) }));
}
export default ActiveFilter;
