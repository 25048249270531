import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { format } from 'date-fns';
import styles from './trial-notification-wrapper.css';
export function TrialNotificationWrapper(props) {
    const { trial, children } = props;
    return (_jsxs(_Fragment, { children: [(trial === null || trial === void 0 ? void 0 : trial.state) === 'ACTIVE' && trial.endDate && (_jsx("p", { className: styles.trialEndMessage, children: formatTrialEndDate(trial.endDate) })), _jsx("div", { className: styles.widgetContentWrapper, children: children })] }));
}
function formatTrialEndDate(trialEnd) {
    const trialEndText = format(trialEnd, 'MMM dd, yyyy');
    return `Your trial ends on ${trialEndText}`;
}
