import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import Button from '@jetbrains/ring-ui/components/button/button';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import downloadIcon from '@jetbrains/icons/download';
import NavigationLink from '@app/common/navigation/navigation-link';
import { ExternalLinks } from '@app/common/constants/constants';
import styles from './offline-mode.css';
export default function OfflineMode() {
    return (_jsxs("div", { className: styles.contentLayout, children: [_jsx("div", { className: styles.contentSection, children: _jsxs(Text, { children: ["JetBrains IDE Services allows your server to run without internet access.", ' ', _jsx(NavigationLink, { target: "_blank", href: ExternalLinks.JB_TBE_OFFLINE_MODE_DOCS, children: "Learn more" }), "."] }) }), _jsxs("div", { className: styles.contentSection, children: [_jsx(H3, { children: "Command Line Interface" }), _jsx("p", { children: _jsx(Text, { children: "Download the JetBrains IDE Services command line tool to list, install, and manage artifacts in offline mode." }) }), _jsx(Button, { className: styles.contentTextButton, text: true, icon: downloadIcon, primary: true, href: "/api/info/cli.zip", download: true, "data-test": "tbe-cli-download-button", children: "Download tbe-cli" })] })] }));
}
