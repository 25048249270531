import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { useSelector } from 'react-redux';
import { getProfile } from '@app/common/store/profiles/selectors';
import { InformationBanner } from '@app/common/information-banner/information-banner';
import ProductLogo from '@common/product-logo/product-logo';
import { useProfileEvaluatedApplicationsContent } from '@common/queries/profiles';
import toolsPreviewStyles from '../profile-ide-provisioner/common/tools-preview.css';
import { toTool } from './formatters';
import styles from './newly-included-apps-banner.css';
/**
 * Banner which renders applications which were excluded and became included
 * @constructor
 */
function NewlyIncludedAppsBanner() {
    const profile = useSelector(getProfile);
    const { data: immutableApps } = useProfileEvaluatedApplicationsContent(profile.id);
    const mutableApps = profile.applications.content;
    const productToRules = useNewlyIncludedAppRules(immutableApps || [], mutableApps);
    const newlyIncludedToolsAndDescriptions = mutableApps
        .filter(x => productToRules === null || productToRules === void 0 ? void 0 : productToRules.has(x.product))
        .map(toTool)
        .sort((x, y) => x.productName.localeCompare(y.productName));
    if (!productToRules || productToRules.size === 0) {
        return null;
    }
    return (_jsx(InformationBanner, { children: _jsxs("div", { className: styles.leftBannerContainer, children: [_jsxs(Text, { children: ["After saving the changes, these applications will become available to profile users:", ' '] }), ' ', _jsx("div", { className: toolsPreviewStyles.productLogos, children: newlyIncludedToolsAndDescriptions.map(tool => (_jsx(Tooltip, { title: format(tool, mutableApps, productToRules), children: _jsx(ProductLogo, { product: tool.product, className: toolsPreviewStyles.productLogo }) }, tool.product))) })] }) }));
}
/**
 * Algorithm that iterates over applications and finds elements which were excluded in the
 * "appsWithExcludeBuilds" and became included in the "appsWithIncludeBuilds".
 * @param appsWithExcludeBuilds
 * @param appsWithIncludeBuilds
 */
function useNewlyIncludedAppRules(appsWithExcludeBuilds, appsWithIncludeBuilds) {
    return useMemo(() => {
        const keys = new Set(appsWithIncludeBuilds.flatMap(x => x.include.map(y => getKey(x.product, y))));
        return appsWithExcludeBuilds.reduce((out, excludedApp) => {
            // Calculate keys intersection
            const builds = excludedApp.exclude.filter(x => keys.has(getKey(excludedApp.product, x)));
            if (builds.length) {
                out.set(excludedApp.product, builds);
            }
            return out;
        }, new Map());
    }, [appsWithExcludeBuilds, appsWithIncludeBuilds]);
    function getKey(product, build) {
        return `${product}${build.build}${build.version}`;
    }
}
function format(tool, apps, productToRules) {
    var _a, _b, _c;
    const { product } = tool;
    const name = ((_a = apps.find(x => x.product === product)) === null || _a === void 0 ? void 0 : _a.productName) || product;
    const builds = (_c = (_b = productToRules === null || productToRules === void 0 ? void 0 : productToRules.get(product)) === null || _b === void 0 ? void 0 : _b.map(x => `${x.build}-${x.version}`)) === null || _c === void 0 ? void 0 : _c.join(', ');
    const suffix = builds ? ` ${builds}` : '';
    return `${name}${suffix}`;
}
export default NewlyIncludedAppsBanner;
