import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@jetbrains/ring-ui/components/button/button';
import { useNavigate } from 'react-router-dom';
import { useModalsController } from '@common/modals';
import ProfileCreatePopup from './profile-create-popup';
export function ProfilesPageActions() {
    const navigate = useNavigate();
    const modalsController = useModalsController();
    const handleCreateProfile = async () => {
        const createProfileDialog = modalsController.openModal(ProfileCreatePopup, {
            onConfirm(id) {
                navigate(`/profiles/${id}`);
                createProfileDialog.closeModal();
            },
            onReject() {
                createProfileDialog.closeModal();
            }
        });
    };
    return (_jsx(Button, { primary: true, "data-test": "create-profile", onClick: handleCreateProfile, children: "Create profile" }));
}
