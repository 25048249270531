import { jsx as _jsx } from "react/jsx-runtime";
import ButtonGroup from '@jetbrains/ring-ui/components/button-group/button-group';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useFormContext } from 'react-hook-form';
import { assignTestId } from '@common/infra/tests/assign-test-id';
const settingTypeOptions = [
    { key: 'include', label: 'Include' },
    { key: 'exclude', label: 'Exclude' }
];
const restrictionTypeOptions = [
    { key: 'include', label: 'Restrict' },
    { key: 'exclude', label: "Don't restrict" }
];
function TypeField({ target }) {
    const { setValue, watch } = useFormContext();
    const type = watch('type');
    const typeOptions = target === 'restrictions' ? restrictionTypeOptions : settingTypeOptions;
    return (_jsx("div", { ...assignTestId('filter-type'), children: _jsx(ButtonGroup, { children: typeOptions.map(x => (_jsx(Button, { active: type === x.key, onClick: () => {
                    setValue('type', x.key.startsWith('include') ? 'include' : 'exclude');
                }, children: x.label }, x.key))) }) }));
}
export default TypeField;
