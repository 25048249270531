// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__a64 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.header__ec1 {
  margin-top: calc(var(--ring-unit) * 1.5);
  align-self: start;
  display: flex;
  align-items: center;
  gap: calc(var(--ring-unit) * 1.5);

  > h3 {
    margin-top: 0;
  }
}

.companyLogo__cb9 {
  margin-top: calc(var(--ring-unit) * 15);
}

.companyName__e83 {
  margin-top: calc(var(--ring-unit) * 4);
  text-align: center;
}

.info__ec8 {
  margin-top: calc(var(--ring-unit) * 2);
}

.loginBtn__ee6 {
  margin-top: calc(var(--ring-unit) * 3);
}

.link__a9e {
  padding: 0;
}

.aboutLink__b8c {
  margin-top: calc(var(--ring-unit) * 11);
}
`, "",{"version":3,"sources":["webpack://./src/pages/code-with-me/unauthorized-page/session-page-unauthorized.css"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;EACxC,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,iCAAiC;;EAEjC;IACE,aAAa;EACf;AACF;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".wrapper {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n\n.header {\n  margin-top: calc(var(--ring-unit) * 1.5);\n  align-self: start;\n  display: flex;\n  align-items: center;\n  gap: calc(var(--ring-unit) * 1.5);\n\n  > h3 {\n    margin-top: 0;\n  }\n}\n\n.companyLogo {\n  margin-top: calc(var(--ring-unit) * 15);\n}\n\n.companyName {\n  margin-top: calc(var(--ring-unit) * 4);\n  text-align: center;\n}\n\n.info {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n\n.loginBtn {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.link {\n  padding: 0;\n}\n\n.aboutLink {\n  margin-top: calc(var(--ring-unit) * 11);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__a64`,
	"header": `header__ec1`,
	"companyLogo": `companyLogo__cb9`,
	"companyName": `companyName__e83`,
	"info": `info__ec8`,
	"loginBtn": `loginBtn__ee6`,
	"link": `link__a9e`,
	"aboutLink": `aboutLink__b8c`
};
export default ___CSS_LOADER_EXPORT___;
