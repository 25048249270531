// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__fc4 {
  color: var(--ring-secondary-color);
  flex-wrap: nowrap;
}

.rescUiWrapper__d9f {
  display: flex;
  gap: 1.6px;
}

.archDropdown__e4a {
  color: var(--ring-text-color);
}

.archDropdown__e4a button {
  color: var(--ring-text-color);
}

.fetchingLoader__e7e {
  margin-right: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/modules/machine-config/download-toolbox-app.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".wrapper {\n  color: var(--ring-secondary-color);\n  flex-wrap: nowrap;\n}\n\n.rescUiWrapper {\n  display: flex;\n  gap: 1.6px;\n}\n\n.archDropdown {\n  color: var(--ring-text-color);\n}\n\n.archDropdown button {\n  color: var(--ring-text-color);\n}\n\n.fetchingLoader {\n  margin-right: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__fc4`,
	"rescUiWrapper": `rescUiWrapper__d9f`,
	"archDropdown": `archDropdown__e4a`,
	"fetchingLoader": `fetchingLoader__e7e`
};
export default ___CSS_LOADER_EXPORT___;
