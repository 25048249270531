// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.groupDetails__c1c {
  margin: -4px 0 0 12px;
  line-height: var(--ring-line-height);
  font-size: var(--ring-font-size-smaller);
  color: var(--ring-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-users/assign-dialog/group-details.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,oCAAoC;EACpC,wCAAwC;EACxC,kCAAkC;AACpC","sourcesContent":[".groupDetails {\n  margin: -4px 0 0 12px;\n  line-height: var(--ring-line-height);\n  font-size: var(--ring-font-size-smaller);\n  color: var(--ring-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupDetails": `groupDetails__c1c`
};
export default ___CSS_LOADER_EXPORT___;
