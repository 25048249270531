// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content__eb9 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.content__eb9 > h2 {
  margin-bottom: 0; /* By default H1 has a bottom margin, so we remove it */
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/dialog-header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,gBAAgB,EAAE,uDAAuD;EACzE,WAAW;AACb","sourcesContent":[".content {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n}\n\n.content > h2 {\n  margin-bottom: 0; /* By default H1 has a bottom margin, so we remove it */\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `content__eb9`
};
export default ___CSS_LOADER_EXPORT___;
