// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner__eaa {
  margin: 0 calc(2 * var(--ring-unit));
  line-height: var(--ring-line-height);
  vertical-align: middle;
}

.icon__f93 {
  margin-right: var(--ring-unit);
}

@media (max-width: 1250px) {
  .bannerText__dae {
    display: none;
  }

  .banner__eaa {
    margin: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/common/app-layout/app-header/offline-mode-banner.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,oCAAoC;EACpC,sBAAsB;AACxB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,SAAS;EACX;AACF","sourcesContent":[".banner {\n  margin: 0 calc(2 * var(--ring-unit));\n  line-height: var(--ring-line-height);\n  vertical-align: middle;\n}\n\n.icon {\n  margin-right: var(--ring-unit);\n}\n\n@media (max-width: 1250px) {\n  .bannerText {\n    display: none;\n  }\n\n  .banner {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": `banner__eaa`,
	"icon": `icon__f93`,
	"bannerText": `bannerText__dae`
};
export default ___CSS_LOADER_EXPORT___;
