// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar__de9 {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.jsonEditorButton__a50 {
  color: #999999;
  font-size: 13px;
  line-height: 13px;
  display: flex;
  align-items: flex-end;
}

.xpathEditor__f26 {
  border-top: 1px solid var(--ring-line-color);
  padding-top: calc(1.5 * var(--ring-unit));
}

.xpathEditorContent__bcf {
  border-width: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/ide-settings/form/common/setting-form-tab.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,4CAA4C;EAC5C,yCAAyC;AAC3C;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".toolbar {\n  display: flex;\n  justify-content: space-between;\n  align-items: start;\n}\n\n.jsonEditorButton {\n  color: #999999;\n  font-size: 13px;\n  line-height: 13px;\n  display: flex;\n  align-items: flex-end;\n}\n\n.xpathEditor {\n  border-top: 1px solid var(--ring-line-color);\n  padding-top: calc(1.5 * var(--ring-unit));\n}\n\n.xpathEditorContent {\n  border-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": `toolbar__de9`,
	"jsonEditorButton": `jsonEditorButton__a50`,
	"xpathEditor": `xpathEditor__f26`,
	"xpathEditorContent": `xpathEditorContent__bcf`
};
export default ___CSS_LOADER_EXPORT___;
