import { isEmpty } from 'lodash';
import { SessionExpiredError, UserDeactivatedError } from '@common/auth/auth-models';
/**
 * This is a legacy function. It takes an arbitrary request error and returns an error message
 * or validation errors. It'll be refactored in the scope of the TBE-2290
 * @param e
 */
export function getApiError(e) {
    var _a, _b;
    if (e instanceof SessionExpiredError) {
        return {
            success: false,
            errorMessage: 'Session expired. Please log in again.'
        };
    }
    if (e instanceof UserDeactivatedError) {
        return {
            success: false,
            errorMessage: 'User is deactivated'
        };
    }
    const data = ((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) ? e.response.data : undefined;
    switch ((_b = e === null || e === void 0 ? void 0 : e.response) === null || _b === void 0 ? void 0 : _b.status) {
        case 401:
            return handleUnauthorizedResponse();
        case 400:
        case 403:
        case 409:
            return handleClientErrorResponse(e, data);
        case 404:
            return handleNotFoundResponse();
        case 500:
            return handleInternalServerResponse(data);
        default:
            break;
    }
    // eslint-disable-next-line no-console
    console.error('Unexpected error', e);
    return { success: false, errorMessage: 'Unexpected error' };
}
function handleUnauthorizedResponse() {
    return { success: false, errorMessage: 'Session expired. Please log in again.' };
}
function handleClientErrorResponse(e, data) {
    if (data === null || data === void 0 ? void 0 : data.errors) {
        return { success: false, errors: data.errors };
    }
    return { success: false, errorMessage: (data === null || data === void 0 ? void 0 : data.detail) || e.message };
}
function handleNotFoundResponse() {
    return {
        success: false,
        errorMessage: 'No such record found',
        notFound: true
    };
}
function handleInternalServerResponse(data) {
    return {
        success: false,
        errorMessage: (data === null || data === void 0 ? void 0 : data.detail) ? `Server error: ${data.detail}` : `Unknown server error`
    };
}
export function formatApiError(err, message = 'Failed to load data') {
    const data = getApiError(err);
    const errorsSuffix = isEmpty(data.errors)
        ? ''
        : ': request contains invalid fields, see the console for details';
    const messageSuffix = data.errorMessage ? `: ${data.errorMessage}` : '';
    return message + (errorsSuffix || messageSuffix);
}
