import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
import { H2 } from '@jetbrains/ring-ui/components/heading/heading';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { AppBuildSettings } from './app-build-settings/app-build-settings';
import { AppBuildSelector, BuildKey } from './app-build-selector';
import { AppBuildSettingsError } from './app-build-settings-error/app-build-settings-error';
import styles from './application-settings.css';
export function ApplicationSettings(props) {
    const { className, application, userId, setSelectedBuildKey, selectedBuildKey, onViewSelect, selectedView } = props;
    const { availableBuilds } = application;
    const selectedValidBuildKey = useMemo(() => selectedBuildKey === null || selectedBuildKey === void 0 ? void 0 : selectedBuildKey.getValidBuildKey(), [selectedBuildKey]);
    const buildsMap = useMemo(() => {
        const map = new Map();
        availableBuilds.forEach(build => {
            map.set(BuildKey.getValidBuildKey(build), build);
        });
        return map;
    }, [availableBuilds]);
    const selectedBuild = selectedValidBuildKey && buildsMap.get(selectedValidBuildKey);
    return (_jsxs("div", { className: classNames(styles.wrapper, className), ...assignTestId('application-settings'), children: [_jsx(H2, { className: styles.header, ...assignTestId('app-title'), children: application.name }), !!availableBuilds.length && (_jsx(AppBuildSelector, { className: styles.appSelector, builds: availableBuilds, selectedBuild: selectedBuildKey !== null && selectedBuildKey !== void 0 ? selectedBuildKey : BuildKey.fromProductBuild(availableBuilds[0]), onBuildSelect: setSelectedBuildKey })), selectedBuild && (_jsx(AppBuildSettings, { className: styles.configuration, userId: userId, application: application, productBuild: selectedBuild, selectedView: selectedView, onViewSelect: onViewSelect })), availableBuilds.length === 0 && (_jsx(AppBuildSettingsError, { className: styles.unavailableNote, ...assignTestId('build-settings-not-available-error'), children: "This application is not available for the current user" })), !!availableBuilds.length && selectedValidBuildKey && !selectedBuild && (_jsx(AppBuildSettingsError, { className: styles.unavailableNote, ...assignTestId('filters-not-match-error'), children: "No settings found. Try adjusting the filter." })), !!availableBuilds.length && !selectedValidBuildKey && (_jsx(AppBuildSettingsError, { className: styles.unavailableNote, ...assignTestId('empty-filters-error'), children: "Specify the product build, OS, and architecture to view the settings configured across the assigned profiles" }))] }));
}
