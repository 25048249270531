// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settingFields__aaf > *:not(:last-child) {
  margin-bottom: calc(2 * var(--ring-unit));
}

.forcedCheckbox__f9d {
  margin-right: var(--ring-unit);
}

.contentFieldBlock__d04 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: nowrap;
}

.contentFieldBlockTitle__e82 {
  margin: 5px 0;
  font-weight: bold;
}

.contentFieldBlockDescription__f93 {
  font-size: var(--ring-font-size-smaller);
  color: var(--ring-secondary-color);
}

.contentFieldBlock__d04 > *:not(:last-child) {
  margin-right: var(--ring-unit);
}

.contentFieldBlock__d04 > *:last-child {
  text-align: right;
  margin-left: auto;
}

.contentFieldDialogSubheader__a0f {
  font-size: var(--ring-font-size-smaller);
  font-weight: normal;
  display: block;
}

.dialogHeaderButton__b9d {
  float: right;
}

.manualModeButton__f35 {
  color: var(--ring-secondary-color);
}

.smartModeButton__ed1 {
  color: var(--ring-link-color);
}

.helpIcon__b96 {
  margin-left: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/ide-plugins/new-auto-installed-plugins/fields/content-field.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;EAC3B,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,wCAAwC;EACxC,kCAAkC;AACpC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,wCAAwC;EACxC,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".settingFields > *:not(:last-child) {\n  margin-bottom: calc(2 * var(--ring-unit));\n}\n\n.forcedCheckbox {\n  margin-right: var(--ring-unit);\n}\n\n.contentFieldBlock {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  justify-content: flex-start;\n  width: 100%;\n  flex-wrap: nowrap;\n}\n\n.contentFieldBlockTitle {\n  margin: 5px 0;\n  font-weight: bold;\n}\n\n.contentFieldBlockDescription {\n  font-size: var(--ring-font-size-smaller);\n  color: var(--ring-secondary-color);\n}\n\n.contentFieldBlock > *:not(:last-child) {\n  margin-right: var(--ring-unit);\n}\n\n.contentFieldBlock > *:last-child {\n  text-align: right;\n  margin-left: auto;\n}\n\n.contentFieldDialogSubheader {\n  font-size: var(--ring-font-size-smaller);\n  font-weight: normal;\n  display: block;\n}\n\n.dialogHeaderButton {\n  float: right;\n}\n\n.manualModeButton {\n  color: var(--ring-secondary-color);\n}\n\n.smartModeButton {\n  color: var(--ring-link-color);\n}\n\n.helpIcon {\n  margin-left: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingFields": `settingFields__aaf`,
	"forcedCheckbox": `forcedCheckbox__f9d`,
	"contentFieldBlock": `contentFieldBlock__d04`,
	"contentFieldBlockTitle": `contentFieldBlockTitle__e82`,
	"contentFieldBlockDescription": `contentFieldBlockDescription__f93`,
	"contentFieldDialogSubheader": `contentFieldDialogSubheader__a0f`,
	"dialogHeaderButton": `dialogHeaderButton__b9d`,
	"manualModeButton": `manualModeButton__f35`,
	"smartModeButton": `smartModeButton__ed1`,
	"helpIcon": `helpIcon__b96`
};
export default ___CSS_LOADER_EXPORT___;
