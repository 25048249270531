// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageTitle__e80 {
  margin-bottom: 0;
}

.description__f7a {
  margin-top: var(--ring-unit);
}

.tabs__d12 {
  margin-top: calc(var(--ring-unit) * 3);
}

.banners__db5 {
  margin-top: calc(var(--ring-unit) * 2);
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);

  &:empty {
    display: none;
  }
}

.inactiveLabel__ef4 {
  margin-left: calc(var(--ring-unit) / 2);
  font-weight: 400;
}

.contentWrapper__e21 {
  margin-top: calc(var(--ring-unit) * 3);

  .tabs__d12 + & {
    margin-top: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-details-page.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;EACtC,aAAa;EACb,sBAAsB;EACtB,qBAAqB;;EAErB;IACE,aAAa;EACf;AACF;;AAEA;EACE,uCAAuC;EACvC,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;;EAEtC;IACE,aAAa;EACf;AACF","sourcesContent":[".pageTitle {\n  margin-bottom: 0;\n}\n\n.description {\n  margin-top: var(--ring-unit);\n}\n\n.tabs {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.banners {\n  margin-top: calc(var(--ring-unit) * 2);\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n\n  &:empty {\n    display: none;\n  }\n}\n\n.inactiveLabel {\n  margin-left: calc(var(--ring-unit) / 2);\n  font-weight: 400;\n}\n\n.contentWrapper {\n  margin-top: calc(var(--ring-unit) * 3);\n\n  .tabs + & {\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageTitle": `pageTitle__e80`,
	"description": `description__f7a`,
	"tabs": `tabs__d12`,
	"banners": `banners__db5`,
	"inactiveLabel": `inactiveLabel__ef4`,
	"contentWrapper": `contentWrapper__e21`
};
export default ___CSS_LOADER_EXPORT___;
