import { useEffect, useState } from 'react';
export var ScreenSize;
(function (ScreenSize) {
    ScreenSize[ScreenSize["MOBILE"] = 0] = "MOBILE";
    ScreenSize[ScreenSize["DESKTOP"] = 1] = "DESKTOP";
})(ScreenSize || (ScreenSize = {}));
export function useScreenSize() {
    const [screenSize, setScreenSize] = useState(getScreenSize());
    useEffect(() => {
        function handleWindowResize() {
            setScreenSize(getScreenSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [screenSize]);
    return screenSize;
}
const getScreenSize = () => {
    return window.innerWidth < 740 ? ScreenSize.MOBILE : ScreenSize.DESKTOP;
};
