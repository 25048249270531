import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-restricted-imports */
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import ringLinkStyles from '@jetbrains/ring-ui/components/link/link.css';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
import styles from './navigation-link.css';
export default function NavigationLink(props) {
    const { href, enableIsActive = true, secondary = false, className, ...rest } = props;
    return (_jsx(NavLink, { className: state => {
            return classNames(ringLinkStyles.link, typeof className === 'function' ? className(state) : className, {
                [ringLinkStyles.active]: state.isActive && enableIsActive,
                [styles.linkSecondary]: secondary
            });
        }, to: href, ...rest, ...assignTestId('ring-link', rest) }));
}
