// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usersLimit__bf7 + .usedBy__e0b {
  margin-left: calc(var(--ring-unit) * 1.5);
}

.usersData__aa5 + .notInUseWarning__c4d {
  margin-top: calc(var(--ring-unit) / 2);
}

.notInUseWarning__c4d {
  color: var(--ring-warning-color);
}

.usersLimit__bf7 + .overLimitError__c95 {
  margin-top: calc(var(--ring-unit) / 2);
}

.overLimitError__c95 {
  display: flex;
  align-items: center;
}

.overLimitIcon__b89 {
  color: var(--ring-error-color);
}

.overLimitErrorMsg__e3e {
  margin-left: calc(var(--ring-unit) / 2);
  color: var(--ring-error-color);
}

.actions__edb {
  margin-top: calc(var(--ring-unit) * 1.5);

  display: flex;
  gap: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/ai-constituent/ai-state-block/ai-enabled/ai-enabled.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,uCAAuC;EACvC,8BAA8B;AAChC;;AAEA;EACE,wCAAwC;;EAExC,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".usersLimit + .usedBy {\n  margin-left: calc(var(--ring-unit) * 1.5);\n}\n\n.usersData + .notInUseWarning {\n  margin-top: calc(var(--ring-unit) / 2);\n}\n\n.notInUseWarning {\n  color: var(--ring-warning-color);\n}\n\n.usersLimit + .overLimitError {\n  margin-top: calc(var(--ring-unit) / 2);\n}\n\n.overLimitError {\n  display: flex;\n  align-items: center;\n}\n\n.overLimitIcon {\n  color: var(--ring-error-color);\n}\n\n.overLimitErrorMsg {\n  margin-left: calc(var(--ring-unit) / 2);\n  color: var(--ring-error-color);\n}\n\n.actions {\n  margin-top: calc(var(--ring-unit) * 1.5);\n\n  display: flex;\n  gap: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"usersLimit": `usersLimit__bf7`,
	"usedBy": `usedBy__e0b`,
	"usersData": `usersData__aa5`,
	"notInUseWarning": `notInUseWarning__c4d`,
	"overLimitError": `overLimitError__c95`,
	"overLimitIcon": `overLimitIcon__b89`,
	"overLimitErrorMsg": `overLimitErrorMsg__e3e`,
	"actions": `actions__edb`
};
export default ___CSS_LOADER_EXPORT___;
