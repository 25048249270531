// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxContainer__a7c {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) / 2);
  padding: calc(var(--ring-unit) / 2);
  width: 100%;
  box-sizing: border-box;
}

.boxSettingsListContainer__f34 {
  display: flex;
  flex-direction: column;
}

.boxText__c84 {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  font-weight: 400;
  line-height: 20px;
  white-space: pre;
  word-wrap: break-word;
}

.showMoreBlock__d9b {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/settings-preview.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,mCAAmC;EACnC,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,WAAW;;EAEX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".boxContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) / 2);\n  padding: calc(var(--ring-unit) / 2);\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.boxSettingsListContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.boxText {\n  display: inline-block;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  max-width: 100%;\n  font-weight: 400;\n  line-height: 20px;\n  white-space: pre;\n  word-wrap: break-word;\n}\n\n.showMoreBlock {\n  width: 100%;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxContainer": `boxContainer__a7c`,
	"boxSettingsListContainer": `boxSettingsListContainer__f34`,
	"boxText": `boxText__c84`,
	"showMoreBlock": `showMoreBlock__d9b`
};
export default ___CSS_LOADER_EXPORT___;
