// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings__f61 {
  margin-top: calc(var(--ring-unit) * 3);
}

.settingsForm__a33 {
  margin-top: var(--ring-unit);
}

.toggleWrapper__a24 {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ai/ai-available/ai-available.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".settings {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.settingsForm {\n  margin-top: var(--ring-unit);\n}\n\n.toggleWrapper {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": `settings__f61`,
	"settingsForm": `settingsForm__a33`,
	"toggleWrapper": `toggleWrapper__a24`
};
export default ___CSS_LOADER_EXPORT___;
