// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.licenseBlock__ecb + .licenseBlock__ecb {
  margin-top: calc(var(--ring-unit) * 3);
}

.separator__ba0 {
  max-width: fit-content;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--ring-line-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/license-activation-page.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,+CAA+C;AACjD","sourcesContent":[".licenseBlock + .licenseBlock {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.separator {\n  max-width: fit-content;\n  padding-bottom: 30px;\n  border-bottom: 1px solid var(--ring-line-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"licenseBlock": `licenseBlock__ecb`,
	"separator": `separator__ba0`
};
export default ___CSS_LOADER_EXPORT___;
