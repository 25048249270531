// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__b76 > div > div {
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/common/dialogs/delete-provider-dialog/delete-provider-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB","sourcesContent":[".container > div > div {\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__b76`
};
export default ___CSS_LOADER_EXPORT___;
