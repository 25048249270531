// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table__f5d thead {
  display: none;
}

.table__f5d tbody tr {
  cursor: pointer;
}

.table__f5d tr:hover .removeButton__cbb {
  visibility: visible;
}

.firstCell__bf8.firstCell__bf8 {
  padding-left: var(--ring-unit);
}

/* For those rows which have a drag button */
.firstCell__bf8.firstCell__bf8:has(div button) {
  padding-left: calc(2.5 * var(--ring-unit));
  max-width: var(--ring-unit);
  white-space: normal;
}

.removeButtonCell__d75.removeButtonCell__d75 {
  display: flex;
  padding-right: calc(2.8 * var(--ring-unit));
}

.addButton__eba {
  margin-top: var(--ring-unit);
  margin-bottom: var(--ring-unit);
}

.removeButton__cbb {
  visibility: hidden;
  flex-direction: row-reverse;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/filters-editor/array-editor/array-editor.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA,4CAA4C;AAC5C;EACE,0CAA0C;EAC1C,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,2CAA2C;AAC7C;;AAEA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;AAC7B","sourcesContent":[".table thead {\n  display: none;\n}\n\n.table tbody tr {\n  cursor: pointer;\n}\n\n.table tr:hover .removeButton {\n  visibility: visible;\n}\n\n.firstCell.firstCell {\n  padding-left: var(--ring-unit);\n}\n\n/* For those rows which have a drag button */\n.firstCell.firstCell:has(div button) {\n  padding-left: calc(2.5 * var(--ring-unit));\n  max-width: var(--ring-unit);\n  white-space: normal;\n}\n\n.removeButtonCell.removeButtonCell {\n  display: flex;\n  padding-right: calc(2.8 * var(--ring-unit));\n}\n\n.addButton {\n  margin-top: var(--ring-unit);\n  margin-bottom: var(--ring-unit);\n}\n\n.removeButton {\n  visibility: hidden;\n  flex-direction: row-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `table__f5d`,
	"removeButton": `removeButton__cbb`,
	"firstCell": `firstCell__bf8`,
	"removeButtonCell": `removeButtonCell__d75`,
	"addButton": `addButton__eba`
};
export default ___CSS_LOADER_EXPORT___;
