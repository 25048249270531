import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import RegisteredInput from '@app/common/hook-form/registered-input';
import { Required } from '@app/common/form/important-asterisk';
function XpathField({ settingIndex, specsIndex }) {
    const { control } = useFormContext();
    return (_jsx(RegisteredInput, { label: Required('XPath'), size: Size.FULL, name: `content.${settingIndex}.specs.${specsIndex}.xpath`, control: control, autoComplete: "off" }));
}
export default XpathField;
