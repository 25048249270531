// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scheduledSync__b75 svg {
  color: var(--ring-success-color);
}
`, "",{"version":3,"sources":["webpack://./src/modules/users/id-provider/idp-sync-users-button.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC","sourcesContent":[".scheduledSync svg {\n  color: var(--ring-success-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scheduledSync": `scheduledSync__b75`
};
export default ___CSS_LOADER_EXPORT___;
