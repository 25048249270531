// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentWrapper__b12 {
  width: calc(var(--ring-unit) * 75);
}

.providerLabel__c18 {
  color: var(--ring-secondary-color);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.providerLabel__c18 + .select__da1 {
  margin-top: calc(var(--ring-unit) / 2);
}

.select__da1 + .limit__ea3 {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.modelsTable__ab2 {
  padding-bottom: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/common/dialogs/ai-settings-dialog/ai-settings-dialog.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;EAClC,wCAAwC;EACxC,2CAA2C;AAC7C;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".contentWrapper {\n  width: calc(var(--ring-unit) * 75);\n}\n\n.providerLabel {\n  color: var(--ring-secondary-color);\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n}\n\n.providerLabel + .select {\n  margin-top: calc(var(--ring-unit) / 2);\n}\n\n.select + .limit {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.modelsTable {\n  padding-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `contentWrapper__b12`,
	"providerLabel": `providerLabel__c18`,
	"select": `select__da1`,
	"limit": `limit__ea3`,
	"modelsTable": `modelsTable__ab2`
};
export default ___CSS_LOADER_EXPORT___;
