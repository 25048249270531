import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import download from '@jetbrains/icons/download';
import info from '@jetbrains/icons/info';
import bell from '@jetbrains/icons/bell';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useSelector } from 'react-redux';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import classNames from 'classnames';
import { isFuture } from 'date-fns';
import { isAppChanged } from '@app/common/store/profiles/selectors';
import ProductLogo from '@common/product-logo/product-logo';
import { SettingLayout } from '../../common/setting-layout';
import { useProfilesParams } from '../../context/profiles-params-context';
import { ApplicationVersionsDialog } from '../application-versions-dialog';
import styles from './product-box.css';
const getDescription = (include, exclude) => {
    if (exclude.length === 0) {
        return 'Including all';
    }
    if (include.length === 0) {
        return 'Excluding all';
    }
    return `Excluding ${exclude.map(item => item.build)}`;
};
export function ProductBox({ item, selectable, className }) {
    const { product, productName, notifications, exclude, include, autoinstalled } = item;
    const hasFutureNotifications = notifications.some(it => isFuture(it.dueDate));
    const [selectedProduct, setSelectedProduct] = useState();
    const isChanged = useSelector(state => isAppChanged(state, product));
    const { focusedApp, setFocusedApp, clearParams } = useProfilesParams();
    const isFocused = (focusedApp === null || focusedApp === void 0 ? void 0 : focusedApp.id) === product;
    const triggerFocusedApp = (application) => {
        if (isFocused) {
            clearParams();
            return;
        }
        setFocusedApp(application);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(SettingLayout, { icons: [
                    {
                        glyph: info,
                        onClick: () => setSelectedProduct(product),
                        title: 'Application versions'
                    }
                ], selected: isFocused, variant: "flat", onTileClick: selectable ? () => triggerFocusedApp({ id: product }) : undefined, isChanged: isChanged, className: classNames(styles.wrapper, className), children: [_jsx(ProductLogo, { product: product, className: styles.productLogo }), _jsxs("div", { className: styles.textContainer, children: [_jsx(Text, { children: productName }), _jsxs("div", { className: styles.infoContainer, children: [_jsx(Text, { className: styles.excludeInfo, info: true, children: getDescription(include, exclude) }), hasFutureNotifications && (_jsx(Tooltip, { title: "Some builds of this application will become unavailable in the future due to a scheduled restriction", children: _jsx(Icon, { glyph: bell, className: styles.notificationIcon }) })), autoinstalled && (_jsx(Tooltip, { title: `${product} ${autoinstalled} will be installed for devs that don't have any ${product} yet installed`, children: _jsx(Icon, { glyph: download }) }))] })] })] }), selectedProduct && (_jsx(ApplicationVersionsDialog, { onReject: () => setSelectedProduct(undefined), item: selectedProduct }))] }));
}
