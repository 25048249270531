import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import Icon from '@jetbrains/ring-ui/components/icon';
import addIcon from '@jetbrains/icons/add';
import Button from '@jetbrains/ring-ui/components/button/button';
import { BasicTableView } from '@common/tables/basic-table-view';
import styles from '../styles.css';
import { DEFAULT_PRESET_VALUES, OPTIONAL_MODELS } from '../../constants';
import { DeploymentName } from './cells/deployment-name';
import { RemoveOptionalModel } from './cells/remove-optional-model';
const baseColumns = [
    {
        id: 'id',
        title: 'ID'
    },
    {
        id: 'modelName',
        title: 'Model name'
    }
];
export function OpenAiModelsTable({ models, onModelChange, preset }) {
    const optionalModelsToAdd = useMemo(() => {
        if (!models.length) {
            return [];
        }
        return OPTIONAL_MODELS.filter(optionalModel => !models.some(model => model.id === optionalModel.id));
    }, [models]);
    const handleRemoveModel = useCallback((id) => {
        onModelChange(models.filter(it => it.id !== id));
    }, [models, onModelChange]);
    const handleUpdateModel = useCallback((model) => {
        onModelChange(models.map(it => (it.id === model.id ? model : it)));
    }, [models, onModelChange]);
    const handleAddOptionalModel = useCallback(() => {
        onModelChange(models.concat(optionalModelsToAdd));
    }, [models, onModelChange, optionalModelsToAdd]);
    const handleAddDefaultModels = useCallback(() => {
        onModelChange(DEFAULT_PRESET_VALUES[preset].models);
    }, [onModelChange, preset]);
    const columns = useMemo(() => {
        const removeOptionalModelColumn = [
            {
                id: 'removeOptionalModel',
                getValue: ({ id }) => (_jsx(RemoveOptionalModel, { id: id, callback: () => handleRemoveModel(id) }))
            }
        ];
        if (preset !== 'AZURE') {
            return baseColumns.concat(removeOptionalModelColumn);
        }
        const azureColumns = [
            {
                id: 'deploymentName',
                title: 'Deployment name',
                getValue: (model) => (_jsx(DeploymentName, { model: model, onModelChange: handleUpdateModel }))
            }
        ];
        return baseColumns.concat(azureColumns).concat(removeOptionalModelColumn);
    }, [handleRemoveModel, handleUpdateModel, preset]);
    return (_jsxs("div", { children: [_jsx(BasicTableView, { tableWrapperClassName: styles.modelsTable, columns: columns, data: models, selectable: false, renderEmpty: () => (_jsxs(Text, { info: true, children: ["No models yet", ' ', _jsxs(Button, { text: true, onClick: handleAddDefaultModels, children: [_jsx(Icon, { glyph: addIcon }), " Add"] })] })) }), !!optionalModelsToAdd.length && (_jsxs(Button, { text: true, inline: true, onClick: handleAddOptionalModel, children: [_jsx(Icon, { glyph: addIcon }), ' ', optionalModelsToAdd.length === 1 ? 'Add optional model' : 'Add optional models'] }))] }));
}
