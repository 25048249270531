export const getBuildsListOptions = (builds) => {
    const structuredBuilds = buildTreeStructure(assignParentBuild(builds));
    return structuredBuilds;
};
function assignParentBuild(dataset) {
    const buildsWithParents = dataset.reverse().map(({ build, info }) => {
        var _a;
        const potentialParent = build.slice(0, build.lastIndexOf('.'));
        const parent = (_a = dataset.find(item => {
            if (build.slice(build.lastIndexOf('.')) === '.*') {
                return item.build === `${potentialParent.slice(0, potentialParent.lastIndexOf('.'))}.*`;
            }
            return item.build === `${potentialParent}.*`;
        })) === null || _a === void 0 ? void 0 : _a.build;
        if (parent) {
            return { build, label: info, id: build, items: [], parent };
        }
        return { build, id: build, label: info, items: [] };
    });
    const result = buildsWithParents.reduce((res, item) => {
        const children = buildsWithParents.filter(child => child.parent === item.build);
        if (item.parent && children.length === 1 && children[0].build.includes('*')) {
            const node = res.find(child => child.parent === item.build);
            if (node) {
                return res.map(child => child.parent === item.build ? { ...child, parent: item.parent } : child);
            }
            return [...res, { ...children[0], parent: item.parent }];
        }
        return [...res, item];
    }, []);
    return result.reverse();
}
function buildTreeStructure(builds) {
    const result = [];
    const nodeMap = builds.reduce((acc, item) => {
        acc[item.id] = { ...item, items: [] };
        return acc;
    }, {});
    builds.forEach(item => {
        if (item.parent === undefined) {
            result.push(nodeMap[item.id]);
        }
        else {
            nodeMap[item.parent].items.push(nodeMap[item.id]);
        }
    });
    return result;
}
