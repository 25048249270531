import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { format, isPast, parseISO } from 'date-fns';
import { H4 } from '@jetbrains/ring-ui/components/heading/heading';
import Text from '@jetbrains/ring-ui/components/text/text';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import classNames from 'classnames';
import Icon, { Size } from '@jetbrains/ring-ui/components/icon/icon';
import bell from '@jetbrains/icons/bell';
import styles from '@modules/applications/builds-list/nested-data-list/nested-data-list.css';
export function RestrictionInfo({ notifications }) {
    const sortedNotifications = useMemo(() => {
        return notifications.sort((notificationA, notificationB) => getTime(notificationA) - getTime(notificationB));
        function getTime(notification) {
            return parseISO(notification.dueDate).getTime();
        }
    }, [notifications]);
    const restrictionEffectiveDate = useMemo(() => {
        var _a;
        return parseISO((_a = sortedNotifications[0]) === null || _a === void 0 ? void 0 : _a.dueDate);
    }, [sortedNotifications]);
    const isInPast = useMemo(() => !!restrictionEffectiveDate && isPast(restrictionEffectiveDate), [restrictionEffectiveDate]);
    const text = useMemo(() => {
        if (!restrictionEffectiveDate) {
            return '';
        }
        if (isPast(restrictionEffectiveDate)) {
            return `Disabled`;
        }
        return `Will become disabled on ${format(restrictionEffectiveDate, 'd MMM yyyy')}`;
    }, [restrictionEffectiveDate]);
    const tooltip = useMemo(() => {
        if (!notifications.length) {
            return '';
        }
        return (_jsxs(_Fragment, { children: [_jsx(H4, { children: getTooltipHeader() }), _jsx("div", { className: styles.restrictionsDescriptions, children: notifications.map(notification => (_jsx("div", { children: _jsxs(Text, { info: true, size: "s", children: [_jsxs("strong", { children: [format(notification.dueDate, 'd MMM yyyy'), ': '] }), notification.description] }) }))) }), !isInPast && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.restrictionsNoteSeparator }), _jsx("div", { className: styles.restrictionsDescriptions, children: _jsxs(Text, { size: "s", info: true, children: ["Those users who have this build installed on a machine, may continue using it until", ' ', format(restrictionEffectiveDate, 'd MMM yyyy'), ". But the new installations of a restricted build are not possible."] }) })] }))] }));
        function getTooltipHeader() {
            if (isInPast) {
                return notifications.length > 1
                    ? 'Restrictions with past due date'
                    : 'Restriction with past due date';
            }
            return notifications.length > 1 ? 'Upcoming restrictions' : 'Upcoming restriction';
        }
    }, [notifications, isInPast, restrictionEffectiveDate]);
    return (_jsx(Tooltip, { title: tooltip, children: _jsxs(Text, { size: "s", className: classNames({
                [styles.restrictionPast]: isInPast,
                [styles.restrictionFuture]: !isInPast
            }), children: [_jsx(Icon, { className: styles.restrictionIcon, glyph: bell, size: Size.Size12 }), text] }) }));
}
