import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import styles from './content-field.css';
export default function TypeDescription() {
    const { watch } = useFormContext();
    const watchType = watch('type');
    const watchSource = watch('source');
    if (watchType === 'channel' && watchSource === 'MARKETPLACE') {
        return (_jsx("div", { className: styles.contentFieldBlockDescription, children: "IDE will keep being updated with the newest suitable plugin from marketplace. Users can manually override this channel in IDE unless this plugin is marked as forced." }));
    }
    if (watchType === 'channel') {
        return (_jsx("div", { className: styles.contentFieldBlockDescription, children: "IDE will keep being updated with the newest suitable plugin from either Stable or additional custom channel. Users can manually override this channel in IDE unless this plugin is marked as forced." }));
    }
    return (_jsx("div", { className: styles.contentFieldBlockDescription, children: "The exact version of the plugin will be provided to all matching IDEs and enforced as the only available version of the plugin. Users will not be be able to uninstall, upgrade, downgrade or subscribe to any channel for possible updates." }));
}
