import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import DropdownMenu from '@jetbrains/ring-ui/components/dropdown-menu/dropdown-menu';
import { Type } from '@jetbrains/ring-ui/components/list/consts';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EditProfileButton } from '@app/pages/profiles/profile-details/profile-ide-provisioner/common/edit-profile-button';
import { useCodeWithMeConstituent } from '@app/common/queries/code-with-me';
import { useIdeProvisionerConstituent } from '@app/common/queries/ide-provisioner';
import { useAiConstituent } from '@app/common/queries/ai-enterprise';
import { getProfileConstituents } from '@app/common/store/profiles/selectors';
import auth from '@app/common/auth/auth';
import styles from './profile-dropdown-menu.css';
export function ProfileDropdownMenu(props) {
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    const navigate = useNavigate();
    const { editProfile, deleteProfile, profileId, onIdeProvisionerActivationChange, onAiActivationChange, onCwmActivationChange } = props;
    const handleProfileHistoryClick = useCallback(() => navigate(`/profiles/${profileId}/history`), [navigate, profileId]);
    const handleLicenseAndActivationClick = useCallback(() => {
        navigate(`/configuration/license-activation`);
    }, [navigate]);
    const constituents = useSelector(getProfileConstituents);
    const { data: cwmResponse, isLoading: isCwmLoading } = useCodeWithMeConstituent();
    const { data: ideProvisionerResponse, isLoading: isIdePELoading } = useIdeProvisionerConstituent();
    const { data: aiResponse, isLoading: isAiLoading } = useAiConstituent();
    const showCwmBtn = (cwmResponse === null || cwmResponse === void 0 ? void 0 : cwmResponse.status) === 'enabled' && !isCwmLoading;
    const showIdeProvisionerBtn = (ideProvisionerResponse === null || ideProvisionerResponse === void 0 ? void 0 : ideProvisionerResponse.status) === 'enabled' && !isIdePELoading;
    const showAiBtn = !offlineModeEnabled && (aiResponse === null || aiResponse === void 0 ? void 0 : aiResponse.status) === 'enabled' && !isAiLoading;
    const handleEditProfileClick = useCallback(() => editProfile(), [editProfile]);
    const handleDeleteClick = useCallback(() => deleteProfile(), [deleteProfile]);
    const handleActivateCwm = useCallback(() => onCwmActivationChange(true), [onCwmActivationChange]);
    const handleDeactivateCwm = useCallback(() => onCwmActivationChange(false), [onCwmActivationChange]);
    const handleActivateIdeProvisioner = useCallback(() => onIdeProvisionerActivationChange(true), [onIdeProvisionerActivationChange]);
    const handleDeactivateIdeProvisioner = useCallback(() => onIdeProvisionerActivationChange(false), [onIdeProvisionerActivationChange]);
    const handleActivateAi = useCallback(() => onAiActivationChange(true), [onAiActivationChange]);
    const handleDeactivateAi = useCallback(() => onAiActivationChange(false), [onAiActivationChange]);
    const dropdownItems = [
        {
            key: 'edit-profile',
            label: 'Edit profile',
            onClick: handleEditProfileClick
        },
        {
            key: 'profile-history',
            label: 'View profile history',
            onClick: handleProfileHistoryClick
        },
        {
            key: 'delete-profile',
            label: 'Delete',
            onClick: handleDeleteClick,
            className: styles.danger
        },
        showCwmBtn || showIdeProvisionerBtn || showAiBtn ? { rgItemType: Type.SEPARATOR } : undefined,
        showCwmBtn && constituents.cwm.isEnabled
            ? { key: 'disable-cwm', label: 'Disable Code With Me', onClick: handleDeactivateCwm }
            : undefined,
        showCwmBtn && !constituents.cwm.isEnabled
            ? { key: 'enable-cwm', label: 'Activate Code With Me', onClick: handleActivateCwm }
            : undefined,
        showIdeProvisionerBtn && constituents.idep.isEnabled
            ? {
                key: 'disable-ide-provisioner',
                label: 'Disable IDE Provisioner',
                onClick: handleDeactivateIdeProvisioner
            }
            : undefined,
        showIdeProvisionerBtn && !constituents.idep.isEnabled
            ? {
                key: 'enable-ide-provisioner',
                label: 'Activate IDE Provisioner',
                onClick: handleActivateIdeProvisioner
            }
            : undefined,
        showAiBtn && constituents.ai.isEnabled
            ? { key: 'disable-ai', label: 'Disable AI Enterprise', onClick: handleDeactivateAi }
            : undefined,
        showAiBtn && !constituents.ai.isEnabled
            ? { key: 'enable-ai', label: 'Activate AI Enterprise', onClick: handleActivateAi }
            : undefined,
        {
            key: 'bottom-separator',
            rgItemType: Type.SEPARATOR
        },
        {
            key: 'license-activation',
            label: 'Go to License & Activation',
            onClick: handleLicenseAndActivationClick
        }
    ];
    const filtered = dropdownItems.filter((item) => Boolean(item));
    return (_jsx(DropdownMenu, { "data-test": "edit-delete-dropdown-button", anchor: _jsx(EditProfileButton, {}), data: filtered }));
}
