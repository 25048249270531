// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__bb6 {
  .messageRow__ebf {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    &:first-child {
      margin-top: calc(var(--ring-unit) * 1.5);
    }
  }

  .messageRow__ebf + .messageRow__ebf {
    margin-top: var(--ring-unit);
  }

  .messageNum__b1e + .message__e65 {
    margin-left: var(--ring-unit);
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/constituents/widget/badges/popup-stepped-message/popup-stepped-message.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,iBAAiB;IACjB,uBAAuB;;IAEvB;MACE,wCAAwC;IAC1C;EACF;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,6BAA6B;EAC/B;AACF","sourcesContent":[".wrapper {\n  .messageRow {\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: flex-start;\n\n    &:first-child {\n      margin-top: calc(var(--ring-unit) * 1.5);\n    }\n  }\n\n  .messageRow + .messageRow {\n    margin-top: var(--ring-unit);\n  }\n\n  .messageNum + .message {\n    margin-left: var(--ring-unit);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__bb6`,
	"messageRow": `messageRow__ebf`,
	"messageNum": `messageNum__b1e`,
	"message": `message__e65`
};
export default ___CSS_LOADER_EXPORT___;
