import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CopiedCode from '@common/clipboard/copied-code/copied-code';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { SettingsGroup } from '../settings-group/settings-group';
import styles from './build-settings-json-view.css';
export function BuildSettingsJsonView(props) {
    const { appType, productBuild, ideSettingsJSON, toolboxAppConfigJSON, className } = props;
    const tbaConfigName = `tba-${appType}-${productBuild.buildNumber}-${productBuild.buildOs}-${productBuild.buildArchitecture}.json`;
    const ideConfigName = `ide-${appType}-${productBuild.buildNumber}-${productBuild.buildOs}-${productBuild.buildArchitecture}.json`;
    return (_jsxs("div", { className: className, ...assignTestId('build-settings-json-view'), children: [_jsx(SettingsGroup, { groupName: "Toolbox Configuration", children: _jsx(CopiedCode, { text: toolboxAppConfigJSON, downloadFilename: tbaConfigName }) }), ideSettingsJSON && (_jsx(SettingsGroup, { groupName: "IDE Configuration", className: styles.ideSettings, children: _jsx(CopiedCode, { text: ideSettingsJSON, downloadFilename: ideConfigName }) }))] }));
}
