import { useMutation, useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { getCurrentUser } from '@api/users';
import { getIdePState } from '@api/ide-provisioner';
import { deletePlugin, deletePluginVersion, getExistingChannels, getPlugins, getPluginById, synchronizePluginsState, updateVersionPluginChannels, updatePluginPrivacy, updatePluginVersionExpirationDate, uploadPlugin, getPluginVersions, getPluginChannels, getAuditLogs } from '../../api/plugins';
import { fetchPluginDownloads, fetchPluginDownloadsAnalytics } from '../../api/plugins-analytics';
import { formatApiError } from '../../api/errors';
import queryClient, { DEFAULT_STALE_TIME } from './query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from './formatters/alerts';
import { buildDeletePluginVersionsErrorMessage, buildDeletePluginVersionsSuccessMessage, PluginsMutationError } from './formatters/plugins';
export const keys = {
    all: ['plugins'],
    channels: () => ['plugins', 'channels'],
    pluginChannels: (id) => ['plugin', id, 'channels'],
    allDownloadsAnalytics: () => ['plugins', 'downloads-analytics'],
    downloadsAnalytics: (range) => ['plugins', 'downloads-analytics', range],
    list: () => ['plugins', 'list'],
    listCtx: () => ({
        paginated: (page, vendorOrPluginName) => ['plugins', 'list', 'paginated', page, vendorOrPluginName]
    }),
    allDetails: () => ['plugins', 'detail'],
    detail: (id) => ['plugins', 'detail', id],
    detailCtx: (id) => ({
        sorted: (sortProps) => ['plugins', 'detail', id, 'sorted', sortProps],
        allDownloads: () => ['plugins', 'detail', id, 'downloads'],
        downloads: (range) => ['plugins', 'detail', id, 'downloads', range]
    }),
    restrictedMode: ['restricted-mode'],
    history: (params) => ['plugins-history', { ...params }]
};
export function getPlugin(key, source, label, version = '') {
    return { key, source, label, version };
}
const pluginVersionsQuery = (id, sortProps) => ({
    queryKey: keys.detailCtx(id).sorted(sortProps),
    queryFn: () => getPluginVersions(id, sortProps)
});
export function usePluginVersions(id, sortProps, enabled = true) {
    return useQuery({
        ...pluginVersionsQuery(id, sortProps),
        staleTime: DEFAULT_STALE_TIME,
        keepPreviousData: true,
        enabled,
        meta: getQueryAlertMessages(error => formatApiError(error, `Failed to load plugin '${id}'`))
    });
}
const pluginChannelsQuery = (id) => ({
    queryKey: keys.pluginChannels(id),
    queryFn: () => getPluginChannels(id)
});
export function usePluginChannels(id, enabled = true) {
    return useQuery({
        ...pluginChannelsQuery(id),
        staleTime: DEFAULT_STALE_TIME,
        keepPreviousData: true,
        enabled,
        meta: getQueryAlertMessages(error => formatApiError(error, `Failed to load plugin '${id}'`))
    });
}
export function ensurePluginQueryData(id) {
    return queryClient.ensureQueryData({
        queryKey: keys.detail(id),
        queryFn: () => getPluginById(id)
    });
}
export function usePluginDownloads(pluginId, range, enabled = true) {
    const { data, isError, isFetching } = useQuery({
        queryKey: keys.detailCtx(pluginId).downloads(range),
        queryFn: () => fetchPluginDownloads(pluginId, range),
        enabled,
        staleTime: DEFAULT_STALE_TIME,
        placeholderData: { entries: [] },
        meta: getQueryAlertMessages(error => formatApiError(error, `Failed to load downloads for the plugin '${pluginId}'`))
    });
    return { data, isError, isFetching };
}
export function usePluginDownloadsAnalytics(range) {
    return useQuery({
        queryKey: keys.downloadsAnalytics(range),
        queryFn: () => fetchPluginDownloadsAnalytics(range),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load plugin downloads analytics'))
    });
}
export function usePaginatedPlugins(page, vendorOrPluginName) {
    return useQuery({
        queryKey: keys.listCtx().paginated(page, vendorOrPluginName),
        queryFn: () => getPlugins(page, vendorOrPluginName),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load plugins'))
    });
}
const channelsQueryOptions = {
    queryKey: keys.channels(),
    queryFn: getExistingChannels,
    placeholderData: [],
    staleTime: DEFAULT_STALE_TIME,
    meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load all plugin channels'))
};
export function useExistingPluginChannels() {
    return useQuery(channelsQueryOptions);
}
export function ensurePluginChannels() {
    return queryClient.ensureQueryData(channelsQueryOptions);
}
export function useTbePlugins(enabled = true) {
    return useQuery({
        queryKey: keys.list(),
        queryFn: () => getPlugins(undefined, undefined, 1000).then(data => { var _a; return (_a = data.items) === null || _a === void 0 ? void 0 : _a.map(plugin => getPlugin(plugin.id, 'TBE', plugin.name)); }),
        staleTime: DEFAULT_STALE_TIME,
        placeholderData: [],
        enabled,
        meta: getQueryAlertMessages(error => formatApiError(error, "Failed to load plugins from organization's repository"))
    });
}
export function useUpdatePluginChannelsMutation() {
    return useMutation({
        mutationFn: ({ pluginId, pluginVersion, newChannels }) => {
            return updateVersionPluginChannels(pluginId, pluginVersion, newChannels);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(keys.channels());
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to update plugin channels'), () => 'Plugin channels updated')
    });
}
export function useDeletePluginVersionsMutation() {
    return useMutation({
        mutationFn: async ({ id, versions }) => {
            const result = await Promise.allSettled(versions.map(version => {
                return deletePluginVersion(id, version.version);
            }));
            if (result.some(x => x.status === 'rejected')) {
                throw new PluginsMutationError('Failed to delete plugin versions', result);
            }
            return result;
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(keys.all);
        },
        onError: async (err) => {
            const someFilesWereSuccessfullyUploaded = err.mutationResult.some(x => x.status === 'fulfilled');
            if (someFilesWereSuccessfullyUploaded) {
                await queryClient.invalidateQueries(keys.all);
            }
        },
        meta: getMutationAlertMessages(({ mutationResult }, vars) => {
            return buildDeletePluginVersionsErrorMessage(mutationResult, vars.versions);
        }, (_, vars) => buildDeletePluginVersionsSuccessMessage(vars.versions))
    });
}
export function useDeletePluginMutation() {
    return useMutation({
        mutationFn: (id) => deletePlugin(id),
        onSuccess: async (_, id) => {
            const detailKey = keys.detail(id);
            await queryClient.invalidateQueries(keys.all, {
                predicate: query => {
                    return !isEqual(detailKey, query.queryKey.slice(0, detailKey.length));
                }
            });
            await queryClient.removeQueries({ queryKey: detailKey });
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to remove plugin'), () => 'Plugin removed')
    });
}
export function usePluginPrivacyMutation() {
    return useMutation({
        mutationFn: async (plugin) => updatePluginPrivacy(plugin.id, plugin.isPrivate),
        onSuccess: async (data, plugin) => {
            queryClient.setQueryData(keys.detail(plugin.id), data);
            const allDetailsKey = keys.allDetails();
            await queryClient.invalidateQueries(keys.all, {
                predicate: query => {
                    return !isEqual(query.queryKey.slice(0, allDetailsKey.length), allDetailsKey);
                }
            });
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to update plugin'), () => 'Plugin updated')
    });
}
export function useUploadPluginsMutation() {
    return useMutation({
        mutationFn: async ({ file, channels, expirationDate, onPluginLoadingProgressChanged }) => {
            return uploadPlugin(file, channels, (progress) => {
                return onPluginLoadingProgressChanged(file, progress);
            }, expirationDate);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(keys.all);
        }
    });
}
export function useExpirationDateMutation() {
    return useMutation({
        mutationFn: ({ pluginId, pluginVersion, expirationDate }) => updatePluginVersionExpirationDate(pluginId, pluginVersion, expirationDate),
        onSuccess: async () => {
            await queryClient.invalidateQueries(keys.all);
        },
        meta: getMutationAlertMessages((_data, variables) => {
            return `Failed to update an expiration date of the "${variables.pluginVersion}" version`;
        }, () => 'Expiration date updated')
    });
}
export function useSynchronizePluginsStateMutation() {
    return useMutation({
        mutationFn: synchronizePluginsState,
        onSuccess: async () => {
            await queryClient.invalidateQueries(keys.all);
        },
        meta: getMutationAlertMessages(() => 'Failed to synchronize plugins', () => 'Successfully synchronized plugins')
    });
}
export async function invalidatePluginDownloads(pluginId) {
    await queryClient.invalidateQueries(keys.detailCtx(pluginId).allDownloads());
    await queryClient.invalidateQueries(keys.allDownloadsAnalytics());
}
export function usePluginRepositoryRestrictedMode() {
    return useQuery({
        queryKey: keys.restrictedMode,
        queryFn: () => Promise.all([getCurrentUser(), getIdePState()]),
        select: data => {
            var _a;
            const [user, idep] = data;
            const restrictedModePlugins = ((_a = user.features.pluginRepositoryRestrictedPlugins) !== null && _a !== void 0 ? _a : []);
            return { isRestrictedMode: idep.status !== 'enabled', restrictedModePlugins };
        }
    });
}
export function usePluginsAuditLogs(params) {
    return useQuery({
        queryKey: keys.history(params),
        queryFn: () => getAuditLogs(params)
    });
}
