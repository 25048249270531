import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { noop } from 'lodash';
import RingFieldsList from '@app/common/form/ring-fields-list';
import ReplaceTextFields from './replace-text-fields';
import SetAttributeFields from './set-attribute-fields';
import ReplaceChildFields from './replace-child-fields';
import ActionField from './action-field';
import XpathField from './xpath-field';
function XpathFields({ settingIndex, specsIndex, onActionUpdate = noop }) {
    const { watch } = useFormContext();
    const watchAction = watch(`content.${settingIndex}.specs.${specsIndex}.action`);
    return (_jsxs(RingFieldsList, { children: [_jsx(XpathField, { settingIndex: settingIndex, specsIndex: specsIndex }), _jsx(ActionField, { settingIndex: settingIndex, specsIndex: specsIndex, onActionUpdate: onActionUpdate }), watchAction === 'replace-text' && (_jsx(ReplaceTextFields, { settingIndex: settingIndex, specsIndex: specsIndex })), watchAction === 'set-attribute' && (_jsx(SetAttributeFields, { settingIndex: settingIndex, specsIndex: specsIndex })), watchAction === 'replace-child' && (_jsx(ReplaceChildFields, { settingIndex: settingIndex, specsIndex: specsIndex }))] }));
}
export default XpathFields;
