import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import Text from '@jetbrains/ring-ui/components/text/text';
import warningIcon from '@jetbrains/icons/warning';
import Icon from '@jetbrains/ring-ui/components/icon';
import List from '@jetbrains/ring-ui/components/list/list';
import { noop } from 'lodash';
import { useAssignGroupToProfileMutation, useRemoveGroupFromProfile } from '@app/common/queries/groups';
import { fetchUserData, useAddProfileToUsersMutation, useUnAssignUsersMutation } from '@app/common/queries/users';
import { Collapsible } from '@app/common/collapsible/collapsible';
import { AssignButton } from './assign-button';
import { GroupDetails } from './group-details';
import styles from './assign-user-dialog.css';
function UnassignUserWithGroupDialog({ profileName, profileId, userName, onReject, groups, userId }) {
    const [assignedGroups, setAssignedGroups] = useState(groups);
    const [enableUserAssignment, setEnableUserAssignment] = useState(false);
    const [isAssignedDirectly, setIsAssignedDirectly] = useState(false);
    const { mutateAsync: assignGroupMutation } = useAssignGroupToProfileMutation();
    const { mutateAsync: unassignGroupMutation } = useRemoveGroupFromProfile();
    const { mutateAsync: unassignMutation } = useUnAssignUsersMutation();
    const mutation = useAddProfileToUsersMutation(profileName, [userName]);
    const handleUnassignGroup = useCallback((id) => {
        unassignGroupMutation({ profileId, groupId: id })
            .then(() => setAssignedGroups(prev => prev.filter(group => group.id !== id)))
            .then(() => {
            if (assignedGroups.length === 1) {
                fetchUserData(userId).then(data => {
                    const isAssigned = data === null || data === void 0 ? void 0 : data.profiles.map(({ id }) => id).includes(profileId);
                    setIsAssignedDirectly(isAssigned);
                    setEnableUserAssignment(isAssigned);
                });
            }
        })
            .catch(noop);
    }, [assignedGroups.length, profileId, unassignGroupMutation, userId]);
    const handleAssignGroup = useCallback((id) => {
        var _a;
        const group = (_a = groups.find(group => group.id === id)) !== null && _a !== void 0 ? _a : [];
        assignGroupMutation({ profileId, groupId: id })
            .then(() => setAssignedGroups(prev => prev.concat(group)))
            .then(() => {
            if (assignedGroups.length !== 1) {
                setEnableUserAssignment(false);
            }
        })
            .catch(noop);
    }, [assignGroupMutation, assignedGroups.length, groups, profileId]);
    const handleGroupEnterClick = useCallback((event, id) => {
        if (event.key === 'Enter') {
            if (assignedGroups.find(item => item.id === id) === undefined) {
                handleAssignGroup(id);
            }
            else {
                handleUnassignGroup(id);
            }
        }
    }, [assignedGroups, handleAssignGroup, handleUnassignGroup]);
    const handleAssignUser = useCallback((id) => {
        mutation
            .mutateAsync({ users: [{ id }], profileId })
            .then(() => setIsAssignedDirectly(true))
            .catch(noop);
    }, [mutation, profileId]);
    const handleUserEnterClick = useCallback((event, id) => {
        if (event.key === 'Enter') {
            handleAssignUser(id);
        }
    }, [handleAssignUser]);
    const handleUnassignUser = useCallback((id) => {
        unassignMutation({
            profileId,
            usersList: [{ id }]
        })
            .then(() => setIsAssignedDirectly(false))
            .catch(noop);
    }, [profileId, unassignMutation]);
    const groupsData = useMemo(() => {
        var _a;
        return [
            {
                key: 'title',
                label: 'Groups',
                rgItemType: List.ListProps.Type.TITLE,
                className: styles.listTitle
            }
        ]
            .concat((_a = groups.map(group => ({
            key: `group-${group.id}`,
            rgItemType: List.ListProps.Type.CUSTOM,
            template: (_jsxs("div", { className: styles.listItem, onKeyDown: e => handleGroupEnterClick(e, group.id), role: "button", tabIndex: 0, children: [_jsx(Collapsible, { title: _jsx(Text, { children: group.name }), primary: true, children: _jsx(GroupDetails, { groupId: group.id }) }), _jsx(AssignButton, { assigned: assignedGroups.map(({ id }) => id).includes(group.id), onAssign: () => handleAssignGroup(group.id), onUnAssign: () => handleUnassignGroup(group.id), canUnassign: true })] }))
        }))) !== null && _a !== void 0 ? _a : [])
            .concat(enableUserAssignment
            ? {
                key: `direct`,
                rgItemType: List.ListProps.Type.CUSTOM,
                template: (_jsxs("div", { className: styles.listItem, onKeyDown: e => handleUserEnterClick(e, userId), role: "button", tabIndex: 0, children: [_jsx("div", { className: styles.userInfo, children: _jsx(Text, { children: "Assigned directly" }) }), _jsx(AssignButton, { assigned: isAssignedDirectly, onAssign: () => handleAssignUser(userId), onUnAssign: () => handleUnassignUser(userId), canUnassign: true })] }))
            }
            : []);
    }, [
        assignedGroups,
        enableUserAssignment,
        groups,
        handleAssignGroup,
        handleAssignUser,
        handleGroupEnterClick,
        handleUnassignGroup,
        handleUnassignUser,
        handleUserEnterClick,
        isAssignedDirectly,
        userId
    ]);
    return (_jsxs(Dialog, { show: true, showCloseButton: true, closeButtonInside: true, autoFocusFirst: false, onCloseAttempt: onReject, children: [_jsxs(Header, { title: `Unassign ${profileName} from ${userName}`, children: ["Unassign ", userName, " from ", profileName] }), _jsxs(Content, { children: [_jsxs("div", { className: styles.textContainer, children: [_jsxs(Text, { children: [profileName, " was assigned to ", userName, " via ", countGroups(groups.length), " groups. To make ", profileName, " unavailable to ", userName, ", unassign it from all groups listed below."] }), _jsxs(Text, { className: styles.warning, children: [_jsx(Icon, { glyph: warningIcon }), " Note that this action will affect all other members of these groups."] })] }), _jsx("div", { className: styles.listContainer, children: _jsx(List, { compact: true, shortcuts: true, data: groupsData, activateSingleItem: false, renderOptimization: false, disableScrollToActive: true }) })] })] }));
}
function countGroups(groupsLength) {
    if (groupsLength > 1) {
        return `${groupsLength} groups`;
    }
    return 'group';
}
export default UnassignUserWithGroupDialog;
