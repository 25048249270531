import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { addDays, endOfToday, startOfDay } from 'date-fns';
import { NumberParam, StringParam } from 'serialize-query-params';
import { PageTitle } from '@app/common/page-layout/page-title';
import HorizontalContainer from '@app/common/page-layout/horizontal-container';
import { useSyncedSearchArgs } from '@app/common/navigation/use-synced-search-args';
import SearchFilter from '@app/common/search-filter/search-filter';
import CwmSessionsBody from './cwm-sessions-body';
import UsersFilter from './users-filter';
import { DateFilter } from './date-filters';
const maxDate = endOfToday().getTime();
function getFromDefaultValue(to) {
    return startOfDay(addDays(to, -13)).getTime();
}
const tsTypeToQuerySchema = {
    to: NumberParam,
    from: NumberParam,
    page: NumberParam,
    user: StringParam,
    sort: StringParam,
    order: StringParam,
    sessionId: StringParam
};
function validateSessionsFilter(parsed) {
    const to = Math.max(0, Math.min(parsed.to || maxDate, maxDate));
    return {
        user: parsed.user,
        page: parsed.page && parsed.page > 0 ? parsed.page : 1,
        to,
        from: parsed.from && parsed.from > 0 && parsed.from <= to ? parsed.from : getFromDefaultValue(to),
        sortOrder: parsed.sortOrder || 'DESC',
        sortKey: parsed.sortKey || 'TIMESTAMP',
        sessionId: parsed.sessionId
    };
}
export function CwmSessionsPage() {
    var _a;
    const [urlParams, setUrlParams] = useSyncedSearchArgs(tsTypeToQuerySchema, validateSessionsFilter);
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: "Code With Me Sessions", breadcrumbs: [{ title: 'Dashboard', href: '/dashboard' }] }), _jsxs(HorizontalContainer, { children: [_jsx(SearchFilter, { placeholder: "Search by Session ID", value: (_a = urlParams.sessionId) !== null && _a !== void 0 ? _a : '', onChange: val => setUrlParams({ ...urlParams, sessionId: val, page: 1 }) }), _jsx(DateFilter, { params: urlParams, onChange: params => setUrlParams({ ...urlParams, ...params, page: 1 }) }), _jsx(UsersFilter, { selected: urlParams.user, onSelect: val => setUrlParams({ ...urlParams, user: val, page: 1 }) })] }), _jsx(CwmSessionsBody, { params: { ...urlParams }, onParamChange: setUrlParams })] }));
}
