import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isPast } from 'date-fns';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { Directions } from '@jetbrains/ring-ui/components/popup/popup.consts';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import bell from '@jetbrains/icons/bell';
import { Size } from '@jetbrains/ring-ui/components/icon/icon__constants';
import { formatDateString } from '@common/dates/format';
import { SettingsGroup, SettingsItem } from '../../settings-group/settings-group';
import styles from './restriction-preview.css';
export function RestrictionPreview(props) {
    const { restriction, buildNumber, appName } = props;
    return (_jsx(SettingsGroup, { groupName: "Restrictions", children: _jsxs(SettingsItem, { className: styles.restriction, additionalInfo: _jsx(Tooltip, { title: restriction.description, popupProps: {
                    directions: [Directions.BOTTOM_LEFT]
                }, children: isPast(restriction.deadline) ? (_jsx("span", { className: styles.restrictedLabel, children: "Restricted" })) : (_jsxs(_Fragment, { children: [_jsx(Icon, { glyph: bell, size: Size.Size16, className: styles.plannedIcon }), _jsxs("span", { className: styles.plannedDate, children: ["Restricted from ", formatDateString(restriction.deadline, 'd MMM yyyy, HH:mm:ss')] })] })) }), children: [_jsx("div", { className: styles.appName, children: appName }), _jsx("div", { className: styles.buildNumber, children: buildNumber })] }) }));
}
