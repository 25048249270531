import { apiClientManager } from '@common/api';
import auth from '../common/auth/auth';
import { DEFAULT_TABLE_PAGE_SIZE } from './paging-utils';
function getSortParam(sortKey) {
    switch (sortKey) {
        case 'createdBy':
            return 'CREATED_BY';
        case 'createdDate':
            return 'CREATED_DATE';
        default:
            return null;
    }
}
export function getPluginsUrlFeed() {
    // language=http-url-reference
    return `${auth.getBaseURL()}/api/plugin-repository`;
}
/**
 *
 * @param {string} url
 * @param {function(number): void} onProgressChange - callback which accepts value from 0 to 1 representing current
 * download progress. E.x. if half of the file is downloaded then 0.5 is returned
 * @return {*} - axios response
 */
export function getPluginAsBlob(url, onProgressChange) {
    return apiClientManager.getClient().get(url, {
        responseType: 'blob',
        onDownloadProgress: progressEvent => {
            if (progressEvent.progress) {
                onProgressChange(progressEvent.progress);
            }
        }
    });
}
export function getPluginsUploadUrl() {
    // language=http-url-reference
    return `${auth.getBaseURL()}/api/plugins`;
}
const castDate = (date) => (date ? new Date(date) : null);
export async function getPluginById(id) {
    const response = await apiClientManager
        .getClient()
        .get(`/api/plugins/${encodeURIComponent(id)}`, {});
    return response.data;
}
export async function getPluginVersions(id, sortProps) {
    const response = await apiClientManager
        .getClient()
        .get(`/api/plugins/${encodeURIComponent(id)}/versions`, {
        params: sortProps
    });
    return response.data;
}
export async function getPluginChannels(id) {
    const response = await apiClientManager
        .getClient()
        .get(`/api/plugins/${encodeURIComponent(id)}/channels`);
    return response.data;
}
export async function deletePluginVersion(pluginId, version) {
    await apiClientManager.getClient().request({
        url: `/api/plugins/${encodeURIComponent(pluginId)}/versions/${encodeURIComponent(version)}`,
        method: 'DELETE'
    });
}
export async function uploadPlugin(file, channels, onProgressChange, expirationDate) {
    const data = new FormData();
    data.append('file', file);
    if (expirationDate) {
        data.append('expirationDate', expirationDate.toISOString());
    }
    if (channels.length) {
        data.append('channels', new Blob([JSON.stringify(channels)], { type: 'application/json' }));
    }
    return apiClientManager
        .getClient()
        .post('/api/plugins', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: progressEvent => {
            if (progressEvent.progress) {
                onProgressChange(Math.min(progressEvent.progress, 0.99));
            }
        }
    })
        .then(({ data }) => {
        onProgressChange(1);
        return data;
    });
}
export async function updatePluginPrivacy(pluginId, isPrivate) {
    const patch = { isPrivate };
    const response = await apiClientManager
        .getClient()
        .patch(`/api/plugins/${encodeURIComponent(pluginId)}`, patch);
    return response.data;
}
export async function updateVersionPluginChannels(pluginId, pluginVersion, channels) {
    const response = await apiClientManager
        .getClient()
        .patch(`/api/plugins/${encodeURIComponent(pluginId)}/versions/${encodeURIComponent(pluginVersion)}`, { channels });
    return response.data;
}
export async function updatePluginVersionExpirationDate(pluginId, pluginVersion, expirationDate) {
    const response = await apiClientManager
        .getClient()
        .patch(`/api/plugins/${encodeURIComponent(pluginId)}/versions/${encodeURIComponent(pluginVersion)}`, { expirationDate }, { params: { version: pluginVersion } });
    return { expirationDate: castDate(response.data.expirationDate) };
}
export async function synchronizePluginsState() {
    return apiClientManager.getClient().post('/api/schedule/synchronizeDbAndMinio');
}
export async function getExistingChannels() {
    const res = await apiClientManager.getClient().get('/api/plugins-channels');
    return res.data.items;
}
export async function getPlugins(page, vendorOrPluginName, limit) {
    const res = await apiClientManager.getClient().get('/api/plugins', {
        params: { page, limit, vendorOrPluginName }
    });
    return res.data;
}
export async function deletePlugin(id) {
    await apiClientManager.getClient().request({
        url: `/api/plugins/${encodeURIComponent(id)}`,
        method: 'DELETE'
    });
}
export async function getAuditLogs(params) {
    const orderParam = params.sortOrder;
    const sortParam = getSortParam(params.sortKey);
    let url = `/api/plugins-audit-logs?page=${params.page}&limit=${DEFAULT_TABLE_PAGE_SIZE}`;
    if (sortParam != null) {
        url += `&sort=${sortParam}&order=${orderParam}`;
    }
    if (params.filter) {
        url += `&pluginIdFilter=${params.filter}`;
    }
    const response = await apiClientManager.getClient().get(url);
    return response.data;
}
