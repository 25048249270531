// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.licenceActivationForm__a09 {
  width: 800px;
  max-width: 90vw;
  display: flex;
  gap: calc(2 * var(--ring-unit));
  flex-direction: column;
}

.licenceActivationPrimaryActions__d95 {
  align-items: center;
  display: inline-flex;
  gap: calc(3 * var(--ring-unit));
}

.generateNewKeyButton__d3f {
  float: right;
}

.successMessageContainer__ccb {
  display: flex;
  gap: var(--ring-unit);
}

.successIcon__ced {
  color: var(--ring-icon-success-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/license/license-activation-page.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,+BAA+B;EAC/B,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,+BAA+B;AACjC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".licenceActivationForm {\n  width: 800px;\n  max-width: 90vw;\n  display: flex;\n  gap: calc(2 * var(--ring-unit));\n  flex-direction: column;\n}\n\n.licenceActivationPrimaryActions {\n  align-items: center;\n  display: inline-flex;\n  gap: calc(3 * var(--ring-unit));\n}\n\n.generateNewKeyButton {\n  float: right;\n}\n\n.successMessageContainer {\n  display: flex;\n  gap: var(--ring-unit);\n}\n\n.successIcon {\n  color: var(--ring-icon-success-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"licenceActivationForm": `licenceActivationForm__a09`,
	"licenceActivationPrimaryActions": `licenceActivationPrimaryActions__d95`,
	"generateNewKeyButton": `generateNewKeyButton__d3f`,
	"successMessageContainer": `successMessageContainer__ccb`,
	"successIcon": `successIcon__ced`
};
export default ___CSS_LOADER_EXPORT___;
