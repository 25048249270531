// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentWrapper__de9 {
  display: flex;
  flex-direction: column;
}

.info__d1d + .limit__c87 {
  margin-top: calc(var(--ring-unit) * 3);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ide-provisioner/common/dialogs/idep-settings-dialog/idep-settings-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".contentWrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.info + .limit {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `contentWrapper__de9`,
	"info": `info__d1d`,
	"limit": `limit__c87`
};
export default ___CSS_LOADER_EXPORT___;
