// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapsibleWithBookedSpaceForCollapsedContent__ef0 {
  width: 800px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-history/cells/profile-history-item.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd","sourcesContent":[".collapsibleWithBookedSpaceForCollapsedContent {\n  width: 800px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapsibleWithBookedSpaceForCollapsedContent": `collapsibleWithBookedSpaceForCollapsedContent__ef0`
};
export default ___CSS_LOADER_EXPORT___;
