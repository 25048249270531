import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import ButtonGroup from '@jetbrains/ring-ui/components/button-group/button-group';
import Button from '@jetbrains/ring-ui/components/button/button';
import { assignTestId } from '@common/infra/tests/assign-test-id';
export const architectureOptions = [
    { key: 'x64', label: 'x64' },
    { key: 'arm64', label: 'arm64' }
];
function ArchField() {
    const { setValue, watch } = useFormContext();
    const arch = watch('arch');
    const normalizedArch = useMemo(() => {
        if (!arch || !architectureOptions.some(it => arch.indexOf(it.key) === -1)) {
            return null;
        }
        return arch[0];
    }, [arch]);
    const onOptionClick = (key) => setValue('arch', key ? [key] : []);
    return (_jsx("div", { ...assignTestId('filter-arch'), children: _jsxs(ButtonGroup, { children: [_jsx(Button, { onClick: () => onOptionClick(), active: !normalizedArch, children: "All" }), architectureOptions.map(it => (_jsx(Button, { onClick: () => onOptionClick(it.key), active: normalizedArch === it.key, children: it.label })))] }) }));
}
export default ArchField;
