import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { noop } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useEvaluatedAppFilters } from '@app/common/queries/profile-evaluations';
import styles from '../common/filters-field.css';
import SimpleToolsPreview from '../common/simple-tools-preview';
import FiltersEditor from '../common/filters-editor/filters-editor';
function AppFiltersField({ onIsFetchingChange = noop }) {
    const { watch, setValue, formState } = useFormContext();
    const watchFilters = watch('filters');
    const { errors } = formState;
    const setFilters = (filters) => {
        setValue('filters', filters, {
            shouldDirty: true
        });
    };
    const { isFetching, data: tools } = useEvaluatedAppFilters(watchFilters);
    useEffect(() => {
        onIsFetchingChange(isFetching);
    }, [isFetching, onIsFetchingChange]);
    return (_jsxs("div", { className: styles.filterField, "data-test": "filter-field", children: [watchFilters.length !== 0 && (_jsx(SimpleToolsPreview, { tools: tools !== null && tools !== void 0 ? tools : [], isLoading: isFetching, className: styles.toolsPreview })), _jsx(FiltersEditor, { filters: watchFilters, onFiltersChanged: setFilters, target: "applications" }), errors.filters && _jsx("p", { className: styles.errorMessage, children: errors.filters.message })] }));
}
export default AppFiltersField;
