import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import { ExternalLinks } from '@app/common/constants/constants';
import NavigationLink from '@app/common/navigation/navigation-link';
import styles from './plugins-restriction-banner.css';
const CWM_RESTRICTION = 'com.jetbrains.codeWithMe';
export function PluginsRestrictionBanner(props) {
    const { restrictedModePlugins } = props;
    const hasCWMRestricted = () => Array.isArray(restrictedModePlugins) && restrictedModePlugins.includes(CWM_RESTRICTION);
    return (_jsx("div", { className: styles.container, children: _jsxs(Text, { children: ["IDE Provisioner is operating in restricted mode, allowing for the uploading and management of only the JetBrains IDE Services ", hasCWMRestricted() && 'and Code With Me ', "plugin", hasCWMRestricted() && 's', ".", _jsx("br", {}), " Contact the", ' ', _jsx(NavigationLink, { target: "_blank", href: ExternalLinks.SUPPORT, children: "support team" }), ' ', "for more information."] }) }));
}
