import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import Toggle, { Size } from '@jetbrains/ring-ui/components/toggle/toggle';
import { LimitAssigmentEditor } from '@modules/constituents/constituent-dialog';
import { HiddenLimit } from '@modules/constituents/constituent-dialog/hidden-limit';
import { useConstituentCurrentUsage } from '@common/queries/dashboard';
import NavigationLink from '@common/navigation/navigation-link';
import { StatisticsWidgetInfoStats, StatisticsWidgetInfoStatsSize } from '@common/widgets/statistics-widget/statistics-widget-info-stats';
import { useTbeLicense } from '@common/queries/tbe-license';
import { ExternalLinks } from '@common/constants/constants';
import styles from '../styles.css';
const id = 'ai';
const title = 'AI Enterprise';
export function AiGeneralSection({ form }) {
    var _a, _b, _c;
    const { data } = useTbeLicense();
    const { data: currentUsage } = useConstituentCurrentUsage({
        id,
        title
    });
    const aiConstituentLicense = (_a = data === null || data === void 0 ? void 0 : data.constituents) === null || _a === void 0 ? void 0 : _a.find(constituent => constituent.id === id);
    const { watch, setValue } = form;
    const initialLimitUsage = (_b = watch('assignedUsersLimit')) !== null && _b !== void 0 ? _b : 'unlimited';
    const handleDataCollection = (e) => {
        setValue('allowDataCollection', e.target.checked, { shouldDirty: true, shouldValidate: true });
    };
    const handleLimitChange = (val) => {
        setValue('assignedUsersLimit', val === 'unlimited' ? undefined : val, {
            shouldDirty: true,
            shouldValidate: true
        });
    };
    return (_jsxs("div", { className: cn(styles.container, styles.topSeparator, styles.bottomSeparator), children: [_jsx(StatisticsWidgetInfoStats, { statName: "Current users", size: StatisticsWidgetInfoStatsSize.Narrow, statValue: _jsx(NavigationLink, { href: "/users?constituentId=ai", children: _jsx("span", { children: (_c = currentUsage === null || currentUsage === void 0 ? void 0 : currentUsage.value) !== null && _c !== void 0 ? _c : 0 }) }) }), (data === null || data === void 0 ? void 0 : data.plan) === 'PREPAID' && _jsx(HiddenLimit, { className: styles.limit }), (data === null || data === void 0 ? void 0 : data.plan) === 'POSTPAID' && (_jsx(LimitAssigmentEditor, { toggleText: "Unlimited number of users", limitNotice: "You can set a limit of assigned users to help control your spend. Users above the limit will have restricted access.", limit: (aiConstituentLicense === null || aiConstituentLicense === void 0 ? void 0 : aiConstituentLicense.trial.state) === 'ACTIVE'
                    ? aiConstituentLicense.trial.maxUsage
                    : initialLimitUsage, onLimitChange: handleLimitChange, readonly: (aiConstituentLicense === null || aiConstituentLicense === void 0 ? void 0 : aiConstituentLicense.trial.state) === 'ACTIVE' })), _jsxs("div", { children: [_jsx(Toggle, { checked: watch('allowDataCollection'), onChange: handleDataCollection, size: Size.Size16, children: "Allow detailed data collection" }), _jsxs("div", { className: styles.dataCollectionNotice, children: [_jsxs("p", { children: ["Detailed data collection includes full data about the interactions with large language models.", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_AI_ENTERPRISE_DETAILED_DATA_COLLECTION, target: "_blank", children: "Learn more" })] }), _jsxs("p", { children: ["This setting enables detailed data collection for your organization. Proceed by configuring this setting in the relevant", ' ', _jsx(NavigationLink, { href: "/profiles", children: "profiles" }), "."] })] })] })] }));
}
