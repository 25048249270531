import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import Toggle, { Size } from '@jetbrains/ring-ui/components/toggle/toggle';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import { useGenerateNewPublicKeyMutation } from '@common/queries/tbe-license';
import NavigationLink from '@common/navigation/navigation-link';
import { ExternalLinks } from '@common/constants/constants';
import { useCurrentUserPolling } from '@common/queries/users';
import styles from './license-generate-new-public-key-dialog.css';
export function LicenseGenerateNewPublicKeyDialog(props) {
    const { onCloseAttempt } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [loadUser, setLoadUser] = useState(false);
    const { mutateAsync: disable } = useGenerateNewPublicKeyMutation();
    const { data } = useCurrentUserPolling(loadUser);
    const [isConfirmed, setConfirmed] = useState(false);
    // Hack: Manually poll `user/me` data until `serverActivated` flag turns `false`.
    // TODO: Should be removed once `user/me` will be fully synchronized with `generateNewPublicKey`.
    // Reference: IDES-5355
    const handleDisable = useCallback(async () => {
        setIsLoading(true);
        await disable()
            .then(() => {
            setLoadUser(true);
        })
            .catch(() => setLoadUser(false));
    }, [disable]);
    useEffect(() => {
        if ((data === null || data === void 0 ? void 0 : data.serverActivated) === false) {
            window.location.reload();
        }
    }, [data === null || data === void 0 ? void 0 : data.serverActivated]);
    const handleClose = useCallback(onCloseAttempt, [onCloseAttempt]);
    const handleCloseDialog = () => {
        if (isLoading) {
            return;
        }
        handleClose({});
    };
    return (_jsxs(Dialog, { show: true, onCloseClick: handleCloseDialog, onCloseAttempt: handleCloseDialog, children: [_jsx(Header, { children: "Generate New Public Key" }), _jsxs(Content, { children: [_jsxs("p", { children: ["Generating a new public key will result in the loss of all existing license details and will require server re-activation. Contact the", ' ', _jsx(NavigationLink, { href: `${ExternalLinks.SUPPORT}/hc/`, target: "_blank", children: "IDE Services support team" }), ' ', "before proceeding further."] }), _jsxs("p", { className: styles.toggleWrapper, children: [_jsx(Toggle, { size: Size.Size16, checked: isConfirmed, onChange: e => setConfirmed(e.target.checked) }), "I understand the effects of generating a new public key and have contacted the IDE Services support team"] })] }), _jsxs(Panel, { children: [_jsx(Button, { disabled: !isConfirmed, onClick: handleDisable, loader: isLoading, primary: true, children: "Generate public key" }), _jsx(Button, { disabled: isLoading, onClick: handleClose, children: "Cancel" }), isLoading && _jsx(LoaderInline, {})] })] }));
}
