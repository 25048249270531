import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import Text from '@jetbrains/ring-ui/components/text/text';
import { IntervalWrapper } from './interval-wrapper';
import styles from './interval-bar-chart.css';
export function IntervalBarChart({ colorScheme = 'default', intervals, activeInterval, height = 60, onActivateInterval, className }) {
    const maxTotal = useMemo(() => intervals.reduce((accum, current) => Math.max(accum, current.total), 0), [intervals]);
    const formattedActiveDayDate = useMemo(() => activeInterval && format(activeInterval === null || activeInterval === void 0 ? void 0 : activeInterval.from, 'MMM d'), [activeInterval]);
    return (_jsxs("div", { className: classNames(styles.chartWrapper, className), children: [_jsx("div", { className: styles.chartSpace, children: _jsx("div", { className: classNames(styles.chart, {
                        [styles.chartColorDefault]: colorScheme === 'default',
                        [styles.chartColorSuccessAndFailure]: colorScheme === 'success-and-failure'
                    }), style: { height }, onMouseLeave: () => intervals.length && onActivateInterval(intervals[intervals.length - 1]), children: intervals.map((it, idx) => (_jsx(IntervalWrapper, { interval: it, active: it === activeInterval, onActivateInterval: onActivateInterval, children: _jsx("div", { className: styles.chartBarTotal, style: {
                                height: maxTotal ? (it.total / maxTotal) * height : 0,
                                animationDelay: `${idx * 20}ms`
                            }, children: !!it.error && (_jsx("div", { className: styles.chartBarError, style: {
                                    height: (it.error / maxTotal) * height
                                } })) }) }, `chart-bar-item-${it.from.getTime()}`))) }) }), _jsx(Text, { className: styles.chartLabel, info: true, size: "s", children: formattedActiveDayDate })] }));
}
