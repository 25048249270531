import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import trash from '@jetbrains/icons/trash';
import { isApplicationRuleChanged } from '@app/common/store/profiles/selectors';
import { SettingLayout } from '../common/setting-layout';
import { RestrictionPreview } from '../common/restriction-preview';
export function NotificationListEntry({ entry, onEditClick, onDeleteClick }) {
    const { id } = entry;
    const isChanged = useSelector(state => isApplicationRuleChanged(state, id || ''));
    const actionIcons = useMemo(() => (id ? [{ glyph: trash, onClick: () => onDeleteClick(id), title: 'Remove setting' }] : []), [id, onDeleteClick]);
    return (_jsx(SettingLayout, { isChanged: isChanged, icons: actionIcons, onTileClick: id ? () => onEditClick(id) : undefined, children: _jsx(RestrictionPreview, { restrictionRule: entry }) }, id));
}
