import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty, isNil } from 'lodash';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { useDialogCancelConfirmation } from '@app/common/hooks/use-dialog-cancel-confirmation';
import RingFieldsList from '@common/form/ring-fields-list';
import { replaceProps, replaceQuality, withReplacedSuffix } from '../../../../../../modules/applications/build-filters/filter-build-options';
import TypeField from './fields/type-field';
import ProductField from './fields/product-field';
import BuildField from './fields/build-field/build-field';
import OsField from './fields/os-field';
import ArchField from './fields/arch-field';
import QualityField from './fields/quality-field';
import styles from './filters-editor.css';
const defaultFilter = {
    type: 'include',
    archs: [],
    builds: [],
    products: [],
    operatingSystems: [],
    quality: undefined
};
const optionalProps = ['arch', 'builds', 'products', 'os'];
function FilterForm({ item, onReject, onSubmit, allProducts, allQualities, target }) {
    const form = useForm({
        defaultValues: item
            ? replaceQuality(replaceProps(withReplacedSuffix(item), optionalProps))
            : replaceQuality(defaultFilter)
    });
    const { formState: { isDirty }, handleSubmit } = form;
    const submit = (filterValues) => {
        var _a, _b;
        const filterToSubmit = { ...filterValues };
        const allOptionalPropsFlag = optionalProps.every(prop => isEmpty(filterToSubmit[prop]));
        if (filterValues.quality && filterToSubmit.quality) {
            filterToSubmit.quality = {
                name: (_b = (_a = allQualities === null || allQualities === void 0 ? void 0 : allQualities.find(x => { var _a; return x.orderValue === ((_a = filterValues.quality) === null || _a === void 0 ? void 0 : _a.orderValue); })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
                condition: filterValues.type === 'include' ? 'le' : 'gt',
                orderValue: filterValues.quality.orderValue
            };
        }
        // If deleted all the optional props then rule type should include all the products
        // e.x. include becomes include-all
        if (allOptionalPropsFlag && isNil(filterValues.quality)) {
            filterToSubmit.type += '-all';
        }
        onSubmit(filterToSubmit);
    };
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(onReject, !isDirty);
    return (_jsxs(Dialog, { show: true, onCloseAttempt: onCloseAttempt, label: "Filter", autoFocusFirst: true, contentClassName: styles.dialogContent, children: [_jsx(Header, { children: "Add Filter" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsx("form", { onSubmit: handleSubmit(submit), children: _jsxs(RingFieldsList, { children: [_jsx(TypeField, { target: target }), _jsx(ProductField, { allProducts: allProducts !== null && allProducts !== void 0 ? allProducts : [] }), _jsx(BuildField, { allProducts: allProducts !== null && allProducts !== void 0 ? allProducts : [] }), _jsx(OsField, {}), _jsx(ArchField, {}), target === 'applications' && _jsx(QualityField, { allQualities: allQualities })] }) }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(submit), children: "OK" }), _jsx(Button, { ref: cancelButtonRef, onClick: onReject, children: "Cancel" })] })] }));
}
export default FilterForm;
