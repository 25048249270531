import { apiClientManager } from '@common/api';
import { withAuditFieldTexts } from './audit-utils';
export async function getAutomationTokens(state) {
    const response = await apiClientManager
        .getClient()
        .get('/api/automation-tokens', {
        params: {
            state: state.tokenState === 'ALL' ? undefined : state.tokenState
        }
    });
    return response.data.map(x => withAuditFieldTexts(x));
}
export async function createToken(tokenData) {
    const response = await apiClientManager
        .getClient()
        .post('/api/automation-tokens', tokenData);
    return response.data;
}
export async function updateToken(tokenId, tokenData) {
    const response = await apiClientManager
        .getClient()
        .patch(`/api/automation-tokens/${tokenId}`, tokenData);
    return withAuditFieldTexts(response.data);
}
export async function deleteToken(tokenId) {
    await apiClientManager.getClient().delete(`/api/automation-tokens/${tokenId}`);
}
