// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nestedListContainer__c59 {
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);
}

.itemContainer__c12 {
  display: flex;
  padding: var(--ring-unit) calc(var(--ring-unit) * 3);
  gap: var(--ring-unit);
}

.labelContainer__cd5 {
  display: flex;
  flex-direction: column;
}

.disabled__af1 {
  color: var(--ring-icon-error-color);
}

.parentWithRestriction__f9f {
  color: var(--ring-icon-warning-color);
}

.autoinstalled__ba4 {
  color: var(--ring-icon-success-color);
}

.restrictionIcon__f4b {
  margin-right: 6px;
  vertical-align: text-bottom;
}

.restrictionFuture__c7b {
  color: var(--ring-icon-warning-color);
}

.restrictionPast__c00 {
  color: var(--ring-icon-error-color);
}

.restrictionsDescriptions__e7e {
  margin: 4px 0;
}

.restrictionsNoteSeparator__d35 {
  border-top: solid var(--ring-borders-color) 1px;
  height: 0;
  margin: calc(2 * var(--ring-unit)) 0 var(--ring-unit) 0;
}

.titleContainer__e9b {
  height: auto;
}

.titleContainer__e9b > span {
  display: flex;
  align-items: baseline;
}

.title__c40 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filterContainer__e92 {
  margin-right: auto;
  margin-bottom: var(--ring-unit);
}

.titleDescription__f4c {
  display: flex;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/modules/applications/builds-list/nested-data-list/nested-data-list.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,oDAAoD;EACpD,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,iBAAiB;EACjB,2BAA2B;AAC7B;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,+CAA+C;EAC/C,SAAS;EACT,uDAAuD;AACzD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".nestedListContainer {\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n}\n\n.itemContainer {\n  display: flex;\n  padding: var(--ring-unit) calc(var(--ring-unit) * 3);\n  gap: var(--ring-unit);\n}\n\n.labelContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.disabled {\n  color: var(--ring-icon-error-color);\n}\n\n.parentWithRestriction {\n  color: var(--ring-icon-warning-color);\n}\n\n.autoinstalled {\n  color: var(--ring-icon-success-color);\n}\n\n.restrictionIcon {\n  margin-right: 6px;\n  vertical-align: text-bottom;\n}\n\n.restrictionFuture {\n  color: var(--ring-icon-warning-color);\n}\n\n.restrictionPast {\n  color: var(--ring-icon-error-color);\n}\n\n.restrictionsDescriptions {\n  margin: 4px 0;\n}\n\n.restrictionsNoteSeparator {\n  border-top: solid var(--ring-borders-color) 1px;\n  height: 0;\n  margin: calc(2 * var(--ring-unit)) 0 var(--ring-unit) 0;\n}\n\n.titleContainer {\n  height: auto;\n}\n\n.titleContainer > span {\n  display: flex;\n  align-items: baseline;\n}\n\n.title {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.filterContainer {\n  margin-right: auto;\n  margin-bottom: var(--ring-unit);\n}\n\n.titleDescription {\n  display: flex;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nestedListContainer": `nestedListContainer__c59`,
	"itemContainer": `itemContainer__c12`,
	"labelContainer": `labelContainer__cd5`,
	"disabled": `disabled__af1`,
	"parentWithRestriction": `parentWithRestriction__f9f`,
	"autoinstalled": `autoinstalled__ba4`,
	"restrictionIcon": `restrictionIcon__f4b`,
	"restrictionFuture": `restrictionFuture__c7b`,
	"restrictionPast": `restrictionPast__c00`,
	"restrictionsDescriptions": `restrictionsDescriptions__e7e`,
	"restrictionsNoteSeparator": `restrictionsNoteSeparator__d35`,
	"titleContainer": `titleContainer__e9b`,
	"title": `title__c40`,
	"filterContainer": `filterContainer__e92`,
	"titleDescription": `titleDescription__f4c`
};
export default ___CSS_LOADER_EXPORT___;
