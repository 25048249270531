import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import { useGetAgreementJson } from '@app/common/queries/agreement';
import { PageTitle } from '@app/common/page-layout/page-title';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
import UnknownErrorPage from '../../common/errors/unknown-error-page';
import Markdown from '../../common/markdown/markdown';
export default function DataCollectionAgreementView() {
    const { data, isLoading, isError } = useGetAgreementJson();
    if (isError) {
        return _jsx(UnknownErrorPage, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: "Data Collection Statement" }), isLoading || !(data === null || data === void 0 ? void 0 : data.text) ? (_jsx(Loader, {})) : (_jsx("div", { ...assignTestId('data-collection-statement-text'), children: _jsx(Markdown, { text: data.text }) }))] }));
}
