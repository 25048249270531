// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[8].use[1]!../../../node_modules/@jetbrains/ring-ui/components/global/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__b14 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.scrollContainer__b43 {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  overflow: auto;

  padding: calc(var(--ring-unit) * 1.75) 0 calc(var(--ring-unit) * 4);
}

.centerWrapper__b1d {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  max-width: var(--max-page-width);
  padding: 0 calc(var(--ring-unit) * 4);

  margin: 0 auto;

  .authorized__be2 & {
    min-width: calc(${___CSS_LOADER_ICSS_IMPORT_0___.locals["breakpoint-middle"]} + var(--ring-unit) * 4);
  }
}

.sidebar__e82 {
  flex: 0 0 240px;

  display: flex;
  flex-direction: column;

  height: fit-content;

  border: 1px var(--ring-line-color) solid;
  border-radius: var(--ring-border-radius);
  background: var(--ring-sidebar-background-color);

  padding-bottom: calc(var(--ring-unit) * 2 - 3px);
}

.sidebar__e82 + .content__fe5 {
  margin-left: calc(var(--ring-unit) * 4);
}

.content__fe5 {
  flex: 1 1 100%;
  width: 100%;
}

.footerContainer__f7f {
  margin: auto auto 0;
}

.footer__d49 {
  display: contents;
}
`, "",{"version":3,"sources":["webpack://./src/common/app-layout/app-layout.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;;EAEZ,aAAa;EACb,sBAAsB;;EAEtB,cAAc;;EAEd,mEAAmE;AACrE;;AAEA;EACE,aAAa;EACb,cAAc;EACd,WAAW;EACX,gCAAgC;EAChC,qCAAqC;;EAErC,cAAc;;EAEd;IACE,oFAAyD;EAC3D;AACF;;AAEA;EACE,eAAe;;EAEf,aAAa;EACb,sBAAsB;;EAEtB,mBAAmB;;EAEnB,wCAAwC;EACxC,wCAAwC;EACxC,gDAAgD;;EAEhD,gDAAgD;AAClD;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["@value breakpoint-middle from '~@jetbrains/ring-ui/components/global/global.css';\n\n.app {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.scrollContainer {\n  width: 100%;\n  height: 100%;\n\n  display: flex;\n  flex-direction: column;\n\n  overflow: auto;\n\n  padding: calc(var(--ring-unit) * 1.75) 0 calc(var(--ring-unit) * 4);\n}\n\n.centerWrapper {\n  display: flex;\n  flex: 1 0 auto;\n  width: 100%;\n  max-width: var(--max-page-width);\n  padding: 0 calc(var(--ring-unit) * 4);\n\n  margin: 0 auto;\n\n  .authorized & {\n    min-width: calc(breakpoint-middle + var(--ring-unit) * 4);\n  }\n}\n\n.sidebar {\n  flex: 0 0 240px;\n\n  display: flex;\n  flex-direction: column;\n\n  height: fit-content;\n\n  border: 1px var(--ring-line-color) solid;\n  border-radius: var(--ring-border-radius);\n  background: var(--ring-sidebar-background-color);\n\n  padding-bottom: calc(var(--ring-unit) * 2 - 3px);\n}\n\n.sidebar + .content {\n  margin-left: calc(var(--ring-unit) * 4);\n}\n\n.content {\n  flex: 1 1 100%;\n  width: 100%;\n}\n\n.footerContainer {\n  margin: auto auto 0;\n}\n\n.footer {\n  display: contents;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakpoint-middle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["breakpoint-middle"]}`,
	"app": `app__b14`,
	"scrollContainer": `scrollContainer__b43`,
	"centerWrapper": `centerWrapper__b1d`,
	"authorized": `authorized__be2`,
	"sidebar": `sidebar__e82`,
	"content": `content__fe5`,
	"footerContainer": `footerContainer__f7f`,
	"footer": `footer__d49`
};
export default ___CSS_LOADER_EXPORT___;
