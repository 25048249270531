import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useMemo } from 'react';
import { uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import Text from '@jetbrains/ring-ui/components/text/text';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import classnames from 'classnames';
import { getApplications } from '@app/common/store/profiles/selectors';
import ProductLogo from '@common/product-logo/product-logo';
import styles from './tools-preview.css';
export default function SmartToolsPreview({ tools, className, isLoading = false }) {
    const uniqueTools = uniqBy(tools, 'product');
    const { content } = useSelector(getApplications);
    const allProductsMatched = useMemo(() => {
        const uniqueIncludedProducts = new Set(content.filter(x => x.include.length > 0).map(x => x.product));
        return (uniqueTools.length === uniqueIncludedProducts.size &&
            uniqueTools.every(x => uniqueIncludedProducts.has(x.product)));
    }, [content, uniqueTools]);
    return (_jsxs(Text, { className: classnames(styles.boxHeading, className), children: [uniqueTools.length === 0 && 'No matching applications', uniqueTools.length > 0 &&
                (allProductsMatched ? ('All enabled applications') : (_jsx("div", { className: styles.productLogos, children: uniqueTools.map(tool => (_jsx(Fragment, { children: _jsx(Tooltip, { className: styles.productLogoTooltip, title: tool.productName, children: _jsx(ProductLogo, { product: tool.product, className: styles.productLogo }) }) }, tool.product))) }))), isLoading && _jsx(LoaderInline, {})] }));
}
