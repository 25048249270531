import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useCompanyName } from '@app/common/hooks/use-company-name';
import { DownloadTbeToolboxApp, useMachineConfigDownloadButtonProps } from '@app/modules/machine-config';
import auth from '@app/common/auth/auth';
import { PageTitle } from '@app/common/page-layout/page-title';
import CopiedText from '@app/common/clipboard/copied-text/copied-text';
import { ExternalLinks } from '@app/common/constants/constants';
import { ConstituentEnabledGuard } from '@app/modules/constituents/constituent-enabled-guard/constituent-enabled-guard';
import NavigationLink from '@app/common/navigation/navigation-link';
import { LicencesTable } from './licences-table';
import styles from './user-welcome-page.css';
export function UserWelcomePage() {
    const [showDownloadToolbox, setShowDownloadToolbox] = useState(false);
    const { companyName, isLoading: isCompnanyNameLoading } = useCompanyName();
    const userProfile = auth.getUserProfile();
    const machineConfigurationBtnProps = useMachineConfigDownloadButtonProps(`Join ${companyName}`);
    if (!userProfile) {
        throw new Error('Invalid application state');
    }
    return (_jsxs("main", { "data-test": "user-welcome-page-wrapper", children: [_jsx(PageTitle, { title: userProfile.username }), (userProfile === null || userProfile === void 0 ? void 0 : userProfile.email) && (_jsxs("p", { className: styles.email, children: ["Email: ", _jsx(CopiedText, { text: userProfile.email })] })), _jsx(ConstituentEnabledGuard, { constituent: "lv", children: _jsxs("p", { className: styles.instruction, children: ["Refer to our documentation for", ' ', _jsx(NavigationLink, { target: "_blank", href: ExternalLinks.JB_LV_ACTIVATION, children: "step-by-step activation instructions" }), ".", _jsx("br", {}), "If you don\u2019t have a license, contact your License Vault administrator."] }) }), !isCompnanyNameLoading && (_jsxs("p", { className: styles.joinMsg, children: ["Join to gain access to tools and settings approved by ", companyName, ". Make sure the Toolbox App is installed on your computer."] })), _jsxs("div", { className: styles.actions, children: [!isCompnanyNameLoading && (_jsx(Button, { "data-test": "join-organization-button", loader: isCompnanyNameLoading, onClickCapture: () => setShowDownloadToolbox(true), ...machineConfigurationBtnProps })), showDownloadToolbox && (_jsxs("div", { className: styles.downloadToolboxContainer, children: [_jsxs(Text, { children: [_jsx("strong", { children: "Did that not work?" }), " ", _jsx("br", {}), " Ensure the Toolbox App is installed"] }), _jsx(DownloadTbeToolboxApp, { primary: true })] }))] }), _jsx(ConstituentEnabledGuard, { constituent: "lv", children: _jsx(LicencesTable, { className: styles.table }) })] }));
}
