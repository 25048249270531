import { trim } from 'lodash';
import auth from '@common/auth/auth';
const autologinParameterName = 'autologin';
export function processAutologinOption() {
    const autologin = getAutologinQueryOption();
    if (autologin.value) {
        if (auth.isGuest()) {
            auth.login();
        }
        else {
            autologin.reset();
        }
    }
}
function getAutologinQueryOption() {
    const searchParams = window.URLSearchParams
        ? new URL(window.location.href).searchParams
        : undefined;
    const hasAutologinParameter = !!(searchParams === null || searchParams === void 0 ? void 0 : searchParams.has(autologinParameterName));
    return {
        value: hasAutologinParameter && !isFalsyStringValue(searchParams === null || searchParams === void 0 ? void 0 : searchParams.get(autologinParameterName)),
        reset: resetAutologinOption
    };
    function resetAutologinOption() {
        if (searchParams && hasAutologinParameter) {
            searchParams.delete(autologinParameterName);
            window.location.replace(`${window.location.href.split('?')[0]}?${searchParams.toString()}`);
        }
    }
    function isFalsyStringValue(value) {
        const normalizedValue = trim((value === null || value === void 0 ? void 0 : value.toLowerCase()) || '', ' \'"');
        return ['false', 'null', 'undefined', '0'].some(it => it === normalizedValue);
    }
}
