// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warningText__d49 {
  color: var(--ring-text-color);
}

.errorText__a83 {
  color: var(--ring-error-color);
}

.placeholder__b57 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 500px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/ide-settings/json-editor/json-editor-fields.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,aAAa;EACb,WAAW;AACb","sourcesContent":[".warningText {\n  color: var(--ring-text-color);\n}\n\n.errorText {\n  color: var(--ring-error-color);\n}\n\n.placeholder {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  height: 500px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningText": `warningText__d49`,
	"errorText": `errorText__a83`,
	"placeholder": `placeholder__b57`
};
export default ___CSS_LOADER_EXPORT___;
