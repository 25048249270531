import { NumberParam, StringParam, createEnumParam } from 'serialize-query-params';
import { booleanSortOrder, pagingSortOrder } from '@api/paging-utils';
import { transformPseudoBooleanToBoolean, useSyncedSearchArgs } from '@common/navigation/use-synced-search-args';
import { availableConstituents, sortableColumns } from './constants';
export function useUserParams() {
    return useSyncedSearchArgs(tsTypeToQuerySchema, validateUsersFilter);
}
function validateUsersFilter(parsed) {
    var _a, _b, _c, _d, _e, _f, _g;
    const isActive = transformPseudoBooleanToBoolean(parsed.isActive);
    return {
        role: (_a = parsed.role) !== null && _a !== void 0 ? _a : null,
        isActive,
        query: (_b = parsed.query) !== null && _b !== void 0 ? _b : '',
        profileId: (_c = parsed.profileId) !== null && _c !== void 0 ? _c : null,
        page: parsed.page && parsed.page > 0 ? parsed.page : 1,
        sortKey: (_d = parsed.sortKey) !== null && _d !== void 0 ? _d : 'username',
        sortOrder: (_e = parsed.sortOrder) !== null && _e !== void 0 ? _e : 'ASC',
        groupId: (_f = parsed.groupId) !== null && _f !== void 0 ? _f : null,
        constituentId: (_g = parsed.constituentId) !== null && _g !== void 0 ? _g : null
    };
}
const tsTypeToQuerySchema = {
    role: StringParam,
    isActive: createEnumParam(booleanSortOrder),
    query: StringParam,
    profileId: StringParam,
    page: NumberParam,
    sortKey: createEnumParam(Object.values(sortableColumns)),
    sortOrder: createEnumParam(pagingSortOrder),
    groupId: StringParam,
    constituentId: createEnumParam(availableConstituents)
};
