import { jsx as _jsx } from "react/jsx-runtime";
import { AiLogo, CodeWithMeLogo, IdeProvisionerLogo, LicenseVaultLogo } from '@jetbrains/logos/react';
import classNames from 'classnames';
import styles from './constituent-logo.css';
export function ConstituentLogo(props) {
    const { id, className } = props;
    switch (id) {
        case 'idep':
            return _jsx(IdeProvisionerLogo, { className: classNames(styles.logo, className) });
        case 'ai':
            return _jsx(AiLogo, { className: classNames(styles.logo, className) });
        case 'cwm':
            return _jsx(CodeWithMeLogo, { className: classNames(styles.logo, className) });
        case 'lv':
            return _jsx(LicenseVaultLogo, { className: classNames(styles.logo, className) });
        default:
            assert_exhaustive(id);
    }
}
