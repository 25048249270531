export function getConstituentName(id) {
    switch (id) {
        case 'ai':
            return 'AI Enterprise';
        case 'idep':
            return 'IDE Provisioner';
        case 'cwm':
            return 'Code With Me';
        case 'lv':
            return 'License Vault';
        default:
            return assert_exhaustive(id);
    }
}
