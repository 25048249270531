import { isEmpty, range, sortBy, uniqBy } from 'lodash';
export function getBuildsOptions(allProducts, selectedProducts, defaultBuildFilter = () => true) {
    if (isEmpty(selectedProducts)) {
        // If user didn't select any product, suggest major builds (e.g. 213) of all products
        const builds = allProducts.flatMap(product => product.builds.map(build => ({
            info: `${product.id} ${build.name}`,
            label: product.name,
            build: build.id
        })));
        const patterns = builds
            .filter(item => defaultBuildFilter(item.build) && item.build.includes('.'))
            .map(({ build }) => ({
            label: null,
            build: `${build.split('.')[0]}.*`,
            info: null
        }));
        return sortBy(uniqBy(patterns, item => item.build), item => item.build);
    }
    // If user selected one or more products, suggest builds of selected products
    const builds = allProducts
        .filter(product => selectedProducts.includes(product.id))
        .flatMap(product => product.builds.map(build => ({
        info: `${product.id} ${build.name}`,
        label: product.name,
        build: build.id
    })));
    const mergedBuilds = builds.map(item => ({
        ...item,
        info: builds.filter(build => build.build === item.build).map(build => build.info)
    }));
    const buildsResult = sortBy(uniqBy(mergedBuilds, item => item.build), item => item.build);
    // Patterns with wildcards, e.g. '211.*', '211.7628.*'
    const patterns = builds.flatMap(({ build }) => {
        const parts = build.split('.');
        return range(1, parts.length).map(i => ({
            info: null,
            label: null,
            build: `${parts.slice(0, i).join('.')}.*`
        }));
    });
    return sortBy(uniqBy([...patterns, ...buildsResult], item => item.build), item => item.build);
}
