// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.root__ed1 {
  display: flex;
  align-items: end;
  gap: var(--ring-unit);
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/common/page-layout/horizontal-container.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":[".root {\n  display: flex;\n  align-items: end;\n  gap: var(--ring-unit);\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `root__ed1`
};
export default ___CSS_LOADER_EXPORT___;
