import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { NotificationContext } from '@app/common/notifications/notification-context';
import { localStorageService } from '@app/common/storage';
import styles from './notification-wrapper.css';
export function useControlledNotification(notificationId, content) {
    const [openedNotification, setOpenedNotification] = useState();
    const [stopShowing, rawSetStopShowing] = useState(() => { var _a; return (_a = localStorageService.get(notificationId)) !== null && _a !== void 0 ? _a : false; });
    const setStopShowing = useCallback((shouldStopShowing) => {
        localStorageService.set(notificationId, shouldStopShowing);
        rawSetStopShowing(shouldStopShowing);
    }, [notificationId]);
    useLayoutEffect(() => {
        var _a;
        setOpenedNotification(undefined);
        setStopShowing((_a = localStorageService.get(notificationId)) !== null && _a !== void 0 ? _a : false);
    }, [setStopShowing, notificationId]);
    const closeNotification = useCallback((animate) => {
        if (!openedNotification) {
            return;
        }
        if (animate) {
            alert.remove(openedNotification.alertId);
        }
        else {
            alert.removeWithoutAnimation(openedNotification.alertId);
        }
    }, [openedNotification]);
    const notificationContextValue = useMemo(() => ({
        closeNotification,
        setStopShowingNotification: setStopShowing,
        stopShowingNotification: stopShowing
    }), [stopShowing, setStopShowing, closeNotification]);
    const showNotification = useCallback(() => {
        if (!openedNotification && !stopShowing) {
            const alertId = alert.addAlert(_jsx(NotificationContext.Provider, { value: notificationContextValue, children: content }), undefined, 0, {
                className: styles.wrapper,
                'data-test': `notification-${notificationId}`,
                onClose: () => setOpenedNotification(undefined)
            });
            setOpenedNotification({ notificationId, alertId });
        }
    }, [content, notificationContextValue, notificationId, openedNotification, stopShowing]);
    useEffect(() => {
        return () => closeNotification();
    }, [closeNotification]);
    return useMemo(() => ({ closeNotification, showNotification }), [closeNotification, showNotification]);
}
