import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CodeWithMeLogo } from '@jetbrains/logos/react';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import userWarningIcon from '@jetbrains/icons/user-warning';
import { Size } from '@jetbrains/ring-ui/components/icon/icon__constants';
import { ExternalLinks } from '@app/common/constants/constants';
import NavigationLink from '@app/common/navigation/navigation-link';
import { ConstituentBlock } from '@modules/constituents/constituent-block';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './code-with-me-misconfigured.css';
export function CodeWithMeMisconfigured(props) {
    const { className, data } = props;
    return (_jsxs(ConstituentBlock, { title: "Code With Me", titleLogo: _jsx(CodeWithMeLogo, {}), state: "disabled", className: className, ...assignTestId('cwm-block'), children: [_jsx("p", { children: "Code With Me Enterprise offers real-time synchronous collaboration for developers within your organization" }), _jsxs("p", { className: styles.error, children: [_jsx(Icon, { className: styles.errorIcon, glyph: userWarningIcon, size: Size.Size16, suppressSizeWarning: true }), _jsx("span", { className: styles.errorMsg, children: _jsx(ErrorMsg, { data: data }) })] }), _jsx("p", { className: styles.linkWrapper, children: _jsx(NavigationLink, { target: "_blank", href: ExternalLinks.JB_TBE_CWM_CONFIGURATION_DOC, children: "JetBrains IDE Services documentation" }) })] }));
}
function ErrorMsg(props) {
    var _a;
    const { data } = props;
    switch (data.errorCode) {
        case 'CODE_WITH_ME_INVALID_LOBBY_CONFIGURATION':
            return _jsx(_Fragment, { children: "Required configuration is missing. Configure the product in application.yaml" });
        default:
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return _jsx(_Fragment, { children: (_a = data.title) !== null && _a !== void 0 ? _a : 'Unknown Error' });
    }
}
