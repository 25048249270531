import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { usePaginatedPlugins, usePluginRepositoryRestrictedMode } from '@app/common/queries/plugins';
import LoadingError from '@app/common/errors/loading-error';
import HorizontalContainer from '@app/common/page-layout/horizontal-container';
import { PluginsRestrictionBanner } from './plugins-restriction-banner';
import PluginsFilters from './filters/plugins-filters';
import { usePluginsParams } from './use-plugins-params';
import { PluginsPageTable } from './plugins-page-table';
import { PluginsPageActions } from './plugins-page-actions';
export default function PluginsPageBody() {
    const [urlParams, setUrlParams] = usePluginsParams();
    const { data: restrictedModeData, isLoading: isRestrictedModeLoading } = usePluginRepositoryRestrictedMode();
    const { data, isError, isLoading, isFetching } = usePaginatedPlugins(urlParams.page, urlParams.searchValue);
    if (isError) {
        return _jsx(LoadingError, {});
    }
    return (_jsxs(_Fragment, { children: [!isRestrictedModeLoading && (restrictedModeData === null || restrictedModeData === void 0 ? void 0 : restrictedModeData.isRestrictedMode) && (_jsx(PluginsRestrictionBanner, { restrictedModePlugins: restrictedModeData.restrictedModePlugins })), _jsxs(HorizontalContainer, { children: [_jsx(PluginsPageActions, {}), _jsx(PluginsFilters, { params: urlParams, onParamsChange: setUrlParams, loading: isLoading !== null && isLoading !== void 0 ? isLoading : isFetching })] }), _jsx(PluginsPageTable, { data: data, isLoading: isLoading !== null && isLoading !== void 0 ? isLoading : isFetching, onParamsChange: setUrlParams, params: urlParams })] }));
}
