import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useParams } from 'react-router-dom';
import { getProfileSettings } from '@app/common/store/profiles/selectors';
import CopiedCode from '@app/common/clipboard/copied-code/copied-code';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
import ProfileEditFields from './profile-edit-fields';
import { tProfileSettingsSchema } from './schemas/profile-settings';
import styles from './profile-edit-dialog.css';
function ProfileEditDialog({ onReject }) {
    const dispatch = useDispatch();
    const { id } = useParams();
    const form = useForm({
        defaultValues: useSelector(getProfileSettings),
        resolver: zodResolver(tProfileSettingsSchema)
    });
    const { formState: { isDirty }, handleSubmit } = form;
    const onSubmit = (values) => {
        dispatch(ProfileActions.updateProfileSettings(values));
        onReject();
    };
    return (_jsxs(Dialog, { show: true, autoFocusFirst: false, onCloseAttempt: onReject, children: [_jsx(Header, { children: "Edit Profile" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { className: styles.copyingContainer, children: [_jsx(Text, { size: "s", info: true, children: "ID" }), _jsx(CopiedCode, { text: id })] }), _jsx(ProfileEditFields, {}), _jsx("input", { type: "submit", hidden: true, disabled: !isDirty })] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, disabled: !isDirty, onClick: handleSubmit(onSubmit), children: "Save" }), _jsx(Button, { onClick: onReject, children: "Cancel" })] })] }));
}
export default ProfileEditDialog;
