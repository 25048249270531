import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { roleTexts } from '@api/users';
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import { BasicSelectableTable } from '@common/tables/basic-selectable-table';
import { withSortableColumnFlag } from '@common/tables/table-columns';
import auth from '@common/auth/auth';
import { sortableColumns } from './constants';
import { UsersTableUsername } from './cells/users-table-username';
import { UsersTableProfiles } from './cells/users-table-profiles';
import { UsersTableGroups } from './cells/users-table-groups';
import { UsersTableConstituents } from './cells/users-table-constituents';
import { UsersTableActions } from './cells/users-table-actions';
export function UsersPageTable({ data, params, onParamsChange, onSelectUsers, isLoading }) {
    var _a;
    const navigate = useNavigate();
    const { idpImportEnabled } = auth.getFeatures();
    const total = (_a = data === null || data === void 0 ? void 0 : data.total) !== null && _a !== void 0 ? _a : 0;
    const users = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.users) !== null && _a !== void 0 ? _a : []; }, [data]);
    const doSetSelection = useCallback((updatedSelection) => {
        onSelectUsers(Array.from(updatedSelection.getActive()));
    }, [onSelectUsers]);
    const onEnterUser = useCallback((user) => {
        navigate(getUserPageUrl(user));
    }, [navigate]);
    const columns = useMemo(() => withSortableColumnFlag([
        {
            id: sortableColumns.username,
            title: 'Name',
            getValue: user => _jsx(UsersTableUsername, { ...user })
        },
        {
            id: sortableColumns.email,
            title: 'Email'
        },
        {
            id: sortableColumns.role,
            title: 'Role',
            getValue: user => roleTexts[user.role] || ''
        },
        {
            id: 'profileTitles',
            title: 'Profiles',
            getValue: user => _jsx(UsersTableProfiles, { ...user })
        },
        idpImportEnabled
            ? {
                id: 'groupsTitles',
                title: 'Groups',
                getValue: user => _jsx(UsersTableGroups, { ...user })
            }
            : { id: 'placeholderGroupsTitles' },
        {
            id: 'constituents',
            title: 'Products',
            getValue: user => _jsx(UsersTableConstituents, { ...user })
        },
        {
            id: sortableColumns.lastActionDate,
            title: 'Last Online',
            rightAlign: true
        },
        {
            id: 'actionButtons',
            getValue: user => _jsx(UsersTableActions, { ...user })
        }
    ], sortableColumns), [idpImportEnabled]);
    return (_jsx(BasicSelectableTable, { data: users, loading: isLoading, columns: columns, onItemEnter: onEnterUser, sortKey: params.sortKey, sortOrder: params.sortOrder === 'ASC', onSort: event => onParamsChange({
            ...params,
            sortKey: event.column.id,
            sortOrder: event.order ? 'ASC' : 'DESC',
            page: 1
        }), renderEmpty: () => (isLoading ? 'Loading...' : 'No users found'), onSelect: doSetSelection, pagerProps: {
            total,
            currentPage: params.page,
            pageSize: DEFAULT_TABLE_PAGE_SIZE,
            onPageChange: newPage => {
                onParamsChange({ ...params, page: newPage });
            },
            disablePageSizeSelector: true
        } }));
}
function getUserPageUrl(user) {
    return `/users/${user.id}`;
}
