import { apiClientManager } from '@common/api';
import auth from '@common/auth/auth';
import { localStorageService } from '@common/storage';
import { LicenseInactiveError } from '@common/auth/auth-models';
const PAYMENT_REQUIRED_ERROR_KEY = 'PAYMENT_REQUIRED_ERROR';
export class LicenseActivationService {
    constructor(apiManager, 
    // fixme: should be not auth, but something like config
    authService, storageService) {
        this.apiManager = apiManager;
        this.authService = authService;
        this.storageService = storageService;
        this.hadPaymentRequiredError = this.checkPaymentRequiredErrorFlag();
        this.licenceInactiveErrorCodes = [402];
        this.paymentErrorInterceptor = async (error) => {
            const { response } = error;
            if (this.licenceInactiveErrorCodes.some(code => code === (response === null || response === void 0 ? void 0 : response.status))) {
                this.reloadPageInPaymentRequiredMode();
                return Promise.reject(new LicenseInactiveError());
            }
            return Promise.reject(error);
        };
        this.apiManager.addResponseInterceptor({ onRejected: this.paymentErrorInterceptor });
    }
    hasLicense() {
        var _a;
        return !!((_a = this.authService.getUserProfile()) === null || _a === void 0 ? void 0 : _a.serverActivated) && !this.hadPaymentRequiredError;
    }
    checkPaymentRequiredErrorFlag() {
        const flag = this.storageService.get(PAYMENT_REQUIRED_ERROR_KEY);
        this.storageService.remove(PAYMENT_REQUIRED_ERROR_KEY);
        return flag;
    }
    reloadPageInPaymentRequiredMode() {
        if (!this.hadPaymentRequiredError) {
            localStorageService.set(PAYMENT_REQUIRED_ERROR_KEY, true);
            window.location.reload();
        }
    }
}
// fixme: initialize explicitly
export const licenseActivationService = new LicenseActivationService(apiClientManager, auth, localStorageService);
