import * as z from 'zod';
const tEmailSubjectObject = z.object({
    email: z.preprocess(val => (String(val).trim().length ? val : undefined), z
        .string()
        .email('This is not a valid email.')
        .optional()
        .transform(val => (val === undefined ? '' : val))),
    subject: z.preprocess(val => (val ? String(val).trim() : undefined), z.string().optional())
});
export const tUserSchema = z
    .object({
    username: z.string().min(1, { message: 'Username is required' }),
    isActive: z.boolean(),
    role: z.union([z.literal('USER'), z.literal('ADMIN')], {
        required_error: 'At list one role must be chosen'
    }),
    profiles: z
        .array(z.object({
        id: z.string(),
        title: z.string(),
        isAssignedDirectly: z.boolean().optional(),
        groups: z
            .array(z.object({
            id: z.string(),
            name: z.string()
        }))
            .optional()
            .nullable()
    }))
        .transform(items => items.filter(item => !item.groups || item.groups.length === 0))
})
    .merge(tEmailSubjectObject.partial())
    .refine(data => !!data.email || !!data.subject, {
    path: ['email'],
    message: 'Either email or subject should be filled in.'
});
