import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getAutoInstalledPlugins } from '@app/common/store/profiles/selectors';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { SettingsList } from '../../common/settings-list';
import SettingsListEntry from '../../common/settings-list-entry';
import { SettingPlaceholder } from '../../common/settings-placeholder';
import { useSettingsContext } from '../../context/settings-context';
import { showFilteredSettings, showHiddenSettingsPlaceholder } from '../../functions/settings';
import { filterByFocusedApp, useProfilesParams } from '../../context/profiles-params-context';
import AutoInstalledPluginsDialogForm from './auto-installed-plugins-dialog-form';
function AutoInstalledPlugins() {
    const plugins = useSelector(getAutoInstalledPlugins);
    const { focusedApp } = useProfilesParams();
    const filteredPlugins = filterByFocusedApp(plugins, focusedApp);
    const dispatch = useDispatch();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { setFormState, showForm, resetFormState, settingId } = useSettingsContext('autoInstalled');
    const deleteEntry = (settingId) => {
        dispatch(ProfileActions.deleteSetting({ settingType: 'autoInstalled', settingId }));
    };
    const submitContentForm = (values) => {
        dispatch(ProfileActions.updateAutoInstalledPlugins(values));
        setIsCollapsed(false);
        resetFormState();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(SettingsList, { title: "auto-installed", isCollapsed: isCollapsed, onToggleCollapsed: () => setIsCollapsed(prev => !prev), ...assignTestId('auto-installed-plugins-setting'), children: [isEmpty(plugins) && _jsx(SettingPlaceholder, { title: "No auto-installed plugins yet" }), showHiddenSettingsPlaceholder(plugins, filteredPlugins) && (_jsx(SettingPlaceholder, { title: plugins.length === 1 ? 'One plugin is hidden' : `${plugins.length} plugins are hidden` })), showFilteredSettings(filteredPlugins) &&
                        filteredPlugins.map(entry => (_jsx(SettingsListEntry, { entry: entry, onEditClick: setFormState, onDeleteClick: deleteEntry, settingType: "autoInstalled" }, entry.id)))] }), showForm && (_jsx(AutoInstalledPluginsDialogForm, { onReject: resetFormState, initialFormValues: plugins.find(({ id }) => id === settingId), onSubmit: submitContentForm }))] }));
}
export default AutoInstalledPlugins;
