import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import permissionIcon from '@jetbrains/icons/permission';
import userIcon from '@jetbrains/icons/user';
import mutedIcon from '@jetbrains/icons/muted';
import { licenseVaultApi } from '@jetbrains-license-vault/client/lib';
import { format, parseISO } from 'date-fns';
import { isNil } from 'lodash';
import { ConstituentEnabledWidget } from '@modules/constituents/widget/constituent-enabled-widget';
import { useDeniedLicensesNumber } from '@common/queries/license-vault';
import NavigationLink from '@common/navigation/navigation-link';
import { StatisticsWidgetCounter } from '@common/widgets/statistics-widget/statistics-widget-counter';
import { StatisticsWidgetInfoStats, StatisticsWidgetInfoStatsSize } from '@common/widgets/statistics-widget/statistics-widget-info-stats';
import { assignTestId } from '@common/infra/tests/assign-test-id';
export function LvActiveWidget(props) {
    var _a, _b, _c;
    const planInfo = licenseVaultApi.server.usePlanInfo();
    // @ts-ignore
    const licenses = licenseVaultApi.licenses.useLicenses('product');
    const validUntil = useMemo(() => {
        var _a, _b;
        return ((_a = planInfo.data) === null || _a === void 0 ? void 0 : _a.validUntil)
            ? parseISO((_b = planInfo.data) === null || _b === void 0 ? void 0 : _b.validUntil)
            : undefined;
    }, [planInfo.data]);
    const { data: deniedRequestsNumber } = useDeniedLicensesNumber();
    const usersTotal = licenseVaultApi.users.useUsersTotal();
    const licensesStat = useMemo(() => {
        const data = Array.isArray(licenses.data) && !licenses.error ? licenses.data : [];
        return data.reduce((accum, license) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            const { usage } = license;
            return {
                available: accum.available +
                    (((_a = usage === null || usage === void 0 ? void 0 : usage.virtual) === null || _a === void 0 ? void 0 : _a.available) || 0) +
                    (((_b = usage === null || usage === void 0 ? void 0 : usage.regular) === null || _b === void 0 ? void 0 : _b.available) || 0) +
                    (((_c = usage === null || usage === void 0 ? void 0 : usage.trueUp) === null || _c === void 0 ? void 0 : _c.available) || 0),
                used: accum.used +
                    (((_e = (_d = license === null || license === void 0 ? void 0 : license.usage) === null || _d === void 0 ? void 0 : _d.virtual) === null || _e === void 0 ? void 0 : _e.used) || 0) +
                    (((_g = (_f = license === null || license === void 0 ? void 0 : license.usage) === null || _f === void 0 ? void 0 : _f.regular) === null || _g === void 0 ? void 0 : _g.used) || 0) +
                    (((_j = (_h = license === null || license === void 0 ? void 0 : license.usage) === null || _h === void 0 ? void 0 : _h.trueUp) === null || _j === void 0 ? void 0 : _j.used) || 0)
            };
        }, { available: 0, used: 0 });
    }, [licenses]);
    return (_jsxs(ConstituentEnabledWidget, { ...props, ...assignTestId('widget-license-vault', props), loading: !planInfo.data || !licenses.data, children: [_jsx(StatisticsWidgetInfoStats, { statName: "Plan:", statValue: _jsxs(_Fragment, { children: [_jsx(NavigationLink, { href: "/license-vault/settings", children: (_a = planInfo.data) === null || _a === void 0 ? void 0 : _a.name }), ' ', validUntil && _jsxs(Text, { info: true, children: ["until ", format(validUntil, 'dd MMM yyyy')] })] }), size: StatisticsWidgetInfoStatsSize.Narrow }), _jsx(StatisticsWidgetCounter, { icon: permissionIcon, count: licensesStat.used, label: "Licenses in use", ...assignTestId('licenses-in-use') }), _jsx(StatisticsWidgetCounter, { icon: userIcon, count: (_c = (_b = usersTotal.data) === null || _b === void 0 ? void 0 : _b.total) !== null && _c !== void 0 ? _c : 0, label: "Active users" }), !isNil(deniedRequestsNumber) && (_jsx(StatisticsWidgetCounter, { icon: mutedIcon, count: deniedRequestsNumber, label: "Requests denied for 24h" }))] }));
}
