import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Tray from '@jetbrains/ring-ui/components/header/tray';
import Theme, { ThemeProvider } from '@jetbrains/ring-ui/components/global/theme';
import auth from '@app/common/auth/auth';
// eslint-disable-next-line import/no-restricted-paths
import { IdeServicesLogo } from '@app/modules/ide-services/ide-services-logo/ide-services-logo';
import NavigationLink from '@app/common/navigation/navigation-link';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import HeaderDropdown from './authorized-header-dropdown';
import { OfflineModeBanner } from './offline-mode-banner';
import { NavigationMenu } from './navigation/navigation-menu';
import styles from './app-header.css';
function AuthorizedHeader() {
    return (_jsx(ThemeProvider, { theme: Theme.DARK, children: _jsx("header", { className: styles.appHeader, ...assignTestId('app-header', 'authorized'), children: _jsxs("div", { className: styles.contentWrapper, children: [_jsxs("div", { className: styles.innerContainer, children: [_jsx(NavigationLink, { href: "/", children: _jsx(IdeServicesLogo, { collapsible: true }) }), _jsx(NavigationMenu, {})] }), _jsxs(Tray, { className: styles.tray, children: [auth.getFeatures().offlineModeEnabled && auth.hasRoles(['ADMIN']) && (_jsx(OfflineModeBanner, {})), _jsx(HeaderDropdown, {})] })] }) }) }));
}
export default AuthorizedHeader;
