import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import Select from '@jetbrains/ring-ui/components/select/select';
import { usePluginChannels } from '@app/common/queries/plugins';
function TbePluginChannelField() {
    const { watch, setValue } = useFormContext();
    const watchChannel = watch('channel');
    const watchPluginId = watch('pluginId');
    const disabled = isEmpty(watchPluginId);
    const { data, isFetching } = usePluginChannels(watchPluginId, !disabled);
    const channels = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.items) === null || _a === void 0 ? void 0 : _a.filter(x => x.name !== 'Stable'); }, [data]);
    const selected = isEmpty(watchChannel)
        ? undefined
        : { key: watchChannel.id, label: watchChannel.name };
    const handleSelect = (value) => {
        return setValue('channel', value ? { id: `${value === null || value === void 0 ? void 0 : value.key}`, name: `${value === null || value === void 0 ? void 0 : value.label}` } : undefined);
    };
    return (_jsx(Select, { onChange: handleSelect, label: "Unstable recommended channel", selectedLabel: "Channel", disabled: disabled, data: channels === null || channels === void 0 ? void 0 : channels.map(x => ({
            key: x.id,
            label: x.name,
            'data-test': `channel-option-${x.name}`
        })), size: Size.L, selected: selected, loading: isFetching, clear: true, "data-test": "channel-field" }));
}
function ChannelField() {
    const { watch } = useFormContext();
    const watchSource = watch('source');
    return (watchSource === 'TBE' && _jsx(TbePluginChannelField, {})) || null;
}
export default ChannelField;
