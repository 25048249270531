// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[8].use[1]!../../../../node_modules/@jetbrains/ring-ui/components/global/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__d8e {
  height: 100%;
  display: flex;
}

.wrapperInner__c6d {
  position: relative;

  margin: calc(var(--ring-unit) * 15) auto;
  max-width: calc(var(--ring-unit) * 90);

  @media ${___CSS_LOADER_ICSS_IMPORT_0___.locals["large-screen-media"]} {
    margin-left: calc(var(--ring-unit) * 26);
  }
}

h1 + .text__fcd {
  margin-top: calc(var(--ring-unit) * 2);
}

h1 + .text__fcd.retryBlock__d83 {
  margin-top: calc(var(--ring-unit) * 4);
}

.downloadToolbox__fa1 {
  margin-top: var(--ring-unit);
  color: var(--ring-action-link-color);
}

.downloadToolboxRetry__ca2,
.downloadToolboxRetry__ca2 svg {
  color: var(--ring-link-hover-color);
  fill: var(--ring-link-hover-color);
}

.joinButton__ba1 {
  margin: calc(var(--ring-unit) * 3) 0;
}

.aboutParagraph__bf9 {
  margin-top: calc(var(--ring-unit) * 4);
}

.aboutLink__d10 {
  padding: 0;
}

.manualDownloadButton__e7c {
  margin-top: calc(var(--ring-unit) * 4);
  margin-left: -12px;
}

.manualDownloadContent__b1f {
  padding-left: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/code-with-me/session-page/session-page.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;;EAElB,wCAAwC;EACxC,sCAAsC;;EAEtC;IACE,wCAAwC;EAC1C;AACF;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,4BAA4B;EAC5B,oCAAoC;AACtC;;AAEA;;EAEE,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["@value large-screen-media from '~@jetbrains/ring-ui/components/global/global.css';\n\n.wrapper {\n  height: 100%;\n  display: flex;\n}\n\n.wrapperInner {\n  position: relative;\n\n  margin: calc(var(--ring-unit) * 15) auto;\n  max-width: calc(var(--ring-unit) * 90);\n\n  @media large-screen-media {\n    margin-left: calc(var(--ring-unit) * 26);\n  }\n}\n\nh1 + .text {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n\nh1 + .text.retryBlock {\n  margin-top: calc(var(--ring-unit) * 4);\n}\n\n.downloadToolbox {\n  margin-top: var(--ring-unit);\n  color: var(--ring-action-link-color);\n}\n\n.downloadToolboxRetry,\n.downloadToolboxRetry svg {\n  color: var(--ring-link-hover-color);\n  fill: var(--ring-link-hover-color);\n}\n\n.joinButton {\n  margin: calc(var(--ring-unit) * 3) 0;\n}\n\n.aboutParagraph {\n  margin-top: calc(var(--ring-unit) * 4);\n}\n\n.aboutLink {\n  padding: 0;\n}\n\n.manualDownloadButton {\n  margin-top: calc(var(--ring-unit) * 4);\n  margin-left: -12px;\n}\n\n.manualDownloadContent {\n  padding-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"large-screen-media": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["large-screen-media"]}`,
	"wrapper": `wrapper__d8e`,
	"wrapperInner": `wrapperInner__c6d`,
	"text": `text__fcd`,
	"retryBlock": `retryBlock__d83`,
	"downloadToolbox": `downloadToolbox__fa1`,
	"downloadToolboxRetry": `downloadToolboxRetry__ca2`,
	"joinButton": `joinButton__ba1`,
	"aboutParagraph": `aboutParagraph__bf9`,
	"aboutLink": `aboutLink__d10`,
	"manualDownloadButton": `manualDownloadButton__e7c`,
	"manualDownloadContent": `manualDownloadContent__b1f`
};
export default ___CSS_LOADER_EXPORT___;
