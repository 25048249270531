// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topBar__f0e {
  max-width: 500px; /* Also used by the filterEditorContainer */
  display: flex;
  justify-content: space-between;
}

.buttonsToolbar__b62 {
  align-self: flex-end;
}

.content__f1b {
  max-width: 500px; /* Also used by the ruleTopBar */
}

.addRuleButton__bfa {
  padding: 0;
  align-self: start;
}

.notificationRule__cd0:hover {
  border: 1px solid var(--ring-icon-warning-color);
}

.notificationText__f36 {
  color: var(--ring-warning-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/ide-settings/form/common/rule-editor/rule-editor.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAE,2CAA2C;EAC7D,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,gBAAgB,EAAE,gCAAgC;AACpD;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,gDAAgD;AAClD;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".topBar {\n  max-width: 500px; /* Also used by the filterEditorContainer */\n  display: flex;\n  justify-content: space-between;\n}\n\n.buttonsToolbar {\n  align-self: flex-end;\n}\n\n.content {\n  max-width: 500px; /* Also used by the ruleTopBar */\n}\n\n.addRuleButton {\n  padding: 0;\n  align-self: start;\n}\n\n.notificationRule:hover {\n  border: 1px solid var(--ring-icon-warning-color);\n}\n\n.notificationText {\n  color: var(--ring-warning-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topBar": `topBar__f0e`,
	"buttonsToolbar": `buttonsToolbar__b62`,
	"content": `content__f1b`,
	"addRuleButton": `addRuleButton__bfa`,
	"notificationRule": `notificationRule__cd0`,
	"notificationText": `notificationText__f36`
};
export default ___CSS_LOADER_EXPORT___;
