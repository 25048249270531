import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Input, { Size as InputSize } from '@jetbrains/ring-ui/components/input/input';
import Select from '@jetbrains/ring-ui/components/select/select';
import { Required } from '@common/form/important-asterisk';
import RingFieldsList from '@common/form/ring-fields-list';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from '../styles.css';
import { AI_PRESET_OPTIONS, DEFAULT_PRESET_VALUES } from '../../constants';
import { OpenAiModelsTable } from './open-ai-models-table';
const API_KEY_EXISTENCE_INDICATOR = '⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱';
export function OpenAiProviderForm({ form }) {
    var _a, _b, _c, _d, _e, _f;
    const { setValue, watch, getValues, formState: { errors } } = form;
    const apiKey = watch('provider.openai.apiKey');
    const handlePresetSelect = (preset) => {
        if (preset) {
            const provider = getValues('provider.openai');
            if (provider) {
                setValue('provider.openai', { ...provider, ...DEFAULT_PRESET_VALUES[preset.key] }, { shouldDirty: true });
            }
        }
    };
    return (_jsxs(RingFieldsList, { children: [_jsx(Select, { ...assignTestId('preset-select'), selectedLabel: "Preset", className: styles.select, data: AI_PRESET_OPTIONS, selected: AI_PRESET_OPTIONS.find(item => item.key === watch('provider.openai.preset')), onSelect: handlePresetSelect }), _jsx(Input, { value: watch('provider.openai.url'), label: Required('Endpoint'), onChange: val => setValue('provider.openai.url', val.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                }), size: InputSize.L, error: (_c = (_b = (_a = errors.provider) === null || _a === void 0 ? void 0 : _a.openai) === null || _b === void 0 ? void 0 : _b.url) === null || _c === void 0 ? void 0 : _c.message }), _jsx(Input, { ...assignTestId('api-key-input'), value: watch('provider.openai.apiKey'), label: Required('API key'), placeholder: apiKey === null ? API_KEY_EXISTENCE_INDICATOR : '', onChange: val => setValue('provider.openai.apiKey', val.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                }), type: "password", size: InputSize.L, error: (_f = (_e = (_d = errors.provider) === null || _d === void 0 ? void 0 : _d.openai) === null || _e === void 0 ? void 0 : _e.apiKey) === null || _f === void 0 ? void 0 : _f.message }), _jsx(OpenAiModelsTable, { models: watch('provider.openai.models'), onModelChange: models => setValue('provider.openai.models', models, { shouldValidate: true, shouldDirty: true }), preset: watch('provider.openai.preset') })] }));
}
