import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Badge from '@jetbrains/ring-ui/components/badge/badge';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import NavigationLink from '@common/navigation/navigation-link';
import { getProfileUrl } from '../functions';
import styles from '../profiles-admin-body.css';
export function ProfilesTableTitle({ title, id, isDefault }) {
    return (_jsxs("div", { className: styles.title, children: [_jsx(NavigationLink, { ...assignTestId('profile-link', 'profile-title', {
                    'profile-default': isDefault
                }), href: getProfileUrl(id), children: title }), isDefault ? (_jsx(Badge, { "data-test": "default-profile-badge", className: styles.badge, children: "Default" })) : null] }));
}
