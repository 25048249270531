import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import HorizontalContainer from '@common/page-layout/horizontal-container';
import SearchFilter from '@common/search-filter/search-filter';
export function PluginsAuditLogsFilters({ params, onFiltersChange }) {
    const handleSearchValueChange = useCallback((newValue) => {
        onFiltersChange({ ...params, filter: newValue });
    }, [onFiltersChange, params]);
    return (_jsx(HorizontalContainer, { children: _jsx(SearchFilter, { value: params.filter || '', onChange: handleSearchValueChange, placeholder: "Search plugin ID" }) }));
}
