import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { assignProfileToGroup, getGroupDetails, getGroups, getGroupsForProfile, getUsersForGroups, removeGroupFromProfile, syncIdpGroups } from '@api/groups';
import { MIN_LIST_SIZE } from '@api/paging-utils';
import { formatApiError } from '../../api/errors';
import { getMutationAlertMessages, getQueryAlertMessages } from './formatters/alerts';
import { DEFAULT_STALE_TIME } from './query-client';
import { invalidateAllQueries } from './functions';
export const keys = {
    all: ['groups'],
    groupDetails: (id) => ['groups', { id }],
    groups: (params) => ['groups', { params }],
    groupsForProfile: (profileId, params) => ['groups', 'profiles', { profileId }, { params }],
    groupUsers: (id, limit) => ['groups', id, limit],
    infinite: (filters) => ['groups', 'infinite', { filters }]
};
export function useGetGroups(params) {
    return useQuery({
        queryKey: keys.groups(params),
        queryFn: () => getGroups(params),
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load groups'))
    });
}
export function useGetUsersForGroup(groupId, limit) {
    return useQuery({
        queryKey: keys.groupUsers(groupId, limit),
        queryFn: () => getUsersForGroups(groupId, { limit }),
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load users for group'))
    });
}
const getPageParam = (pageParam) => ({
    page: pageParam,
    sortKey: 'name',
    sortOrder: 'ASC',
    limit: 10
});
export function useInfiniteGroupsQuery(filters, enabled = true) {
    return useInfiniteQuery({
        enabled,
        queryKey: keys.infinite(filters),
        queryFn: ({ pageParam = 1 }) => getGroups({ ...getPageParam(pageParam), ...filters }),
        getNextPageParam: (lastPage, allPages) => {
            const { total } = lastPage;
            const current = allPages.flatMap(({ items }) => items).length;
            if (total > current) {
                return current / MIN_LIST_SIZE + 1;
            }
            return undefined;
        },
        staleTime: DEFAULT_STALE_TIME,
        keepPreviousData: true,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load groups'))
    });
}
export function useGetGroupsForProfilesQuery(profileId, params) {
    return useQuery({
        queryFn: () => getGroupsForProfile(profileId, params),
        queryKey: keys.groupsForProfile(profileId, params),
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load groups')),
        staleTime: DEFAULT_STALE_TIME
    });
}
export function useAssignGroupToProfileMutation() {
    return useMutation({
        mutationFn: ({ profileId, groupId }) => assignProfileToGroup(profileId, groupId),
        onSuccess: async () => {
            await invalidateAllQueries();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to assign profile'), () => 'Profile assigned to group')
    });
}
export function useGetGroupDetailsForSelect(groupId, enabled) {
    return useQuery({
        queryFn: () => getGroupDetails(groupId),
        queryKey: keys.groupDetails(groupId),
        enabled
    });
}
export function useRemoveGroupFromProfile() {
    return useMutation({
        mutationFn: ({ groupId, profileId }) => removeGroupFromProfile(groupId, profileId),
        onSuccess: async () => {
            await invalidateAllQueries();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to unassign profile'), () => 'Profile unassigned from group')
    });
}
export function useSyncIdpUsersAndGroups(limit) {
    return useMutation({
        mutationFn: () => syncIdpGroups(limit),
        onSuccess: async () => {
            await invalidateAllQueries();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to synchronize users and groups'), data => {
            if (data.syncType === 'SCHEDULED') {
                return 'Users and groups synchronisation is successfully scheduled';
            }
            return data.updatedUsers.total === 0
                ? 'Users and groups are up to date'
                : `Users and groups synchronized. ${data.updatedUsers.total} users, ${data.updatedGroups.total} groups were affected.`;
        })
    });
}
