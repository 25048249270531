import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { BasicSelectableTable } from '@common/tables/basic-selectable-table';
import { AutomationTokenAuthor } from './cells/automation-token-author';
import { AutomationTokenDate } from './cells/automation-token-date';
import { AutomationTokenUpdatedBy } from './cells/automation-token-updated-by';
import { AutomationTokenClientId } from './cells/automation-token-client-id';
import { AutomationTokenActions } from './cells/automation-token-actions';
export function AutomationTokensTable(props) {
    const { className, tokens, loading, onEditTokenClick, onDeleteTokenClick, selectedTokenState } = props;
    const columns = useMemo(() => [
        {
            id: 'clientId',
            title: 'Token name',
            getValue: (token) => _jsx(AutomationTokenClientId, { token: token })
        },
        {
            id: 'createdBy',
            title: 'Author',
            getValue: (token) => _jsx(AutomationTokenAuthor, { token: token })
        },
        {
            id: 'createdDate',
            title: 'Created',
            rightAlign: true,
            getValue: (token) => (_jsx(AutomationTokenDate, { date: new Date(token.createdDate) }))
        },
        {
            id: 'updated_by',
            title: 'Updated',
            rightAlign: true,
            getValue: token => {
                if (token.lastModifiedDate && token.lastModifiedBy) {
                    return _jsx(AutomationTokenUpdatedBy, { token: token });
                }
                return undefined;
            }
        },
        {
            id: 'expireDate',
            title: 'Expires',
            rightAlign: true,
            getValue: (token) => {
                return token.expireDate ? (_jsx(AutomationTokenDate, { date: new Date(token.expireDate), expired: token.state === 'EXPIRED' })) : undefined;
            }
        },
        {
            id: 'actions',
            getValue: token => (_jsx(AutomationTokenActions, { token: token, onDeleteTokenClick: onDeleteTokenClick, onEditTokenClick: onEditTokenClick, disabled: token.state !== 'ACTIVE' }))
        }
    ], [onDeleteTokenClick, onEditTokenClick]);
    return (_jsx(BasicSelectableTable, { tableWrapperClassName: className, data: tokens, columns: columns, selectable: false, renderEmpty: () => (_jsx("div", { children: selectedTokenState === 'ACTIVE' ? 'No active tokens' : 'No automation tokens created' })), loading: loading }));
}
