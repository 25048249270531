// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageTitle__c0e {
  white-space: nowrap;
  margin-bottom: calc(3 * var(--ring-unit));
}

.pageTitleRightPanel__d22 {
  float: right;
  padding: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.pageTitleContainer__be2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: calc(var(--ring-unit) * 1.25);
}

.title__ec5 {
  display: inline-flex;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/common/page-layout/page-title.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,kCAAkC;AACpC;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB","sourcesContent":[".pageTitle {\n  white-space: nowrap;\n  margin-bottom: calc(3 * var(--ring-unit));\n}\n\n.pageTitleRightPanel {\n  float: right;\n  padding: 4px 0;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-left: auto;\n}\n\n.pageTitleContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  gap: calc(var(--ring-unit) * 1.25);\n}\n\n.title {\n  display: inline-flex;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageTitle": `pageTitle__c0e`,
	"pageTitleRightPanel": `pageTitleRightPanel__d22`,
	"pageTitleContainer": `pageTitleContainer__be2`,
	"title": `title__ec5`
};
export default ___CSS_LOADER_EXPORT___;
