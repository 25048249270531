import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import { getOsName } from '@modules/ide-provisioner/application-settings/common/name-formatter/get-os-name';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { Required } from '@common/form/important-asterisk';
import styles from './selector.css';
export function OsSelector(props) {
    const { osList, onOsSelect, selectedOs } = props;
    const osItems = useMemo(() => osList.map(os => createOsSelectItem(os)), [osList]);
    const handleSelectOs = useCallback((selected) => {
        if (selected) {
            onOsSelect(selected.buildOs);
        }
    }, [onOsSelect]);
    return (_jsxs("div", { ...assignTestId('os-select-wrapper'), children: [_jsx("p", { ...assignTestId('os-select-label'), className: styles.label, children: Required('OS') }), _jsx(Select, { ...assignTestId('os-select'), className: styles.selector, onSelect: handleSelectOs, data: osItems, selected: selectedOs ? createOsSelectItem(selectedOs) : undefined, label: "Choose OS" })] }));
}
function createOsSelectItem(os) {
    return {
        buildOs: os,
        key: os,
        label: getOsName(os)
    };
}
