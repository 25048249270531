import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import { useCodeWithMeConstituent } from '@app/common/queries/code-with-me';
import { getProfileConstituents } from '@app/common/store/profiles/selectors';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
import { CWMDisabled } from './cwm-disabled/cwm-disabled';
import { CWMAvailable } from './cwm-available/cwm-available';
export function ProfileCodeWithMePage() {
    const { data: cwmResponse, isLoading: isCwmLoading } = useCodeWithMeConstituent();
    const { cwm } = useSelector(getProfileConstituents);
    const dispatch = useDispatch();
    const setEnabledForProfile = useCallback((enabled) => dispatch(ProfileActions.updateCwmConstituent({ isEnabled: enabled })), [dispatch]);
    if (isCwmLoading) {
        return _jsx(Loader, {});
    }
    if ((cwmResponse === null || cwmResponse === void 0 ? void 0 : cwmResponse.status) === 'enabled') {
        return (_jsx(CWMAvailable, { enabledForProfile: cwm.isEnabled, setEnabledForProfile: setEnabledForProfile }));
    }
    return _jsx(CWMDisabled, {});
}
