import {orderBy} from 'lodash';
import {sliceToPage} from '../../api/paging-utils';

const SET_ITEMS = 'SET_ITEMS';
const IS_FETCHING = 'IS_FETCHING';
const FETCHING_ERROR = 'FETCHING_ERROR';
const PAGE_PARAMS = 'PAGE_PARAMS';
const SORT_PARAMS = 'SORT_PARAMS';

export function baseAnalyticsTableReducer(eventTypes, onUnknownEventType, state, action) {
  const eventToGenericHandler = objectFlip(eventTypes);
  switch (eventToGenericHandler[action.type]) {
    case SET_ITEMS:
      const items = action.payload.map(e => {
        return {
          ...e,
          key: Object.values(e).join('_')
        };
      });
      const afterSorting = sortRecords(items, state.sortKey, state.sortOrder);
      return {
        ...state,
        items: afterSorting,
        isFetching: false,
        isFetchError: false,
        currentPageData: sliceToPage(afterSorting, state.pageSize, 1),
        currentPageIndex: 1,
        totalItems: items.length
      };
    case IS_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case FETCHING_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchError: true
      };
    case PAGE_PARAMS:
      return {
        ...state,
        currentPageIndex: action.payload.nextPageIndex,
        currentPageData: sliceToPage(
          state.items,
          action.payload.pageSize,
          action.payload.nextPageIndex
        ),
        pageSize: action.payload.pageSize
      };
    case SORT_PARAMS:
      const sorted = sortRecords(state.items, action.payload.column.id, action.payload.order);
      return {
        ...state,
        items: sorted,
        sortKey: action.payload.column.id,
        sortOrder: action.payload.order,
        currentPageData: sliceToPage(sorted, state.pageSize, state.currentPageIndex)
      };
    default:
      return onUnknownEventType(action, state);
  }
}

function objectFlip(obj) {
  const ret = {};
  Object.keys(obj).forEach(key => {
    ret[obj[key]] = key;
  });
  return ret;
}

export function sortRecords(records, sortKey, order) {
  return orderBy(records, [sortKey], [order ? 'asc' : 'desc']);
}
