import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getApplication } from '@app/common/store/profiles/selectors';
import { getBuildsGroups } from './get-builds-groups';
import { NestedDataList } from './nested-data-list/nested-data-list';
import styles from './builds-list.css';
export function BuildsList({ item }) {
    const applicationData = useSelector(state => getApplication(state, item));
    const buildsGroups = useMemo(() => (applicationData ? getBuildsGroups(applicationData) : undefined), [applicationData]);
    return (_jsx("div", { className: styles.buildListContainer, children: buildsGroups && _jsx(NestedDataList, { data: buildsGroups }) }));
}
