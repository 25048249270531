export const initialState = {
    id: '',
    title: '',
    description: '',
    isDefault: false,
    applications: {
        content: [],
        rules: []
    },
    settings: {
        ide: [],
        vmOptions: [],
        toolbox: [],
        autoinstalledTools: [],
        marketplace: []
    },
    plugins: {
        disabled: [],
        autoInstalled: []
    },
    restrictions: {
        rules: []
    },
    constituents: {
        ai: { isEnabled: false, allowDataCollection: false, provider: undefined },
        idep: { isEnabled: false },
        cwm: { isEnabled: false }
    },
    version: 0
};
