// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__ac5 {
  display: flex;
  flex-direction: column;

  .viewSelector__f3c {
    margin-left: auto;
  }

  .viewSelector__f3c + .settingsView__d4d {
    margin-top: calc(var(--ring-unit) * 3);
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/ide-provisioner/application-settings/app-build-settings/app-build-settings.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;;EAEtB;IACE,iBAAiB;EACnB;;EAEA;IACE,sCAAsC;EACxC;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n\n  .viewSelector {\n    margin-left: auto;\n  }\n\n  .viewSelector + .settingsView {\n    margin-top: calc(var(--ring-unit) * 3);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__ac5`,
	"viewSelector": `viewSelector__f3c`,
	"settingsView": `settingsView__d4d`
};
export default ___CSS_LOADER_EXPORT___;
