import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import Text from '@jetbrains/ring-ui/components/text/text';
// eslint-disable-next-line no-restricted-imports
import HelpIcon from '@common/util/help-icon';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './user-machine-info-list.css';
export function UserMachineInfoList(props) {
    const { machineInfo } = props;
    const { ram, cpuCores, cpuArch } = machineInfo;
    return (_jsxs("div", { ...assignTestId('user-machine-info'), children: [_jsxs(H3, { children: ["Machine Info", ' ', _jsx(Tooltip, { title: `JetBrains IDE Services retrieves the details about the developer's most recently used machine. This information is updated every time the developer launches an IDE.`, children: _jsx(HelpIcon, {}) })] }), _jsxs("div", { className: styles.fieldsContainer, children: [_jsx("span", { children: "RAM size" }), _jsx("div", { children: _jsx(Text, { children: ram ? `${ram} GB` : 'unknown' }) }), _jsx("span", { children: "CPU cores" }), _jsx("div", { children: _jsx(Text, { children: cpuCores ? `${cpuCores}` : 'unknown' }) }), _jsx("span", { children: "CPU architecture" }), _jsx("div", { children: _jsx(Text, { children: (cpuArch === null || cpuArch === void 0 ? void 0 : cpuArch.toString()) || 'unknown' }) })] })] }));
}
