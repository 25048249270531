import { sha256 } from 'js-sha256';
import { encode as b64urlEncode } from 'b64web';
import { localStorageService } from '@app/common/storage';
import { randomString } from './url-hash';
import { AuthError } from './auth-models';
const LOGIN_CHALLENGE_VERIFIER_KEY = 'LOGIN_CHALLENGE_VERIFIER';
export async function getUserProfile(baseURL, accessToken) {
    var _a;
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('X-Request-Source', 'TBE_web');
    const response = await fetch(`${baseURL}/api/users/me`, {
        method: 'GET',
        headers
    });
    if (!response.ok) {
        const defaultMessage = `Unable to load user profile. Server response status: ${response.status}`;
        if (response.headers.get('Content-Type') === 'application/json') {
            const data = await response.json();
            throw new AuthError((_a = data.detail) !== null && _a !== void 0 ? _a : defaultMessage, response.status);
        }
        throw new AuthError(defaultMessage, response.status);
    }
    return response.json();
}
export async function doRefreshTokens(baseURL, refreshToken) {
    const response = await fetch(`${baseURL}/api/login/refresh`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Request-Source': 'TBE_web'
        },
        body: JSON.stringify({
            refresh_token: refreshToken
        })
    });
    if (!response.ok) {
        throw new AuthError(`Unable to refresh OAuth2 token.\nServer response status: ${response.status}`, response.status);
    }
    return response.json();
}
export async function exchangeCodeForTokens(baseURL, code) {
    const verifier = localStorageService.get(LOGIN_CHALLENGE_VERIFIER_KEY);
    if (verifier == null) {
        return;
    }
    localStorageService.remove(LOGIN_CHALLENGE_VERIFIER_KEY);
    const response = await fetch(`${baseURL}/api/login/token`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Request-Source': 'TBE_web'
        },
        body: JSON.stringify({
            code,
            verifier
        })
    });
    if (!response.ok) {
        throw new AuthError(`Unable to get OAuth2 token.\nServer response status: ${response.status}`, response.status);
    }
    // eslint-disable-next-line consistent-return
    return response.json();
}
export async function redirectToLogin(baseURL, option) {
    const verifier = randomString(128);
    const encoder = new TextEncoder();
    const data = encoder.encode(verifier);
    const digest = sha256.create().update(data).arrayBuffer();
    // challenge is sent to initiate authentication
    const challenge = b64urlEncode(digest, { urlsafe: true });
    // remember verifier until we receive code
    localStorageService.set(LOGIN_CHALLENGE_VERIFIER_KEY, verifier);
    const redirectPath = encodeURIComponent(window.location.pathname);
    // language=http-url-reference
    let url = `${baseURL}/api/login?source=web&challenge=${challenge}&redirect=${redirectPath}`;
    if (option) {
        url = `${url}&option=${option}`;
    }
    return url;
}
