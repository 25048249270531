import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { parseISO } from 'date-fns';
import Text from '@jetbrains/ring-ui/components/text/text';
import groupIcon from '@jetbrains/icons/group';
import { useActiveUsersWidgetStatistics } from '@common/queries/dashboard';
import { Widget, WidgetSize, WidgetVariant } from '@common/widgets/widget/widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { IntervalStatisticsWidgetCounter } from '@common/widgets/statistics-widget/statistics-widget-counter';
import { StatisticsWidgetBarChart } from '@common/widgets/statistics-widget/statistics-widget-bar-chart';
export function ActiveUsers() {
    var _a;
    const { data, isLoading } = useActiveUsersWidgetStatistics();
    const [activeInterval, setActiveInterval] = useState();
    const chartIntervals = useMemo(() => ((data === null || data === void 0 ? void 0 : data.perDays) || [])
        .map(it => ({
        from: parseISO(it.date),
        to: parseISO(it.date),
        total: it.activeUsersCount
    }))
        .sort((a, b) => {
        if (a.from.getTime() === b.from.getTime()) {
            return 0;
        }
        return a.from.getTime() < b.from.getTime() ? -1 : 1;
    }), [data]);
    const hasActiveUsers = useMemo(() => !!(data === null || data === void 0 ? void 0 : data.total) && (data === null || data === void 0 ? void 0 : data.total) > 0, [data]);
    useEffect(() => {
        if (!activeInterval && chartIntervals.length) {
            setActiveInterval(chartIntervals[chartIntervals.length - 1]);
        }
    }, [activeInterval, chartIntervals]);
    return (_jsxs(Widget, { title: "Active Users", loading: isLoading, widgetSize: WidgetSize.Wide, variant: WidgetVariant.Filled, icon: groupIcon, ...assignTestId('active-users'), children: [!hasActiveUsers && _jsx(Text, { info: true, children: "No active users this month" }), hasActiveUsers && activeInterval && (_jsxs(_Fragment, { children: [_jsx(StatisticsWidgetBarChart, { intervals: chartIntervals, activeInterval: activeInterval, onActivateInterval: setActiveInterval }), _jsx(IntervalStatisticsWidgetCounter, { activeInterval: activeInterval, icon: groupIcon, count: (_a = activeInterval === null || activeInterval === void 0 ? void 0 : activeInterval.total) !== null && _a !== void 0 ? _a : 0, measure: "Active users" })] }))] }));
}
