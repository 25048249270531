import { jsx as _jsx } from "react/jsx-runtime";
import { ConstituentDisabledWidget } from '@modules/constituents/widget/constituent-disabled-widget';
import { useOpenModal } from '@common/modals/hooks';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { LvEnableDialog } from '../../../common/dialogs/lv-enable-dialog/lv-enable-dialog';
export function LvDisabledNoTrialWidget(props) {
    const { licenseType } = props;
    const openEnableDialog = useOpenModal(LvEnableDialog, {
        showPostpaidHint: licenseType === 'POSTPAID'
    });
    return (_jsx(ConstituentDisabledWidget, { ...props, ...assignTestId('widget-license-vault'), onEnableClick: openEnableDialog, children: "Seamlessly distribute and manage JetBrains IDE licenses with features like configurable access rules, real-time usage tracking, and secure access with single sign-on" }));
}
