// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warningText__afb {
  color: var(--ring-warning-color);
  text-align: justify;
}

.warningIcon__de0 {
  margin-right: var(--ring-unit);
}

.infoText__d09 {
  text-align: justify;
}

.formContainer__d66 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 1.5);
}

.dialogContainer__bc9.dialogContainer__bc9 > div > div {
  min-width: 600px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/autoinstalled-tools/profiles-autoinstalled-tools-form.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".warningText {\n  color: var(--ring-warning-color);\n  text-align: justify;\n}\n\n.warningIcon {\n  margin-right: var(--ring-unit);\n}\n\n.infoText {\n  text-align: justify;\n}\n\n.formContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.dialogContainer.dialogContainer > div > div {\n  min-width: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningText": `warningText__afb`,
	"warningIcon": `warningIcon__de0`,
	"infoText": `infoText__d09`,
	"formContainer": `formContainer__d66`,
	"dialogContainer": `dialogContainer__bc9`
};
export default ___CSS_LOADER_EXPORT___;
