import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { noop } from 'lodash';
import Button from '@jetbrains/ring-ui/components/button/button';
import { CloseableInformationBanner } from '@common/information-banner/closeable-infomation-banner';
import NavigationLink from '@common/navigation/navigation-link';
import { useSyncIdpUsersAndGroups } from '@common/queries/groups';
import idProvider from '../id-provider/id-provider';
import styles from './no-created-users-banner.css';
const DEFAULT_TEXT = 'No users yet?';
export function NoCreatedUsersBanner({ children, bannerKey }) {
    const hasIdp = idProvider.isEnabled();
    const { mutateAsync: syncMutation, isLoading: syncUsersInProgress, isSuccess: syncUsersIsSuccessful } = useSyncIdpUsersAndGroups();
    const handleSyncClick = useCallback(() => syncMutation().catch(noop), [syncMutation]);
    return (_jsx(CloseableInformationBanner, { variant: "neutral", bannerKey: bannerKey, className: styles.banner, children: _jsxs("div", { children: [children || DEFAULT_TEXT, ' ', hasIdp ? (_jsxs("span", { children: ["Please, create users on a side of your Identity Provider and", ' ', _jsx(Button, { text: true, onClick: handleSyncClick, className: styles.inlineButton, loader: syncUsersInProgress, disabled: syncUsersInProgress || syncUsersIsSuccessful, title: syncUsersIsSuccessful ? 'Users synchronization was already launched' : '', children: "synchronize" }), ' ', "them to IDES"] })) : (_jsxs("span", { children: ["Please, create users on a", ' ', _jsx(NavigationLink, { href: "/users", children: "users' list page" })] }))] }) }));
}
