// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.documentationButton__dd3 {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/banner/tutorial/tutorial-alert-content.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB","sourcesContent":[".documentationButton {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentationButton": `documentationButton__dd3`
};
export default ___CSS_LOADER_EXPORT___;
