import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { AddUsersIDPBanner } from '@modules/users/banners/add-users-idp-banner';
import idProvider from '@modules/users/id-provider/id-provider';
import { useUsers } from '@common/queries/users';
import { UsersFilters } from './filters/users-filters';
import { useUserParams } from './use-user-params';
import { UsersPageTable } from './users-page-table';
import { UsersPageActions } from './users-page-actions';
import UsersPagePopups from './popups/users-page-popups';
import styles from './users-admin-page.css';
function UsersPageBody() {
    const [urlParams, setUrlParams] = useUserParams();
    const { data, isFetching } = useUsers(urlParams);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [currentPopup, setCurrentPopup] = useState();
    const canAssignProfile = selectedUsers.length !== 0;
    return (_jsxs(_Fragment, { children: [idProvider.isEnabled() && (_jsx("div", { className: styles.infoBanner, children: _jsx(AddUsersIDPBanner, { bannerKey: "idp-info-users-list" }) })), _jsx(UsersPageActions, { openPopup: setCurrentPopup, canAssignProfile: canAssignProfile }), _jsx(UsersFilters, { params: urlParams, onParamsChange: setUrlParams }), _jsx(UsersPageTable, { data: data, isLoading: isFetching, onParamsChange: setUrlParams, params: urlParams, onSelectUsers: setSelectedUsers }), _jsx(UsersPagePopups, { selectedUsers: selectedUsers, currentPopup: currentPopup, setCurrentPopup: setCurrentPopup })] }));
}
export default UsersPageBody;
