// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.authorColumn__dfe {
  vertical-align: top;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-history/profile-history-page-body.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB","sourcesContent":[".authorColumn {\n  vertical-align: top;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authorColumn": `authorColumn__dfe`
};
export default ___CSS_LOADER_EXPORT___;
