import { createEnumParam, NumberParam, StringParam } from 'serialize-query-params';
import { pagingSortOrder } from '@api/paging-utils';
import { apiUserAuditTypeOptions } from '@api/users';
import { sortableColumns } from '@modules/users/user-details/audit-logs/constants';
import { useSyncedSearchArgs } from '@common/navigation/use-synced-search-args';
export function useUserAuditLogsParams() {
    return useSyncedSearchArgs(tsTypeToQuerySchema, validateUserAuditLogsFilter);
}
function validateUserAuditLogsFilter(parsed) {
    var _a, _b;
    return {
        search: parsed.search,
        type: parsed.type,
        page: parsed.page && parsed.page > 0 ? parsed.page : 1,
        sortOrder: (_a = parsed.sortOrder) !== null && _a !== void 0 ? _a : 'DESC',
        sortKey: (_b = parsed.sortKey) !== null && _b !== void 0 ? _b : 'date'
    };
}
const tsTypeToQuerySchema = {
    search: StringParam,
    type: createEnumParam(Object.keys(apiUserAuditTypeOptions)),
    page: NumberParam,
    sortKey: createEnumParam(Object.values(sortableColumns)),
    sortOrder: createEnumParam(pagingSortOrder)
};
