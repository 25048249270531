import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import ButtonGroup from '@jetbrains/ring-ui/components/button-group/button-group';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import { Collapsible } from '@common/collapsible/collapsible';
import { BuildDataItem } from './build-data-item';
import { BuildDataTitle } from './build-data-title';
import styles from './nested-data-list.css';
export function NestedDataList({ data }) {
    const [filterState, setFilterState] = useState('ALL');
    const filteredItems = useMemo(() => {
        if (filterState === 'ALL') {
            return data;
        }
        return data
            .map(mainBuild => {
            const children = mainBuild.children.filter(build => build.isExcluded === (filterState === 'DISABLED'));
            return { ...mainBuild, children };
        })
            .filter(item => item.children.length > 0);
    }, [data, filterState]);
    return (_jsxs("div", { className: styles.nestedListContainer, children: [_jsxs(ButtonGroup, { className: styles.filterContainer, children: [_jsx(Button, { onClick: () => setFilterState('ALL'), active: filterState === 'ALL', children: "All" }), _jsx(Button, { onClick: () => setFilterState('AVAILABLE'), active: filterState === 'AVAILABLE', children: "Available" }), _jsx(Button, { onClick: () => setFilterState('DISABLED'), active: filterState === 'DISABLED', children: "Disabled" })] }), filteredItems.map(item => {
                return (_jsx(Collapsible, { titleClassName: styles.titleContainer, title: _jsx(BuildDataTitle, { item: item }), primary: true, children: item.children.map(item => (_jsx(BuildDataItem, { item: item }))) }, item.id));
            }), filteredItems.length === 0 && _jsx(Text, { children: "No builds for selected filter" })] }));
}
