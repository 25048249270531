import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import getUID from '@jetbrains/ring-ui/components/global/get-uid';
import TagsInput from '@jetbrains/ring-ui/components/tags-input/tags-input';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import Text from '@jetbrains/ring-ui/components/text/text';
import DatePicker from '@jetbrains/ring-ui/components/date-picker/date-picker';
import ErrorBubble from '@jetbrains/ring-ui/components/error-bubble/error-bubble';
import { ensurePluginChannels } from '@app/common/queries/plugins';
import HorizontalContainer from '@app/common/page-layout/horizontal-container';
import FilesAttachment, { formatFileRejections } from '@app/common/drop-zone/files-attachment';
import { localDateString } from '@app/common/dates/format';
import { validateChannel } from '../channel-validation';
import { UploadingFilePreviews } from './uploading-file-previews';
import styles from './upload-plugin.css';
// Our FE can have significant freezes if we set big files amount because we don't optimize
// algorithms that track files being uploaded
const MAX_FILES_UPLOAD_AMOUNT = 50;
const PLUGIN_MIME_TYPES = [
    'application/gzip',
    'application/zip',
    'multipart/x-zip',
    'application/x-zip-compressed',
    'application/zip-compressed',
    'application/vnd.rar',
    'application/x-rar-compressed',
    'application/octet-stream',
    'application/x-tar',
    'application/x-gtar',
    'application/x-7z-compressed',
    'application/java-archive'
];
function toChannelTag(channel) {
    return { key: channel.id, label: channel.name, 'data-test': `channel-option-${channel.name}` };
}
export function UploadPluginForm({ channels, onChannelsUpdated, isLoading, files, addFile, removeFile, expirationDate, onExpirationDateUpdated }) {
    const [filesAttachmentError, setFilesAttachmentError] = useState(null);
    const [channelValidationError, setChannelValidationError] = useState();
    const today = useMemo(localDateString, []);
    const onFilesAttached = useCallback(async (newFiles, fileRejections) => {
        if (fileRejections.length > 0) {
            setFilesAttachmentError(formatFileRejections(fileRejections, MAX_FILES_UPLOAD_AMOUNT));
        }
        else {
            setFilesAttachmentError(null);
            newFiles.map(file => addFile({
                id: getUID(`file-${file.name}`),
                progress: -1,
                file
            }));
        }
    }, [addFile]);
    const onAddChannel = useCallback(({ tag }) => {
        if (!tag.label) {
            return;
        }
        const { errorMessage } = validateChannel(tag);
        if (errorMessage) {
            setChannelValidationError(errorMessage);
            return;
        }
        setChannelValidationError(undefined);
        onChannelsUpdated(channels.concat([{ id: tag.key, name: tag.label }]));
    }, [channels, onChannelsUpdated]);
    return (_jsxs("div", { children: [_jsxs(HorizontalContainer, { className: styles.channelsWrapper, children: [_jsx(ErrorBubble, { error: channelValidationError, children: _jsx(TagsInput, { disabled: isLoading, placeholder: !channels.length ? 'Stable' : 'Add channel', label: "Channels", tags: channels.map(toChannelTag), dataSource: () => ensurePluginChannels().then(result => result.map(toChannelTag)), allowAddNewTags: true, onAddTag: onAddChannel, onRemoveTag: ({ tag }) => onChannelsUpdated(channels.filter(it => it.id !== tag.key)), filter: true }) }), _jsx(Tooltip, { title: _jsxs(_Fragment, { children: ["A channel represents a stream of plugin versions. When you upload a plugin version to channels other than ", _jsx("code", { children: "Stable" }), ", all authorized developers will be able to install it."] }), children: _jsx(Text, { className: styles.channelsInfo, info: true, size: "s", children: "What is a channel?" }) })] }), _jsx(DatePicker, { disabled: isLoading, className: styles.expirationDate, dateTimePlaceholder: "Set expiration date", date: expirationDate, onChange: onExpirationDateUpdated, withTime: true, minDate: today, clear: true }), !isLoading && (_jsx(FilesAttachment, { className: styles.dropzone, onFilesAttached: onFilesAttached, dropzoneOptions: {
                    maxFiles: MAX_FILES_UPLOAD_AMOUNT,
                    accept: PLUGIN_MIME_TYPES
                }, dropzoneText: _jsxs(_Fragment, { children: [_jsx(Text, { info: true, children: "Drag & drop archive here, or click to select one" }), filesAttachmentError && (_jsx(Text, { size: Text.Size.S, className: styles.errorText, children: filesAttachmentError }))] }) })), _jsx(UploadingFilePreviews, { files: files, onRemove: removeFile })] }));
}
