import { addMonths, addWeeks, interval, isWithinInterval } from 'date-fns';
export function getTrialEndsInterval(trialEnd) {
    const twoWeekInterval = interval(new Date(), addWeeks(new Date(), 2));
    const oneMonthInterval = interval(new Date(), addMonths(new Date(), 1));
    if (isWithinInterval(trialEnd, twoWeekInterval)) {
        return 'twoWeeks';
    }
    if (isWithinInterval(trialEnd, oneMonthInterval)) {
        return 'oneMonth';
    }
    return 'moreThanOneMonth';
}
