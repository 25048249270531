import { jsx as _jsx } from "react/jsx-runtime";
import { useAiConstituent } from '@app/common/queries/ai-enterprise';
import { useOpenModal } from '@app/common/modals/hooks';
import { AiDisableDialog } from '../../common/dialogs/ai-disable-dialog/ai-disable-dialog';
import { AiEnableDialog } from '../../common/dialogs/ai-settings-dialog/ai-enable-dialog';
import { AiEnabled } from './ai-enabled/ai-enabled';
import { AiDisabled } from './ai-disabled/ai-disabled';
import { AiDisabledJba } from './ai-disabled-jba/ai-disabled-jba';
export function AiEnterpriseConstituent(props) {
    const { licenseType, constituentLicense, className } = props;
    const { data } = useAiConstituent();
    const limitLockType = getLimitLockType(licenseType, constituentLicense);
    const openEnableModal = useOpenModal(AiEnableDialog, {
        limitLockType,
        initialLimitUsage: constituentLicense.trial.state === 'ACTIVE' ? constituentLicense.trial.maxUsage : undefined
    });
    const openDisableAiModal = useOpenModal(AiDisableDialog, {});
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled_jba') {
        return _jsx(AiDisabledJba, { className: className });
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled') {
        return _jsx(AiDisabled, { onSetupClick: openEnableModal, className: className });
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'enabled') {
        return (_jsx(AiEnabled, { aiPlatformData: data.data, onDisableClick: openDisableAiModal, className: className, license: constituentLicense, licenseType: licenseType }));
    }
    return null;
}
function getLimitLockType(licenseType, constituentLicense) {
    if (constituentLicense.trial.state === 'ACTIVE') {
        return 'readonly';
    }
    if (licenseType === 'PREPAID') {
        return 'hidden';
    }
    return undefined;
}
