// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.licenseLimitsDialog__e98 {
  width: fit-content;
  max-width: 90vw;
  min-width: 700px;
}

.licenseName__b51.licenseName__b51 {
  min-width: calc(8 * var(--ring-unit));
  max-width: calc(20 * var(--ring-unit));
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

.licenseLimitInput__bba {
  flex-wrap: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/modules/license-vault/common/dialogs/license-vault-limits/license-vault-limits.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,qCAAqC;EACrC,sCAAsC;EACtC,uBAAuB;EACvB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".licenseLimitsDialog {\n  width: fit-content;\n  max-width: 90vw;\n  min-width: 700px;\n}\n\n.licenseName.licenseName {\n  min-width: calc(8 * var(--ring-unit));\n  max-width: calc(20 * var(--ring-unit));\n  text-overflow: ellipsis;\n  overflow: hidden;\n  display: inline-block;\n}\n\n.licenseLimitInput {\n  flex-wrap: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"licenseLimitsDialog": `licenseLimitsDialog__e98`,
	"licenseName": `licenseName__b51`,
	"licenseLimitInput": `licenseLimitInput__bba`
};
export default ___CSS_LOADER_EXPORT___;
