// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__ac3 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 3);
}

.restrictionWarning__ca0 {
  color: var(--ring-error-color);
}

.restrictionMsg__ba4 {
  margin-left: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ide-provisioner/application-settings/app-build-settings/build-settings-preview/settings-preview.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 3);\n}\n\n.restrictionWarning {\n  color: var(--ring-error-color);\n}\n\n.restrictionMsg {\n  margin-left: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__ac3`,
	"restrictionWarning": `restrictionWarning__ca0`,
	"restrictionMsg": `restrictionMsg__ba4`
};
export default ___CSS_LOADER_EXPORT___;
