import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from '@jetbrains/ring-ui/components/select/select';
import { getArchName } from '@modules/ide-provisioner/application-settings/common/name-formatter/get-arch-name';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { Required } from '@common/form/important-asterisk';
import styles from './selector.css';
export function ArchitectureSelector(props) {
    const { architectureList, onArchitectureSelect, selectedArchitecture } = props;
    const archItems = architectureList.map(arch => createArchSelectItem(arch));
    const handleSelectArch = (selected) => {
        if (selected) {
            onArchitectureSelect(selected.buildArchitecture);
        }
    };
    return (_jsxs("div", { ...assignTestId('architecture-select-wrapper'), children: [_jsx("p", { ...assignTestId('architecture-select-label'), className: styles.label, children: Required('Architecture') }), _jsx(Select, { ...assignTestId('architecture-select'), className: styles.selector, onSelect: handleSelectArch, data: archItems, selected: selectedArchitecture ? createArchSelectItem(selectedArchitecture) : undefined, label: "Choose architecture" })] }));
}
function createArchSelectItem(arch) {
    return {
        buildArchitecture: arch,
        key: arch,
        label: getArchName(arch)
    };
}
