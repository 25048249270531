import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { Required } from '@common/form/important-asterisk';
import styles from './selector.css';
export function BuildNumberSelector(props) {
    const { selectedBuildNumber, onBuildNumberSelect, buildNumbers } = props;
    const buildNumbersItems = useMemo(() => buildNumbers.map(buildNumber => createBuildNumberItem(buildNumber)), [buildNumbers]);
    const handleSelectBuildNumber = useCallback((selected) => {
        if (selected) {
            onBuildNumberSelect(selected.buildNumber);
        }
    }, [onBuildNumberSelect]);
    return (_jsxs("div", { ...assignTestId('build-number-select-wrapper'), children: [_jsx("p", { ...assignTestId('build-number-select-label'), className: styles.label, children: Required('Build') }), _jsx(Select, { ...assignTestId('build-number-select'), className: styles.selector, onSelect: handleSelectBuildNumber, data: buildNumbersItems, selected: selectedBuildNumber ? createBuildNumberItem(selectedBuildNumber) : undefined, label: "Choose build" })] }));
}
function createBuildNumberItem(buildNumber) {
    return {
        buildNumber,
        key: buildNumber,
        label: buildNumber
    };
}
