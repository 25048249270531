import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import NavigationLink from '@common/navigation/navigation-link';
import { TrialOngoingBadge } from './badges/trial-ongoing-badge';
import { TrialEndedBadge } from './badges/trial-ended-badge';
import { BaseConstituentWidget } from './base-constituent-widget';
import { EnabledBadge } from './badges/enabled-badge';
export function ConstituentEnabledWidget(props) {
    const { constituentLicense, licenseType, ...rest } = props;
    const badges = useMemo(() => {
        return (_jsxs(_Fragment, { children: [constituentLicense.trial.endDate && (_jsxs(_Fragment, { children: [constituentLicense.trial.state === 'ACTIVE' && licenseType === 'POSTPAID' && (_jsx(TrialOngoingBadge, { endDate: constituentLicense.trial.endDate, tooltipContent: "When the trial ends, the product will switch to a paid subscription model. Current users will retain access to the product features." })), constituentLicense.trial.state === 'ACTIVE' && licenseType === 'PREPAID' && (_jsx(TrialOngoingBadge, { endDate: constituentLicense.trial.endDate, tooltipContent: "When the trial ends, the product will be disabled. Please reach out to the IDE Services sales team to continue using the product." }))] })), constituentLicense.trial.state === 'ENDED' && licenseType === 'POSTPAID' && (_jsx(TrialEndedBadge, { tooltipContent: "The trial has ended. The product is now switched to a paid subscription model." })), _jsx(EnabledBadge, {})] }));
    }, [constituentLicense, licenseType]);
    return (_jsx(BaseConstituentWidget, { topRightContent: badges, controls: _jsx(NavigationLink, { href: "configuration/license-activation", children: "Settings" }), ...rest }));
}
