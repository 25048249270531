import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InlineLoader from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import Text from '@jetbrains/ring-ui/components/text/text';
import { isNil } from 'lodash';
import { makePluginDownloadPath } from '@modules/plugins/plugins-paths';
import { formatNumberShortening } from '@common/dates/format';
import NavigationLink from '@common/navigation/navigation-link';
import { useDebounce } from '@common/hooks/use-debounce-component-value';
import { usePluginDownloads } from '@common/queries/plugins';
import styles from './plugin-downloads-info.css';
export default function PluginDownloads({ pluginId, range }) {
    const dPluginId = useDebounce(pluginId, 500);
    const { data, isFetching } = usePluginDownloads(dPluginId, range);
    const total = formatNumberShortening(countTotalDownloads(data));
    const encodedPluginId = encodeURIComponent(pluginId);
    return (_jsxs("div", { children: [isFetching && _jsx(InlineLoader, {}), !isFetching && (_jsxs("div", { className: styles.downloadsContainer, children: [_jsxs(Text, { children: [total, " downloads"] }), _jsx(NavigationLink, { href: makePluginDownloadPath(encodedPluginId), className: styles.statsLink, children: _jsx(Text, { info: true, size: "s", children: "Show statistic" }) })] }))] }));
}
export function countTotalDownloads(data) {
    return isNil(data) ? 0 : data.entries.reduce((x, y) => x + y.downloads, 0);
}
