// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error__e46 {
  margin-top: calc(var(--ring-unit) / 2);

  display: flex;
  align-items: center;

  color: var(--ring-error-color);
}

.errorIcon__b29 + .errorMsg__d01 {
  margin-left: calc(var(--ring-unit) / 2);
}

.linkWrapper__b90 {
  margin-top: calc(var(--ring-unit) / 2);
}
`, "",{"version":3,"sources":["webpack://./src/modules/code-with-me/code-with-me-constituent/code-with-me-state-block/code-with-me-misconfigured/code-with-me-misconfigured.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;;EAEtC,aAAa;EACb,mBAAmB;;EAEnB,8BAA8B;AAChC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".error {\n  margin-top: calc(var(--ring-unit) / 2);\n\n  display: flex;\n  align-items: center;\n\n  color: var(--ring-error-color);\n}\n\n.errorIcon + .errorMsg {\n  margin-left: calc(var(--ring-unit) / 2);\n}\n\n.linkWrapper {\n  margin-top: calc(var(--ring-unit) / 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `error__e46`,
	"errorIcon": `errorIcon__b29`,
	"errorMsg": `errorMsg__d01`,
	"linkWrapper": `linkWrapper__b90`
};
export default ___CSS_LOADER_EXPORT___;
