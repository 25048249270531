// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listItem__b83 {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  line-height: var(--ring-line-height-lower);
  padding: 0 calc(var(--ring-unit) * 2) 2px calc(var(--ring-unit) * 2);
}

.listTitle__e5e {
  margin-left: calc(2 * var(--ring-unit));
}

.listButton__f7f {
  line-height: var(--ring-line-height);
  padding-right: 2px;
}

.listButtonIcon__b50 {
  vertical-align: text-bottom;
}

.listButtonIcon__b50 svg {
  color: var(--ring-secondary-color);
}

.listButtonIcon__b50:hover svg {
  color: var(--ring-link-hover-color);
}

.listContainer__fff {
  margin-right: calc(var(--ring-unit) * -4);
  margin-left: calc(var(--ring-unit) * -4);
}

.loader__d1f {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  margin: calc(2 * var(--ring-unit)) 0;
}

.dialogContainer__ab9 {
  width: calc(var(--ring-unit) * 58);
  height: 85vh;
}

.userInfo__a42 {
  display: flex;
  flex-direction: column;
}

.separator__d00 {
  width: 100%;
  border-bottom: 1px solid var(--ring-line-color);
}

.textContainer__fa6 {
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);
  width: fit-content;
}

.warning__fa6 {
  color: var(--ring-warning-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-users/assign-dialog/assign-user-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,0CAA0C;EAC1C,oEAAoE;AACtE;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,yCAAyC;EACzC,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,kCAAkC;EAClC,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,+CAA+C;AACjD;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".listItem {\n  display: flex;\n  justify-content: space-between;\n  align-items: baseline;\n  line-height: var(--ring-line-height-lower);\n  padding: 0 calc(var(--ring-unit) * 2) 2px calc(var(--ring-unit) * 2);\n}\n\n.listTitle {\n  margin-left: calc(2 * var(--ring-unit));\n}\n\n.listButton {\n  line-height: var(--ring-line-height);\n  padding-right: 2px;\n}\n\n.listButtonIcon {\n  vertical-align: text-bottom;\n}\n\n.listButtonIcon svg {\n  color: var(--ring-secondary-color);\n}\n\n.listButtonIcon:hover svg {\n  color: var(--ring-link-hover-color);\n}\n\n.listContainer {\n  margin-right: calc(var(--ring-unit) * -4);\n  margin-left: calc(var(--ring-unit) * -4);\n}\n\n.loader {\n  display: flex;\n  flex-direction: column;\n  row-gap: 6px;\n  margin: calc(2 * var(--ring-unit)) 0;\n}\n\n.dialogContainer {\n  width: calc(var(--ring-unit) * 58);\n  height: 85vh;\n}\n\n.userInfo {\n  display: flex;\n  flex-direction: column;\n}\n\n.separator {\n  width: 100%;\n  border-bottom: 1px solid var(--ring-line-color);\n}\n\n.textContainer {\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n  width: fit-content;\n}\n\n.warning {\n  color: var(--ring-warning-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": `listItem__b83`,
	"listTitle": `listTitle__e5e`,
	"listButton": `listButton__f7f`,
	"listButtonIcon": `listButtonIcon__b50`,
	"listContainer": `listContainer__fff`,
	"loader": `loader__d1f`,
	"dialogContainer": `dialogContainer__ab9`,
	"userInfo": `userInfo__a42`,
	"separator": `separator__d00`,
	"textContainer": `textContainer__fa6`,
	"warning": `warning__fa6`
};
export default ___CSS_LOADER_EXPORT___;
