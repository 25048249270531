import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLoaderData } from 'react-router-dom';
import { PageTitle } from '@common/page-layout/page-title';
import { PluginAuditLogsBody } from './plugin-audit-logs-body';
export function PluginAuditLogsPage() {
    const plugin = useLoaderData();
    return (_jsxs("div", { children: [_jsx(PageTitle, { title: "Change history", breadcrumbs: [
                    { title: 'Plugins', href: '/plugins' },
                    { title: `${plugin.name}`, href: `/plugins/${plugin.id}` }
                ] }), _jsx(PluginAuditLogsBody, { plugin: plugin })] }));
}
