// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[8].use[1]!../../../../../node_modules/@jetbrains/ring-ui/components/global/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerLink__b78 {
  color: unset;
  line-height: 22px;
  position: relative;

  transition: text-shadow var(--ring-fast-ease);

  &:hover {
    color: unset;
    text-shadow: 0.5px 0 0 currentColor;
  }

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    bottom: -16px;
    height: var(--header-size);
  }
}

.headerLinkActive__f99 {
  text-shadow: 0.5px 0 0 currentColor;
}
`, "",{"version":3,"sources":["webpack://./src/common/app-layout/app-header/header-link/header-link.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;;EAElB,6CAA6C;;EAE7C;IACE,YAAY;IACZ,mCAAmC;EACrC;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,aAAa;IACb,0BAA0B;EAC5B;AACF;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":["@value unit from \"~@jetbrains/ring-ui/components/global/global.css\";\n\n.headerLink {\n  color: unset;\n  line-height: 22px;\n  position: relative;\n\n  transition: text-shadow var(--ring-fast-ease);\n\n  &:hover {\n    color: unset;\n    text-shadow: 0.5px 0 0 currentColor;\n  }\n\n  &:before {\n    position: absolute;\n    content: '';\n    width: 100%;\n    bottom: -16px;\n    height: var(--header-size);\n  }\n}\n\n.headerLinkActive {\n  text-shadow: 0.5px 0 0 currentColor;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["unit"]}`,
	"headerLink": `headerLink__b78`,
	"headerLinkActive": `headerLinkActive__f99`
};
export default ___CSS_LOADER_EXPORT___;
