import { jsx as _jsx } from "react/jsx-runtime";
import { useIdeProvisionerConstituent } from '@common/queries/ide-provisioner';
import { useOpenModal } from '@common/modals/hooks';
import { IdePEnableDialog } from '../../common/dialogs/idep-settings-dialog/idep-enable-dialog';
import { IdePChangeSettingsDialog } from '../../common/dialogs/idep-settings-dialog/idep-change-settings-dialog';
import { IdePDisableDialog } from '../../common/dialogs/idep-disable-dialog/idep-disable-dialog';
import { IdeProvisionerEnabled } from './ide-provisioner-enabled/ide-provisioner-enabled';
import { IdeProvisionerDisabled } from './ide-provisioner-disabled/ide-provisioner-disabled';
export function IdeProvisionerConstituent(props) {
    const { className, licenseType, constituentLicense } = props;
    const { data } = useIdeProvisionerConstituent();
    const limitLockType = getLimitLockType(licenseType, constituentLicense);
    const openDisableModal = useOpenModal(IdePDisableDialog, {});
    const openEnableModal = useOpenModal(IdePEnableDialog, {
        limitLockType,
        initialLimitUsage: constituentLicense.trial.state === 'ACTIVE' ? constituentLicense.trial.maxUsage : undefined
    });
    const openChangeSettingsModal = useOpenModal(IdePChangeSettingsDialog, {
        initialLimitUsage: (data === null || data === void 0 ? void 0 : data.status) === 'enabled' ? data.data.assignedUsersLimit : undefined
    });
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled') {
        return _jsx(IdeProvisionerDisabled, { className: className, onEnabledClick: openEnableModal });
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'enabled') {
        return (_jsx(IdeProvisionerEnabled, { onDisableClick: openDisableModal, onEditClick: openChangeSettingsModal, allowEditing: constituentLicense.trial.state !== 'ACTIVE' && licenseType === 'POSTPAID', data: data.data, className: className, licenseType: licenseType, license: constituentLicense }));
    }
    return null;
}
function getLimitLockType(licenseType, constituentLicense) {
    if (constituentLicense.trial.state === 'ACTIVE') {
        return 'readonly';
    }
    if (licenseType === 'PREPAID') {
        return 'hidden';
    }
    return undefined;
}
