import {sliceToPage} from '../../api/paging-utils';

const SET_USAGE_DATA_HISTORY = 'SET_USAGE_DATA_HISTORY';
const SET_USAGE_DATA_HISTORY_IS_FETCHING = 'SET_USAGE_DATA_HISTORY_IS_FETCHING';
const SET_USAGE_DATA_HISTORY_FETCH_ERROR = 'SET_USAGE_DATA_HISTORY_FETCH_ERROR';
const SET_USAGE_DATA_HISTORY_REFRESH_ERROR = 'SET_USAGE_DATA_HISTORY_REFRESH_ERROR';
const SET_USAGE_DATA_HISTORY_PAGE_PARAMS = 'SET_USAGE_DATA_HISTORY_PAGE_PARAMS';
const SET_USAGE_DATA_HISTORY_SORT_PARAMS = 'SET_USAGE_DATA_HISTORY_SORT_PARAMS';

const defaultState = {
  items: [],
  isFetching: true,
  isFetchError: false,
  isRefreshError: false,
  sortKey: 'batchName',
  currentPageIndex: 1,
  currentPageData: [],
  sortOrder: false,
  pageSize: 25
};

export default function usageUsageDataHistoryReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_USAGE_DATA_HISTORY:
      const items = sortRecords(action.payload, state.sortKey, state.sortOrder);
      return {
        ...state,
        items,
        isFetching: false,
        isFetchError: false,
        currentPageData: sliceToPage(items, state.pageSize, state.currentPageIndex)
      };
    case SET_USAGE_DATA_HISTORY_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
        isFetchError: false
      };
    case SET_USAGE_DATA_HISTORY_FETCH_ERROR:
      return {
        ...state,
        isFetchError: action.payload,
        isFetching: false
      };
    case SET_USAGE_DATA_HISTORY_REFRESH_ERROR:
      return {
        ...state,
        isRefreshError: action.payload,
        isFetching: false
      };
    case SET_USAGE_DATA_HISTORY_PAGE_PARAMS:
      return {
        ...state,
        currentPageIndex: action.payload.nextPageIndex,
        currentPageData: sliceToPage(
          state.items,
          action.payload.pageSize,
          action.payload.nextPageIndex
        ),
        pageSize: action.payload.pageSize
      };
    case SET_USAGE_DATA_HISTORY_SORT_PARAMS:
      const sorted = sortRecords(state.items, action.payload.column.id, action.payload.order);
      return {
        ...state,
        items: sorted,
        sortKey: action.payload.column.id,
        sortOrder: action.payload.order,
        currentPageData: sliceToPage(sorted, state.pageSize, state.currentPageIndex)
      };
    default:
      return state;
  }
}

export function setUsageDataHistoryInfo(data) {
  return {type: SET_USAGE_DATA_HISTORY, payload: data};
}

export function setUsageDataHistoryIsFetching(isFetching) {
  return {type: SET_USAGE_DATA_HISTORY_IS_FETCHING, payload: isFetching};
}

export function setUsageDataHistoryFetchError(error) {
  return {type: SET_USAGE_DATA_HISTORY_FETCH_ERROR, payload: error};
}

export function setUsageDataHistoryRefreshError(error) {
  return {type: SET_USAGE_DATA_HISTORY_REFRESH_ERROR, payload: error};
}

export function setPagingParameters(pageParams) {
  return {type: SET_USAGE_DATA_HISTORY_PAGE_PARAMS, payload: pageParams};
}

export function setSortingParameters(params) {
  return {type: SET_USAGE_DATA_HISTORY_SORT_PARAMS, payload: params};
}

const sortRecords = (records, sortKey, order) => {
  const orderModifier = order ? 1 : -1;
  const copy = [...records];

  if (sortKey === 'batchName') {
    copy.sort((a, b) => {
      // Custom sorting of batch names. Assumption is that it consist of ints joined by '_'
      const parse = element =>
        element
          .split('_')
          .map(e => parseInt(e))
          .filter(e => !isNaN(e));
      const fragmentsA = parse(a[sortKey]);
      const fragmentsB = parse(b[sortKey]);

      for (let i = 0; i < fragmentsA.length; i++) {
        const comparisonKey = (fragmentsA[i] - fragmentsB[i]) * orderModifier;
        if (comparisonKey !== 0 || i === fragmentsA.length - 1) {
          return comparisonKey;
        }
      }
    });
  } else {
    copy.sort((a, b) => (a[sortKey] - b[sortKey]) * orderModifier);
  }

  return copy;
};
