import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useNavigate } from 'react-router-dom';
import { WidgetHighlightingColor } from '@common/widgets/widget/widget';
import NavigationLink from '@common/navigation/navigation-link';
import { useAuthentication } from '@common/queries/authentication';
import { BaseConstituentWidget } from './base-constituent-widget';
import { EnabledBadge } from './badges/enabled-badge';
import { ConfigurationBadge } from './badges/configuration-badge';
import { TrialNotificationWrapper } from './trial-notification-wrapper/trial-notification-wrapper';
import { PopupSteppedMessage } from './badges/popup-stepped-message/popup-stepped-message';
export function ConstituentNotConfiguredWidget(props) {
    const { constituentName, constituentLicense, children, ...rest } = props;
    const navigate = useNavigate();
    const badges = useMemo(() => {
        return (_jsxs(_Fragment, { children: [_jsx(ConfigurationBadge, { tooltipTitle: `To enable ${constituentName} for users`, tooltipContent: _jsx(EnableForUsersMessage, { constituentName: constituentName }) }), _jsx(EnabledBadge, {})] }));
    }, [constituentName]);
    return (_jsx(BaseConstituentWidget, { topRightContent: badges, highlighting: WidgetHighlightingColor.Warning, controls: _jsx(Button, { primary: true, onClick: () => navigate('/profiles'), children: "Go to profiles" }), ...rest, children: _jsx(TrialNotificationWrapper, { trial: constituentLicense.trial, children: children }) }));
}
function EnableForUsersMessage(props) {
    const { constituentName } = props;
    const { data: authentication } = useAuthentication();
    return (_jsxs(PopupSteppedMessage, { children: [(authentication === null || authentication === void 0 ? void 0 : authentication.configurationUrl) ? (_jsxs(_Fragment, { children: [_jsx(NavigationLink, { href: authentication.configurationUrl, children: "Configure your identity provider" }), ' ', "and synchronize users."] })) : undefined, _jsxs(_Fragment, { children: ["Enable ", constituentName, " in a", ' ', _jsx(NavigationLink, { href: "/profiles", children: "new or existing profile" }), "."] }), _jsxs(_Fragment, { children: ["Assign the configured profile to ", _jsx(NavigationLink, { href: "/users", children: "users" }), " or user groups."] })] }));
}
