import { jsx as _jsx } from "react/jsx-runtime";
import { Size } from '@jetbrains/ring-ui/components/input/input';
import { useFormContext } from 'react-hook-form';
import RegisteredInput from '@app/common/hook-form/registered-input';
import { Required } from '@app/common/form/important-asterisk';
function DescriptionField({ settingIndex }) {
    const { control } = useFormContext();
    return (_jsx(RegisteredInput, { label: Required('Description'), size: Size.FULL, name: `content.${settingIndex}.description`, autoComplete: "off", control: control }));
}
export default DescriptionField;
