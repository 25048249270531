import { useQuery } from '@tanstack/react-query';
import { evaluateAppFilters, evaluateProfileFilters, evaluateSettingFilters } from '../../api/profiles';
import { formatApiError } from '../../api/errors';
import { DEFAULT_STALE_TIME } from './query-client';
import { getQueryAlertMessages } from './formatters/alerts';
export const keys = {
    all: ['redux-profile-evaluations'],
    evaluatedFilters: (profile) => ['redux-profile-evaluations', 'filters', profile],
    evaluatedSettingFilters: (filtersToEvaluate) => [
        'redux-profile-evaluations',
        'setting-filters',
        filtersToEvaluate.applicationFilters,
        filtersToEvaluate.settingFilters
    ],
    evaluatedAppFilters: (filters) => ['redux-profile-evaluations', 'app-filters', filters]
};
export function useEvaluatedReduxProfileFilters(profile, enabled) {
    return useQuery({
        queryKey: keys.evaluatedFilters(profile),
        queryFn: () => evaluateProfileFilters(profile),
        enabled,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to evaluate profile filters'))
    });
}
export function useEvaluatedSettingFilters(applicationFilters, settingFilters) {
    const filtersToEvaluate = {
        applicationFilters,
        settingFilters
    };
    return useQuery({
        queryKey: keys.evaluatedSettingFilters(filtersToEvaluate),
        queryFn: () => evaluateSettingFilters(filtersToEvaluate),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to evaluate setting filters'))
    });
}
export function useEvaluatedAppFilters(filters) {
    return useQuery({
        queryKey: keys.evaluatedAppFilters(filters),
        queryFn: () => evaluateAppFilters(filters),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to evaluate app filters'))
    });
}
