import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import { useEventsHistoryDateTableColumn } from '@modules/events-history';
import { ActorMention } from '@modules/actor';
import { useGetLicenseEvents } from '@common/queries/tbe-license';
import { BasicSelectableTable } from '@common/tables/basic-selectable-table';
import { LicenseEventDescription } from './cells/license-event-description';
import { LicenseEventChecksum } from './cells/license-event-checksum';
function dateFieldGetter(item) {
    return item.timestamp;
}
export function LicenseHistoryTable({ params, onParamChange }) {
    var _a;
    const { data, isLoading, isFetching } = useGetLicenseEvents({
        ...params
    });
    const events = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.items) !== null && _a !== void 0 ? _a : []; }, [data]);
    const total = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.total) !== null && _a !== void 0 ? _a : 0; }, [data]);
    const dateColumn = useEventsHistoryDateTableColumn(events, dateFieldGetter);
    const columns = useMemo(() => [dateColumn].concat([
        {
            id: 'licenseId',
            title: 'License'
        },
        {
            id: 'action',
            title: 'Event',
            getValue: event => _jsx(LicenseEventDescription, { event: event })
        },
        {
            id: 'checksumInfo',
            title: 'Checksum',
            getValue: event => _jsx(LicenseEventChecksum, { event: event })
        },
        {
            id: 'author',
            title: 'Author',
            getValue: ({ author }) => _jsx(ActorMention, { actor: author })
        }
    ]), [dateColumn]);
    return (_jsx(BasicSelectableTable, { data: events, selectable: false, columns: columns, loading: isLoading, renderEmpty: () => (isFetching ? 'Loading...' : 'No events found'), pagerProps: {
            total,
            currentPage: (_a = params.page) !== null && _a !== void 0 ? _a : 1,
            pageSize: DEFAULT_TABLE_PAGE_SIZE,
            onPageChange: newPage => {
                onParamChange({ ...params, page: newPage });
            },
            disablePageSizeSelector: true
        } }));
}
