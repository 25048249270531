import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { uniqBy } from 'lodash';
import ProductLogo from '@common/product-logo/product-logo';
import { SelectableListItem } from '@common/selectable-list/selectable-list-item';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { formatPlural } from '@common/format-plural';
import styles from './application-list-item.css';
export function ApplicationListItem(props) {
    const { item, onItemClick, selected } = props;
    const { availableBuilds, unavailableBuilds } = item;
    const handleClick = useCallback(() => onItemClick(item), [item, onItemClick]);
    const uniqBuilds = uniqBy(availableBuilds, 'buildNumber');
    return (_jsxs(SelectableListItem, { variant: item.available ? 'primary' : 'secondary', onClick: handleClick, selected: selected, ...assignTestId('application', { selected }), children: [_jsx(ProductLogo, { product: item.id, className: styles.productLogo }), _jsxs("div", { className: styles.appInfo, children: [_jsx("p", { ...assignTestId('application-name'), children: item.name }), _jsx("p", { className: styles.appVersion, ...assignTestId('builds-info'), children: !unavailableBuilds.length
                            ? 'All builds'
                            : formatPlural(uniqBuilds.length, {
                                zero: 'No builds available',
                                one: `${uniqBuilds.length} build available`,
                                multiple: `${uniqBuilds.length} builds available`
                            }) })] })] }));
}
