import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Icon from '@jetbrains/ring-ui/components/icon';
import { format, isToday } from 'date-fns';
import classNames from 'classnames';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './statistics-widget.css';
export function StatisticsWidgetCounter(props) {
    const { icon, label, className, count } = props;
    return (_jsxs("span", { className: classNames(styles.statisticsCounter, className), ...assignTestId('statistic-counter', props), children: [_jsx("span", { className: styles.icon, children: _jsx(Icon, { glyph: icon, size: Icon.Size.Size18 }) }), _jsx("span", { className: styles.count, ...assignTestId('count'), children: count }), _jsx("span", { className: styles.label, children: label })] }));
}
export function IntervalStatisticsWidgetCounter(props) {
    const { measure, activeInterval, ...rest } = props;
    const periodText = useMemo(() => {
        if (isToday(activeInterval.from)) {
            return 'today';
        }
        return `on ${format(activeInterval === null || activeInterval === void 0 ? void 0 : activeInterval.from, 'MMM d')}`;
    }, [activeInterval]);
    return _jsx(StatisticsWidgetCounter, { label: `${measure} ${periodText}`, ...rest });
}
