import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@jetbrains/ring-ui/components/button/button';
import { ExternalLinks } from '@app/common/constants/constants';
import { ConstituentBlock } from '@modules/constituents/constituent-block';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { AiLogo } from '../../../common/ai-logo/ai-logo';
export function AiDisabledJba(props) {
    const { className } = props;
    return (_jsxs(ConstituentBlock, { className: className, title: "AI Enterprise", state: "disabled", titleLogo: _jsx(AiLogo, {}), ...assignTestId('ai-block'), children: ["Enable AI Enterprise for your organization in your", ' ', _jsx(Button, { text: true, inline: true, href: ExternalLinks.JB_ACCOUNT, children: "JetBrains Account" })] }));
}
