import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { formatDistance } from 'date-fns';
import Icon from '@jetbrains/ring-ui/components/icon';
import timeIcon from '@jetbrains/icons/time';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { formatDateString } from '@app/common/dates/format';
import NavigationLink from '@common/navigation/navigation-link';
import { LicenseDetailItem } from './license-detail-item';
import { LicenseDetailsActionButtons } from './license-details-action-buttons';
import styles from './license-details.css';
function LicenseDetails({ licenseData }) {
    var _a, _b;
    const distanceBetween = useMemo(() => (licenseData.expirationDate ? calculateDays(licenseData.expirationDate) : ''), [licenseData.expirationDate]);
    return (_jsxs("div", { className: styles.container, children: [_jsx(Tooltip, { title: "License history", className: styles.floatingIcon, children: _jsx(NavigationLink, { href: "/configuration/license-activation/history", className: styles.icon, children: _jsx(Icon, { glyph: timeIcon }) }) }), licenseData.id && _jsx(LicenseDetailItem, { title: "License ID", value: licenseData.id }), (licenseData === null || licenseData === void 0 ? void 0 : licenseData.expirationDate) && (_jsx(LicenseDetailItem, { title: "Active until", value: `${formatDateString(licenseData === null || licenseData === void 0 ? void 0 : licenseData.expirationDate, 'd MMM yyyy')},
          ${distanceBetween} left` })), ((_a = licenseData === null || licenseData === void 0 ? void 0 : licenseData.activatedBy) === null || _a === void 0 ? void 0 : _a.name) && licenseData.activationDate && (_jsx(LicenseDetailItem, { title: "Activated by", value: `${(_b = licenseData === null || licenseData === void 0 ? void 0 : licenseData.activatedBy) === null || _b === void 0 ? void 0 : _b.name} on
            ${formatDateString(licenseData === null || licenseData === void 0 ? void 0 : licenseData.activationDate, 'd MMM yyyy')}` })), _jsx(LicenseDetailsActionButtons, { licenseData: licenseData })] }));
}
function calculateDays(date) {
    const currentDate = new Date();
    return formatDistance(new Date(date), currentDate);
}
export default LicenseDetails;
