import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import editIcon from '@jetbrains/icons/pencil';
import removeIcon from '@jetbrains/icons/trash';
import { TableActionButton } from '@app/common/tables/table-action-button';
export function AutomationTokenActions(props) {
    const { token, onEditTokenClick, onDeleteTokenClick, disabled } = props;
    const [actionPerforming, setActionPerforming] = useState(false);
    const handleEditTokenClick = () => onEditTokenClick(token);
    const handleDeleteTokenClick = useCallback(async () => {
        setActionPerforming(true);
        await onDeleteTokenClick(token);
        setActionPerforming(false);
    }, [token, onDeleteTokenClick]);
    return (_jsxs(_Fragment, { children: [_jsx(TableActionButton, { text: true, title: "Edit token", icon: editIcon, loader: actionPerforming, action: handleEditTokenClick, disabled: disabled }), _jsx(TableActionButton, { title: "Delete token", loader: actionPerforming, disabled: disabled || actionPerforming, icon: removeIcon, "data-test": "remove-plugin", action: handleDeleteTokenClick, danger: true })] }));
}
