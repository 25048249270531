import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import DropdownMenu from '@jetbrains/ring-ui/components/dropdown-menu/dropdown-menu';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import pencil from '@jetbrains/icons/pencil';
import { ProfileAutoinstalledTools } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/autoinstalled-tools/profile-autoinstalled-tools';
import { AddSettingButton } from '@app/pages/profiles/profile-details/profile-ide-provisioner/common/add-setting-button';
import { SettingBlock } from '@app/pages/profiles/profile-details/profile-ide-provisioner/common/setting-block';
import { getSettings } from '@app/common/store/profiles/selectors';
import { SettingPlaceholder } from '@app/pages/profiles/profile-details/profile-ide-provisioner/common/settings-placeholder';
import { SettingsContext } from '@app/pages/profiles/profile-details/profile-ide-provisioner/context/settings-context';
import { ProfileVMOptions } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/vm-options/profile-vm-options';
import { ProfileIdeSettings } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/ide-settings/profile-ide-settings';
import { ProfileToolboxSettings } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/profile-toolbox-settings';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
import MarketplaceSettings from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/marketplace-settings/marketplace-settings';
import { useUserPluginRestrictedMode } from '../hooks/user-restricted-modes';
import styles from './settings.css';
const VM_OPTION_LABEL = 'VM option';
const IDE_SETTING_LABEL = 'IDE setting';
const AUTOINSTALLED_TOOL_LABEL = 'Auto-installed tool';
const TOOLBOX_SETTINGS_LABEL = 'Toolbox settings';
const MARKETPLACE_SETTINGS_LABEL = 'Marketplace settings';
const DROPDOWN_OPTIONS = [
    { label: VM_OPTION_LABEL, testId: 'vm-option' },
    { label: IDE_SETTING_LABEL, testId: 'ide-settings' },
    { label: AUTOINSTALLED_TOOL_LABEL, testId: 'auto-installed-tools' },
    { label: TOOLBOX_SETTINGS_LABEL, testId: 'toolbox-settings' },
    { label: MARKETPLACE_SETTINGS_LABEL, testId: 'marketplace-settings' }
];
export function Settings() {
    const { isRestricted } = useUserPluginRestrictedMode();
    const { ide, vmOptions, autoinstalledTools, toolbox, marketplace } = useSelector(getSettings);
    const [sharedState, setSharedState] = useState();
    const sharedContext = useMemo(() => ({ formState: sharedState, setFormState: setSharedState }), [sharedState]);
    const showPlaceholder = sharedState === undefined &&
        isEmpty(vmOptions) &&
        isEmpty(toolbox) &&
        isEmpty(ide) &&
        isEmpty(autoinstalledTools) &&
        isEmpty(marketplace);
    const onSelect = ({ label }) => {
        const settingType = (() => {
            switch (label) {
                case VM_OPTION_LABEL:
                    return 'vmOptions';
                case IDE_SETTING_LABEL:
                    return 'ide';
                case TOOLBOX_SETTINGS_LABEL:
                    return 'toolboxSetting';
                case MARKETPLACE_SETTINGS_LABEL:
                    return 'marketplaceSetting';
                default:
                    return 'autoinstalledTools';
            }
        })();
        const settingId = (() => {
            var _a, _b;
            switch (label) {
                case TOOLBOX_SETTINGS_LABEL:
                    // Toolbox settings are unique in that we want to select and edit the first setting if it exists
                    // Other settings want to select nothing, causing them to create a new setting every time
                    return (_a = toolbox[0]) === null || _a === void 0 ? void 0 : _a.id;
                case MARKETPLACE_SETTINGS_LABEL:
                    // Marketplace setting is unique in that we want to select and edit the first setting if it exists
                    // Other settings want to select nothing, causing them to create a new setting every time
                    return (_b = marketplace[0]) === null || _b === void 0 ? void 0 : _b.id;
                default:
                    return undefined;
            }
        })();
        setSharedState({
            settingType,
            settingId
        });
    };
    const handleAddToolboxSettingsClick = useCallback(() => {
        var _a;
        setSharedState({ settingType: 'toolboxSetting', settingId: (_a = toolbox[0]) === null || _a === void 0 ? void 0 : _a.id });
    }, [setSharedState, toolbox]);
    // fixme:
    return (_jsx(SettingBlock, { header: isRestricted ? 'Toolbox App Settings' : 'Settings', headerActionComponent: isRestricted ? (_jsx(AddSettingButton, { onClick: handleAddToolboxSettingsClick, glyph: pencil, title: "Edit Toolbox App Settings" })) : (_jsx(DropdownMenu, { anchor: _jsx(AddSettingButton, {}), onSelect: onSelect, data: DROPDOWN_OPTIONS.map(option => ({
                label: option.label,
                key: option.label,
                ...assignTestId(option.testId)
            })), "data-test": "settings-block-dropdown", title: "Add setting" })), children: _jsx("div", { className: styles.settingsContainer, children: showPlaceholder ? (_jsx(SettingPlaceholder, { title: "No settings yet" })) : (_jsxs(SettingsContext.Provider, { value: sharedContext, children: [!isRestricted && (_jsxs(_Fragment, { children: [_jsx(ProfileVMOptions, {}), _jsx(ProfileIdeSettings, {}), _jsx(ProfileAutoinstalledTools, {}), _jsx(MarketplaceSettings, {})] })), _jsx(ProfileToolboxSettings, { headless: isRestricted })] })) }) }));
}
