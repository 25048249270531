import { jsx as _jsx } from "react/jsx-runtime";
import { ConstituentDisabledWidget } from '@modules/constituents/widget/constituent-disabled-widget';
import { useOpenModal } from '@common/modals/hooks';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { LvEnableDialog } from '../../../common/dialogs/lv-enable-dialog/lv-enable-dialog';
export function LvDisabledTrialActiveWidget(props) {
    const { licenseType, constituentLicense } = props;
    const openEnableDialog = useOpenModal(LvEnableDialog, {
        showPostpaidHint: constituentLicense.trial.state !== 'ACTIVE' && licenseType === 'POSTPAID',
        limit: constituentLicense.trial.state === 'ACTIVE' ? constituentLicense.trial.maxUsage : undefined
    });
    return (_jsx(ConstituentDisabledWidget, { ...props, ...assignTestId('widget-license-vault'), onEnableClick: openEnableDialog, children: "Your License Vault trial is ready. Enable and configure License Vault to start using its features in your organization." }));
}
