import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Navigate, useLoaderData, useNavigate } from 'react-router-dom';
import { AiSettingsForm } from '@modules/ai/ai-settings-form/ai-settings-form';
import { AiDisableDialog } from '@modules/ai/common/dialogs/ai-disable-dialog/ai-disable-dialog';
import { PageTitle } from '@common/page-layout/page-title';
import { ensureAiConstituentQueryData } from '@common/queries/ai-enterprise';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useOpenModal } from '@common/modals/hooks';
export async function AiSettingsPageResolver() {
    return ensureAiConstituentQueryData();
}
export function AiSettingsPage() {
    const navigate = useNavigate();
    const aiConstituent = useLoaderData();
    const openModal = useOpenModal(AiDisableDialog, {});
    const handleDisableAi = useCallback(() => openModal().then(success => {
        if (success) {
            navigate('/configuration/license-activation');
        }
    }), [openModal, navigate]);
    if ((aiConstituent === null || aiConstituent === void 0 ? void 0 : aiConstituent.status) !== 'enabled') {
        return _jsx(Navigate, { to: "/configuration/license-activation" });
    }
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: "AI Enterprise Settings ", breadcrumbs: [
                    { title: 'Configuration', href: '/configuration' },
                    { title: 'License and Activation', href: '/configuration/license-activation' }
                ], rightPanel: _jsx(Button, { danger: true, onClick: handleDisableAi, ...assignTestId('disable-button'), children: "Disable" }) }), _jsx(AiSettingsForm, { data: aiConstituent })] }));
}
