// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__cd2 {
}

.counter__db1 {
}

.counter__db1 + .tableWrapper__a2d {
  margin-top: calc(1.5 * var(--ring-unit));
}

.activeLicense__e10 {
  color: var(--ring-success-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/welcome-page/user-welcome-page/licences-table/licenses-table.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;AACA;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".wrapper {\n}\n\n.counter {\n}\n\n.counter + .tableWrapper {\n  margin-top: calc(1.5 * var(--ring-unit));\n}\n\n.activeLicense {\n  color: var(--ring-success-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__cd2`,
	"counter": `counter__db1`,
	"tableWrapper": `tableWrapper__a2d`,
	"activeLicense": `activeLicense__e10`
};
export default ___CSS_LOADER_EXPORT___;
