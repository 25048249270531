// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.helpIcon__d9e {
  margin-left: calc(var(--ring-unit) * 0.75);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/client-machine-configuration/server-url/server-url.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;AAC5C","sourcesContent":[".helpIcon {\n  margin-left: calc(var(--ring-unit) * 0.75);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpIcon": `helpIcon__d9e`
};
export default ___CSS_LOADER_EXPORT___;
