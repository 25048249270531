import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import AssignProfileDialogForm from './assign-profile-dialog-form';
import UserAddPopup from './user-add-popup';
import { ConfigurationFilePopup } from './configuration-file-popup';
import { InvitationLinkPopup } from './invitation-link-popup';
export var PopupType;
(function (PopupType) {
    PopupType["MANUALL"] = "Manually";
    PopupType["INVITATION_LINK"] = "By invitation link";
    PopupType["CONFIGURATION_FILE"] = "By client machine configuration file";
    PopupType["ADD_PROFILE"] = "Add Profile";
})(PopupType || (PopupType = {}));
function UsersPagePopups({ selectedUsers, setCurrentPopup, currentPopup }) {
    return (_jsxs(_Fragment, { children: [currentPopup === PopupType.MANUALL && (_jsx(UserAddPopup, { onReject: () => setCurrentPopup(undefined), onUserAdded: () => setCurrentPopup(undefined) })), currentPopup === PopupType.ADD_PROFILE && (_jsx(AssignProfileDialogForm, { selectedUsers: selectedUsers, onProfileAssigned: () => setCurrentPopup(undefined), onReject: () => setCurrentPopup(undefined) })), currentPopup === PopupType.CONFIGURATION_FILE && (_jsx(ConfigurationFilePopup, { onReject: () => setCurrentPopup(undefined) })), currentPopup === PopupType.INVITATION_LINK && (_jsx(InvitationLinkPopup, { onReject: () => setCurrentPopup(undefined) }))] }));
}
export default UsersPagePopups;
