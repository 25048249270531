// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__c91 {
  position: relative;
  --cwm-violet: 108, 88, 255;
  --cwm-violet-075: rgba(var(--cwm-violet, 0.75));
}

.gradientsWrapper__b75 {
  position: absolute;
  top: 0;
  left: calc(var(--ring-unit) * -4);

  width: calc(var(--ring-unit) * 8 + 100%);

  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.bottomGradient__cc2 {
  position: absolute;
  top: -100px;
  left: 400px;

  width: 946px;
  height: 1186px;
  transform: rotate(-153.357deg);
  border-radius: 1186px;
  opacity: 0.3;
  background: radial-gradient(
    48.24% 41.33% at 50% 50%,
    var(--cwm-violet-075) 0%,
    var(--ring-content-background-color) 100%
  );
}
`, "",{"version":3,"sources":["webpack://./src/pages/code-with-me/session-page/gradient-wrapper/background-gradients-wrapper.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,+CAA+C;AACjD;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,iCAAiC;;EAEjC,wCAAwC;;EAExC,YAAY;EACZ,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;;EAEX,YAAY;EACZ,cAAc;EACd,8BAA8B;EAC9B,qBAAqB;EACrB,YAAY;EACZ;;;;GAIC;AACH","sourcesContent":[".wrapper {\n  position: relative;\n  --cwm-violet: 108, 88, 255;\n  --cwm-violet-075: rgba(var(--cwm-violet, 0.75));\n}\n\n.gradientsWrapper {\n  position: absolute;\n  top: 0;\n  left: calc(var(--ring-unit) * -4);\n\n  width: calc(var(--ring-unit) * 8 + 100%);\n\n  height: 100%;\n  pointer-events: none;\n  overflow: hidden;\n}\n\n.bottomGradient {\n  position: absolute;\n  top: -100px;\n  left: 400px;\n\n  width: 946px;\n  height: 1186px;\n  transform: rotate(-153.357deg);\n  border-radius: 1186px;\n  opacity: 0.3;\n  background: radial-gradient(\n    48.24% 41.33% at 50% 50%,\n    var(--cwm-violet-075) 0%,\n    var(--ring-content-background-color) 100%\n  );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__c91`,
	"gradientsWrapper": `gradientsWrapper__b75`,
	"bottomGradient": `bottomGradient__cc2`
};
export default ___CSS_LOADER_EXPORT___;
