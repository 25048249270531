import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import { noop } from 'lodash';
import checkmarkIcon from '@jetbrains/icons/checkmark';
import refreshIcon from '@jetbrains/icons/update';
import classNames from 'classnames';
import { useSyncIdpUsersAndGroups } from '@common/queries/groups';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import iconLoaders from '@common/loaders/icon-loaders.css';
import styles from './idp-sync-users-button.css';
export function IDPSyncUsersButton() {
    const syncUsersAndGroups = useSyncIdpUsersAndGroups();
    const [syncIsScheduled, setSyncIsScheduled] = useState(false);
    const handleSyncClick = async () => {
        const response = (await syncUsersAndGroups.mutateAsync().catch(noop));
        setSyncIsScheduled((response === null || response === void 0 ? void 0 : response.syncType) === 'SCHEDULED');
    };
    if (syncIsScheduled) {
        return (_jsx(Button, { text: true, disabled: true, className: styles.scheduledSync, icon: checkmarkIcon, title: "Users are going to be synchronized from your identity provider soon", ...assignTestId('synchronize-button'), children: "Users sync is scheduled" }));
    }
    return (_jsx(Button, { text: true, onClick: handleSyncClick, loader: syncUsersAndGroups.isLoading, disabled: syncUsersAndGroups.isLoading, className: classNames({
            [iconLoaders.refreshIconLoader]: syncUsersAndGroups.isLoading
        }), icon: refreshIcon, ...assignTestId('synchronize-button'), children: "Synchronize users" }));
}
