import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import closeIcon from '@jetbrains/icons/close';
import { InformationBanner } from '@common/information-banner/information-banner';
import { localStorageService } from '@common/storage';
const getLocalStorageKey = (bannerKey) => `banner.${bannerKey}.hidden`;
export function CloseableInformationBanner({ bannerKey, ...rest }) {
    const [isHidden, setIsHidden] = useState(localStorageService.get(getLocalStorageKey(bannerKey)));
    const onClose = useCallback(() => {
        localStorageService.set(getLocalStorageKey(bannerKey), true);
        setIsHidden(true);
    }, [bannerKey]);
    return isHidden ? null : (_jsx(InformationBanner, { buttons: _jsx(Button, { icon: closeIcon, onClick: onClose, title: "Hide banner" }), ...rest }));
}
