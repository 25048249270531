// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__fb3 {
  display: flex;
  flex-direction: column;
  padding-top: calc(var(--ring-unit) * 1.75);
  gap: calc(var(--ring-unit) * 2.75);
  min-width: 722px;
}

.topSeparator__f33 {
  max-width: fit-content;
  width: 100%;
  border-top: 1px solid var(--ring-line-color);
}

.bottomSeparator__e90 {
  max-width: fit-content;
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--ring-line-color);
}

.availableProvidersContainer__f87 {
  flex-direction: column;
  display: flex;
  gap: var(--ring-unit);
  background-color: var(--ring-sidebar-background-color);
  padding: calc(var(--ring-unit) * 3);
  border-radius: calc(var(--ring-unit) * 2);
}
.dataCollectionNotice__a1b {
  color: var(--ring-secondary-color);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
  margin-top: calc(var(--ring-unit) / 2);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/ai-settings-form/sections/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,0CAA0C;EAC1C,kCAAkC;EAClC,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,4CAA4C;AAC9C;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,oBAAoB;EACpB,+CAA+C;AACjD;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,qBAAqB;EACrB,sDAAsD;EACtD,mCAAmC;EACnC,yCAAyC;AAC3C;AACA;EACE,kCAAkC;EAClC,wCAAwC;EACxC,2CAA2C;EAC3C,sCAAsC;AACxC","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  padding-top: calc(var(--ring-unit) * 1.75);\n  gap: calc(var(--ring-unit) * 2.75);\n  min-width: 722px;\n}\n\n.topSeparator {\n  max-width: fit-content;\n  width: 100%;\n  border-top: 1px solid var(--ring-line-color);\n}\n\n.bottomSeparator {\n  max-width: fit-content;\n  width: 100%;\n  padding-bottom: 30px;\n  border-bottom: 1px solid var(--ring-line-color);\n}\n\n.availableProvidersContainer {\n  flex-direction: column;\n  display: flex;\n  gap: var(--ring-unit);\n  background-color: var(--ring-sidebar-background-color);\n  padding: calc(var(--ring-unit) * 3);\n  border-radius: calc(var(--ring-unit) * 2);\n}\n.dataCollectionNotice {\n  color: var(--ring-secondary-color);\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n  margin-top: calc(var(--ring-unit) / 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__fb3`,
	"topSeparator": `topSeparator__f33`,
	"bottomSeparator": `bottomSeparator__e90`,
	"availableProvidersContainer": `availableProvidersContainer__f87`,
	"dataCollectionNotice": `dataCollectionNotice__a1b`
};
export default ___CSS_LOADER_EXPORT___;
