import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { SettingPlaceholder } from '@app/pages/profiles/profile-details/profile-ide-provisioner/common/settings-placeholder';
import { SettingsList } from '@app/pages/profiles/profile-details/profile-ide-provisioner/common/settings-list';
import { getToolboxSettings } from '@app/common/store/profiles/selectors';
import { useSettingsContext } from '@app/pages/profiles/profile-details/profile-ide-provisioner/context/settings-context';
import { ToolboxSettingsDialogForm } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/profiles-toolbox-settings-dialog-form';
import { filterByFocusedApp, useProfilesParams } from '@app/pages/profiles/profile-details/profile-ide-provisioner/context/profiles-params-context';
import SettingsListEntry from '@app/pages/profiles/profile-details/profile-ide-provisioner/common/settings-list-entry';
import { showFilteredSettings, showHiddenSettingsPlaceholder } from '@app/pages/profiles/profile-details/profile-ide-provisioner/functions/settings';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
export function ProfileToolboxSettings(props) {
    const { headless } = props;
    const dispatch = useDispatch();
    const toolboxSettings = useSelector(getToolboxSettings);
    const { focusedApp } = useProfilesParams();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const filteredToolboxSettings = filterByFocusedApp(toolboxSettings, focusedApp);
    const { setFormState, showForm, resetFormState, settingId } = useSettingsContext('toolboxSetting');
    const onSettingsListEntryDeleteClick = (settingId) => {
        dispatch(ProfileActions.deleteSetting({ settingType: 'toolboxSetting', settingId }));
    };
    const onSubmit = (data) => {
        dispatch(ProfileActions.updateToolboxSettings(data));
        setIsCollapsed(false);
        resetFormState();
    };
    const selectedToolboxSetting = toolboxSettings.find(({ id }) => id === settingId);
    return (_jsxs(_Fragment, { children: [_jsxs(SettingsList, { title: "toolbox settings", isCollapsed: isCollapsed, onToggleCollapsed: () => setIsCollapsed(prev => !prev), headless: headless, children: [isEmpty(toolboxSettings) && _jsx(SettingPlaceholder, { title: "No Toolbox settings yet" }), showHiddenSettingsPlaceholder(toolboxSettings, filteredToolboxSettings) && (_jsx(SettingPlaceholder, { title: toolboxSettings.length === 1
                            ? 'One Toolbox setting is hidden'
                            : `${toolboxSettings.length} Toolbox settings are hidden` })), showFilteredSettings(filteredToolboxSettings) &&
                        filteredToolboxSettings.map(entry => (_jsx(SettingsListEntry, { entry: entry, onEditClick: setFormState, onDeleteClick: onSettingsListEntryDeleteClick, settingType: "toolboxSetting" }, entry.id)))] }), showForm && (_jsx(ToolboxSettingsDialogForm, { item: selectedToolboxSetting, onReject: resetFormState, onSubmit: onSubmit }))] }));
}
