import { jsx as _jsx } from "react/jsx-runtime";
import Badge from '@jetbrains/ring-ui/components/badge/badge';
import classNames from 'classnames';
import { PopupMessage } from '@common/popup-message/popup-message';
import styles from './badges.css';
export function TrialEndedBadge(props) {
    const { tooltipContent, tooltipTitle } = props;
    const badge = _jsx(Badge, { className: classNames(styles.badge, styles.error), children: "Trial ended" });
    if (!tooltipContent) {
        return badge;
    }
    return (_jsx(PopupMessage, { title: tooltipTitle, anchor: badge, children: tooltipContent }));
}
