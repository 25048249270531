// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__bed {
  display: flex;
  flex-direction: column;

  .groupName__b2e + :nth-child(2) {
    margin-top: calc(var(--ring-unit) * 1.5);
  }

  .item__f8c + .item__f8c {
    margin-top: var(--ring-unit);
  }

  .item__f8c {
    display: flex;
    align-items: flex-start;

    padding: calc(var(--ring-unit) * 2);
    border-radius: var(--ring-border-radius);
    border: 1px solid var(--ring-line-color);

    background-color: var(--ring-sidebar-background-color);

    .mainContent__d50 {
      flex: 1 1 auto;
    }

    .additionalInfo__fa2 {
      flex: 0 0 auto;
    }

    .mainContent__d50 + .additionalInfo__fa2 {
      margin-left: auto;
    }

    .additionalInfo__fa2 + .readOnly__aa9 {
      margin-left: calc(var(--ring-unit) * 2);
    }

    .mainContent__d50 + .readOnly__aa9 {
      margin-left: auto;
    }

    .readOnlyIcon__eb8 {
      margin-left: calc(var(--ring-unit) * 0.75);
      color: var(--ring-icon-color);
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/ide-provisioner/application-settings/app-build-settings/settings-group/settings-group.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;;EAEtB;IACE,wCAAwC;EAC1C;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,aAAa;IACb,uBAAuB;;IAEvB,mCAAmC;IACnC,wCAAwC;IACxC,wCAAwC;;IAExC,sDAAsD;;IAEtD;MACE,cAAc;IAChB;;IAEA;MACE,cAAc;IAChB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,uCAAuC;IACzC;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,0CAA0C;MAC1C,6BAA6B;IAC/B;EACF;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n\n  .groupName + :nth-child(2) {\n    margin-top: calc(var(--ring-unit) * 1.5);\n  }\n\n  .item + .item {\n    margin-top: var(--ring-unit);\n  }\n\n  .item {\n    display: flex;\n    align-items: flex-start;\n\n    padding: calc(var(--ring-unit) * 2);\n    border-radius: var(--ring-border-radius);\n    border: 1px solid var(--ring-line-color);\n\n    background-color: var(--ring-sidebar-background-color);\n\n    .mainContent {\n      flex: 1 1 auto;\n    }\n\n    .additionalInfo {\n      flex: 0 0 auto;\n    }\n\n    .mainContent + .additionalInfo {\n      margin-left: auto;\n    }\n\n    .additionalInfo + .readOnly {\n      margin-left: calc(var(--ring-unit) * 2);\n    }\n\n    .mainContent + .readOnly {\n      margin-left: auto;\n    }\n\n    .readOnlyIcon {\n      margin-left: calc(var(--ring-unit) * 0.75);\n      color: var(--ring-icon-color);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__bed`,
	"groupName": `groupName__b2e`,
	"item": `item__f8c`,
	"mainContent": `mainContent__d50`,
	"additionalInfo": `additionalInfo__fa2`,
	"readOnly": `readOnly__aa9`,
	"readOnlyIcon": `readOnlyIcon__eb8`
};
export default ___CSS_LOADER_EXPORT___;
