// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pluginContainer__fce {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: calc(var(--ring-unit) * 1.5);
}

.mainContainer__faf {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 3);
}

.optionsContainer__b5c {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 1.5);
}

.hiddenButton__f6a {
  visibility: hidden;
}

.closeButton__e77 {
  top: var(--ring-unit);
  right: var(--ring-unit);
}

.addPluginButton__ea5 {
  padding: 0;
  width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/ide-plugins/disabled-plugins/plugin-field-array.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;EACvB,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB","sourcesContent":[".pluginContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.mainContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 3);\n}\n\n.optionsContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.hiddenButton {\n  visibility: hidden;\n}\n\n.closeButton {\n  top: var(--ring-unit);\n  right: var(--ring-unit);\n}\n\n.addPluginButton {\n  padding: 0;\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pluginContainer": `pluginContainer__fce`,
	"mainContainer": `mainContainer__faf`,
	"optionsContainer": `optionsContainer__b5c`,
	"hiddenButton": `hiddenButton__f6a`,
	"closeButton": `closeButton__e77`,
	"addPluginButton": `addPluginButton__ea5`
};
export default ___CSS_LOADER_EXPORT___;
