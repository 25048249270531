import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import CopiedText from '@common/clipboard/copied-text/copied-text';
import auth from '@common/auth/auth';
import PluginDownloads from './plugin-downloads';
import styles from './plugin-info.css';
export const LAST_7_DAYS = 'LAST_7_DAYS';
function PluginInfo({ plugin, downloadsEnabled = true }) {
    const areAnalyticsEnabled = downloadsEnabled && auth.getFeatures().analyticsEnabled === true;
    return (_jsxs("div", { className: styles.infoContainer, children: [_jsxs(Text, { children: ["Plugin ID: ", _jsx(CopiedText, { text: plugin.id })] }), _jsxs(Text, { children: ["Vendor: ", plugin.vendor] }), areAnalyticsEnabled && _jsx(PluginDownloads, { pluginId: plugin.id, range: LAST_7_DAYS })] }));
}
export default PluginInfo;
