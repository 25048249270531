import { isApiError } from '@api/is-api-error';
import { apiClientManager } from '@common/api';
export async function getIdePState() {
    var _a, _b;
    const serviceClient = apiClientManager.getClient();
    try {
        const res = await serviceClient.get('/api/constituents/idep');
        return { status: 'enabled', data: res.data };
    }
    catch (e) {
        if (isApiError(e)) {
            if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                return { status: 'disabled' };
            }
            if (((_b = e.response) === null || _b === void 0 ? void 0 : _b.status) === 403) {
                return { status: 'disabled_jba' };
            }
        }
        throw e;
    }
}
export async function enableIdeProvisioner(params) {
    const serviceClient = apiClientManager.getClient();
    const res = await serviceClient.post('/api/constituents/idep', params);
    return { status: 'enabled', data: res.data };
}
export async function changeIdeProvisionerSettings(params) {
    const serviceClient = apiClientManager.getClient();
    const res = await serviceClient.put('/api/constituents/idep', params);
    return { status: 'enabled', data: res.data };
}
export async function disableIdeProvisioner() {
    await apiClientManager.getClient().delete('/api/constituents/idep');
    return { status: 'disabled' };
}
