// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content__ff5 {
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);
}

.code__cae {
  border: 1px solid var(--ring-icon-disabled-color);
  border-radius: 4px;
}

.formContainer__c1f {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 1.5);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/automation-tokens/automation-token-popups/automation-token-popups.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,iDAAiD;EACjD,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n}\n\n.code {\n  border: 1px solid var(--ring-icon-disabled-color);\n  border-radius: 4px;\n}\n\n.formContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `content__ff5`,
	"code": `code__cae`,
	"formContainer": `formContainer__c1f`
};
export default ___CSS_LOADER_EXPORT___;
