// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__bd0 {
  display: flex;
  flex-direction: column;
  margin: calc(var(--ring-unit) * 2) 0;
  gap: calc(var(--ring-unit) * 2);
  min-width: 560px;
  max-width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/modules/license/license-activation/license-activation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,oCAAoC;EACpC,+BAA+B;EAC/B,gBAAgB;EAChB,sBAAsB;AACxB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  margin: calc(var(--ring-unit) * 2) 0;\n  gap: calc(var(--ring-unit) * 2);\n  min-width: 560px;\n  max-width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__bd0`
};
export default ___CSS_LOADER_EXPORT___;
