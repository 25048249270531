import { combineReducers } from '@reduxjs/toolkit';
import { ProfileReducer } from '@common/store/profiles/profile-reducer';
import usageDataBatchesReducer from './usage-data-current-batch';
import usageUsageDataHistoryReducer from './usage-data-history';
import osAnalyticsReducer from './operating-systems-analytics';
import ideAnalyticsReducer from './ide-analytics';
import pluginsAnalyticsReducer from './plugins-analytics';
import freezesAnalyticsReducer from './freezes-analytics';
export const rootReducer = combineReducers({
    usageDataCurrentBatch: usageDataBatchesReducer,
    usageDataHistory: usageUsageDataHistoryReducer,
    profileReducer: ProfileReducer,
    osAnalytics: osAnalyticsReducer,
    ideAnalytics: ideAnalyticsReducer,
    pluginsAnalytics: pluginsAnalyticsReducer,
    freezesAnalytics: freezesAnalyticsReducer
});
