import { jsx as _jsx } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import { ConstituentDisabledWidget } from '@modules/constituents/widget/constituent-disabled-widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useOpenModal } from '@common/modals/hooks';
import { CwmEnableDialog } from '../../../common/dialogs/cwm-enable-dialog/cwm-enable-dialog';
export function CwmDisabledTrialActiveWidget(props) {
    const { constituentLicense } = props;
    const openEnableDialog = useOpenModal(CwmEnableDialog, {
        inputSettings: { initialLimitUsage: constituentLicense.trial.maxUsage, readonly: true }
    });
    return (_jsx(ConstituentDisabledWidget, { ...props, ...assignTestId('widget-code-with-me'), onEnableClick: openEnableDialog, children: _jsx(Text, { children: "Your trial is ready. Enable and configure Code With Me to start using its features in your organization." }) }));
}
