import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import userOk from '@jetbrains/icons/user-ok';
import Button from '@jetbrains/ring-ui/components/button/button';
import { uniq } from 'lodash';
import Badge from '@jetbrains/ring-ui/components/badge/badge';
import classNames from 'classnames';
import { Widget, WidgetHighlightingColor, WidgetSize, WidgetVariant } from '@common/widgets/widget/widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useUsers } from '@common/queries/users';
import { StatisticsWidgetInfoStats, StatisticsWidgetInfoStatsSize } from '@common/widgets/statistics-widget/statistics-widget-info-stats';
import NavigationLink from '@common/navigation/navigation-link';
import { useIdentityProviders } from '@common/queries/authentication';
import { ExternalLinks } from '@common/constants/constants';
import { formatPlural } from '@common/format-plural';
import styles from './authentication-widget.css';
export function AuthenticationWidget(props) {
    var _a;
    const { authentication } = props;
    const { data: identityProviders, isLoading: isIdentityProvidersLoading } = useIdentityProviders();
    const { data: totalUsers, isLoading: isTotalUsersLoading } = useUsers({
        limit: 0,
        page: 1,
        isActive: true
    });
    const configurationRequired = !authentication.isConfigured;
    const isLoading = isIdentityProvidersLoading || isTotalUsersLoading;
    const defaultProvider = useMemo(() => {
        return identityProviders === null || identityProviders === void 0 ? void 0 : identityProviders.items.find(provider => provider.isDefault);
    }, [identityProviders]);
    const allowedDomains = useMemo(() => {
        if (defaultProvider) {
            return defaultProvider.allowedDomains;
        }
        return identityProviders === null || identityProviders === void 0 ? void 0 : identityProviders.items.flatMap(provider => provider.allowedDomains).filter((domain) => !!domain);
    }, [defaultProvider, identityProviders]);
    const displayDefaultProvider = defaultProvider !== undefined;
    const displayAllProviders = !!(identityProviders === null || identityProviders === void 0 ? void 0 : identityProviders.items.length) &&
        identityProviders.items.some(provider => !provider.isDefault);
    return (_jsxs(Widget, { widgetSize: WidgetSize.Wide, title: "Authentication", variant: WidgetVariant.Filled, icon: userOk, loading: isLoading, highlighting: configurationRequired ? WidgetHighlightingColor.Warning : undefined, topRightContent: configurationRequired ? (_jsx(Badge, { className: classNames(styles.setupBadge), children: "Setup required" })) : undefined, controls: _jsxs(_Fragment, { children: [authentication.configurationUrl && (_jsx(Button, { primary: configurationRequired, href: authentication.configurationUrl, children: "Configure" })), _jsx(NavigationLink, { href: ExternalLinks.JB_IDP_CONFIGURATION_URL, children: "Learn how" })] }), ...assignTestId('widget-authentication'), children: [_jsx(StatisticsWidgetInfoStats, { size: StatisticsWidgetInfoStatsSize.TwoColumns, statName: "Users with access", statValue: _jsx(NavigationLink, { href: "/users", children: (_a = totalUsers === null || totalUsers === void 0 ? void 0 : totalUsers.total) !== null && _a !== void 0 ? _a : 0 }) }), displayDefaultProvider && (_jsx(StatisticsWidgetInfoStats, { statName: "Default identity provider", statValue: defaultProvider.name, size: StatisticsWidgetInfoStatsSize.TwoColumns })), displayAllProviders && (_jsx(StatisticsWidgetInfoStats, { statName: formatPlural(identityProviders.items.length, {
                    one: 'Identity provider',
                    multiple: 'Identity providers'
                }), statValue: uniq(identityProviders.items.map(provider => provider.name)).join(', '), size: StatisticsWidgetInfoStatsSize.TwoColumns })), !!(allowedDomains === null || allowedDomains === void 0 ? void 0 : allowedDomains.length) && (_jsx(StatisticsWidgetInfoStats, { statName: "Allowed domains", statValue: allowedDomains.join(', '), size: StatisticsWidgetInfoStatsSize.TwoColumns }))] }));
}
