import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import NavigationLink from '@common/navigation/navigation-link';
import styles from './actor-mention.css';
const EVENT_LOG_AUTHOR_DATA_TEST = 'event-log-author';
export function ActorMention({ actor, ...rest }) {
    const ActorComponent = ActorComponents[actor.type];
    return (_jsx("div", { className: styles.container, children: _jsx(ActorComponent, { actor: actor, ...rest }) }));
}
const ActorComponents = {
    AUTOMATION: AutomationActor,
    USER: UserAdminActor,
    ADMIN: UserAdminActor,
    SYSTEM: SystemActor,
    OTHER: OtherActor
};
function AutomationActor({ actor, ...rest }) {
    return (_jsxs(_Fragment, { children: ["Automation token", ' ', _jsx("strong", { ...assignTestId(EVENT_LOG_AUTHOR_DATA_TEST, rest), children: actor.id }), ' ', _jsxs(Text, { info: true, children: ["(see the list of", ' ', _jsx(NavigationLink, { href: "/configuration/automation-tokens", children: "automation tokens" }), ")"] })] }));
}
function UserAdminActor({ actor, ...rest }) {
    return (_jsx(NavigationLink, { href: `/users/${actor.id}`, ...assignTestId(EVENT_LOG_AUTHOR_DATA_TEST, rest), children: actor.name }));
}
function SystemActor({ actor, ...rest }) {
    return _jsx(Text, { ...assignTestId(EVENT_LOG_AUTHOR_DATA_TEST, rest), children: "System" });
}
function OtherActor({ actor, ...rest }) {
    return _jsx(Text, { ...assignTestId(EVENT_LOG_AUTHOR_DATA_TEST, rest), children: actor.name });
}
