import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
// eslint-disable-next-line no-restricted-imports
import { formatDateTimeWithRelative } from '@app/common/util/i18n';
import styles from './licenses-table.css';
export function AllocationDateField(props) {
    const { license, className } = props;
    const { from, to } = license;
    return (_jsxs("div", { className: className, children: [_jsx("span", { children: formatDateTimeWithRelative(from) }), ' - ', _jsx("span", { className: classNames({ [styles.activeLicense]: !to }), children: to ? formatDateTimeWithRelative(to) : 'Now' })] }));
}
