// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__aa4 {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  --ring-link-color: var(--ring-action-link-color);
}

.buttonLink__bb9 {
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/app-authenticated/app-authenticated-page.css"],"names":[],"mappings":"AAAA;EACE,YAAY;;EAEZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;;EAElB,gDAAgD;AAClD;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".wrapper {\n  height: 100%;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n\n  --ring-link-color: var(--ring-action-link-color);\n}\n\n.buttonLink {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__aa4`,
	"buttonLink": `buttonLink__bb9`
};
export default ___CSS_LOADER_EXPORT___;
