import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SettingsGroup, SettingsItem } from '@modules/ide-provisioner/application-settings/app-build-settings/settings-group/settings-group';
import { Collapsible } from '@common/collapsible/collapsible';
import styles from './ide-settings-preview.css';
export function IdeSettingsPreview(props) {
    const { ideSettings } = props;
    return (_jsx(SettingsGroup, { groupName: "IDE Settings", children: ideSettings.map(ideSetting => {
            var _a;
            const jsonValue = JSON.stringify(ideSetting, null, 2);
            return (_jsxs(SettingsItem, { readOnly: isSettingForced(ideSetting), children: [(_a = ideSetting.description) !== null && _a !== void 0 ? _a : `"${ideSetting.kind}" Setting`, _jsx(Collapsible, { title: "Show details", className: styles.details, children: _jsx("pre", { className: styles.jsonValue, children: jsonValue }) })] }, jsonValue));
        }) }));
}
function isSettingForced(setting) {
    return 'forced' in setting && setting.forced;
}
