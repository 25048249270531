import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { noop } from 'lodash';
import removeIcon from '@jetbrains/icons/trash';
import linkIcon from '@jetbrains/icons/link';
import { useDeletePluginMutation } from '@common/queries/plugins';
import { TableActionButton } from '@common/tables/table-action-button';
import { useModalsController } from '@common/modals';
import { confirmPluginDeletion } from '../popups/confirmations';
import InstallPluginDialog from '../popups/install-plugin-dialog';
export function PluginsTableActions(plugin) {
    const { id } = plugin;
    const modalsController = useModalsController();
    const mutation = useDeletePluginMutation();
    const isMutating = mutation.isLoading && mutation.variables === id;
    const handleCopyInstallationLink = async () => {
        const installPluginDialog = modalsController.openModal(InstallPluginDialog, {
            plugin,
            onClose() {
                installPluginDialog.closeModal();
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(TableActionButton, { text: true, title: "Copy plugin installation link", icon: linkIcon, action: handleCopyInstallationLink }), _jsx(TableActionButton, { title: "Remove plugin", loader: isMutating, disabled: isMutating, icon: removeIcon, danger: true, "data-test": "remove-plugin", action: () => confirmPluginDeletion(plugin)
                    .then(() => mutation.mutateAsync(id))
                    .catch(noop) })] }));
}
