import confirm from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
export default function confirmUserActiveStatusChange(userName, newValueOfIsActiveFlag = false) {
    const confirmationTexts = newValueOfIsActiveFlag
        ? {
            text: `Activate ${userName}?`,
            description: 'After activation, the user will start being managed by JetBrains IDE Services',
            confirmLabel: 'Activate'
        }
        : {
            text: `Deactivate ${userName}?`,
            description: 'After deactivation, the user will no longer be managed by JetBrains IDE Services',
            confirmLabel: 'Deactivate'
        };
    return confirm({
        ...confirmationTexts,
        rejectLabel: 'Cancel',
        cancelIsDefault: true
    });
}
