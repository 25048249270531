import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import classNames from 'classnames';
import ChannelField from '@app/pages/profiles/profile-details/profile-ide-provisioner/ide-plugins/new-auto-installed-plugins/fields/channel-field';
import { useDialogCancelConfirmation } from '@app/common/hooks/use-dialog-cancel-confirmation';
import TypeField from '@app/pages/profiles/profile-details/profile-ide-provisioner/ide-plugins/new-auto-installed-plugins/fields/type-field';
import { tSingleAutoInstalledPluginSchema } from '../../../schemas/auto-installed-plugins-schema';
import DialogHeader from '../../../common/dialog-header';
import { useUserPluginRestrictedMode } from '../../../hooks/user-restricted-modes';
import VersionField from './version-field';
import SourceField from './source-field';
import PluginIdField from './plugin-id-field';
import ForcedField from './forced-field';
import DescriptionField from './description-field';
import TypeDescription from './type-description';
import styles from './content-field.css';
export default function NewPluginDialog({ initialValue, onSave, onCancel }) {
    const [isSmartMode, setIsSmartMode] = useState(true);
    const { isRestricted, restrictedPlugins } = useUserPluginRestrictedMode();
    const form = useForm({
        mode: 'onChange',
        defaultValues: initialValue,
        resolver: zodResolver(tSingleAutoInstalledPluginSchema(isRestricted, restrictedPlugins))
    });
    const doSave = form.handleSubmit(value => onSave(value));
    const { onEscPress, cancelButtonRef } = useDialogCancelConfirmation(onCancel, form.formState.isDirty);
    const watchType = form.watch('type');
    return (_jsxs(Dialog, { show: true, onEscPress: onEscPress, label: "Add Plugin", children: [_jsxs(DialogHeader, { children: ["Add Plugin", _jsx(Button, { text: true, onClick: () => setIsSmartMode(!isSmartMode), className: classNames(styles.dialogHeaderButton, isSmartMode ? styles.smartModeButton : styles.manualModeButton), children: isSmartMode ? 'Manual mode' : 'Smart mode' })] }), _jsx(Content, { scrollableWrapperClassName: styles.settingFields, children: _jsx(FormProvider, { ...form, children: _jsxs("div", { className: styles.settingFields, children: [_jsx(SourceField, {}), _jsx(TypeField, {}), _jsx(TypeDescription, {}), _jsx(PluginIdField, { isSmartMode: isSmartMode }), _jsx(DescriptionField, {}), watchType === 'version' && _jsx(VersionField, { isSmartMode: true }), watchType === 'channel' && (_jsxs(_Fragment, { children: [_jsx(ChannelField, {}), _jsx(ForcedField, {})] }))] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { onClick: doSave, primary: true, children: "Save" }), _jsx(Button, { ref: cancelButtonRef, onClick: onCancel, children: "Cancel" })] })] }));
}
