// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner__f48 {
  margin: 0 0 calc(var(--ring-unit) * 2) 0;
}

.inlineButton__e1f {
  margin: calc(-1 * var(--ring-unit)) calc(-2 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/modules/users/banners/no-created-users-banner.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,+DAA+D;AACjE","sourcesContent":[".banner {\n  margin: 0 0 calc(var(--ring-unit) * 2) 0;\n}\n\n.inlineButton {\n  margin: calc(-1 * var(--ring-unit)) calc(-2 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": `banner__f48`,
	"inlineButton": `inlineButton__e1f`
};
export default ___CSS_LOADER_EXPORT___;
