import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { isFuture } from 'date-fns';
import { RestrictionInfo } from './restriction-info';
import styles from './nested-data-list.css';
export function BuildDataItem({ item }) {
    const { id, notifications, isAutoinstalled, label, isExcluded } = item;
    const notificationsToDisplay = useMemo(() => {
        return notifications.filter(notification => isFuture(notification.dueDate) === !isExcluded);
    }, [notifications, isExcluded]);
    return (_jsx(Text, { className: styles.itemContainer, children: _jsxs("div", { className: styles.labelContainer, children: [id, _jsx(Text, { size: "s", info: true, children: label }), isExcluded && notificationsToDisplay.length === 0 && (_jsx(Text, { size: "s", className: styles.disabled, children: "Disabled" })), notificationsToDisplay.length > 0 && (_jsx(RestrictionInfo, { notifications: notificationsToDisplay })), !isExcluded && isAutoinstalled && (_jsx(Text, { size: "s", className: styles.autoinstalled, children: "Automatic installation" }))] }) }));
}
