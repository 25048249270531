import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { zodResolver } from '@hookform/resolvers/zod';
import { getNotification } from '@app/common/store/profiles/selectors';
import { useDialogCancelConfirmation } from '@app/common/hooks/use-dialog-cancel-confirmation';
import { tNotificationssSchema } from '../schemas/notifications-schema';
import { getEmptyNotificationForm } from '../schemas/ctors';
import { NotificationFields } from './notification-fields';
import NotificationFiltersField from './notification-filters-field';
import styles from './notification-dialog-form.css';
export function NotificationForm({ id, onReject, onSubmit }) {
    const notificationData = useSelector(state => getNotification(state, id));
    const form = useForm({
        defaultValues: notificationData !== null && notificationData !== void 0 ? notificationData : getEmptyNotificationForm(),
        resolver: zodResolver(tNotificationssSchema)
    });
    const { formState: { isDirty, isSubmitting }, handleSubmit } = form;
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(onReject, isDirty);
    return (_jsxs(Dialog, { label: "Rule", show: true, autoFocusFirst: true, onCloseAttempt: onCloseAttempt, contentClassName: styles.dialogContent, children: [_jsx(Header, { children: "Restrict IDE Usage" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), className: styles.formContainer, children: [_jsx(NotificationFiltersField, {}), _jsx(NotificationFields, {})] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(onSubmit), type: "submit", loader: isSubmitting, disabled: !isDirty, children: "Save" }), _jsx(Button, { ref: cancelButtonRef, onClick: onReject, children: "Cancel" })] })] }));
}
