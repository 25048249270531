import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { uniq } from 'lodash';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { BuildKey } from './build-key';
import { ArchitectureSelector, BuildNumberSelector, OsSelector } from './selectors';
import styles from './app-build-selector.css';
export function AppBuildSelector(props) {
    const { builds, selectedBuild, onBuildSelect, className } = props;
    const buildNumbers = useMemo(() => uniq(builds.map(build => build.buildNumber)), [builds]);
    const selectedBuildNumber = useMemo(() => selectedBuild.buildNumber, [selectedBuild]);
    const handleSelectBuildNumber = useCallback((buildNumber) => onBuildSelect(new BuildKey(buildNumber, selectedBuild.buildOs, selectedBuild.buildArchitecture)), [onBuildSelect, selectedBuild]);
    const osList = useMemo(() => uniq(builds.map(build => build.buildOs)), [builds]);
    const selectedOs = useMemo(() => selectedBuild.buildOs, [selectedBuild]);
    const handleSelectOs = useCallback((os) => onBuildSelect(new BuildKey(selectedBuild.buildNumber, os, selectedBuild.buildArchitecture)), [onBuildSelect, selectedBuild]);
    const architectureList = useMemo(() => uniq(builds.map(build => build.buildArchitecture)), [builds]);
    const selectedArchitecture = useMemo(() => selectedBuild.buildArchitecture, [selectedBuild]);
    const handleSelectArchitecture = useCallback((architecture) => onBuildSelect(new BuildKey(selectedBuild.buildNumber, selectedBuild.buildOs, architecture)), [onBuildSelect, selectedBuild]);
    return (_jsxs("div", { className: classNames(styles.wrapper, className), ...assignTestId('app-build-selector'), children: [_jsx(BuildNumberSelector, { buildNumbers: buildNumbers, selectedBuildNumber: selectedBuildNumber, onBuildNumberSelect: handleSelectBuildNumber }), _jsx(OsSelector, { osList: osList, selectedOs: selectedOs, onOsSelect: handleSelectOs }), _jsx(ArchitectureSelector, { architectureList: architectureList, selectedArchitecture: selectedArchitecture, onArchitectureSelect: handleSelectArchitecture })] }));
}
