// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editButton__fd4 {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  width: calc(4 * var(--ring-unit));
  height: calc(4 * var(--ring-unit));
}

.editButton__fd4 :hover {
  color: var(--ring-icon-hover-components);
}

.editIcon__fc5 {
  color: var(--ring-icon-color);
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/edit-profile-button.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAU;EACV,mBAAmB;EACnB,uBAAuB;EACvB,iCAAiC;EACjC,kCAAkC;AACpC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,6BAA6B;EAC7B,mBAAmB;AACrB","sourcesContent":[".editButton {\n  display: flex;\n  padding: 0;\n  align-items: center;\n  justify-content: center;\n  width: calc(4 * var(--ring-unit));\n  height: calc(4 * var(--ring-unit));\n}\n\n.editButton :hover {\n  color: var(--ring-icon-hover-components);\n}\n\n.editIcon {\n  color: var(--ring-icon-color);\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editButton": `editButton__fd4`,
	"editIcon": `editIcon__fc5`
};
export default ___CSS_LOADER_EXPORT___;
