import { jsx as _jsx } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import { ConstituentDisabledWidget } from '@modules/constituents/widget/constituent-disabled-widget';
import { useOpenModal } from '@common/modals/hooks';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { AiEnableDialog } from '../../../common/dialogs/ai-settings-dialog/ai-enable-dialog';
export function AiDisabledNoTrialWidget(props) {
    const { licenseType } = props;
    const openEnableAiModal = useOpenModal(AiEnableDialog, {
        limitLockType: licenseType === 'PREPAID' ? 'hidden' : undefined
    });
    return (_jsx(ConstituentDisabledWidget, { ...props, ...assignTestId('widget-ai-enterprise'), onEnableClick: openEnableAiModal, children: _jsx(Text, { children: "Empower your developers and enhance their productivity with AI Assistant. Your code will remain secure and your spending will stay completely in check." }) }));
}
