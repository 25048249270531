import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { format } from 'date-fns';
import Text from '@jetbrains/ring-ui/components/text/text';
import { BasicSelectableTable } from '@common/tables/basic-selectable-table';
import { useGetCwmEvents } from '../../../common/queries/dashboard';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../api/paging-utils';
import NavigationLink from '../../../common/navigation/navigation-link';
const currentYear = new Date().getFullYear();
function CwmSessionsBody({ params, onParamChange }) {
    var _a;
    const { data, isLoading, isFetching } = useGetCwmEvents({
        ...params
    });
    const events = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.items) !== null && _a !== void 0 ? _a : []; }, [data]);
    const total = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.total) !== null && _a !== void 0 ? _a : 0; }, [data]);
    const columns = useMemo(() => [
        {
            id: 'description',
            title: 'Event'
        },
        {
            id: 'owner',
            title: 'Author',
            getValue: ({ owner, ownerLabel }) => {
                return (owner === null || owner === void 0 ? void 0 : owner.id) ? (_jsx(NavigationLink, { href: `/users/${owner.id}`, children: owner.userName })) : (ownerLabel && _jsx(Text, { info: true, children: ownerLabel }));
            }
        },
        {
            id: 'timestamp',
            title: 'Date',
            getValue: ({ timestamp }) => {
                const date = new Date(timestamp * 1000);
                return format(date, date.getFullYear() === currentYear ? 'd MMM HH:mm' : 'd MMM yyyy HH:mm');
            },
            sortable: true,
            rightAlign: true
        },
        {
            id: 'sessionId',
            title: 'Session ID',
            sortable: true
        }
    ], []);
    return (_jsx(BasicSelectableTable, { data: events, columns: columns, selectable: false, loading: isLoading, renderEmpty: () => (isFetching ? 'Loading...' : 'No events found'), sortKey: params.sortKey, sortOrder: params.sortOrder === 'ASC', onSort: event => onParamChange({
            ...params,
            sortOrder: event.order ? 'ASC' : 'DESC',
            sortKey: event.column.id
        }), pagerProps: {
            total,
            currentPage: (_a = params.page) !== null && _a !== void 0 ? _a : 1,
            pageSize: DEFAULT_TABLE_PAGE_SIZE,
            onPageChange: newPage => {
                onParamChange({ ...params, page: newPage });
            },
            disablePageSizeSelector: true
        } }));
}
export default CwmSessionsBody;
