// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details__d72 {
  margin-top: var(--ring-unit);
}

.jsonValue__ce9 {
  font-size: var(--ring-font-size-smaller);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ide-provisioner/application-settings/app-build-settings/build-settings-preview/ide-settings-preview/ide-settings-preview.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".details {\n  margin-top: var(--ring-unit);\n}\n\n.jsonValue {\n  font-size: var(--ring-font-size-smaller);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"details": `details__d72`,
	"jsonValue": `jsonValue__ce9`
};
export default ___CSS_LOADER_EXPORT___;
