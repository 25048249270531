import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import trashIcon from '@jetbrains/icons/trash';
import confirmUserActiveStatusChange from '@modules/users/user-operations/confirm-user-active-status-change';
import idProvider from '@modules/users/id-provider/id-provider';
import { useActiveStatusUserMutation } from '@common/queries/users';
import { TableActionButton } from '@common/tables/table-action-button';
export function UsersTableActions({ id, isActive, username, version }) {
    const mutation = useActiveStatusUserMutation();
    const hasIdp = idProvider.isEnabled();
    const onDisableUser = useCallback((userId) => {
        mutation.mutate({
            id: userId,
            version,
            isActive: false
        });
    }, [mutation, version]);
    return (_jsx(TableActionButton, { title: hasIdp
            ? 'The users are managed in IDP. To deactivate user go to you Identity Provider'
            : 'Deactivate user', disabled: !isActive || hasIdp, icon: trashIcon, danger: true, action: () => confirmUserActiveStatusChange(username)
            .then(() => onDisableUser(id))
            .catch(hideConfirm) }));
}
