import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import { useTbePlugins } from '@app/common/queries/plugins';
import { useDebounce } from '@app/common/hooks/use-debounce-component-value';
import RegisteredSelect from '@app/common/hook-form/registered-select';
import RegisteredInput from '@app/common/hook-form/registered-input';
import { Required } from '@app/common/form/important-asterisk';
import { useFilteredMarketplacePlugins } from '@app/modules/plugins/marketplace-query-wrappers';
import { toPluginSelectItem } from '../../formatters';
import { useUserPluginRestrictedMode } from '../../../hooks/user-restricted-modes';
import styles from './plugin-id-field.css';
function TbePluginIdField({ disabled }) {
    var _a;
    const { isRestricted, restrictedPlugins } = useUserPluginRestrictedMode();
    const { control, watch } = useFormContext();
    const watchPluginId = watch('pluginId');
    const { data: tbePlugins, isFetching } = useTbePlugins();
    const data = useMemo(() => {
        const data = tbePlugins === null || tbePlugins === void 0 ? void 0 : tbePlugins.map((x) => toPluginSelectItem(x, false));
        if (isRestricted) {
            return data === null || data === void 0 ? void 0 : data.filter(({ key }) => restrictedPlugins.includes(key));
        }
        return data;
    }, [isRestricted, restrictedPlugins, tbePlugins]);
    const selected = (_a = data === null || data === void 0 ? void 0 : data.find((x) => x.key === watchPluginId)) !== null && _a !== void 0 ? _a : {
        key: watchPluginId,
        label: watchPluginId || 'Choose plugin'
    };
    return (_jsx(RegisteredSelect, { name: "pluginId", control: control, buttonClassName: isEmpty(watchPluginId) ? styles.placeholderButton : '', selectedLabel: Required('Plugin name'), data: data, filter: { fuzzy: true }, size: Size.L, selected: selected, loading: isFetching, disabled: disabled }));
}
function MarketplacePluginIdField({ disabled }) {
    var _a;
    const { control, watch } = useFormContext();
    const { isRestricted, restrictedPlugins } = useUserPluginRestrictedMode();
    const watchPluginId = watch('pluginId');
    const [filter, setFilter] = useState('');
    const debouncedFilter = useDebounce(filter, 500);
    const { data: plugins, isFetching } = useFilteredMarketplacePlugins(debouncedFilter, true);
    const pluginsWithDescription = useMemo(() => {
        var _a;
        const data = (_a = plugins === null || plugins === void 0 ? void 0 : plugins.map(x => toPluginSelectItem(x))) !== null && _a !== void 0 ? _a : [];
        if (isRestricted) {
            return data === null || data === void 0 ? void 0 : data.filter(({ key }) => restrictedPlugins.includes(key));
        }
        return data;
    }, [isRestricted, plugins, restrictedPlugins]);
    const selected = (_a = pluginsWithDescription.find(x => x.key === watchPluginId)) !== null && _a !== void 0 ? _a : {
        key: watchPluginId,
        label: watchPluginId || 'Choose plugin'
    };
    return (_jsx(RegisteredSelect, { name: "pluginId", control: control, buttonClassName: isEmpty(watchPluginId) ? styles.placeholderButton : '', selectedLabel: Required('Plugin name'), data: pluginsWithDescription, filter: true, onFilter: query => setFilter(query), loading: isFetching, size: Size.L, selected: selected, disabled: disabled }));
}
function RawPluginIdField() {
    const { watch, control } = useFormContext();
    const watchSource = watch('source');
    const disabled = isEmpty(watchSource);
    return (_jsx(RegisteredInput, { name: "pluginId", control: control, label: Required('Plugin ID'), size: Size.L, disabled: disabled, placeholder: "Enter plugin ID", autoComplete: "off" }));
}
function PluginIdField({ isSmartMode }) {
    const { watch } = useFormContext();
    const watchSource = watch('source');
    const disabled = isEmpty(watchSource);
    if (!isSmartMode) {
        return _jsx(RawPluginIdField, {});
    }
    if (watchSource === 'TBE') {
        return _jsx(TbePluginIdField, { disabled: disabled });
    }
    return _jsx(MarketplacePluginIdField, { disabled: disabled });
}
export default PluginIdField;
