import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Outlet, useNavigation } from 'react-router-dom';
import { Container } from '@webteam/layout';
import classNames from 'classnames';
import auth from '@app/common/auth/auth';
// eslint-disable-next-line import/no-restricted-paths
import { ConstituentExceedNotificationController } from '@app/modules/constituents/constituent-exceed-notification-controller/constituent-exceed-notification-controller';
import { ModalsRenderer } from '@app/common/modals';
import { licenseActivationService } from '@common/licensing/license-activation-service';
import { AppHeader } from './app-header/app-header';
import { LayoutContext } from './context';
import { useMountingPoint } from './hooks';
import styles from './app-layout.css';
export function AppLayout(props) {
    const { showLoader, showContent } = props;
    const navigation = useNavigation();
    const isAuthorized = auth.isAuthenticated();
    const [sidebarVisible, sidebarRef, sidebarMountingPointRecord] = useMountingPoint('sidebar');
    const [footerVisible, footerRef, footerMountingPointRecord] = useMountingPoint('footer');
    const layoutContextValue = useMemo(() => ({ ...sidebarMountingPointRecord, ...footerMountingPointRecord }), [sidebarMountingPointRecord, footerMountingPointRecord]);
    return (_jsxs(LayoutContext.Provider, { value: layoutContextValue, children: [_jsxs("div", { className: classNames(styles.app, { [styles.authorized]: isAuthorized }), children: [_jsx(AppHeader, { loading: showLoader || navigation.state === 'loading' }), _jsxs("div", { className: styles.scrollContainer, "data-test": "app", children: [_jsxs("div", { className: styles.centerWrapper, children: [sidebarVisible && _jsx("aside", { ref: sidebarRef, className: styles.sidebar }), _jsx("main", { className: styles.content, children: showContent && _jsx(Outlet, {}) })] }), footerVisible && (_jsx(Container, { className: styles.footerContainer, children: _jsx("footer", { ref: footerRef, className: styles.footer }) }))] })] }), licenseActivationService.hasLicense() && auth.hasRoles(['ADMIN']) && (_jsx(ConstituentExceedNotificationController, {})), _jsx(ModalsRenderer, {})] }));
}
