import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import trash from '@jetbrains/icons/trash';
import { SettingsList } from '@app/pages/profiles/profile-details/profile-ide-provisioner/common/settings-list';
import { getMarketplaceSettings } from '@app/common/store/profiles/selectors';
import { useSettingsContext } from '@app/pages/profiles/profile-details/profile-ide-provisioner/context/settings-context';
import { MarketplaceSettingsDialogForm } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/marketplace-settings/marketplace-settings-dialog-form';
import { SettingLayout } from '@app/pages/profiles/profile-details/profile-ide-provisioner/common/setting-layout';
import ProductLogo from '@common/product-logo/product-logo';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
import styles from './marketplace-settings.css';
export default function MarketplaceSettings() {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const marketplaceSettings = useSelector(getMarketplaceSettings);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { showForm, resetFormState } = useSettingsContext('marketplaceSetting');
    const onDeleteClick = (settingId) => {
        dispatch(ProfileActions.deleteSetting({ settingType: 'marketplaceSetting', settingId }));
    };
    const onSubmit = (data) => {
        dispatch(ProfileActions.updateMarketplaceSettings(data));
        setIsCollapsed(false);
        resetFormState();
    };
    if (isEmpty(marketplaceSettings) && !showForm) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(SettingsList, { title: "Marketplace settings", isCollapsed: isCollapsed, onToggleCollapsed: () => setIsCollapsed(prev => !prev), children: marketplaceSettings && marketplaceSettings[0] && (_jsxs(SettingLayout, { isChanged: !marketplaceSettings[0].id, icons: [
                        {
                            glyph: trash,
                            onClick: () => onDeleteClick(marketplaceSettings[0].id || ''),
                            title: 'Remove restriction'
                        }
                    ], children: [_jsx(ProductLogo, { product: "Marketplace", className: styles.marketplaceLogo }), _jsxs("div", { className: styles.marketplaceInfo, children: [_jsx("p", { children: "JetBrains Marketplace" }), _jsx("p", { className: styles.marketplaceSettings, children: ((_b = (_a = marketplaceSettings[0].content) === null || _a === void 0 ? void 0 : _a.marketplaceAvailability) === null || _b === void 0 ? void 0 : _b.value) === false
                                        ? 'Access is disabled'
                                        : 'Access is enabled' })] })] })) }), showForm && (_jsx(MarketplaceSettingsDialogForm, { item: (_d = (_c = marketplaceSettings[0]) === null || _c === void 0 ? void 0 : _c.content) === null || _d === void 0 ? void 0 : _d.marketplaceAvailability, onReject: resetFormState, onSubmit: onSubmit }))] }));
}
