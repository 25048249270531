import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import RegisteredInput from '@app/common/hook-form/registered-input';
import { Required } from '@app/common/form/important-asterisk';
import RegisteredCheckbox from '@app/common/hook-form/registered-checkbox';
function ReplaceChildFields({ settingIndex, specsIndex }) {
    const { control } = useFormContext();
    return (_jsxs(_Fragment, { children: [_jsx(RegisteredInput, { label: Required('XML to insert'), size: Size.FULL, name: `content.${settingIndex}.specs.${specsIndex}.child-xml`, control: control, autoComplete: "off" }), _jsx(RegisteredCheckbox, { label: "Keep attributes", name: `content.${settingIndex}.specs.${specsIndex}.keep-attributes`, control: control }), _jsx(RegisteredCheckbox, { label: "Keep name", name: `content.${settingIndex}.specs.${specsIndex}.keep-name`, control: control }), _jsx(RegisteredCheckbox, { label: "Keep content", name: `content.${settingIndex}.specs.${specsIndex}.keep-content`, control: control }), _jsx(RegisteredInput, { label: "Child relative XPath", size: Size.FULL, name: `content.${settingIndex}.specs.${specsIndex}.child-rel-xpath`, control: control, autoComplete: "off" }), _jsx(RegisteredInput, { label: "Insert before relative XPath", size: Size.FULL, name: `content.${settingIndex}.specs.${specsIndex}.insert-before-rel-xpath`, control: control, autoComplete: "off" }), _jsx(RegisteredInput, { label: "Insert after relative XPath", size: Size.FULL, name: `content.${settingIndex}.specs.${specsIndex}.insert-after-rel-xpath`, control: control, autoComplete: "off" })] }));
}
export default ReplaceChildFields;
