import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { useFeedFilterProducts, useQualities } from '@app/common/queries/ide-feed-filters';
import LoadingError from '@app/common/errors/loading-error';
import ArrayEditor from './array-editor/array-editor';
import FilterEntry from './filter-entry';
import FilterForm from './filter-form';
import styles from './filters-editor.css';
function FiltersEditor({ filters, onFiltersChanged, target = 'settings' }) {
    const { id } = useParams();
    const { data: allProducts, isError: isFeedFilterProductsError } = useFeedFilterProducts(id !== null && id !== void 0 ? id : '');
    const { data: allQualities, isError: isQualitiesError } = useQualities();
    const isError = isFeedFilterProductsError || isQualitiesError;
    if (isError) {
        return _jsx(LoadingError, {});
    }
    return (_jsx(ArrayEditor, { items: filters, onChange: onFiltersChanged, allowReorder: true, addActionText: "Add filter", viewItem: ({ item }) => (_jsx(FilterEntry, { ...item, descriptionClassName: styles.draggableFix, target: target })), editItemDialog: props => (_jsx(FilterForm, { allProducts: allProducts, allQualities: allQualities, target: target, ...props })) }));
}
export default FiltersEditor;
