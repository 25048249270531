import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import { ensureProfileDetailsQueryData } from '@app/common/queries/profiles';
import { ProvisionerDisabledIDES } from '@app/pages/profiles/profile-details/profile-ide-provisioner/provisioner-disabled-ides/provisioner-disabled-ides';
import { ProvisionerDisabled } from '@app/pages/profiles/profile-details/profile-ide-provisioner/provisioner-disabled/provisioner-disabled';
import { getProfileConstituents } from '@app/common/store/profiles/selectors';
import { useIdeProvisionerConstituent } from '@app/common/queries/ide-provisioner';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
import { Applications } from './applications/applications';
import { ProfilePlugins } from './ide-plugins/profile-plugins';
import { Settings } from './settings/settings';
import { Notifications } from './notifications/notifications';
import useAutoResetFocusedApp from './hooks/use-auto-reset-focused-app';
import { ProfilesParamsProvider } from './context/profiles-params-context';
import styles from './profile-ide-provisioner-page.css';
export function profilePageResolver(profileId) {
    return ensureProfileDetailsQueryData(profileId);
}
export function ProfileIdeProvisionerPage() {
    const { idep: ideProvisionerConstituent } = useSelector(getProfileConstituents);
    const { data: ideProvisionerResponse, isLoading: isIdePELoading } = useIdeProvisionerConstituent();
    const dispatch = useDispatch();
    const activateProvisioner = useCallback(() => dispatch(ProfileActions.updateIDEPConstituent({ isEnabled: true })), [dispatch]);
    useAutoResetFocusedApp();
    if (isIdePELoading) {
        return _jsx(Loader, {});
    }
    return (_jsxs(ProfilesParamsProvider, { children: [(ideProvisionerResponse === null || ideProvisionerResponse === void 0 ? void 0 : ideProvisionerResponse.status) === 'disabled' && (_jsx(ProvisionerDisabledIDES, { className: styles.activationBlock })), (ideProvisionerResponse === null || ideProvisionerResponse === void 0 ? void 0 : ideProvisionerResponse.status) === 'enabled' && !ideProvisionerConstituent.isEnabled && (_jsx(ProvisionerDisabled, { onActivateClick: activateProvisioner, className: styles.activationBlock })), _jsxs("div", { className: styles.settingsContainer, children: [_jsx(Applications, {}), _jsx(Notifications, {}), _jsx(Settings, {}), _jsx(ProfilePlugins, {})] })] }));
}
