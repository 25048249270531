// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__ec7 {
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);
  margin-top: calc(var(--ring-unit) * 2);
}

.radioContainer__c73 {
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);
  width: fit-content;
}

.textContainer__a28 {
  padding-top: calc(var(--ring-unit) * 2);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ai/provider-selector/provider-selector.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n  margin-top: calc(var(--ring-unit) * 2);\n}\n\n.radioContainer {\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n  width: fit-content;\n}\n\n.textContainer {\n  padding-top: calc(var(--ring-unit) * 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__ec7`,
	"radioContainer": `radioContainer__c73`,
	"textContainer": `textContainer__a28`
};
export default ___CSS_LOADER_EXPORT___;
