import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { StringParam, createEnumParam, NumberParam } from 'serialize-query-params';
import { apiProfileAuditTypeOptions } from '@api/profiles';
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import { useProfileHistoryQuery } from '@app/common/queries/profiles';
import { useSyncedSearchArgs } from '@app/common/navigation/use-synced-search-args';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
import { useEventsHistoryDateTableColumn } from '@modules/events-history';
import { ActorMention } from '@modules/actor';
import { BasicSelectableTable } from '@common/tables/basic-selectable-table';
import ProfileHistoryItem from './cells/profile-history-item';
import { HistoryFilters } from './filters/history-filters';
import styles from './profile-history-page-body.css';
const tsTypeToQuerySchema = {
    page: NumberParam,
    search: StringParam,
    type: createEnumParam(apiProfileAuditTypeOptions.map(item => item.key))
};
function validateProfileHistoryFilter(parsed) {
    var _a, _b;
    return {
        page: parsed.page && parsed.page > 0 ? parsed.page : 1,
        search: (_a = parsed.search) !== null && _a !== void 0 ? _a : undefined,
        type: (_b = parsed.type) !== null && _b !== void 0 ? _b : undefined
    };
}
function dateFieldGetter({ date }) {
    return date || '';
}
function ProfileHistoryPageBody({ profileId }) {
    var _a;
    const [urlParams, setUrlParams] = useSyncedSearchArgs(tsTypeToQuerySchema, validateProfileHistoryFilter);
    const onFiltersChange = useCallback((newFilters) => {
        setUrlParams({
            ...urlParams,
            ...newFilters
        });
    }, [setUrlParams, urlParams]);
    const { data, isLoading } = useProfileHistoryQuery(profileId, urlParams);
    const events = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.items) !== null && _a !== void 0 ? _a : []; }, [data]);
    const total = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.total) !== null && _a !== void 0 ? _a : 0; }, [data]);
    const dateColumn = useEventsHistoryDateTableColumn(events, dateFieldGetter);
    const columns = useMemo(() => [dateColumn].concat([
        {
            id: 'event',
            title: 'Event',
            getValue: (item) => (_jsx(ProfileHistoryItem, { item: item, profileId: profileId }))
        },
        {
            id: 'updatedBy',
            title: 'Author',
            className: styles.authorColumn,
            getValue: (item) => item.author ? (_jsx(ActorMention, { actor: item.author, ...assignTestId('profile-history-event-author') })) : ('')
        }
    ]), [profileId, dateColumn]);
    const hasParams = Boolean(urlParams.page) || Boolean(urlParams.type) || Boolean(urlParams.search);
    return (_jsxs(_Fragment, { children: [_jsx(HistoryFilters, { filters: { ...urlParams }, onFiltersChange: onFiltersChange }), _jsx("div", { ...assignTestId('profile-history-events'), children: _jsx(BasicSelectableTable, { data: events || [], selectable: false, columns: columns, loading: isLoading, renderEmpty: () => (_jsxs(Text, { children: ["No history data available", hasParams && ' for selected criteria', "."] })), pagerProps: {
                        total,
                        currentPage: (_a = urlParams.page) !== null && _a !== void 0 ? _a : 1,
                        pageSize: DEFAULT_TABLE_PAGE_SIZE,
                        onPageChange: newPage => {
                            onFiltersChange({ page: newPage });
                        },
                        disablePageSizeSelector: true
                    } }) })] }));
}
export default ProfileHistoryPageBody;
