import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { getApplication } from '@app/common/store/profiles/selectors';
import { BuildsList } from '@modules/applications/builds-list';
import styles from './application-versions-dialog.css';
export function ApplicationVersionsDialog({ item, onReject }) {
    const applicationData = useSelector(state => getApplication(state, item));
    return (_jsxs(Dialog, { show: true, autoFocusFirst: false, onCloseAttempt: onReject, contentClassName: styles.dialogContainer, children: [_jsxs(Header, { children: [applicationData === null || applicationData === void 0 ? void 0 : applicationData.productName, " Versions"] }), _jsx(Content, { children: _jsx("div", { className: styles.mainContainer, children: _jsx(BuildsList, { item: item }) }) }), _jsx(Panel, { children: _jsx(Button, { onClick: onReject, children: "Close" }) })] }));
}
