// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentContainer__fa6 > div > div {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 1.5);
}

.copyLink__a6f {
  color: var(--ring-action-link-color);
  display: inline;
}

.dialogContainer__bec.dialogContainer__bec {
  min-width: 760px;
}

.licenseTextarea__a8f {
  min-height: calc(6 * var(--ring-line-height-taller));
  max-height: calc(12 * var(--ring-line-height-taller));
}

.successMessageContainer__e77 {
  display: flex;
  gap: var(--ring-unit);
}

.successIcon__c7d {
  color: var(--ring-icon-success-color);
}
`, "",{"version":3,"sources":["webpack://./src/modules/license/license-activation/activation-dialog/license-activation-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oDAAoD;EACpD,qDAAqD;AACvD;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".contentContainer > div > div {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.copyLink {\n  color: var(--ring-action-link-color);\n  display: inline;\n}\n\n.dialogContainer.dialogContainer {\n  min-width: 760px;\n}\n\n.licenseTextarea {\n  min-height: calc(6 * var(--ring-line-height-taller));\n  max-height: calc(12 * var(--ring-line-height-taller));\n}\n\n.successMessageContainer {\n  display: flex;\n  gap: var(--ring-unit);\n}\n\n.successIcon {\n  color: var(--ring-icon-success-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": `contentContainer__fa6`,
	"copyLink": `copyLink__a6f`,
	"dialogContainer": `dialogContainer__bec`,
	"licenseTextarea": `licenseTextarea__a8f`,
	"successMessageContainer": `successMessageContainer__e77`,
	"successIcon": `successIcon__c7d`
};
export default ___CSS_LOADER_EXPORT___;
