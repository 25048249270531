// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setupBadge__fc0 {
  display: inline-flex;
  align-items: center;

  background: var(--ring-warning-background-color);
  border-color: var(--ring-warning-background-color);
  color: var(--ring-warning-color);
}
`, "",{"version":3,"sources":["webpack://./src/modules/authentication/widgets/authentication-widget.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;;EAEnB,gDAAgD;EAChD,kDAAkD;EAClD,gCAAgC;AAClC","sourcesContent":[".setupBadge {\n  display: inline-flex;\n  align-items: center;\n\n  background: var(--ring-warning-background-color);\n  border-color: var(--ring-warning-background-color);\n  color: var(--ring-warning-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setupBadge": `setupBadge__fc0`
};
export default ___CSS_LOADER_EXPORT___;
