import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import { ActorMention } from '@modules/actor';
import { useEventsHistoryDateTableColumn } from '@modules/events-history';
import { BasicSelectableTable } from '@common/tables/basic-selectable-table';
import { withSortableColumnFlag } from '@common/tables/table-columns';
import { UserAuditLogsContent } from './cells/user-audit-logs-content';
import { sortableColumns } from './constants';
const baseColumns = [
    {
        id: 'content',
        title: 'Event',
        getValue: (data) => (_jsx(UserAuditLogsContent, { "data-test": "user-audit-log-item-content", ...data }))
    },
    {
        id: 'createdBy',
        title: 'Author',
        getValue: ({ author }) => author ? _jsx(ActorMention, { actor: author, "data-test": "user-audit-log-item-author" }) : ''
    }
];
export function UserAuditLogsTable({ data, isLoading, params, onParamsChange }) {
    var _a;
    const dateColumn = useEventsHistoryDateTableColumn(data === null || data === void 0 ? void 0 : data.items, getEventDate, 'date');
    const columns = useMemo(() => withSortableColumnFlag([dateColumn].concat(baseColumns), sortableColumns), [dateColumn]);
    const total = (_a = data === null || data === void 0 ? void 0 : data.total) !== null && _a !== void 0 ? _a : 0;
    return (_jsx(BasicSelectableTable, { data: (data === null || data === void 0 ? void 0 : data.items) || [], selectable: false, columns: columns, loading: isLoading, renderEmpty: () => _jsx(Text, { children: "No data available" }), pagerProps: {
            total,
            currentPage: params.page,
            pageSize: DEFAULT_TABLE_PAGE_SIZE,
            onPageChange: newPage => {
                onParamsChange({ ...params, page: newPage });
            },
            disablePageSizeSelector: true
        }, sortKey: params.sortKey, sortOrder: params.sortOrder === 'DESC', onSort: event => onParamsChange({
            ...params,
            sortKey: event.column.id,
            sortOrder: event.order ? 'DESC' : 'ASC',
            page: 1
        }) }));
}
function getEventDate(auditLogEntry) {
    return auditLogEntry.date;
}
