import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import HorizontalContainer from '@app/common/page-layout/horizontal-container';
import SearchFilter from '@app/common/search-filter/search-filter';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
import RoleFilter from './role-filter';
import ActiveFilter from './active-filter';
export function UsersFilters({ filters, onFiltersChange }) {
    var _a;
    const handleSearchValueChange = useCallback((newValue) => {
        if (typeof newValue === 'string') {
            onFiltersChange({
                ...filters,
                query: newValue.length > 0 ? newValue : null
            });
        }
    }, [onFiltersChange, filters]);
    return (_jsxs(HorizontalContainer, { ...assignTestId('user-filters'), children: [_jsx(SearchFilter, { value: filters.query || '', onChange: handleSearchValueChange, placeholder: "Search by name or email" }), _jsx(RoleFilter, { value: filters.role || '', onChange: value => onFiltersChange({ ...filters, role: value !== null && value !== void 0 ? value : null }) }), _jsx(ActiveFilter, { isActive: (_a = filters.isActive) !== null && _a !== void 0 ? _a : undefined, onChange: value => onFiltersChange({ ...filters, isActive: value !== null && value !== void 0 ? value : null }) })] }));
}
