import { jsx as _jsx } from "react/jsx-runtime";
import * as allLogos from '@jetbrains/logos/react';
function ProductLogo({ product, ...rest }) {
    if (product === 'AndroidStudio') {
        return _jsx(allLogos.AndroidStudioLogo, { ...rest });
    }
    if (product === 'AppCode') {
        return _jsx(allLogos.AppcodeLogo, { ...rest });
    }
    if (product === 'Aqua') {
        return _jsx(allLogos.AquaLogo, { ...rest });
    }
    if (product === 'CLion') {
        return _jsx(allLogos.ClionLogo, { ...rest });
    }
    if (product === 'datagrip') {
        return _jsx(allLogos.DatagripLogo, { ...rest });
    }
    if (product === 'Datalore') {
        return _jsx(allLogos.DataloreLogo, { ...rest });
    }
    if (product === 'DataSpell') {
        return _jsx(allLogos.DataspellLogo, { ...rest });
    }
    if (product === 'dotMemory') {
        return _jsx(allLogos.DotmemoryLogo, { ...rest });
    }
    if (product === 'dotPeek') {
        return _jsx(allLogos.DotpeekLogo, { ...rest });
    }
    if (product === 'dotTrace') {
        return _jsx(allLogos.DottraceLogo, { ...rest });
    }
    if (product === 'Fleet') {
        return _jsx(allLogos.FleetLogo, { ...rest });
    }
    if (product === 'Gateway') {
        return _jsx(allLogos.GatewayLogo, { ...rest });
    }
    if (product === 'Goland') {
        return _jsx(allLogos.GolandLogo, { ...rest });
    }
    if (product === 'IDEA-C') {
        return _jsx(allLogos.IntellijIdeaCeLogo, { ...rest });
    }
    if (product === 'IDEA-E') {
        return _jsx(allLogos.IntellijIdeaEduLogo, { ...rest });
    }
    if (product === 'IDEA-U') {
        return _jsx(allLogos.IntellijIdeaLogo, { ...rest });
    }
    if (product === 'MPS') {
        return _jsx(allLogos.MpsLogo, { ...rest });
    }
    if (product === 'PhpStorm') {
        return _jsx(allLogos.PhpstormLogo, { ...rest });
    }
    if (product === 'PyCharm-C') {
        return _jsx(allLogos.PycharmCeLogo, { ...rest });
    }
    if (product === 'PyCharm-E') {
        return _jsx(allLogos.PycharmEduLogo, { ...rest });
    }
    if (product === 'PyCharm-P') {
        return _jsx(allLogos.PycharmLogo, { ...rest });
    }
    if (product === 'ReSharper-U') {
        return _jsx(allLogos.ResharperLogo, { ...rest });
    }
    if (product === 'Rider') {
        return _jsx(allLogos.RiderLogo, { ...rest });
    }
    if (product === 'RubyMine') {
        return _jsx(allLogos.RubymineLogo, { ...rest });
    }
    if (product === 'Space') {
        return _jsx(allLogos.SpaceLogo, { ...rest });
    }
    if (product === 'Toolbox') {
        return _jsx(allLogos.ToolboxLogo, { ...rest });
    }
    if (product === 'WebStorm') {
        return _jsx(allLogos.WebstormLogo, { ...rest });
    }
    if (product === 'Writerside') {
        return _jsx(allLogos.WritersideLogo, { ...rest });
    }
    if (product === 'RustRover') {
        return _jsx(allLogos.RustroverLogo, { ...rest });
    }
    return _jsx(allLogos.JetbrainsSimpleLogo, { ...rest });
}
export default ProductLogo;
