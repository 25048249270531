import * as z from 'zod';
import { apiFilterTypeValues } from '@api/@types';
export const tFiltersSchema = z
    .array(z.object({
    products: z
        .array(z.string())
        .nullable()
        .optional()
        .transform(it => it || undefined),
    builds: z
        .array(z.string())
        .nullable()
        .optional()
        .transform(it => it || undefined),
    type: z.enum(apiFilterTypeValues),
    os: z
        .array(z.string())
        .nullable()
        .optional()
        .transform(it => it || undefined),
    arch: z
        .array(z.string())
        .nullable()
        .optional()
        .transform(it => it || undefined),
    quality: z
        .object({
        name: z.string(),
        orderValue: z.number(),
        condition: z.string()
    })
        .nullable()
        .optional()
        .transform(it => it || undefined)
}))
    .min(1, { message: 'Please add at least one filter option' });
export const tFiltersFormSchema = z.object({ filters: tFiltersSchema });
