import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Badge from '@jetbrains/ring-ui/components/badge/badge';
import classNames from 'classnames';
import { formatDistance } from 'date-fns';
import badgeCommonStyles from '@modules/constituents/widget/badges/badges.css';
import { PopupMessage } from '@common/popup-message/popup-message';
import { getTrialEndsInterval } from '../../common/trial/get-trial-ends-interval';
export function TrialOngoingBadge(props) {
    const { tooltipContent, tooltipTitle, endDate } = props;
    const trialEndInterval = getTrialEndsInterval(endDate);
    const badge = (_jsxs(Badge, { className: classNames(badgeCommonStyles.badge, {
            [badgeCommonStyles.primary]: trialEndInterval === 'moreThanOneMonth',
            [badgeCommonStyles.warning]: trialEndInterval === 'oneMonth',
            [badgeCommonStyles.error]: trialEndInterval === 'twoWeeks'
        }), children: ["Trial ends in ", formatDistance(endDate, new Date())] }));
    if (!tooltipContent) {
        return badge;
    }
    return (_jsx(PopupMessage, { title: tooltipTitle, anchor: badge, children: tooltipContent }));
}
