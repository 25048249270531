import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { formatDateString } from '@common/dates/format';
import styles from './events-history-date-table-column.css';
const currentYear = new Date().getFullYear();
function getIdToFormattedDataMap(history, getGroupingDate) {
    const simplifiedEvents = [...history].reverse().map((item, idx) => {
        const groupingDate = getGroupingDate(item);
        const formattedDate = formatDateString(groupingDate, new Date(groupingDate).getFullYear() === currentYear ? 'd MMM' : 'd MMM yyyy');
        return {
            id: item.id,
            date: formattedDate,
            idx
        };
    });
    const dateHashToIdMap = simplifiedEvents.reduce((accum, curr) => {
        const prevElementHash = makeDateHash(curr.date || '', curr.idx - 1);
        const currentElementHash = makeDateHash(curr.date, curr.idx);
        accum[currentElementHash] = `${curr.id}`;
        if (accum[prevElementHash]) {
            delete accum[prevElementHash];
        }
        return accum;
    }, {});
    return Object.keys(dateHashToIdMap).reduce((accum, dateKey) => {
        accum[dateHashToIdMap[dateKey]] = getDateFromHash(dateKey);
        return accum;
    }, {});
    function makeDateHash(date, idx) {
        return `${date}$${idx}`;
    }
    function getDateFromHash(hash) {
        return hash.split('$')[0] || '';
    }
}
export function useEventsHistoryDateTableColumn(data, dateGetter, columnId) {
    const idToFormattedDataMap = useMemo(() => data && dateGetter && getIdToFormattedDataMap(data, dateGetter), [data, dateGetter]);
    return useMemo(() => ({
        id: columnId !== null && columnId !== void 0 ? columnId : 'event-history-date',
        title: 'Date',
        className: styles.dateColumn,
        getValue: (item) => {
            if (idToFormattedDataMap && idToFormattedDataMap[item.id]) {
                return _jsx(Text, { children: idToFormattedDataMap[item.id] });
            }
            return '';
        }
    }), [columnId, idToFormattedDataMap]);
}
