import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import trashIcon from '@jetbrains/icons/trash';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import { noop } from 'lodash';
import { ensureUsersByProfileId, useDeleteProfileMutation } from '@common/queries/profiles';
import { TableActionButton } from '@common/tables/table-action-button';
import { useUnAssignUsersMutation } from '@common/queries/users';
import confirmProfileDeletion from '../../profile-details/profile-ide-provisioner/confirm-profile-deletion';
export function ProfilesTableActions({ title, id, setLoadingUsers }) {
    var _a;
    const unassignMutation = useUnAssignUsersMutation();
    const deleteMutation = useDeleteProfileMutation();
    const handleDelete = useCallback(async (profileId, profileTitle, usersList) => {
        if (!(usersList === null || usersList === void 0 ? void 0 : usersList.length)) {
            return deleteMutation.mutateAsync({ profileId, profileTitle });
        }
        await unassignMutation.mutateAsync({ profileId, usersList });
        return deleteMutation.mutateAsync({ profileId, profileTitle });
    }, [deleteMutation, unassignMutation]);
    return (_jsx(TableActionButton, { title: "Remove profile", icon: trashIcon, danger: true, loader: deleteMutation.isLoading && ((_a = deleteMutation.variables) === null || _a === void 0 ? void 0 : _a.profileId) === id, "data-test": "remove-profile", action: () => {
            setLoadingUsers(true);
            ensureUsersByProfileId(id)
                .then(users => {
                var _a;
                setLoadingUsers(false);
                confirmProfileDeletion(title, (_a = users.items.length) !== null && _a !== void 0 ? _a : 0)
                    .then(() => handleDelete(id, title, users.items))
                    .catch(hideConfirm);
            })
                .catch(noop)
                .finally(() => setLoadingUsers(false));
        } }));
}
