export function getOsName(os) {
    switch (os) {
        case 'LINUX':
            return 'Linux';
        case 'MAC_OS_X':
            return 'MacOS';
        case 'WINDOWS':
            return 'Windows';
        default:
            return os;
    }
}
