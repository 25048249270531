import { useMutation, useQuery } from '@tanstack/react-query';
import { getAiState, changeAiSettings, disableAi, enableAi, deleteProvider, getProviderInfo } from '@api/ai-enterprise';
import { formatApiError } from '@api/errors';
import queryClient from '@app/common/queries/query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from '@app/common/queries/formatters/alerts';
import auth from '@app/common/auth/auth';
import { invalidateAllQueries } from '@common/queries/functions';
import { DEFAULT_POLLING_INTERVAL } from '@common/queries/constants';
export const keys = {
    all: ['ai'],
    status: ['ai', 'ai-enabled'],
    provider: (provider) => ['ai', { provider }]
};
const aiConstituentQuery = {
    queryKey: keys.status,
    queryFn: getAiState
};
export function useAiConstituent() {
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    return useQuery({
        ...aiConstituentQuery,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch AI Enterprise status')),
        refetchInterval: state => {
            return (state === null || state === void 0 ? void 0 : state.status) === 'enabled' && state.data.activeUsersCount === 0
                ? DEFAULT_POLLING_INTERVAL
                : false;
        },
        enabled: !offlineModeEnabled
    });
}
export function useAiEnableMutation(onSuccess) {
    return useMutation({
        mutationFn: enableAi,
        onSuccess: async (newAiEnabledState) => {
            queryClient.setQueryData(keys.status, newAiEnabledState);
            await invalidateAllQueries();
            onSuccess(newAiEnabledState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to enable AI Enterprise'), () => 'AI Enterprise enabled')
    });
}
export function useAiChangeSettingsMutation(onSuccess) {
    return useMutation({
        mutationFn: changeAiSettings,
        onSuccess: async (newAiEnabledState) => {
            queryClient.setQueryData(keys.status, newAiEnabledState);
            await invalidateAllQueries();
            onSuccess(newAiEnabledState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to change AI Enterprise settings'), () => 'AI Enterprise settings changed')
    });
}
export function useAiDisableMutation(onSuccess) {
    return useMutation({
        mutationFn: disableAi,
        onSuccess: async (newAiEnabledState) => {
            queryClient.setQueryData(keys.status, newAiEnabledState);
            await invalidateAllQueries();
            onSuccess();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to disable AI Enterprise'), () => 'AI Enterprise disabled')
    });
}
export function fetchAiEnabled() {
    return queryClient.fetchQuery({
        queryKey: keys.status,
        queryFn: getAiState,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch AI Enterprise status'))
    });
}
export function ensureAiConstituentQueryData() {
    return queryClient.ensureQueryData({ ...aiConstituentQuery });
}
export function useDeleteProviderMutation(providerLabel, provider) {
    return useMutation({
        mutationFn: (replaceBy) => deleteProvider(provider, replaceBy),
        meta: getMutationAlertMessages(error => formatApiError(error, `Failed to delete ${providerLabel}`), () => `${providerLabel} deleted`)
    });
}
export function useProviderInfo(provider) {
    return useQuery({
        queryFn: () => getProviderInfo(provider),
        queryKey: keys.provider(provider),
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch provider data'))
    });
}
