// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --max-page-width: 1600px;
  --mobile-page-width: 740px;
  --small-mobile-page-width: 420px;
}
`, "",{"version":3,"sources":["webpack://./src/common/responsive/breakpoints.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,gCAAgC;AAClC","sourcesContent":[":root {\n  --max-page-width: 1600px;\n  --mobile-page-width: 740px;\n  --small-mobile-page-width: 420px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
