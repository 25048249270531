import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import _ from 'lodash';
import addIcon from '@jetbrains/icons/add';
import { Directions } from '@jetbrains/ring-ui/components/popup/popup.consts';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { EnumerableTextElements } from '@app/common/text-formattings/enumerable-text-elements';
import { TooltipDelays } from '@common/constants/constants';
import { getApiError } from '../../../api/errors';
import { TableActionButton } from '../../../common/tables/table-action-button';
import { useUpdatePluginChannelsMutation } from '../../../common/queries/plugins';
import ChannelsEditor from './channels-editor';
/**
 * Wrapper which is primarily used in the table representing plugin version as a column component.
 */
export default function ChannelsEditorWrapper({ pluginId, pluginVersion, initialChannels }) {
    const [channels, setChannels] = useState(initialChannels);
    const [isEditing, setIsEditing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const mutation = useUpdatePluginChannelsMutation();
    const handleSubmit = (newChannels) => {
        if (_.isEqual(channels, newChannels)) {
            setIsEditing(false);
            return;
        }
        mutation.mutate({
            pluginId,
            pluginVersion,
            newChannels
        }, {
            onError: error => {
                setErrorMessage(getApiError(error).errorMessage || '');
            },
            onSuccess: data => {
                setChannels((data === null || data === void 0 ? void 0 : data.channels) || []);
                setIsEditing(false);
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(EnumerableTextElements, { getValue: x => x.name, getKey: x => x.id, items: channels }), _jsx(Tooltip, { title: "Add channel", delay: TooltipDelays.NORMAL, popupProps: {
                    directions: [Directions.RIGHT_TOP]
                }, children: _jsx(TableActionButton, { text: true, inline: true, icon: addIcon, action: () => setIsEditing(true) }) }), _jsx(ChannelsEditor, { show: isEditing, loading: mutation.isLoading, initialChannels: channels, errorMessage: errorMessage, onSubmit: newChannels => handleSubmit(newChannels), onCancel: () => setIsEditing(false) })] }));
}
